import { Attributes, Validation, ValueObject } from '@singularsystems/neo-core';
import MemberBeneficiaryGroupingDataLookup from './MemberBeneficiaryGroupingDataLookup';

export default class UpdateMemberNominationCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public memberId: number = 0;

  public sourceId: string = "";

  public memberNo: string = "";

  public schemeNo: string = "";

  @Attributes.ChildObject(MemberBeneficiaryGroupingDataLookup)
  public memberNominations: MemberBeneficiaryGroupingDataLookup = new MemberBeneficiaryGroupingDataLookup();

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Update Member Nomination Command";
  }
}