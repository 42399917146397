import { Data, List, Model, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import EmployerComplaintsResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerComplaintsResultsLookup';
import EmployerComplaintsSchemeResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerComplaintsSchemeResultsLookup';

@NeoModel
export class Complaint extends ModelBase {
  public complaint: string = '';
}

@NeoModel
export default class EmployerComplaintsVM extends Views.ViewModelBase {
  public countryValueId: number | null = 1;
  public countryValue: string = '+27';
  public displayComplain: boolean = false;
  public showBlue: boolean = false;
  public cellNumber: string = "";
  public showGreyBoxMember = false;
  public showGreyBoxScheme = false;
  public complaintReview = false;
  public complaintSummary = false;
  public showSchemeTabDetails = false;
  public complaintTextMember = new Complaint();
  public complaintTextScheme = new Complaint();
  public memberId: string | null = null;
  public memberName: string = "";
  public selectedMember: EmployerComplaintsResultsLookup = new EmployerComplaintsResultsLookup;
  public schemeId: string | null = null;
  public schemeName: string = "";
  public selectedScheme: EmployerComplaintsSchemeResultsLookup = new EmployerComplaintsSchemeResultsLookup;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public employerComplaintsApiClient = AppService.get(Types.Dashboard.ApiClients.EmployerComplaintsApiClient),
  ) {

    super(taskRunner);
  }

  public async initialise() {
  }

  public async showComplain(e: React.MouseEvent) {
    e.stopPropagation();
    this.displayComplain = !this.displayComplain;
    this.showBlue = !this.showBlue;
  }

  public showComplaintReview() {
    this.complaintReview = true;
    this.complaintSummary = false;
  }

  public showComplaintSummary() {
    this.complaintReview = false;
    this.complaintSummary = true;
  }

  public showComplaintScreen() {
    this.complaintReview = false;
    this.complaintSummary = false;
  }

  public handleMemberComplaintChange = (event: any) => {
    this.complaintTextMember.complaint = event.target.value;
  };

  public handleSchemeComplaintChange = (event: any) => {
    this.complaintTextScheme.complaint = event.target.value;
  };

  public memberSelected(item: EmployerComplaintsResultsLookup | undefined) {
    if (item) {
      this.selectedMember = item;
      this.showSchemeTabDetails = false;
    }
    else {
      this.selectedMember = new EmployerComplaintsResultsLookup;
    }
  }

  public schemeSelected(item: EmployerComplaintsSchemeResultsLookup | undefined) {
    if (item) {
      this.selectedScheme = item;
      this.showSchemeTabDetails = true;
    }
    else {
      this.selectedScheme = new EmployerComplaintsSchemeResultsLookup;
    }
  }
}
