import { Attributes, EnumHelper, LookupBase } from '@singularsystems/neo-core';
import { SchemeStatusGroup } from '../../../../Common/Models/Enum/SchemeStatusGroup.enum';

export default class SchemeSearchDataLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public readonly schemeName: string = "";

  public readonly schemeNo: string = "";

  @Attributes.Integer()
  public readonly noOfMembers: number = 0;

  public readonly schemeStatus: string | null = null;

  public readonly schemeStatusGroup: SchemeStatusGroup | null = null;

  // Client only properties / methods

  public get schemeStatusName(): string {
    if (this.schemeStatus) {
      return EnumHelper.getItemMetadata(SchemeStatusGroup, this.schemeStatusGroup!).name
    }
    return "";
  }
}