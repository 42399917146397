import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../MasterDataTypes';
import Title from '../../Models/Title';

export interface ITitleQueryApiClient {

  /** 
   * Gets a list of Titles.
   * @returns A List of Title
   */
  getTitles(): AxiosPromise<Array<Model.PlainTrackedObject<Title>>>;

  /** 
   * Gets Title
   * @returns Title
   */
  getTitle(titleId: number): AxiosPromise<Model.PlainTrackedObject<Title>>;

  // Client only properties / methods
}

@injectable()
export default class TitleQueryApiClient extends Data.ApiClientBase implements ITitleQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/titles`);
  }

  public getTitles(): AxiosPromise<Array<Model.PlainTrackedObject<Title>>> {
    return this.axios.get(`${this.apiPath}/title-list`);
  }

  public getTitle(titleId: number): AxiosPromise<Model.PlainTrackedObject<Title>> {
    return this.axios.get(`${this.apiPath}/title/${titleId}`);
  }

  // Client only properties / methods
}