import React from 'react';
import InstructionsVM from './InstructionsVM';
import { observer } from 'mobx-react';
import '../../App/Styles/Components/Instructions.scss';
import sort_img from '../../App/assets/img/sorting.png';
import img_filter from '../../App/assets/img/img-slider-controls.png';
import { Neo, Views } from '@singularsystems/neo-react';
import InstructionsWebMemberTable from './Components/InstructionsWebMemberTable';
import InstructionsMobileMemberTable from './Components/InstructionsMobileMemberTable';
import InstructionsWebSchemeTable from './Components/InstructionsWebSchemeTable';
import InstructionDetailsView from './Components/InstructionDetailsView';
import InstructionStartNew from './Components/InstructionStartNew';
import ContainerFooterComponent from './Components/ContainerFooterComponent';
import { UserRoleType } from '../../Common/Models/Enum/UserRoleType.enum';

@observer
export default class InstructionsView extends Views.ViewBase<InstructionsVM> {

  constructor(props: unknown) {
    super("Instructions", InstructionsVM, props);
  }

  public render() {
    return (
      <div className='instructions-card'>
        {!this.viewModel.showInstructionDetails && !this.viewModel.showInstructionStartNew &&
          <div className='background-card mt-4'>
            <Neo.Modal title='Start a new instruction'
              bind={this.viewModel.meta.showStartInstruction}
              acceptButton={{ text: "Continue", variant: "success", onClick: () => this.viewModel.startInstruction(this.viewModel.instructionTypeValue) }}
              closeButton={{ text: "Cancel", variant: "light" }}
              showCancelButton={false}
              className='profile-modal'>
              <div className='row'>
                <div className="divider"></div>
              </div>
              <div className='modal-content'>
                <p className='modal-text'>Please provide further details in order to start a new instruction</p>
              </div>
              <div className='mt-0 instructions-card '>
                <span className='drop-down'>Instruction type</span>
                <Neo.AutoCompleteDropDown
                  className='member-profile-card'
                  placeholder="Instruction type"
                  isClearable={true}
                  bind={this.viewModel.meta.instructionTypeId}
                  bindDisplay={this.viewModel.meta.instructionTypeValue}
                  items={this.viewModel.instructionTypeValues}
                ></Neo.AutoCompleteDropDown>
              </div>
              <br />
              <span className='drop-down'>Related Scheme</span>
              <div className='mt-0 instructions-card '>
                <Neo.AutoCompleteDropDown
                  bind={this.viewModel.meta.schemeTypeId}
                  placeholder="Select Scheme"
                  className='member-profile-card'
                  isClearable={true}
                  typeToSearchPrompt="Select Scheme"
                  items={this.viewModel.schemeTypeValues}
                ></Neo.AutoCompleteDropDown>
              </div>
            </Neo.Modal>

            <div className='card-heading-instructions'>View all instructions</div>
            <p className='card-sub-heading'>
              An overview of your instructions that can be viewed and actioned.
            </p>
            <br />
            <Neo.TabContainer variant="flat" className='Instructions-tab-container' selectedTab={this.viewModel.meta.tabValue}>
              <Neo.Tab header={<div className='instruction-tab'>Member instructions</div>} name="Scheme instructions">
                <br />
                <div className='row search-container'>
                  <div className='col-md-10'>
                    <Neo.FormGroup
                      bind={this.viewModel.criteria.meta.searchTerm} suppressLabel
                      placeholder="Search by..."
                      className='search-box'
                      onChange={
                        () => { this.viewModel.updateGrid(this.viewModel.pageManagerMember.data[0].instructionType, this.viewModel.pageManagerMember.data[0].status) }
                      }
                    />
                  </div>
                  <div className='col-md-2 search-div'>
                    <Neo.Button text="Search" className='btn-search' />
                  </div>
                </div>
                {this.viewModel.userRoleType !== UserRoleType.LibertyNonLegacyMember &&
                  <div>
                    <br />
                    <Neo.Button icon="plus-circle" text="Start instruction" onClick={() => { this.viewModel.swapToNewInstrucion() }} className='btn btn-instruction' />
                  </div>
                }
                <br />
                <div className=' row'>
                  <div className='col-md-12 col-12 alert-heading'>
                    <Neo.Alert variant="primary" className='display-alert-intsructions' heading="All instructions that are closed and complete will move to the Completed tab above." >
                    </Neo.Alert>
                  </div>
                </div>
                <br />
                <InstructionsWebMemberTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} sort_img={sort_img} pageManagerMember={this.viewModel.pageManagerMember} />
                <InstructionsMobileMemberTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerMember={this.viewModel.pageManagerMember} propValues={this.viewModel.propValues} propValueId={this.viewModel.meta.propValueId} propValue={this.viewModel.meta.propValue} />
              </Neo.Tab>
              {
                this.viewModel.userRoleType !== UserRoleType.LibertyNonLegacyMember &&
                <Neo.Tab header={<div className='instruction-tab'>Scheme instructions</div>} name="Member instructions">
                  <br />
                  <div className='row'>
                    <div className='col-md-10'>
                      <Neo.FormGroup
                        bind={this.viewModel.criteria.meta.searchTerm} suppressLabel
                        placeholder="Search by..."
                        className='search-box'
                        onChange={
                          () => { this.viewModel.updateGrid(this.viewModel.pageManagerScheme.data[0].instructionType, this.viewModel.pageManagerScheme.data[0].status) }
                        }
                      />
                    </div>
                    <div className='col-md-2'>
                      <Neo.Button text="Search" className='btn btn-search' />
                    </div>
                  </div>
                  <br />
                  <Neo.Button icon="plus-circle" text="Start instruction" onClick={() => { this.viewModel.swapToNewInstrucion() }} className='btn btn-instruction' /><br /><br />
                  <div className=' row'>
                    <div className='col-md-12 col-12'>
                      <Neo.Alert variant="primary" className='display-alert-intsructions' heading="All instructions that are closed and complete will move to the Completed tab above." >
                      </Neo.Alert>
                    </div>
                  </div>
                  <br />
                  <InstructionsWebSchemeTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerScheme={this.viewModel.pageManagerScheme} sort_img={sort_img} />
                </Neo.Tab>
              }
              <Neo.Tab header={<div className='instruction-tab'>Completed instructions</div>} name="Completed instructions">
                <br />
                <div className='row'>
                  <div className='col-md-10'>
                    <Neo.FormGroup
                      bind={this.viewModel.criteria.meta.searchTerm} suppressLabel
                      placeholder="Search by..."
                      className='search-box'
                      onChange={
                        () => { this.viewModel.updateGrid(this.viewModel.pageManagerMemberCompleted.data[0].instructionType, this.viewModel.pageManagerMemberCompleted.data[0].status) }
                      }
                    />
                  </div>
                  <div className='col-md-2 search-div'>
                    <Neo.Button text="Search" className='btn btn-search' />
                  </div>
                </div>
                <br />
                <div className='card-heading-instructions'>Member</div>
                <br />
                <InstructionsWebMemberTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerMember={this.viewModel.pageManagerMemberCompleted} sort_img={sort_img} />
                <InstructionsMobileMemberTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerMember={this.viewModel.pageManagerMemberCompleted} propValues={this.viewModel.propValues} propValueId={this.viewModel.meta.propValueId} propValue={this.viewModel.meta.propValue} />
                {
                  this.viewModel.userRoleType !== UserRoleType.LibertyNonLegacyMember &&
                  <div>
                    <div className='row'>
                      <div className='divider1'></div>
                    </div>
                    <div className='card-heading-instructions'>Scheme</div>
                    <br />
                    <InstructionsWebSchemeTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerScheme={this.viewModel.pageManagerSchemeCompleted} sort_img={sort_img} />
                    <InstructionsMobileMemberTable viewModel={this.viewModel} gotToInstructionView={this.viewModel.gotToInstructionView} pageManagerMember={this.viewModel.pageManagerSchemeCompleted} propValues={this.viewModel.propValues} propValueId={this.viewModel.meta.propValueId} propValue={this.viewModel.meta.propValue} />
                  </div>
                }
              </Neo.Tab>
            </Neo.TabContainer>
            <ContainerFooterComponent />
          </div>
        }
        {this.viewModel.showInstructionDetails &&
          <InstructionDetailsView viewModel={this.viewModel} instruction={this.viewModel.selectedInstruction} />
        }

        {this.viewModel.showInstructionStartNew &&
          <InstructionStartNew viewModel={this.viewModel} />
        }
      </div>
    );
  }
}