import { Attributes, LookupBase, Model, ModelBase } from '@singularsystems/neo-core';

export default class SavingsWithdrawalClaimTemplateCardLookup extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public savingsWithdrawalClaimTemplateCardId: number = 0;

    public savingsWithdrawalClaimTemplateId: number = 0;

    public title: string = "";

    public description: string = "";

    public icon: string = "";

    @Attributes.Integer()
    public order: number = 99;

    // Client only properties / methods

    public  isExpanded = new Model.ObservableValue(false);
}