import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo } from '@singularsystems/neo-react';
import warningYellow from '../../../App/assets/img/warning-yellow.png';
import edit from '../../../App/assets/img/edit.png';
import bin from '../../../App/assets/img/bin.png';
import valid from '../../../App/assets/img/img-valid.png';
import group from '../../../App/assets/img/group.png';
import greyPlus from '../../../App/assets/img/greyPlus.png';
import NoBeneficiaries from './NoBeneficiaries';
import information_icon from '../../../App/assets/img/information-small.png';
import switch_icon from '../../../App/assets/img/switch.png';
import FileUploadBeneficiaries from './FileUploadBeneficiaries';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import { NotifyUtils } from '@singularsystems/neo-core';
import { NotificationDuration } from '../../../App/Models/NotificationDuration';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';

interface IBeneficiariesFuneralBenefitProps {
  viewModel: BeneficiariesVM
}

@observer
export default class BeneficiariesFuneralBenefit extends React.Component<IBeneficiariesFuneralBenefitProps> {

  constructor(props: IBeneficiariesFuneralBenefitProps) {
    super(props);
  }

  public render() {
    return (
      <div className='beneficiaries-approved-death-benefits'>
        <div className={this.props.viewModel.viewOnlyBeneficiary == true ? 'adding-margin-to-the-sides adding-margin-bottom' : 'adding-margin-to-the-sides'}>
          {this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member &&
            <div className='row alert-margin'>
              <div className='col-9 col-md-9'></div>
              <div className='col-3 col-md-3'>
                <div className='checkbox-container verify-checkbox-container'>
                  <input
                    type="checkbox"
                    id="confirmationCheckbox"
                    className='form-check-input'
                    checked={this.props.viewModel.verifyFuneralBenefits}
                    onClick={this.props.viewModel.receivedNominationForm ? () => this.props.viewModel.updateVerifiedStatus(this.props.viewModel.tabValue.toString()) : () => NotifyUtils.addWarning("Beneficiary nomination form", "Please upload a valid beneficiary nomination form", NotificationDuration.Long)}
                  />
                  <label className='address-tick-section beneficiaries-verification'>Verify that this tab has been checked</label>
                </div>
              </div>
            </div>
          }
          <div className='row alert-margin'>
            <div className='col-md-3'>
              <div className='benefit-info-link' onClick={() => this.props.viewModel.showFuneralBenefitInfoModal = true}>
                <img className='alert-approved-badb' src={information_icon} /> What is Funeral benefits?
              </div>
            </div>
            <div className='col-md-9'>
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Beneficiaries outstanding
                </div>
              }
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 1 &&
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Beneficiary 2 is outstanding
                </div>
              }
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 &&
                <div className='details-outstanding green-status-text'>
                  <img className='alert-approved-badb' src={valid} /> Up to date
                </div>
              }
            </div>
          </div>
          <BlueRibbon text='The beneficiary is the person you appoint to claim and receive your funeral policy benefits after
                  your death. Beneficiaries in this list must be 18 years or older. If beneficiary 1 predeceases you,
                  the benefit will be payable to beneficiary 2. If for any reason both beneficiaries predecease you,
                  the benefit will be paid to your estate' />
          <div className='col-md-12'>
            <Neo.Alert variant='success' className='alert-text-badb' bindShow={this.props.viewModel.meta.showApprovedAddedAlert} autoCloseTimeout={3000} >
              <div className='row'>
                <div className='col-1 alert-image-padding'>
                  <img className='alert-approved-badb' src={valid} />
                </div>
                <div className='col-10'>
                  <div className='alert-text-headging-badb'>Existing beneficiary added </div>
                  <div className='alert-text-headging-badb sub-heading'>The beneficiary has been added.</div>
                </div>
              </div>
            </Neo.Alert>
          </div>
          {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
            <NoBeneficiaries viewModel={this.props.viewModel} />
          }
          {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
            <span>
              <div className='show-on-web-screen'>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-heading-text funeral-full-name'>Full name</span>
                  <span className='col-2 card-heading-text '>ID/Passport Number</span>
                  <span className='col-2 card-heading-text '>Relationship</span>
                </div>
                {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).sort((item1, item2) => {
                  return item1.sequence - item2.sequence;
                }).map((item) => (
                  <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId} >
                    <div className='row'>
                      <span className='col-3 padding-beneficiary-number ms-2'>
                        <span className='table-card-name'>{item.firstName} {item.lastName} - <span className='beneficiary-number'> Beneficiary {item.sequence}</span></span>
                      </span>
                      <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                      <span className='col-4 card-items-padding relationship'>{item.relationshipType}</span>
                      <div className='col-1 card-items-padding'>
                        {!this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId)?.isValid ||
                          ((this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) &&
                            !this.props.viewModel.checkFuneralBeneficiariesOver18(item.beneficiaryId)) &&
                          <Neo.Button tooltip='Some details for this beneficiary are invalid' className='btn-no-outline-secondary fake-button' variant='secondary'>
                            <img className='alert-warning' src={warningYellow} />
                          </Neo.Button>
                        }
                      </div>
                      {!this.props.viewModel.viewOnlyBeneficiary &&
                        <>
                          <span className='col-1 card-items-padding-buttons'>
                            <Neo.Button
                              tooltip={'Edit'}
                              className='btn-no-outline-secondary fake-button'
                              onClick={() => {
                                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                  this.props.viewModel.editBeneficiary(item);
                                } else {
                                  NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                }
                              }}>
                              <img src={edit} className='edit-image-size' />
                            </Neo.Button>
                          </span>
                          <span className='col-1 card-items-padding-buttons delete'>
                            <Neo.Button
                              tooltip={'Remove'}
                              className='btn-no-outline-secondary fake-button'
                              onClick={() => {
                                var funeralBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                if (funeralBeneficiary) {
                                  if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                    this.props.viewModel.selectedFuneralBenefitBeneficiary = funeralBeneficiary;
                                    this.props.viewModel.showFuneralDeletedModal = true;
                                  } else {
                                    NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                  }
                                }
                              }}>
                              <img src={bin} className='edit-image-size' />
                            </Neo.Button>
                          </span>
                        </>
                      }
                    </div>
                  </Neo.Card>
                ))}
              </div>
              <div className='show-on-phone-screen'>
                {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).sort((item1, item2) => {
                  return item1.sequence - item2.sequence;
                }).map((item) => (
                  <Neo.Card className='approved-benefit-card-items funeral-benefit-height' key={item.beneficiaryId}>
                    <div className='row'>
                      <span className='col-5 card-text-phone-screen'>Fullname </span>
                      <span className='col-7 padding-phone table-card-name'>{item.firstName} {item.lastName} - <span className='beneficiary-number'> Beneficiary {item.sequence}</span></span>
                      <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                      <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                      {!this.props.viewModel.viewOnlyBeneficiary &&
                        <>
                          <div className='line-badb funeral-beneficiaries-line-padding' />
                          <div className='col-3 card-items-padding'>
                            {!this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId)?.isValid &&
                              <Neo.Button tooltip='Some details for this beneficiary are invalid' className='btn-no-outline-secondary fake-button' variant='secondary'>
                                <img className='alert-warning' src={warningYellow} />
                              </Neo.Button>
                            }
                          </div>
                          <span className='col-4'>
                            <img src={edit} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                  this.props.viewModel.editBeneficiary(item);
                                } else {
                                  NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                }
                              }}>
                              Edit
                            </Neo.Link>
                          </span>
                          <span className='col-5 bin-image-phone'>
                            <img src={bin} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                var funeralBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                if (funeralBeneficiary) {
                                  if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                    this.props.viewModel.selectedFuneralBenefitBeneficiary = funeralBeneficiary;
                                    this.props.viewModel.showFuneralDeletedModal = true
                                  } else {
                                    NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                  }
                                }
                              }
                              }>
                              Remove
                            </Neo.Link>
                          </span>
                        </>
                      }
                    </div>
                  </Neo.Card>
                ))}
              </div>
              {!this.props.viewModel.viewOnlyBeneficiary &&
                <div className={this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 ? 'greyed-out-block' : 'blue-block'}>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Neo.Link className='blue-block-text'
                        onClick={() => {
                          if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                            this.props.viewModel.selectExistingBeneficiary();
                          } else {
                            NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                          }
                        }} >
                        Select existing beneficiary
                      </Neo.Link>
                      <span className='line-break'><br></br></span>
                      <span className='or-text'> or</span>
                      <span className='line-break'><br></br></span>
                      <img src={this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 ? greyPlus : group} className="edit-image-size padding" />
                      <Neo.Link
                        onClick={() => this.props.viewModel.initiateAddNewBeneficiary()}
                        className='add-text'>
                        Add new beneficiary
                      </Neo.Link>
                    </div>
                  </div>
                </div>
              }
            </span>
          }
          <div className={this.props.viewModel.viewOnlyBeneficiary == true ? 'switch-beneficiaries adding-margin-top' : 'switch-beneficiaries'}>
            <Neo.Button className='switch-beneficiaries-button'
              onClick={() => {
                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                  this.props.viewModel.switchBeneficiariesAround();
                } else {
                  NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                }
              }}>
              <img src={switch_icon} /> Switch beneficiaries
            </Neo.Button>
          </div>
        </div>
        {!this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <FileUploadBeneficiaries viewModel={this.props.viewModel} />
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedFuneral.Role + ' - ' + this.props.viewModel.lastModifiedFuneral.UserName}</span>
            </div>
            {this.props.viewModel.funeralBeneficiaryGrouping.isActiveInterim && this.props.viewModel.currentUser.userRoleType === UserRoleType.Member &&
              <div className='align-changes-text'>
                The values on this screen are incomplete and unsubmitted
              </div>
            }
          </div>
        }
        {this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedFuneral.Role + ' - ' + this.props.viewModel.lastModifiedFuneral.UserName}</span>
            </div>
          </div>
        }
        {this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <ContainerFooterComponent
              showPrimaryButton={false}
              showSecondaryButton={false}
              backLinkText='Back to dashboard'
            />
          </div>
        }
        {!this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <ContainerFooterComponent
              showPrimaryButton={true}
              showSecondaryButton={true}
              backLinkText='Save & exit'
              primaryButtonText='Update'
              primaryButtonCallback={() => {
                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) {
                  if (this.props.viewModel.funeralBeneficiaryGrouping.nominationData.length < 2) {
                    NotifyUtils.addDanger("Beneficiary count", "Please ensure that you have two beneficiaries", NotificationDuration.Long);
                    return;
                  } else if (!this.props.viewModel.checkFuneralBeneficiariesOver18()) {
                    NotifyUtils.addDanger("Beneficiary age", "Beneficiaries in this list must be 18 years or older", NotificationDuration.Long);
                    return;
                  } else {
                    this.props.viewModel.verifyUpdatedNominations();
                  }
                }
                else if (this.props.viewModel.allTabsChecked() === false) {
                  NotifyUtils.addDanger("Unchecked tab", "Please ensure that you double check and verify all tabs before submitting updates", NotificationDuration.Long);
                  return;
                } else {
                  this.props.viewModel.verifyUpdatedNominations();
                }
              }}
              disablePrimaryButton={(this.props.viewModel.currentUser.userRoleType === UserRoleType.Member
                || this.props.viewModel.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) ?
                this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(item => !item.isDeleted).length === 0 : !this.props.viewModel.receivedNominationForm}
              secondaryButtonCallback={() => {
                if (this.props.viewModel.funeralBeneficiaryGrouping.isDirty ||
                  this.props.viewModel.funeralBeneficiaryGrouping.isActiveInterim ||
                  this.props.viewModel.receivedNominationForm) {
                  this.props.viewModel.showConfirmSaveChangesModal = true
                }
              }}
            />
          </div>
        }
        <Neo.Modal title='What is funeral death benefits?'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showFuneralBenefitInfoModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showFuneralBenefitInfoModal}>
          This is a benefit payable in the event of death as defined in the group policy. These benefits are paid according to the signed nomination of beneficiary
          form that is on record with your employer. If a completed nomination of beneficiary form is not on record, the funeral benefit is payable to your estate in terms of the policy terms and conditions.
        </Neo.Modal>
        <Neo.Modal
          bind={this.props.viewModel.meta.showFuneralDeletedModal}
          title='Remove beneficiary'
          acceptButton={{ text: "Yes", variant: "primary", onClick: () => this.props.viewModel.deleteFuneralBeneficiary(this.props.viewModel.selectedFuneralBenefitBeneficiary) }}
          closeButton={{ text: "No", variant: "danger", onClick: () => this.props.viewModel.showFuneralDeletedModal = false }}>
          By removing beneficiary 1, you are agreeing that beneficiary 2 will be appointed to claim and receive your funeral policy benefits after your death.
        </Neo.Modal>
      </div>
    );
  }
}