import { ModelBase, StringUtils } from "@singularsystems/neo-core"
import { injectable } from "inversify";
import MemberBeneficiaryLookup from "../../Party/Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup";
import MemberQueryLookupBase from "../../Party/Models/LookUps/MemberQueries/MemberQueryLookupBase";
import TelephoneInfoLookup from "../../Party/Models/LookUps/TelephoneInfoLookup";
import { AppService, Types } from "../../Party/PartiesTypes";

@injectable()
export default class HelpersService extends ModelBase {

  private hasInitialised = false;
  public countryValueId: number | null = null;
  public countryValue: string = '+27';

  constructor(public masterDataService = AppService.get(Types.App.Services.MasterDataService)) {
    super();
    this.makeObservable();
  }

  public async initialise() {
    if (!this.hasInitialised) {
      this.hasInitialised = true;
    }
    this.countryValueId = this.masterDataService.countryCodes.find(c => c.CountryCode === this.countryValue)?.Id ?? null;
  }

  public parseContactNumber(number: string) {
    let numberSplit = number.split(' ');
    if (numberSplit.length >= 2) {
      let result = "";
      numberSplit.forEach(piece => {
        if (!piece.includes("+")) {
          result += piece;
        } else {
          let countryCode = this.masterDataService.countryCodes.find(c => c.CountryCode === piece)
          if (countryCode) {
            this.countryValueId = countryCode.Id;
            this.countryValue = countryCode.CountryCode;
          }
        }
      });
      return result;
    }
    return number;
  }

  public setCountryValue(memberQuery: MemberQueryLookupBase) {
    memberQuery.countryValue = this.countryValue;
    memberQuery.memberContactNumber = memberQuery.contactNumberBase;
  }

  public cleanUpContactNumber(memberQuery: MemberQueryLookupBase) {
    if (memberQuery.countryValue === "+27") {
      let cleanedNumber1 = memberQuery.memberContactNumber;
      cleanedNumber1 = cleanedNumber1.replace(/\s+/g, '');

      if (memberQuery.memberContactNumber.length >= 10 && memberQuery.memberContactNumber.charAt(0) === "0") {
        cleanedNumber1 = cleanedNumber1.slice(1);
      }
      memberQuery.memberContactNumber = cleanedNumber1;
    }
  }

  public cleanUpContactNumberForProfile(profile: TelephoneInfoLookup) {
    if (profile.countryValue === "+27") {
      let cleanedNumber1 = profile.contactNumberBase;
      cleanedNumber1 = cleanedNumber1.replace(/\s+/g, '');

      if (profile.contactNumberBase.length >= 10 && profile.contactNumberBase.charAt(0) === "0") {
        cleanedNumber1 = cleanedNumber1.slice(1);
      }
      profile.contactNumberBase = cleanedNumber1;
    }
  }

  public splitContactNumber(selectedBeneficiaryToEdit: MemberBeneficiaryLookup) {
    let numberSplit = selectedBeneficiaryToEdit.telephoneNo.split(' ');
    if (numberSplit.length >= 2) {
      let result = "";
      numberSplit.forEach(piece => {
        if (!piece.includes("+")) {
          result += piece;
        } else {
          let countryCode = this.masterDataService.countryCodes.find(c => c.CountryCode === piece)
          if (countryCode) {
            selectedBeneficiaryToEdit.countryCodeId = countryCode.Id;
            selectedBeneficiaryToEdit.countryCode = countryCode.CountryCode;
          }
        }
      });
      selectedBeneficiaryToEdit.telephoneNo = result;
    }
  }
}