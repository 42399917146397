import { Attributes, List, LookupBase, Misc } from '@singularsystems/neo-core';

export default class MemberLiensLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  @Attributes.Serialisation(Misc.SerialiseType.NotNull)
  public readonly memberId: number | null = null;

  public readonly sourceId: string = "";

  public readonly divorceOrderAgainstFund: boolean = false;

  public readonly divorceOrderFinalised: boolean = false;

  public readonly maintenanceOrderAgainstFund: boolean = false;

  public readonly maintenanceOrderFinalised: boolean = false;

  public readonly debtToEmployerForHousingLoan: boolean = false;

  public readonly debtToEmployerForDeception: boolean = false;

  // Client only properties / methods
}