import { EnumHelper } from "@singularsystems/neo-core";

export enum ContactDetailsFieldType {
  None = 0,
  Address = 1,
  Telephone = 2,
  Email = 3
}

EnumHelper.decorateEnum(ContactDetailsFieldType, decorator => {
  decorator.describe(ContactDetailsFieldType.None, "None", "");
  decorator.describe(ContactDetailsFieldType.Address, "Address", "");
  decorator.describe(ContactDetailsFieldType.Telephone, "Telephone", "");
  decorator.describe(ContactDetailsFieldType.Email, "Email", "");
});