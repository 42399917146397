import { AppServices } from '@singularsystems/neo-core';
import SchemeDetailsApiClient from './ApiClients/SchemeDetails/SchemeDetailsApiClient';
import MemberComplaintsCommandApiClient from './ApiClients/MemberComplaints/MemberComplaintsCommandApiClient';
import MemberComplaintsQueryApiClient from './ApiClients/MemberComplaints/MemberComplaintsQueryApiClient';
import MemberComplimentsCommandApiClient from './ApiClients/MemberCompliments/MemberComplimentsCommandApiClient';
import MemberComplimentsQueryApiClient from './ApiClients/MemberCompliments/MemberComplimentsQueryApiClient';
import MemberInvestmentValuesApiClient from './ApiClients/MemberInvestmentValues/MemberInvestmentValuesApiClient';
import MemberQueriesCommandApiClient from './ApiClients/MemberQueries/MemberQueriesCommandApiClient';
import MemberQueriesQueryApiClient from './ApiClients/MemberQueries/MemberQueriesQueryApiClient';
import { PartiesTypes } from './PartiesTypes';
import PersonProfileApiClient from './ApiClients/PersonProfile/PersonProfileApiClient';
import MemberContactDetailsApiClient from './ApiClients/MemberContacts/MemberContactDetailsApiClient';
import MemberBenefitsApiClient from './ApiClients/MemberBenefits/MemberBenefitsApiClient';
import MemberBillingApiClient from './ApiClients/MemberBilling/MemberBillingApiClient';
import MemberDetailsApiClient from './ApiClients/MemberDetails/MemberDetailsApiClient';
import BackgroundDataApiClient from './ApiClients/BackgroundData/BackgroundDataApiClient';
import MemberBeneficiariesQueryApiClient from './ApiClients/MemberBeneficiaries/MemberBeneficiariesQueryApiClient';
import MemberBeneficiariesCommandApiClient from './ApiClients/MemberBeneficiaries/MemberBeneficiaryCommandApiClient';
import PersonContactDetailsQueryApiClient from './ApiClients/PersonContactDetails/PersonContactDetailsQueryApiClient';
import PersonContactDetailsCommandApiClient from './ApiClients/PersonContactDetails/PersonContactDetailsCommandApiClient';
import MemberNominationsQueryApiClient from './ApiClients/MemberNominations/MemberNominationsQueryApiClient';
import MemberNominationsCommandApiClient from './ApiClients/MemberNominations/MemberNominationsCommandApiClient';
import SavingsClaimCommandApiClient from './ApiClients/MemberClaims/SavingsClaimCommandApiClient';
import SavingsClaimQueryApiClient from './ApiClients/MemberClaims/SavingsClaimQueryApiClient';
import MemberSavingsClaimEligibilityQueryApiClient from './ApiClients/MemberSavingsClaimEligibility/MemberSavingsClaimEligibilityQueryApiClient';
import ThirdPartyNominationsCommandApiClient from './ApiClients/ThirdPartyNominations/ThirdPartyNominationsCommandApiClient';
import ThirdPartyNominationsQueryApiClient from './ApiClients/ThirdPartyNominations/ThirdPartyNominationsQueryApiClient';
import FAAssistantQueryApiClient from './ApiClients/FAAssistants/FAAssistantQueryApiClient';

export const PartiesAppModule = new AppServices.Module("Party", container => {
  // Api Clients
  container.bind(PartiesTypes.ApiClients.PersonContactDetailsQueryApiClient).to(PersonContactDetailsQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.PersonContactDetailsCommandApiClient).to(PersonContactDetailsCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.SchemeDetailsApiClient).to(SchemeDetailsApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberComplaintsCommandApiClient).to(MemberComplaintsCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberComplaintsQueryApiClient).to(MemberComplaintsQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberComplimentsCommandApiClient).to(MemberComplimentsCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberComplimentsQueryApiClient).to(MemberComplimentsQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberQueriesCommandApiClient).to(MemberQueriesCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberQueriesQueryApiClient).to(MemberQueriesQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.PersonProfileApiClient).to(PersonProfileApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberInvestmentValuesApiClient).to(MemberInvestmentValuesApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberBillingApiClient).to(MemberBillingApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberDetailsApiClient).to(MemberDetailsApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberContactDetailsApiClient).to(MemberContactDetailsApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberBenefitsApiClient).to(MemberBenefitsApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.BackgroundDataApiClient).to(BackgroundDataApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberBeneficiariesQueryApiClient).to(MemberBeneficiariesQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberNominationsQueryApiClient).to(MemberNominationsQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberNominationsCommandApiClient).to(MemberNominationsCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberBeneficiariesCommandApiClient).to(MemberBeneficiariesCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.SavingsClaimCommandApiClient).to(SavingsClaimCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.SavingsClaimQueryApiClient).to(SavingsClaimQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.MemberSavingsClaimEligibilityQueryApiClient).to(MemberSavingsClaimEligibilityQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.ThirdPartyNominationsCommandApiClient).to(ThirdPartyNominationsCommandApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.ThirdPartyNominationsQueryApiClient).to(ThirdPartyNominationsQueryApiClient).inSingletonScope();
  container.bind(PartiesTypes.ApiClients.FAAssistantQueryApiClient).to(FAAssistantQueryApiClient).inSingletonScope();
  // Services

});

export const PartiesTestModule = new AppServices.Module("Party", container => {
  // bind test types
});