import React from 'react';
import { observer } from 'mobx-react';
import InstructionsSearchResultsLookup from '../../../../Dashboard/Models/Lookups/Instructions/InstructionsSearchResultsLookUp';

interface ICardHeadingPhoneScreenProps {
  instruction: InstructionsSearchResultsLookup
}

@observer
export default class InstructionCardHeadingPhoneScreen extends React.Component<ICardHeadingPhoneScreenProps> {

  constructor(props: ICardHeadingPhoneScreenProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-phone-screen'>
        <div className='card-heading-instructions'>{this.props.instruction.instruction} </div>
        <div className='instructon-view-subheading'>{this.props.instruction.name}</div>
        <div className='instructon-view-subheading'>{this.props.instruction.schemeName} - {this.props.instruction.schemeNumber}</div>
      </div>
    );
  }
}