import { AppServices } from '@singularsystems/neo-core';
import EmployerReportsAndDocumentsApiClient from './ApiClients/EmployerDashboard/EmployerReportsAndDocumentsApiClient';
import EmployerComplaintsApiClient from './ApiClients/EmployerDashboard/EmployerComplaintsApiClient';
import DocumentsApiClient from './ApiClients/Documents/DocumentsApiClient';
import { DashboardTypes } from './DashboardTypes';
import EmployerDashboardApiClient from './ApiClients/EmployerDashboard/EmployerDashboardApiClient';
import MemberDashboardApiClient from './ApiClients/MemberDashboard/MemberDashboardApiClient';
import CallsAndNotificationsApiClient from './ApiClients/Notifications/CallsAndNotificationsApiClient';
import CallsAndInstructionsApiClient from './ApiClients/Instructions/CallsAndInstructionsApiClient';
import LegacyMemberDashboardApiClient from './ApiClients/LegacyMemberDashboard/LegacyMemberDashboardApiClient';
import SearchWidgetApiClient from './ApiClients/SearchWidget/SearchWidgetApiClient';
import PermissionQueryApiClient from './ApiClients/Permissions/PermissionQueryApiClient';

export const DashboardModule = new AppServices.Module("Dashboard", container => {
  // Api Clients
  container.bind(DashboardTypes.ApiClients.EmployerReportsAndDocumentsApiClient).to(EmployerReportsAndDocumentsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.EmployerComplaintsApiClient).to(EmployerComplaintsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.DocumentsApiClient).to(DocumentsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.EmployerDashboardApiClient).to(EmployerDashboardApiClient).inSingletonScope();

  container.bind(DashboardTypes.ApiClients.MemberDashboardApiClient).to(MemberDashboardApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.CallsAndNotificationsApiClient).to(CallsAndNotificationsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.CallsAndInstructionsApiClient).to(CallsAndInstructionsApiClient).inSingletonScope();

  container.bind(DashboardTypes.ApiClients.LegacyMemberDashboardApiClient).to(LegacyMemberDashboardApiClient).inSingletonScope();

  container.bind(DashboardTypes.ApiClients.SearchWidgetApiClient).to(SearchWidgetApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.PermissionQueryApiClient).to(PermissionQueryApiClient).inSingletonScope();

  // Services
});