import React from 'react';
import { observer } from 'mobx-react';
import { BenefitGroup } from '../../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
interface IDisabilityBenefitsTableProps {
  benefitGroup: BenefitGroup,
  acceptedAmountTotal: number,
  disclaimerText: string,
  tableText: string,
  benefitType: string,
  entitledAmountTotal: number
}

@observer
export default class DisabilityBenefitsTable extends React.Component<IDisabilityBenefitsTableProps> {

  constructor(props: IDisabilityBenefitsTableProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        {
          (this.props.benefitGroup.unApprovedAcceptedAmount > 0 || this.props.benefitGroup.approvedAcceptedAmount > 0) &&
          <div>
            <div className='table-title-risk'><span className='table-title-risk bold'>{this.props.benefitType}</span>
              ({this.props.disclaimerText})
            </div>
            <table className='table-width-risk'>
              <tr className='table-border-risk'>
                <th className='table-border-risk table-row-header-risk'></th>
                <th className='table-border-risk padding-phone-screen'>Accepted benefit amount</th>
                <th className='table-border-risk padding-phone-screen remove-border-right-phone-risk'>Entitled <span className='space-phone-screen-risk'><br /> </span> benefit amount</th>
                <th className='table-border-risk table-column-underwriting-heading-risk'>Underwriting</th>
              </tr>
              {
                (this.props.benefitGroup.approvedAcceptedAmount !== 0) &&
                <tr className='table-border-risk'>
                  <td className='table-border-risk table-row-header-risk'>An approved lump sum disability benefit <span className='hide-on-phone-risk'> amount of </span></td>
                  <td className='table-border-risk table-column-content-risk'><span className='underline-green-risk'>
                    {this.props.benefitGroup.approvedAcceptedAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </span></td>
                  <td className='table-border-risk table-column-content-risk remove-border-right-phone-risk'>
                    {this.props.benefitGroup.approvedEntitledAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </td>
                  <td rowSpan={2} className='table-border-risk table-column-underwriting-risk remove-border-bottom-web-risk'>
                    {this.props.acceptedAmountTotal === this.props.entitledAmountTotal ? (
                      <span>No further underwriting actions required</span>) : (
                      <span>
                        You will only be paid out your accepted benefit - please speak to the schemes' Financial Advisor to see what action must be taken.
                      </span>
                    )}
                  </td>
                </tr>
              }
              {
                (this.props.benefitGroup.unApprovedAcceptedAmount !== 0) &&
                <tr className='table-border-risk'>
                  <td className='table-border-risk table-row-header-risk'>An Unapproved lump sum disability benefit <span className='hide-on-phone-risk'> amount of </span></td>
                  <td className='table-border-risk table-column-content-risk'><span className='underline-green-risk'>
                    {this.props.benefitGroup.unApprovedAcceptedAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </span></td>
                  <td className='table-border-risk table-column-content-risk add-border remove-border-right-phone-risk'>
                    {this.props.benefitGroup.unApprovedEntitledAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </td>
                  {this.props.benefitGroup.approvedAcceptedAmount == 0 &&
                    <td rowSpan={2} className='table-border-risk table-column-underwriting-risk remove-border-bottom-web-risk'>
                      {this.props.acceptedAmountTotal === this.props.entitledAmountTotal ? (
                        <span>No further underwriting actions required.</span>) : (
                        <span>
                          You will only be paid out your accepted benefit - please speak to the schemes' Financial Advisor to see what action must be taken.
                        </span>
                      )}
                    </td>
                  }
                </tr>
              }
            </table>
          </div>
        }
        <table className='table-width-risk mt-3'>
          <tr className='table-border-risk'>
            <td className='table-border-risk table-column-green-header-risk  table-blank-right'>{this.props.benefitType} amount</td>
            <td className='table-border-risk table-column-green-content-risk '>{
              this.props.acceptedAmountTotal.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</td>
          </tr>
        </table>
      </div>
    );
  }
}