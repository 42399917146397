import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerQueriesComplimentsVM from './EmployerQueriesComplimentsVM';
import { observer } from 'mobx-react';
import img_Upload from '../../../App/assets/img/primary-upload.png';
import img_view from '../../../App/assets/img/open.png';
import img_bin from '../../../App/assets/img/bin.png';
import EmployerQueriesComplimentsSummary from '../../Components/Employer/EmployerQueriesComplimentsSummary';
import EmployerQueriesComplimentsReview from '../../Components/Employer/EmployerQueriesComplimentsReview';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';

@observer
export default class EmployerQueriesComplimentsView extends Views.ViewBase<EmployerQueriesComplimentsVM> {

  constructor(props: unknown) {
    super("Employer Queries & Compliments", EmployerQueriesComplimentsVM, props);
  }

  private countryCodes = [
    { Id: 1, CountryCode: "+27" },
    { Id: 2, CountryCode: "+44" },
    { Id: 3, CountryCode: "+21" }
  ]

  public render() {
    return (
      <div className='employer-complaints'>
        {!this.viewModel.complimentReview && !this.viewModel.complimentSummary &&
          <div>
            <div className='background-card body-card-complaints'>
              <div className='row'>
                <div className='form-group col-12 col-md-12'>
                  <p className='card-header-query mt-2'>Have a question or need assistance?</p>
                  <div>
                  </div>
                  <p className='card-header-complaint'>How can we help you?</p>
                </div>
              </div>
              <div className='row btn-row'>
                <div className='align-contact-complain-buttons mt-2 col-6 col-md-6'>
                  <Neo.Button text="Queries" id='btn-contact-us' onClick={(e) => this.viewModel.showCompliment(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-complain' : 'col-6 btn btn-contact'} />
                  <Neo.Button text="Compliments" id='btn-complain' onClick={(e) => this.viewModel.showCompliment(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-contact' : 'col-6 btn btn-complain'} />
                </div>
              </div>
            </div>
            <div className={this.viewModel.displayCompliment ? 'hide-complain' : 'show-complain'}>
              <div className='background-card body-card-complaints-tabs'>
                <Neo.TabContainer className='complaints-tabs'>
                  <Neo.Tab header="Member">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className="search-bar"
                          bind={this.viewModel.meta.memberQueriesId}
                          bindDisplay={this.viewModel.meta.memberQueriesName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSearchResults}
                          valueMember="memberNumber"
                          displayMember='memberNameAndID'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.queryMemberSelected(item)
                          }}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Member details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedQueriesMember.firstName} {this.viewModel.selectedQueriesMember.lastName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.memberQueriesId}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedQueriesMember.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedQueriesMember.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedQueriesMember.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedQueriesMember.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12 ">
                          <label className='form-label'>Query</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your query here'
                            rows={8}
                            value={this.viewModel.queriesTextMember.meta.compliment.value}
                            onChange={this.viewModel.handleMemberQueryChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                uploadEndpoint={this.viewModel.documentsApiClient.getUploadQueryDocumentUrl()}
                                // ToDo: when/if we need to add this back we need to somehow call fileManager.uploadFiles in the callback
                                // but at the time of writing there was noe fileManager. see https://www.singularwebsites.co.za/reactdemo/demo/files
                                // onFilesSelected={() => { this.viewModel.showGreyQueryBoxScheme = true }}
                                allowedExtensions={[".pdf", ".docx", ".jpeg", ".xlsx"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a query file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                              <Neo.Link className="save-return-link" onClick={() => this.viewModel.downloadQueryDocument()}>Download query file via API</Neo.Link>
                              <Neo.Link className="save-return-link" onClick={() => this.viewModel.downloadQueryDocumentFromUrl()}>Download query file directly from blob storage</Neo.Link>
                            </div>
                            {this.viewModel.showGreyQueryBoxMember &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='grey-upload-box-text'>
                                        Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyQueryBoxMember = false} /></span>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <span className='grey-upload-box-text'>
                                        KFC.pdf
                                      </span>
                                      <span className='grey-upload-box-text floar-right'>
                                        50% Completed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplimentReview()}
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                  <Neo.Tab header="Scheme">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className='search-bar'
                          bind={this.viewModel.meta.schemeQueriesId}
                          bindDisplay={this.viewModel.meta.schemeQueriesName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSchemeSearchResults}
                          valueMember="schemeNumber"
                          displayMember='schemeNameAndNumber'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.querySchemeSelected(item)
                          }}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedQueriesScheme.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedQueriesScheme.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedQueriesScheme.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedQueriesScheme.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12">
                          <label className='form-label'>Query</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your query here'
                            rows={8}
                            value={this.viewModel.queriesTextScheme.meta.compliment.value}
                            onChange={this.viewModel.handleSchemeQueriesChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                onFilesSelected={() => { this.viewModel.showGreyQueryBoxScheme = true }}
                                allowedExtensions={[".pdf", ".doc", ".jpeg"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                            </div>
                            {this.viewModel.showGreyQueryBoxScheme &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='grey-upload-box-text'>
                                        Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyQueryBoxScheme = false} /></span>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <span className='grey-upload-box-text'>
                                        KFC.pdf
                                      </span>
                                      <span className='grey-upload-box-text floar-right'>
                                        50% Completed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplimentReview()}
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                </Neo.TabContainer>
              </div>
            </div>
            <div className={this.viewModel.displayCompliment ? 'show-complain' : 'hide-complain'}>
              <div className='background-card body-card-complaints-tabs'>
                <Neo.TabContainer className='complaints-tabs'>
                  <Neo.Tab header="Member">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className="search-bar"
                          bind={this.viewModel.meta.memberComplimentId}
                          bindDisplay={this.viewModel.meta.memberComplimentName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSearchResults}
                          valueMember="memberNumber"
                          displayMember='memberNameAndID'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.complimentMemberSelected(item)
                          }}
                          // menuIsOpen={true}
                          // closeMenuOnSelect={false}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Member details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedComplimentMember.firstName} {this.viewModel.selectedComplimentMember.lastName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.memberComplimentId}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedComplimentMember.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedComplimentMember.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedComplimentMember.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedComplimentMember.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12 ">
                          <label className='form-label'>Compliment</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your compliment here'
                            rows={8}
                            value={this.viewModel.complimentTextMember.meta.compliment.value}
                            onChange={this.viewModel.handleMemberComplimentChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                uploadEndpoint={this.viewModel.documentsApiClient.getUploadSecureDocumentUrl()}
                                // ToDo: when/if we need to add this back we need to somehow call fileManager.uploadFiles in the callback
                                // but at the time of writing there was noe fileManager. see https://www.singularwebsites.co.za/reactdemo/demo/files
                                // onFilesSelected={() => { this.viewModel.showGreyQueryBoxScheme = true }}
                                allowedExtensions={[".pdf", ".docx", ".jpeg", ".xlsx"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a secure file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                              <Neo.Link className='save-return-link' onClick={() => this.viewModel.downloadSecureDocument()}>Download secure file</Neo.Link>
                            </div>
                            {this.viewModel.showGreyComplimentBoxMember &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='grey-upload-box-text'>
                                        Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyComplimentBoxMember = false} /></span>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <span className='grey-upload-box-text'>
                                        KFC.pdf
                                      </span>
                                      <span className='grey-upload-box-text floar-right'>
                                        50% Completed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplimentReview()}
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                  <Neo.Tab header="Scheme">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className='search-bar'
                          bind={this.viewModel.meta.schemeComplimentId}
                          bindDisplay={this.viewModel.meta.schemeComplimentName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSchemeSearchResults}
                          valueMember="schemeNumber"
                          displayMember='schemeNameAndNumber'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.complimentSchemeSelected(item)
                          }}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedComplimentScheme.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedComplimentScheme.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedComplimentScheme.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedComplimentScheme.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12">
                          <label className='form-label'>Compliment</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your compliment here'
                            rows={8}
                            value={this.viewModel.complimentTextScheme.meta.compliment.value}
                            onChange={this.viewModel.handleSchemeComplimentChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                onFilesSelected={() => { this.viewModel.showGreyComplimentBoxScheme = true }}
                                allowedExtensions={[".pdf", ".doc", ".jpeg"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                            </div>
                            {this.viewModel.showGreyComplimentBoxScheme &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='file-upload-documents'>
                                      <div className='col-md-12'>
                                        <div className='grey-upload-box-text'>
                                          Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyComplimentBoxScheme = false} /></span>
                                        </div>
                                      </div>
                                      <div className='col-md-12'>
                                        <span className='grey-upload-box-text'>
                                          KFC.pdf
                                        </span>
                                        <span className='grey-upload-box-text floar-right'>
                                          50% Completed
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplimentReview()}
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                </Neo.TabContainer>
              </div>
            </div>
          </div>
        }
        {this.viewModel.complimentReview && !this.viewModel.complimentSummary &&
          <EmployerQueriesComplimentsReview viewModel={this.viewModel} />
        }
        {this.viewModel.complimentSummary && !this.viewModel.complimentReview &&
          <EmployerQueriesComplimentsSummary viewModel={this.viewModel} />
        }
      </div >
    );
  }
}