import { AppServices } from '@singularsystems/neo-core';
import { IdentityTypes } from './IdentityTypes';
import UserManagementApiClient from './ApiClients/Custom/UserManagementApiClient';
import IdentityProvidersApiClient from './ApiClients/Custom/IdentityProvidersApiClient';
import UserProfileApiClient from './ApiClients/UserProfile/UserProfileApiClient';
import UserRolesApiClient from './ApiClients/UserRoles/UserRolesApiClient';

// Modules
export const IdentityModule = new AppServices.Module("Identity", container => {

  // ApiClients
  container.bind(IdentityTypes.ApiClients.UserManagementApiClient).to(UserManagementApiClient).inSingletonScope();
  container.bind(IdentityTypes.ApiClients.IdentityProvidersApiClient).to(IdentityProvidersApiClient).inSingletonScope();
  container.bind(IdentityTypes.ApiClients.UserProfileApiClient).to(UserProfileApiClient).inSingletonScope();
  container.bind(IdentityTypes.ApiClients.UserRolesApiClient).to(UserRolesApiClient).inSingletonScope();
});

export const IdentityTestModule = new AppServices.Module("Reporting", container => {
  // bind any test types here
});