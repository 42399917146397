import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import PartyFileDescriptorLookup from '../../Models/LookUps/Files/PartyFileDescriptorLookup';
import UpdateThirdPartyNominationsCommand from '../../Models/LookUps/ThirdPartyNominations/UpdateThirdPartyNominationsCommand';
import UpdateThirdPartyInterimNominationCommand from '../../Models/LookUps/ThirdPartyNominations/UpdateThirdPartyInterimNominationCommand';
import ThirdPartyNominationLookup from '../../Models/LookUps/ThirdPartyNominations/ThirdPartyNominationLookup';

export interface IThirdPartyNominationsCommandApiClient {

  /**
   * Uploads a secure document
   * Note: This method returns the url of the api endpoint. It performs no logic.
   * @returns A member query
   */
  getUploadThirdPartyBeneficiaryNominationFormSecureDocumentUrl(): string;

  /**
   * Updates a member's nomination(s)
   * @returns A string to indicate whether or not the request was successful
   */
  postThirdPartyNominations(thirdPartyNominationsCommand: Model.PartialPlainObject<UpdateThirdPartyNominationsCommand>): AxiosPromise<string>;

  /**
   * UpdateVerifiedStatus
   */
  updateVerifiedStatus(updateThirdPartyInterimNominationCommand: Model.PartialPlainObject<UpdateThirdPartyInterimNominationCommand>, nominationType: string): AxiosPromise;

  /** 
   * DeleteInterimThirdPartyNomination
   */
  deleteInterimThirdPartyNomination(memberId: number, sourceId: string): AxiosPromise;

  // Client only properties / methods
}

@injectable()
export default class ThirdPartyNominationsCommandApiClient extends Data.ApiClientBase implements IThirdPartyNominationsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/third-party-nominations`);
  }

  public getUploadThirdPartyBeneficiaryNominationFormSecureDocumentUrl(): string {
    return `${this.apiPath}/upload-document`;
  }

  public postThirdPartyNominations(thirdPartyNominationsCommand: Model.PartialPlainObject<UpdateThirdPartyNominationsCommand>): AxiosPromise<string> {
    return this.axios.post(`${this.apiPath}/submit-updated`, thirdPartyNominationsCommand);
  }

  public updateVerifiedStatus(updateThirdPartyInterimNominationCommand: Model.PartialPlainObject<UpdateThirdPartyInterimNominationCommand>, nominationType: string): AxiosPromise {
    return this.axios.put(`${this.apiPath}/update-verified-status/${encodeURIComponent(nominationType)}`, updateThirdPartyInterimNominationCommand);
  }

  public deleteInterimThirdPartyNomination(memberId: number, sourceId: string): AxiosPromise {
    return this.axios.put(`${this.apiPath}/delete-interim/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}