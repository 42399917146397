import { Attributes, List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import PartyFileDescriptorLookup from '../Files/PartyFileDescriptorLookup';
import MemberBeneficiaryGroupingDataLookup from '../MemberNominations/MemberBeneficiaryGroupingDataLookup';

export default class ThirdPartyNominationLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public memberPartyId: string = "";

  public memberId: number = 0;

  public sourceId: string = "";

  @Attributes.Date()
  public signedDate: Date | null = null;

  public files = new List(PartyFileDescriptorLookup);

  public interimThirdpartyNominationId: number = 0;

  public userId: number = 0;

  public schemeId: string = "";

  public fileDescriptorId: number = 0;

  public isApprovedVerified: boolean = false;

  public isUnapprovedVerified: boolean = false;

  public isFuneralVerified: boolean = false;

  @Attributes.ChildObject(PartyFileDescriptorLookup)
  public file: PartyFileDescriptorLookup = new PartyFileDescriptorLookup();

  public memberBeneficiaryGroupingDataLookups = new List(MemberBeneficiaryGroupingDataLookup);

  @Attributes.Date()
  public submittedDate: Date = new Date();
  // Client only properties / methods
}