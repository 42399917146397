import React from 'react';
import { Views } from '@singularsystems/neo-react';
import QueriesVM from './QueriesVM';
import { observer } from 'mobx-react';
import "../../App/Styles/Components/Queries.scss";
import img_Upload from '../../App/assets/img/primary-upload.png';
import { Neo } from '@singularsystems/neo-react';
import ComplimentReview from './Components/ComplimentReview';
import SchemeInformationHeader from './Components/SchemeInformationHeader';
import QueryReview from './Components/QueryReview';
import img_view from '../../App/assets/img/open.png';
import img_cross from '../../App/assets/img/close-cross.png';
import successful from '../assets/img/successful.png';
import { Validation } from '@singularsystems/neo-core';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class QueriesView extends Views.ViewBase<QueriesVM> {
  constructor(props: unknown) {
    super("Queries & compliments", QueriesVM, props);

    this.viewModel.fetchMemberCompliment();
    this.viewModel.fetchMemberQuery();
  }

  private handleComplimentChange = (event: any) => {
    this.viewModel.memberCompliment.description = event.target.value;
  };

  private handleQueryChange = (event: any) => {
    this.viewModel.memberQuery.description = event.target.value;
  };

  public render() {
    return (
      <div className='padding-around-background'>
        <Neo.Modal
          title='File upload error'
          acceptButton={{ text: "Okay", variant: "primary" }}
          closeButton={false}
          bind={this.viewModel.meta.showFileSizeModal}
        >
          <p >When uploading documents, members may only submit up to 5 files that are each 5mb or less.</p>
        </Neo.Modal>
        {!this.viewModel.complimentReview && !this.viewModel.queryReview &&
          <div>
            <div className='background-card body-card-complaints-dropdown'>
              <div className='row'>
                <div className='form-group col-12 col-md-12'>
                  <p className='card-header-query mt-2'>Have a question or need assistance?</p>
                  <div>
                  </div>
                  <p className='card-header-complaint'>How can we help you?</p>
                </div>
              </div>
              <div className='row btn-row'>
                <div className='align-contact-complain-buttons mt-2 col-6 col-md-6'>
                  <Neo.Button text="Queries" id='btn-contact-us' onClick={(e) => this.viewModel.showQuery(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-complain' : 'col-6 btn btn-contact'} />
                  <Neo.Button text="Compliments" id='btn-complain' onClick={(e) => this.viewModel.showCompliment(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-contact' : 'col-6 btn btn-complain'} />
                </div>
              </div>
            </div>
            <div className='background-card body-card-complaints'>
              <div className={this.viewModel.displayCompliment ? 'hide-complain' : 'show-complain'}>
                <div className='complaints-css'>
                  <SchemeInformationHeader schemeHeader={'Select a related scheme'} memberName={this.viewModel.memberName} showClaimButton={false} extraCssClasses="" onSchemeSelected={(scheme) => { this.viewModel.setSchemeInfo(scheme); }} />
                  <div className='row queries-complaints-divider-padding'>
                    <div className='divider-complain' />
                  </div>
                  <div className='row ps-2 me-2'>
                    <div className='col-md-6'>
                      <Neo.Card className='member-details-card-bfs' >
                        <div className='member-card-information-bfs'>
                          <div className='complaint-card-header'>Member details</div>
                          <div> {this.viewModel.memberName}</div>
                          <div>{this.viewModel.memberNo}</div>
                        </div>
                      </Neo.Card>
                    </div>
                    <div className='col-md-6'>
                      <Neo.Card className='member-details-card-bfs'>
                        <div className='member-card-information-bfs'>
                          <div className='complaint-card-header'>Scheme details</div>
                          <div> {this.viewModel.schemeName}</div>
                          <div>{this.viewModel.schemeNumber}</div>
                        </div>
                      </Neo.Card>
                    </div>
                  </div>
                  <div className='pt-3 mt-4 pb-3 ps-2 me-2 row'>
                    <div className='form-group padding-fix-complaint col-12 col-md-6'>
                      <label className='form-label'>Cell number</label>
                      <div className='row p-0 col-md-12'>
                        <div className='country-dropdown col-2 col-md-2'>
                          <Neo.AutoCompleteDropDown
                            bind={this.viewModel.helpersService.meta.countryValueId}
                            bindDisplay={this.viewModel.helpersService.meta.countryValue}
                            items={this.viewModel.masterDataService.countryCodes}
                            onItemSelected={() => {
                              this.viewModel.helpersService.setCountryValue(this.viewModel.memberQuery);
                              this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberQuery);
                              this.viewModel.hasNoInfo();
                            }} />
                        </div>
                        <div className='col-9 col-md-10 phone-control-col'>
                          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                            <Neo.FormGroup className='phone-control tel-control-complaint'
                              bind={this.viewModel.memberQuery.meta.memberContactNumber}
                              placeholder={"Please enter cell number"}
                              onBlur={() => {
                                this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberQuery);
                                this.viewModel.hasNoInfo()
                              }} />
                          </Neo.FormContext>
                        </div>
                      </div>
                    </div>
                    <div className='form-group form-group-email col-12 col-md-6'>
                      <label className='form-label'>Email address</label>
                      <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                        <Neo.FormGroup className='phone-control email-control'
                          bind={this.viewModel.memberQuery.meta.emailAddress}
                          placeholder={"Please enter email address"}
                          required
                          onBlur={() => this.viewModel.hasNoInfo()} />
                      </Neo.FormContext>
                    </div>
                  </div>
                  <div className="p-2 mt-2 mb-md-4 row">
                    <div className="form-group padding-fix-complaint-box col-12 col-md-12">
                      <label className='form-label'>Query<span className='display-asterisk'>*</span></label>
                      <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                        <Neo.FormGroup
                          className="query-control"
                          bind={this.viewModel.memberQuery.meta.description}
                          placeholder='Please enter query'
                          input={{ rows: 4 }}>
                        </Neo.FormGroup>
                      </Neo.FormContext>
                    </div>
                  </div>
                  <div className='p-2 mt-md-2 mb-2 mb-md-4 row'>
                    <div className='col-12 padding-fix-complaint col-md-12'>
                      <div className='upload-card card'>
                        <div className='row'>
                          <div className="form-group padding-fix-complaint col-12 col-md-12">
                            <label className='mt-2 ms-2 form-label semi-bold'>Upload document/s</label>
                          </div>
                        </div>
                        <div className="form-group col-12 padding-fix-complaint col-md-12">
                          <label className='ms-2 form-label'>Max file size: 5mb <br></br> File types allowed: .pdf, .jpeg, .docx, .png, .tiff, .heic</label>
                        </div>
                        <div className='form-group col-12 col-md-12'>
                          <Neo.FileContext fileManager={this.viewModel.queriesFileManager}>
                            <Neo.FileDropArea
                              className='file-drop-area'>
                              <i><img src={img_Upload} /></i>
                              <label className='file-upload-label'> Drag-and-drop a file or</label>
                              <Neo.FileUploadButton
                                fileManager={this.viewModel.queriesFileManager}
                                className='browse-button'>browse to upload</Neo.FileUploadButton>
                            </Neo.FileDropArea>
                            <Neo.FileUploadProgress className='grey-upload-box' />
                            {(this.viewModel.memberQuery.files && this.viewModel.memberQuery.files.length > 0) &&
                              <div className='pb-3'>
                                < div className="form-group padding-fix-complaints ms-2 mt-2 col-12 col-md-12 ">
                                  <label className='form-label'>Uploaded document/s</label>
                                </div>
                                <div>
                                  {this.viewModel.memberQuery.files.map((item) => {
                                    return <div className='row file-upload-documents phone-remove-margin'>
                                      <div className='col-12 phone-padding-left-zero'>
                                        <div className='add-margin-to-right phone-justify-left'>
                                          <div className='uploaded-document-info-block'>
                                            <div className='uploaded-document-info-text'>
                                              <div className='file-name'>
                                                {item.fileName}
                                              </div>
                                            </div>
                                            <span className='image-container'>
                                              <img src={img_view} onClick={() => this.viewModel.downloadQuerySecureDocument(item.fileDescriptorId, item.fileName)} />
                                              <img src={img_cross} onClick={() => this.viewModel.deleteQueryFile(item.fileName)} /></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </div>
                              </div>
                            }
                          </Neo.FileContext>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ContainerFooterComponent
                    showPrimaryButton={true}
                    showSecondaryButton={true}
                    primaryButtonText='Continue'
                    secondaryButtonText='Clear'
                    backLinkText={(this.viewModel.memberQuery.isSelfDirty && this.viewModel.memberQuery.isValid) ? 'Save & exit' : 'Back to dashboard'}
                    secondaryButtonCallback={() => this.viewModel.showModal = true}
                    primaryButtonCallback={() => (this.viewModel.queryReview = true, window.scrollTo(0, 0))}
                    backLinkCallback={() => (this.viewModel.hasNoInfo(), this.viewModel.navigateToDashboard())}
                    disablePrimaryButton={!this.viewModel.memberQuery.isValid}
                  />
                </div>
              </div>
              <div className={this.viewModel.displayCompliment ? 'show-complain' : 'hide-complain'}>
                <div className='row'>
                  <div className='form-group col-12 col-md-12'>
                    <div className='complaints-css'>
                      <SchemeInformationHeader schemeHeader={'Select a related scheme.'} memberName={'Benji'} showClaimButton={false} extraCssClasses="" onSchemeSelected={(scheme) => { this.viewModel.setSchemeInfo(scheme); }} />
                      <div className='row queries-complaints-divider-padding'>
                        <div className='divider-complain' />
                      </div>
                      <div className='row ps-2 me-2'>
                        <div className='col-md-6'>
                          <Neo.Card className='member-details-card-bfs' >

                            <div className='member-card-information-bfs'>
                              <div className='complaint-card-header'>Member details</div>
                              <div> {this.viewModel.memberName}</div>
                              <div>{this.viewModel.memberNo}</div>
                            </div>
                          </Neo.Card>
                        </div>
                        <div className='col-md-6'>
                          <Neo.Card className='member-details-card-bfs'>

                            <div className='member-card-information-bfs'>
                              <div className='complaint-card-header'>Scheme details</div>
                              <div> {this.viewModel.schemeName}</div>
                              <div>{this.viewModel.schemeNumber}</div>
                            </div>
                          </Neo.Card>
                        </div>
                      </div>
                      <div className='pt-3 mt-4 pb-3 ps-2 me-2 row'>
                        <div className='form-group padding-fix-complaint col-12 col-md-6'>
                          <label className='form-label'>Cell number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2 drop-down-label'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.helpersService.meta.countryValueId}
                                bindDisplay={this.viewModel.helpersService.meta.countryValue}
                                items={this.viewModel.masterDataService.countryCodes}
                                onItemSelected={() => {
                                  this.viewModel.helpersService.setCountryValue(this.viewModel.memberCompliment);
                                  this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberCompliment);
                                  this.viewModel.hasNoInfo();
                                }} />
                            </div>
                            <div className='col-9 col-md-10 phone-control-col'>
                              <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                                <Neo.FormGroup className='phone-control tel-control-complaint'
                                  bind={this.viewModel.memberCompliment.meta.memberContactNumber}
                                  placeholder={"Please enter cell number"}
                                  onBlur={() => {
                                    this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberCompliment);
                                    this.viewModel.hasNoInfo();
                                  }}>

                                </Neo.FormGroup>
                              </Neo.FormContext>
                            </div>
                          </div>
                        </div>
                        <div className='form-group form-group-email col-12 col-md-6'>
                          <label className='form-label'>Email address</label>
                          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                            <Neo.FormGroup className='phone-control email-control'
                              bind={this.viewModel.memberCompliment.meta.emailAddress}
                              placeholder={"Please enter email address"}
                              required
                              onBlur={() => this.viewModel.hasNoInfo()}>

                            </Neo.FormGroup>
                          </Neo.FormContext>
                        </div>
                      </div>
                      <div className='p-2 mt-2 mb-2 mb-md-4 row'>
                        <div className="form-group padding-fix-complaint-box col-12 col-md-12">
                          <label className='form-label'>Compliment<span className='display-asterisk'>*</span></label>
                          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                            <Neo.FormGroup
                              className="query-control"
                              bind={this.viewModel.memberCompliment.meta.description}
                              placeholder='Please enter compliment'
                              input={{ rows: 4 }}
                            />
                          </Neo.FormContext>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        showPrimaryButton={true}
                        showSecondaryButton={true}
                        primaryButtonText='Continue'
                        secondaryButtonText='Clear'
                        backLinkText={(this.viewModel.memberCompliment.isSelfDirty && this.viewModel.memberCompliment.isValid) ? 'Save & exit' : 'Back to dashboard'}
                        secondaryButtonCallback={() => this.viewModel.showModal = true}
                        primaryButtonCallback={() => (this.viewModel.complimentReview = true, window.scrollTo(0, 0))}
                        backLinkCallback={() => (this.viewModel.hasNoInfo(), this.viewModel.navigateToDashboard())}
                        disablePrimaryButton={!this.viewModel.memberCompliment.isValid}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.viewModel.complimentReview &&
          <ComplimentReview viewModel={this.viewModel} />
        }
        {this.viewModel.queryReview &&
          <QueryReview viewModel={this.viewModel} />
        }
        <Neo.Modal
          bind={this.viewModel.meta.showModal}
          title='Are you sure you want to clear?'
          acceptButton={{ text: "Continue", variant: "primary", onClick: () => this.viewModel.clearQueryOrComplimentInfo() }}
          closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showModal = false }}
        >
          <p>The information that you have filled out will be lost and you will be directed to an empty screen.</p>
        </Neo.Modal>
        <Neo.Modal
          title='Compliment submitted'
          bind={this.viewModel.meta.complimentSummary}
          acceptButton={{ text: "Back to dashboard", variant: "primary", onClick: () => this.viewModel.navigateToDashboard() }}
          closeButton={{ text: "Make another compliment", variant: "danger", onClick: () => this.viewModel.backToComplimentAfterSubmit() }}
          showCancelButton={true}>
          <img src={successful} className='checked-img' />
          <br />< br />
          <p>
            Thank you for your compliment.
          </p>
        </Neo.Modal>
        <Neo.Modal
          title='Query submitted'
          bind={this.viewModel.meta.querySummary}
          acceptButton={{ text: "Back to dashboard", variant: "primary", onClick: () => this.viewModel.navigateToDashboard() }}
          closeButton={{ text: "Make another query", variant: "danger", onClick: () => this.viewModel.backToQueryAfterSubmit() }}
          showCancelButton={true}>
          <img src={successful} className='checked-img' />
          <br />< br />
          <p>
            Your query has been submitted. <br />
            A Liberty consultant will be in touch as soon as possible.
          </p>
        </Neo.Modal>
        <Neo.Modal
          bind={this.viewModel.meta.showQuerySaveExitModal}
          title='Save & exit?'
          acceptButton={{
            text: "Continue", variant: "primary", onClick: () => {
              this.viewModel.hasNoInfo();
              this.viewModel.navigateToDashboard();
            }
          }}
          closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showQuerySaveExitModal = false }}
        >
          <p>Please note: This query has not been submitted. The information captured will be retained for 30 days. Please return to this screen to complete and submit.</p>
        </Neo.Modal>
        <Neo.Modal
          bind={this.viewModel.meta.showComplimentSaveExitModal}
          title='Save & exit?'
          acceptButton={{
            text: "Continue", variant: "primary", onClick: () => {
              this.viewModel.hasNoInfo();
              this.viewModel.navigateToDashboard();
            }
          }}
          closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showComplimentSaveExitModal = false }}
        >
          <p>Please note: This compliment has not been submitted. The information captured will be retained for 30 days. Please return to this screen to complete and submit.</p>
        </Neo.Modal>
      </div >
    );
  }
}