import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../App/Services/AppService';
import EmployerReportsAndDocumentsSearchCriteria from '../../Models/Lookups/EmployerDashboard/EmployerReportsAndDocumentsSearchCriteria';
import EmployerReportsAndDocumentsSearchResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerReportsAndDocumentsSearchResultsLookup';

export interface IEmployerReportsAndDocumentsApiClient {

  /** 
   * Returns the ReportGeneratedResultsLookupListPaged .
   * @returns Paged List Of InstructionsSearchResults.
   */
  getGeneratedReportsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerReportsAndDocumentsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerReportsAndDocumentsSearchResultsLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class EmployerReportsAndDocumentsApiClient extends Data.ApiClientBase implements IEmployerReportsAndDocumentsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/EmployerReportsAndDocuments`);
  }

  public getGeneratedReportsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerReportsAndDocumentsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerReportsAndDocumentsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/report-generator-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}