import { Validation } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import BlueRibbon from '../../App/Views/Components/BlueRibbon';
import EmployerMemberProfileVM from '../Views/Employer/EmployerMemberProfileVM';

interface IEmployerMemberProfileComponentProps {
  viewModel: EmployerMemberProfileVM,
}

@observer
export default class EmployerMemberProfileComponent extends React.Component<IEmployerMemberProfileComponentProps> {

  constructor(props: IEmployerMemberProfileComponentProps) {
    super(props);
  }

  render() {
    return (
      <div className='employer-profile-css'>
        <div className='pt-4'>
          <BlueRibbon text='You can update member information on your employer billing schedule.' />
        </div>
        <div className='align-form-group-row-profile'>
          <Neo.GridLayout>
            <Neo.FormGroup href='Title' bind={this.props.viewModel.personContactDetailsLookup.meta.title} readOnly isDisabled />
            <Neo.FormGroup href='Surname' bind={this.props.viewModel.personContactDetailsLookup.meta.lastName} readOnly isDisabled />
            <Neo.FormGroup href='Name' bind={this.props.viewModel.personContactDetailsLookup.meta.firstName} readOnly isDisabled />
            <Neo.FormGroup href="SAIdOrPassportNumber" bind={this.props.viewModel.personContactDetailsLookup.meta.nationalIdNumber} readOnly isDisabled />
            <Neo.FormGroup href='DateOfBirth' bind={this.props.viewModel.personContactDetailsLookup.meta.birthDate} readOnly isDisabled />
            <Neo.FormGroup href='CountryOfIssue' bind={this.props.viewModel.personContactDetailsLookup.meta.passportCountryOfIssue} readOnly isDisabled />
            <Neo.FormGroup href='TelephoneNumber' bind={this.props.viewModel.telephoneInfo.meta.telePhoneNo} readOnly isDisabled />
            <Neo.FormGroup href='EmailAddress' bind={this.props.viewModel.emailInfo.meta.emailAddress} readOnly isDisabled />
          </Neo.GridLayout>
        </div>

        <div className='mt-4 mb-4'>
          <hr className='mb-4' />
          <BlueRibbon text='The information displayed may not be as expected as the member might have changed it.' />
        </div>
        <div className='align-form-group-row-profile add-background-color'>
          <div className='align-address-box'>
            <div className='address-header'>Address</div>
            <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Never}>
              <Neo.GridLayout>
                <Neo.FormGroup href='StreetName' bind={this.props.viewModel.addressInfo.meta.addressLine1} readOnly isDisabled />
                <Neo.FormGroup href='BuildingName' bind={this.props.viewModel.addressInfo.meta.addressLine2} readOnly isDisabled />
                <Neo.FormGroup href='City' bind={this.props.viewModel.addressInfo.meta.city} readOnly isDisabled />
                <Neo.FormGroup href="Suburb" bind={this.props.viewModel.addressInfo.meta.addressLine3} readOnly isDisabled />
                <div className="employer-member-profile-dropdown">
                  <label className='dropdowm-label'> Province/State </label>
                  <Neo.AutoCompleteDropDown
                    readOnly
                    bind={this.props.viewModel.meta.provinceId}
                    bindDisplay={this.props.viewModel.meta.provinceName}
                    items={this.props.viewModel.provinces}
                    isDisabled
                  />
                </div>
                <div className="employer-member-profile-dropdown">
                  <label className='dropdowm-label'> Country </label>
                  <Neo.AutoCompleteDropDown
                    readOnly
                    bind={this.props.viewModel.meta.countryId}
                    items={this.props.viewModel.countries}
                    isDisabled
                  />
                </div>
                <Neo.FormGroup href='PostalCode' bind={this.props.viewModel.addressInfo.meta.postalCode} readOnly isDisabled />
              </Neo.GridLayout>
            </Neo.FormContext>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='line-employer-header' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='container-div'>
              <div className="left-div">
                <Neo.Link
                  onClick={() => this.props.viewModel.navigateToDashboard()}
                  className='back-to-port'>
                  Back to dashboard
                </Neo.Link>
              </div>
              {/* ToDo:Needs to be uncommented in future */}
              {/* <div className="right-div">
                <Neo.Button
                  className='cancel-btn-css'
                  variant='primary'
                >
                  Cancel
                </Neo.Button>
                <Neo.Button
                  variant='primary'
                  className='cancel-btn-css update-btn'
                >
                  Update
                </Neo.Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}