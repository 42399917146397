import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../MasterDataTypes';
import Bank from '../../Models/Bank';

export interface IBankQueryApiClient {

  /** 
   * Gets a list of Banks
   * @returns A List of Banks
   */
  getBanks(): AxiosPromise<Array<Model.PlainTrackedObject<Bank>>>;

  /** 
   * Gets a single bank
   * @param bankId code of the bank
   * @returns Bank
   */
  getBank(bankId: number): AxiosPromise<Model.PlainTrackedObject<Bank>>;

  // Client only properties / methods
}

@injectable()
export default class BankQueryApiClient extends Data.ApiClientBase implements IBankQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/banks`);
  }

  public getBanks(): AxiosPromise<Array<Model.PlainTrackedObject<Bank>>> {
    return this.axios.get(`${this.apiPath}/bank-list`);
  }

  public getBank(bankId: number): AxiosPromise<Model.PlainTrackedObject<Bank>> {
    return this.axios.get(`${this.apiPath}/bank/${bankId}`);
  }

  // Client only properties / methods
}