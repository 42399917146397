import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import ThirdPartyNominationLookup from '../../Models/LookUps/ThirdPartyNominations/ThirdPartyNominationLookup';

export interface IThirdPartyNominationsQueryApiClient {

  /**
   * GetThirdPartyMemberNominationLookups
   */
  getThirdPartyMemberNominationLookups(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<ThirdPartyNominationLookup>>;

  /** 
   * Gets the stream of a secure file
   * @returns A  representing the result of the asynchronous operation.
   */
  getSecureDocument(sourceId: string, fileName: string, memberId: number): AxiosPromise<Blob>;

  // Client only properties / methods
}

@injectable()
export default class ThirdPartyNominationsQueryApiClient extends Data.ApiClientBase implements IThirdPartyNominationsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/third-party-nominations`);
  }

  public getThirdPartyMemberNominationLookups(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<ThirdPartyNominationLookup>> {
    return this.axios.get(`${this.apiPath}/third-party-lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public getSecureDocument(sourceId: string, fileName: string, memberId: number): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/secure-document/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}/${memberId}`, { responseType: "blob" });
  }

  // Client only properties / methods
}