export default class Permissions {

  //Search widget
  static readonly searchWidgetAccess: string = "Search widget.Detailed search.Schemes";
  static readonly viewOnlyBeneficiaries: string = "Search widget.Beneficiaries.View";
  static readonly updateBeneficiaries: string = "Search widget.Beneficiaries.Update";
  static readonly viewMemberProfile: string = "Search widget.Member profile.View";

  //Reports and documents
  static readonly reportsAndDocumentsSosv: string = "Reports and documents.Reports.SOSV";
  static readonly reportsAndDocumentsSosvBreakdown: string = "Reports and documents.Reports.SOSV breakdown";
  static readonly reportsAndDocumentsSummaryOfBenefits: string = "Reports and documents.Reports.Summary of benefits";
  static readonly reportsAndDocumentsEmployerSchedule: string = "Reports and documents.Reports.Employer schedule";
  static readonly reportsAndDocumentsMemberInvestmentStatement: string = "Reports and documents.Reports.Member investment statement";
  static readonly reportsAndDocumentsMemberBenefitStatement: string = "Reports and documents.Reports.Member benefit statement";
  static readonly reportsAndDocumentsStatementOfAccount: string = "Reports and documents.Documents.Statement of account";

  //Queries
  static readonly viewQueries: string = "Queries.Log A Query.View";
  static readonly updateQueries: string = "Queries.Log A Query.Update";

  //Instructions
  static readonly viewMemberInstructions: string = "Instructions.Member instructions.View";
  static readonly updateMemberInstructions: string = "Instructions.Member instructions.Update";
  static readonly viewSchemeInstructions: string = "Instructions.Scheme instructions.View";
  static readonly updateSchemeInstructions: string = "Instructions.Scheme instructions.Update";
  static readonly viewFuneralClaimsExclSubmitInstructions: string = "Instructions.Funeral claims excl submit.View";
  static readonly updateFuneralClaimsExclSubmitInstructions: string = "Instructions.Funeral claims excl submit.Update";
  static readonly viewFuneralClaimsSubmitInstructions: string = "Instructions.Funeral claims submit.View";
  static readonly updateFuneralClaimsSubmitInstructions: string = "Instructions.Funeral claims submit.Update";
  static readonly viewWithdrawalClaimsExclSubmitInstructions: string = "Instructions.Withdrawal claims excl submit.View";
  static readonly updateWithdrawalClaimsExclSubmitInstructions: string = "Instructions.Withdrawal claims excl submit.Update";
  static readonly viewWithdrawalClaimsSubmitInstructions: string = "Instructions.Withdrawal claims submit.View";
  static readonly updateWithdrawalClaimsSubmitInstructions: string = "Instructions.Withdrawal claims submit.Update";
  static readonly viewRetirementClaimsExclSubmitInstructions: string = "Instructions.Retirement claims excl submit.View";
  static readonly updateRetirementClaimsExclSubmitInstructions: string = "Instructions.Retirement claims excl submit.Update";
  static readonly viewRetirementClaimsSubmitInstructions: string = "Instructions.Retirement claims submit.View";
  static readonly updateRetirementClaimsSubmitInstructions: string = "Instructions.Retirement claims submit.Update";
  static readonly viewLogQueryOrComplimentInstructions: string = "Instructions.Log query or compliment.View";
  static readonly updateLogQueryOrComplimentInstructions: string = "Instructions.Log query or compliment.Update";
  static readonly viewContactUsInstructions: string = "Instructions.Contact us.View";
  static readonly updateContactUsInstructions: string = "Instructions.Contact us.Update";
  static readonly viewLogComplaintInstructions: string = "Instructions.Log complaint.View";
  static readonly updateLogComplaintInstructions: string = "Instructions.Log complaint.Update";
  static readonly viewDeathClaimsExclSubmitInstructions: string = "Instructions.Death claims excl submit.View";
  static readonly updateDeathClaimsExclSubmitInstructions: string = "Instructions.Death claims excl submit.Update";
  static readonly viewDeathClaimsSubmitInstructions: string = "Instructions.Death claims submit.View";
  static readonly updateDeathClaimsSubmitInstructions: string = "Instructions.Death claims submit.Update";
  static readonly viewSwitchesExclSubmitInstructions: string = "Instructions.Switches excl submit.View";
  static readonly updateSwitchesExclSubmitInstructions: string = "Instructions.Switches excl submit.Update";
  static readonly viewSwitchesSubmitInstructions: string = "Instructions.Switches submit.View";
  static readonly updateSwitchesSubmitInstructions: string = "Instructions.Switches submit.Update";

  //Employer portal main menu
  static readonly viewEmployerPortalMainMenuInstructionsLink: string = "Employer portal main menu.Instructions link.View";
  static readonly viewEmployerPortalMainMenuReportsLink: string = "Employer portal main menu.Reports link.View";
  static readonly viewEmployerPortalMainMenuBillingLink: string = "Employer portal main menu.Billing link.View";
  static readonly viewEmployerPortalMainMenuSearchLink: string = "Employer portal main menu.Search link.View";
  static readonly viewEmployerPortalMainMenuQueriesLink: string = "Employer portal main menu.Queries link.View";
  static readonly viewEmployerPortalMainMenuComplaintsLink: string = "Employer portal main menu.Complaints link.View";

  //Complaints
  static readonly viewComplaints: string = "Complaints.Log complaint.View";
  static readonly updateComplaints: string = "Complaints.Log complaint.Update";
  static readonly viewContactUs: string = "Complaints.Contact us.View";
  static readonly updateContactUs: string = "Complaints.Contact us.Update";

  //Billing
  static readonly viewBilling: string = "Billing.Main menu.View";

  //Dashboard member health
  static readonly viewDashboardMemberHealthRetirementGoalsSummaryWidget: string = "Dashboard member health.Retirement goals summary widget.View";
  static readonly viewDashboardMemberHealthRetirementGoalsSummary: string = "Dashboard member health.Retirement goals summary.View";
  static readonly viewDashboardMemberHealthMemberBeneficiaryStatusWidget: string = "Dashboard member health.Member beneficiary status widget.View";
  static readonly viewDashboardMemberHealthMemberBeneficiaryStatus: string = "Dashboard member health.Member beneficiary status.View";
  static readonly viewDashboardMemberHealthMemberProfileStatusWidget: string = "Dashboard member health.Member profile status widget.View";
  static readonly viewDashboardMemberHealthMemberProfileStatusDrilldown: string = "Dashboard member health.Member profile status drillDown.View";

  //Dashboard scheme health
  static readonly viewDashboardSchemeHealthBillingStatusWidget: string = "Dashboard scheme health.Billing status widget.View";
  static readonly viewDashboardSchemeHealthBillingStatusDrilldown: string = "Dashboard scheme health.Billing status drilldown.View";
  static readonly viewDashboardSchemeHealthRolesAllocationStatusWidget: string = "Dashboard scheme health.Roles allocation status widget.View";
  static readonly viewDashboardSchemeHealthRolesAllocationDrilldown: string = "Dashboard scheme health.Roles allocation Drilldown.View";
}