import { UserRoleType } from "../Common/Models/Enum/UserRoleType.enum";

export class User {
  public id: number = 0;
  public userRoleType: UserRoleType = UserRoleType.Member;

  constructor(userRoleType: UserRoleType) {
    this.userRoleType = userRoleType;
  }
}


