import React from 'react';
import { observer } from 'mobx-react';
import { BenefitGroup } from '../../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import RiskBenefitCard from './RiskBenefitCard';
import { Model } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';

interface IDisabilityOfSpouseBenefitsProps {
  spouseDisabilityBenefitGroup: BenefitGroup | undefined,
}

@observer
export default class DisabilityOfSpouseBenefits extends React.Component<IDisabilityOfSpouseBenefitsProps> {

  constructor(props: IDisabilityOfSpouseBenefitsProps) {
    super(props);
  }

  public render() {
    let memberBenefitCategory = new BenefitGroup("Risk benefits payable on the disability of a nominated spouse", 0);
    return (
      <div>
        {
          <div>
            {
              <Neo.Card
                title={memberBenefitCategory.benefitType}
                className="cards-risk cards-headings-risk"
                isExpanded={memberBenefitCategory.isExpanded}
              >
                <div className='grey-box-risk'>
                  These are benefits that may become payable should your spouse become disabled while you are still employed..
                </div>
                <br />
                {
                  this.props.spouseDisabilityBenefitGroup && this.props.spouseDisabilityBenefitGroup.benefitTotal !== null && this.props.spouseDisabilityBenefitGroup.benefitTotal > 0 &&
                  <RiskBenefitCard
                    benefitGroup={this.props.spouseDisabilityBenefitGroup}
                    disclaimer={this.props.spouseDisabilityBenefitGroup.disclaimer}
                    tableText={this.props.spouseDisabilityBenefitGroup.tableText}
                  />
                }
              </Neo.Card>
            }
          </div>
        }
      </div>
    );
  }
}