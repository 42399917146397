import React from 'react';
import { observer } from 'mobx-react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerComplaintsVM from '../../Views/Employer/EmployerComplaintsVM';
import successful from '../../../App/assets/img/successful.png';

interface IEmployerComplaintSummary {
  viewModel: EmployerComplaintsVM,
}

@observer
export default class EmployerComplaintSummary extends React.Component<IEmployerComplaintSummary> {

  constructor(props: IEmployerComplaintSummary) {
    super(props);
  }

  public render() {
    return (
      <div className='background-card employer-complaint-summary'>
        <div className='compliments-css'>
          <div className='show-on-web-screen'>
            <div className='row img-top-div'>
              <div className="col-md-12 img-container">
                <br /><br />
                <img className="summary-img" src={successful} />
              </div>
              <span className='heading-cu-summary'>Complaint Submitted</span>
              <span className='card-text center'>Your complaint has been submitted.<br />
                A Liberty consultant will be in touch as soon as possible.</span>
            </div>
            <br /><br />
            <div className='row pad-lr'>
              <div className='summary-container border-pix'>
                <h5 className='heading-cu pad-left'>Summary</h5>
                <div className='row'>
                  <div className='divider1'></div>
                </div><br />
                <p className='card-text'>Complaint</p>
                {!this.props.viewModel.showSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complaintTextMember.complaint}</p>
                }
                {this.props.viewModel.showSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complaintTextScheme.complaint}</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='queries-css pt-5'>
          <div className='align-buttons-desktop row '>
            <div className='form-group align-buttons-summary padding-fix-queries'>
              <Neo.Button text="Back to my dashboard" className='padding-fix-queries btn btn-continue btn-lg' />
            </div>
          </div>
          <div className='align-buttons-desktop row p-3'>
            <div className='form-group align-buttons-summary padding-fix-queries'>
              <div className="form-group  padding-fix-queries">
                <Neo.Link className='save-return-link'
                  onClick={() => this.props.viewModel.showComplaintScreen()}
                >
                  Make another complaint
                </Neo.Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}