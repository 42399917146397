import { EnumHelper } from "@singularsystems/neo-core";

export enum ProfileSection {
  Profile = 1,
  Beneficiaries = 2,
  // TODO: Uncomment in R4.2
  //Documents = 3,
  //Products = 4
}

EnumHelper.decorateEnum(ProfileSection, decorator => {
  decorator.describe(ProfileSection.Profile, "Profile", "");
  decorator.describe(ProfileSection.Beneficiaries, "Beneficiaries", "");
  // TODO: Uncomment in R4.2
  //decorator.describe(ProfileSection.Documents, "Documents", "");
  //decorator.describe(ProfileSection.Products, "Products", "");
});