import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import UserRoleLookup from '../../Models/Lookups/UserRoleLookup';

export interface IUserRolesApiClient {

  /** 
   * GetUserRoles
   */
  getUserRoles(): AxiosPromise<Array<Model.PlainObject<UserRoleLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class UserRolesApiClient extends Data.ApiClientBase implements IUserRolesApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.identityConfig.identityApiPath}/user-roles`);
  }

  public getUserRoles(): AxiosPromise<Array<Model.PlainObject<UserRoleLookup>>> {
    return this.axios.get(`${this.apiPath}/user-role-types`);
  }

  // Client only properties / methods
}