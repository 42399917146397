import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';

interface IDeductionsProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class Deductions extends React.Component<IDeductionsProps> {

  constructor(props: IDeductionsProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='header-of-step'>
          Deductions
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Do you have any pending divorce proceedings/orders against you? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.divorceOrderAgainstFund}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }}
          />
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Do you have a final divorce order against you? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            readOnly={this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderFinalised ? true : false}
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.divorceOrderFinalised}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }} />
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Do you have any pending maintenance orders against you? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.maintenanceOrderAgainstFund}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }} />
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Do you have any final maintenance orders against you? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            readOnly={this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderFinalised ? true : false}
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.maintenanceOrderFinalised}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }} />
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Do you have any debt owed in respect of a housing loan? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            readOnly={this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan ? true : false}
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.debtToEmployerForHousingLoan}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }} />
        </div>
        <div className="col-md-6 m-4">
          <label className='align-communication-label'>Is there any debt owed to the employer arising from theft, dishonesty, fraud or misconduct? <span className='display-asterisk'>*</span></label>
          <Neo.RadioList className='align-radio'
            readOnly={this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForDeception ? true : false}
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.debtToEmployerForDeception}
            radioList={{
              items: this.props.viewModel.confirmYesNoList,
              inline: true
            }} />
        </div>
      </div>
    );
  }
}