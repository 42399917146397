import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import ComplaintsView from '../../../App/Views/ComplaintsView';
import ContactUsView from '../../../App/Views/ContactUsView';
import ProfileView from '../../../App/Views/MyPortfolio/ProfileView';
import ProfileVM from '../../../App/Views/MyPortfolio/ProfileVM';
import QueriesView from '../../../App/Views/QueriesAndComplimentsView';
import ValueAddedServicesView from '../../../App/Views/ValueAddedServicesView';
import TAndCTemplate from '../../../Domain/Models/TAndC/TAndCTemplate';
import TAndCTemplateLookup from '../../../Domain/Models/TAndC/TAndCTemplateLookup';
import { FundLookup } from '../../Models/Lookups/FundLookup';
import MyGoalsDetailsView from '../Member/MyGoalsDetailsView';
import ProductsView from '../Member/ProductsView';
import MemberEligibilityLookup from '../../../Party/Models/LookUps/MemberClaims/MemberEligibilityLookup';
import PersonFundsFromSchemesLookup from '../../../Party/Models/LookUps/PersonProfile/PersonFundsFromSchemesLookup';
import { SchemeProductClassType } from '../../../Party/Models/LookUps/SchemeProductClassType';
import SavingsWithdrawalClaimOverviewView from '../../../Party/Views/SavingsWithdrawalClaimOverviewView';
import BeneficiariesView from '../../../Party/Views/Beneficiaries/BeneficiariesView';

@NeoModel
export default class LegacyMemberDashboardVM extends Views.ViewModelBase {

  public savingsContainerClassName: string = 'my-retirement-card-legacy collapsed'
  public savingsContainerClassNameSecond: string = 'savings-projections-container';
  public netReplacementContainerClassName: string = 'goal-card collapsed';
  public replacementContainerClassName: string = 'projections-container';
  public agreeButtonCss: string = 'disabled';
  public showTermsLogoutModal: boolean = false;
  public tAndCTemplate = new TAndCTemplateLookup();
  public showTermsModal: boolean = true;
  public acceptedTAndC: boolean = true;
  public tAndCTemplateLatest = new TAndCTemplate();
  public multipleSchemeDetails = new List(PersonFundsFromSchemesLookup);
  public eligibilityData = new MemberEligibilityLookup();
  public totalInvestmentsAcrossAllSchemes: number = 0;
  public funds = new List(FundLookup);
  public memberLandingPageType: SchemeProductClassType = SchemeProductClassType.RiskOnly;
  public profileVM = this.registerViewModel(ProfileVM);
  public percentageComplete: number = 0;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private memberService = AppService.get(Types.App.Services.MemberDataService),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private authService = AppService.get(Types.App.Services.AuthenticationService),
    public userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private userTAndCCommandApiClient = AppService.get(Types.Domain.ApiClients.UserTAndCCommandApiClient),
    private personProfileApiClient = AppService.get(Types.Parties.ApiClients.PersonProfileApiClient),
    private tAndCQueryApiClient = AppService.get(Types.Domain.ApiClients.TAndCQueryApiClient),
    private memberNominationsQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberNominationsQueryApiClient)) {
    super(taskRunner);
  }

  public navigateToProfileView(): void {
    this.navigation.navigateToView(ProfileView);
  }

  public navigateToContactUsView(): void {
    this.navigation.navigateToView(ContactUsView);
  }

  public navigateToVASView(): void {
    this.navigation.navigateToView(ValueAddedServicesView);
  }

  public navigateToComplaintsView(): void {
    this.navigation.navigateToView(ComplaintsView);
  }

  public navigateToQueriesView(): void {
    this.navigation.navigateToView(QueriesView);
  }

  public navigateToBeneficiariesView(): void {
    this.navigation.navigateToView(BeneficiariesView);
  }

  public async navigateToMyGoalsDetail(monthlyIncome: number, schemeId: number) {
    this.userMemberSchemeService.setCurrentSchemeMember(schemeId);
    this.navigation.navigateToView(MyGoalsDetailsView, { monthlyIncome: monthlyIncome });
  }

  public async navigateToMySavingsComponent(schemeId: number) {
    this.userMemberSchemeService.setCurrentSchemeMember(schemeId);
    this.navigation.navigateToView(SavingsWithdrawalClaimOverviewView);
  }

  public navigateToProductsPage(): void {
    this.navigation.navigateToView(ProductsView);
  }

  public navigateToSavingsWithdrawalClaimsPage(): void {
    this.navigation.navigateToView(SavingsWithdrawalClaimOverviewView);
  }

  public async fetchData() {
    await this.taskRunner.run(async () => {
      await Promise.all([this.getMultipleSchemeFunds(), this.profileVM.personContactDetailsComponentVM.getPersonContactDetails(),
      this.getMemberBeneficiariesCompleteness()])
      this.acceptedTAndC = this.userRoleService.acceptedTAndC;
      if (!this.acceptedTAndC) {
        var resp = await this.tAndCQueryApiClient.getLatestTAndCTemplate();
        this.tAndCTemplate = new TAndCTemplateLookup();
        this.tAndCTemplate.templateContent = resp.data.templateContent
      }
    });
  }

  public async initialise() {
    await this.fetchData();
    this.memberLandingPageType = this.userRoleService.memberLandingPageType;
    this.memberService.showDashboard = "Legacy";
  }

  public setShowMoreShowLess() {
    if (this.netReplacementContainerClassName.indexOf('collapsed') >= 0) {
      this.replacementContainerClassName = 'projections-container';
      this.netReplacementContainerClassName = 'goal-card';
    } else {
      this.replacementContainerClassName = 'projections-container';
      this.netReplacementContainerClassName = 'goal-card collapsed';
    }
  }

  public setShowMoreShowLessSavings() {
    if (this.savingsContainerClassName.indexOf('collapsed') >= 0) {
      this.savingsContainerClassNameSecond = 'savings-projections-container';
      this.savingsContainerClassName = 'my-retirement-card-legacy';
    } else {
      this.savingsContainerClassNameSecond = 'savings-projections-container';
      this.savingsContainerClassName = 'my-retirement-card-legacy collapsed';
    }
  }

  public async acceptTAndC() {
    this.taskRunner.run(async () => await this.userTAndCCommandApiClient.acceptTAndC());
    this.acceptedTAndC = true;
    this.userRoleService.acceptedTAndC = true;
    this.showTermsModal = false;
    this.userRoleService.navigateToDashboard();
  }

  private async downloadPdf() {
    const response = await this.tAndCQueryApiClient.getTermsAndConditionsPdf();
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = downloadUrl;
    link.setAttribute('download', "TermsAndConditions.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public downloadTermsAndConditions() {
    this.taskRunner.run(async () => {
      await this.downloadPdf();
    });
  }

  public navigateToLogoutPage() {
    this.authService.beginSignOut();
  }

  public async getMultipleSchemeFunds() {
    const response = await this.taskRunner.waitFor(this.personProfileApiClient.getFundsFromSchemes());
    this.multipleSchemeDetails.set(response.data);
    this.calculatedTotalInvestmentValue();
  }

  public calculatedTotalInvestmentValue() {
    this.multipleSchemeDetails.forEach((item) =>
      item.currentInvestmentValues.forEach((value) =>
        this.totalInvestmentsAcrossAllSchemes += value.totalInvestmentFundValue ? value.totalInvestmentFundValue : 0
      ));
  }

  public get firstScheme(): PersonFundsFromSchemesLookup | null {
    if (this.multipleSchemeDetails.length > 0) {
      return this.multipleSchemeDetails[0];
    }
    return null;
  }

  public get otherSchemes() {
    if (this.multipleSchemeDetails.length > 1) {
      const firstItemId = this.firstScheme!.schemeId;
      return this.multipleSchemeDetails.filter((item) => {
        return item.schemeId != firstItemId;
      })
    }
    return [];
  }

  public async getMemberBeneficiariesCompleteness() {
    let percentageComplete = await this.taskRunner.waitFor(this.memberNominationsQueryApiClient.getMemberBeneficiariesPercentage());
    this.percentageComplete = percentageComplete.data;
  }
}

