import { DateUtils, EnumHelper, ModelBase, NeoModel, NotifyUtils, StringUtils } from "@singularsystems/neo-core"
import { injectable } from "inversify";
import MemberWebInterfaceOptionLookup from "../../Domain/Models/WebInterfaceOptions/MemberWebInterfaceOptionsLookup";
import { User } from '../../UserModel/User';
import FADashboardView from "../../Dashboard/Views/FA/FADashboardView";
import UserProfileLookup from "../../Identity/Models/Lookups/UserProfileLookup";
import LegacyMemberDashboardView from "../../Dashboard/Views/LegacyMember/LegacyMemberDashboardView";
import MemberDashboardView from "../../Dashboard/Views/Member/MemberDashboardView";
import AddressInfoLookup from "../../Party/Models/LookUps/AddressInfoLookup";
import GetPersonContactDetailLookup from "../../Party/Models/LookUps/GetPersonContactDetailLookup";
import StaffDashboardView from "../../Dashboard/Views/Staff/StaffDashboardView";
import { AppService, Types } from "./AppService";
import WalkMeScriptHelper from "./WalkMeScriptHelper";
import { SchemeProductClassType } from "../../Party/Models/LookUps/SchemeProductClassType";
import { UserRoleType } from "../../Common/Models/Enum/UserRoleType.enum";
import LegacyEmployerDashboardView from "../../Dashboard/Views/Employer/LegacyEmployerDashboardView";
import FAAssistantDashboardView from "../../Dashboard/Views/FAAssistant/FAAssistantDashboardView";

@injectable()
export default class UserRoleService extends ModelBase {
  public loggedInUser: User = new User(UserRoleType.Member);
  public rolesArray = EnumHelper.asList(UserRoleType);
  public roleType: UserRoleType = UserRoleType.Member;
  public hasInitialised = false;
  private isFetching = false;
  public userProfile: UserProfileLookup | null = null;
  public acceptedTAndC: boolean = true;
  public personEmailAddress: string = "";
  public personTelephoneNumber: string = "";
  public personContactDetailsLookup = new GetPersonContactDetailLookup;
  public memberWebInterfaceOptionLookup = new MemberWebInterfaceOptionLookup();
  public hasFuneralBenefits: boolean = false;
  public memberLandingPageType: SchemeProductClassType = SchemeProductClassType.RiskOnly;
  public viewOnlyBeneficiary: boolean = false;

  constructor(
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private personContactDetailsQueryApiClient = AppService.get(Types.Parties.ApiClients.PersonContactDetailsQueryApiClient),
    private userProfileApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
    private userTAndCCommandApiClient = AppService.get(Types.Domain.ApiClients.UserTAndCCommandApiClient),
    private MemberWebInterfaceOptionsQueryApiClient = AppService.get(Types.Domain.ApiClients.MemberWebInterfaceOptionsQueryApiClient),
    private backgroundDataApiClient = AppService.get(Types.Parties.ApiClients.BackgroundDataApiClient),
    private memberBenefitsApiClient = AppService.get(Types.Parties.ApiClients.MemberBenefitsApiClient),
    private legacyMemberDashboardApiClient = AppService.get(Types.Dashboard.ApiClients.LegacyMemberDashboardApiClient),
    private userRolesApiClient = AppService.get(Types.Identity.ApiClients.UserRolesApiClient),
    private userMemberShipService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private authenticationService = AppService.get(Types.App.Services.AuthenticationService),
    private config = AppService.get(Types.App.Config)) {
    super();
    this.makeObservable();
  }

  public async populateData() {
    if (!this.hasInitialised && !this.isFetching) {
      this.isFetching = true;
      await this.authenticationService.signinSilent();
      await this.fetchUserProfile();
      await this.getRoles();
      await this.getHasUserAcceptedTAncC();
      await this.getPersonContactDetails();
      await this.fetchMemberLandingPage();
      await this.fetchInterfaceOptions();
      await this.getHasFuneralBenefits();
      if (this.memberWebInterfaceOptionLookup) {
        WalkMeScriptHelper.injectScript(this.memberWebInterfaceOptionLookup);
      }
      this.isFetching = false, this.hasInitialised = true;
    }
  }

  public async fetchInterfaceOptions() {
    var interfaceResult = await this.MemberWebInterfaceOptionsQueryApiClient.getWebInterfaceOption();
    if (interfaceResult) {
      this.memberWebInterfaceOptionLookup.mapFrom(interfaceResult.data);
    }
  }

  public async fetchUserProfile() {
    if (!this.userProfile) {
      var result = await this.userProfileApiClient.getUserProfileLookup();
      if (result.data) {
        this.userProfile = new UserProfileLookup();
        this.userProfile.set(result.data);

        if (this.userProfile.defaultRole) {
          if (this.userProfile.defaultRole.toLowerCase() == "member") {
            this.loggedInUser = new User(UserRoleType.Member);
            this.roleType = UserRoleType.Member;
          }
          else if (this.userProfile.defaultRole.toLowerCase() == "employer") {
            this.loggedInUser = new User(UserRoleType.Employer);
            this.roleType = UserRoleType.Employer;
            this.viewOnlyBeneficiary = true;
          }
          else if (this.userProfile.defaultRole.toLowerCase() == "financialadviser") {
            this.loggedInUser = new User(UserRoleType.FinancialAdviser);
            this.roleType = UserRoleType.FinancialAdviser;
            this.viewOnlyBeneficiary = true;
          }
          else if (this.userProfile.defaultRole.toLowerCase() == "staff") {
            this.loggedInUser = new User(UserRoleType.Staff);
            this.roleType = UserRoleType.Staff
            this.viewOnlyBeneficiary = true;
          }
          else if (this.userProfile.defaultRole.toLowerCase() == "financialadviserassistant") {
            this.loggedInUser = new User(UserRoleType.FinancialAdviserAssistant);
            this.roleType = UserRoleType.FinancialAdviserAssistant;
            this.viewOnlyBeneficiary = true;
          }
          else {
            this.loggedInUser = new User(UserRoleType.LibertyNonLegacyMember);
            this.roleType = UserRoleType.LibertyNonLegacyMember;
          }
        }
        else {
          //If default role is null
          this.loggedInUser = new User(UserRoleType.Member);
          this.roleType = UserRoleType.Member;
        }
      }
      else {
        window.location.href = `${this.config.identityConfig.appInitFailureUrl}`
      }
    }
  }

  public async fetchMemberLandingPage() {
    var result = await this.legacyMemberDashboardApiClient.getMemberLandingPage();
    if (result) {
      this.memberLandingPageType = result.data.schemeProductClassType;
    }
  }

  public async fetchBackgroundData() {
    this.backgroundDataApiClient.fetchMemberBackgroundData();
  }

  public async getHasUserAcceptedTAncC() {
    var respAccepted = await this.userTAndCCommandApiClient.hasAccepted();
    this.acceptedTAndC = respAccepted.data;
  }

  public navigateToDashboard() {
    if (this.loggedInUser.userRoleType == UserRoleType.Member) {
      this.navigation.navigateToView(LegacyMemberDashboardView);
    }
    else if (this.loggedInUser.userRoleType == UserRoleType.Employer) {
      this.navigation.navigateToView(LegacyEmployerDashboardView);
    }
    else if (this.loggedInUser.userRoleType == UserRoleType.FinancialAdviser) {
      this.navigation.navigateToView(FADashboardView);
    }
    else if (this.loggedInUser.userRoleType == UserRoleType.Staff) {
      this.navigation.navigateToView(StaffDashboardView);
    }
    else if (this.loggedInUser.userRoleType == UserRoleType.LibertyNonLegacyMember) {
      this.navigation.navigateToView(MemberDashboardView);
    }
    else if (this.loggedInUser.userRoleType == UserRoleType.FinancialAdviserAssistant) {
      this.navigation.navigateToView(FAAssistantDashboardView);
    }
  }

  public async getPersonContactDetails() {
    var personContactDetails = await this.personContactDetailsQueryApiClient.getPersonContactDetailsSummaryLookupById();
    if (personContactDetails.data) {
      this.personContactDetailsLookup.mapFrom(personContactDetails.data);

      this.personContactDetailsLookup.personFullName = this.personContactDetailsLookup.firstName + " " + this.personContactDetailsLookup.lastName;

      let defaultEmailInfo = this.personContactDetailsLookup.emailInfo.find(c => !StringUtils.isNullOrWhitespace(c.emailAddress));
      this.personEmailAddress = defaultEmailInfo ? defaultEmailInfo.emailAddress : "";

      let defaultTelephoneInfo = this.personContactDetailsLookup.telephoneInfo.find(c => !StringUtils.isNullOrWhitespace(c.telePhoneNo));
      this.personTelephoneNumber = defaultTelephoneInfo ? defaultTelephoneInfo.telePhoneNo : "";

      if (this.personContactDetailsLookup.nationalIdNumber) {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.nationalIdNumber
      } else {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.passportNumber
      }
    }
    else {
      this.personContactDetailsLookup = new GetPersonContactDetailLookup();
    }
  }

  public getLastLogin() {
    if (this.userProfile) {
      if (this.userProfile.lastLoginDate) {
        return this.userProfile.lastLoginDate.toDateString();
      } else if (this.userProfile.currentSessionLoginDate) {
        return this.userProfile.currentSessionLoginDate.toDateString();
      }
    }
    return DateUtils.today().toDateString();
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }

  public async getHasFuneralBenefits() {
    var hasFuneralBenefits = await this.memberBenefitsApiClient.hasFuneralBenefits();
    this.hasFuneralBenefits = hasFuneralBenefits.data;
  }

  public async setRole(roleOption: UserRoleType) {
    if (roleOption !== UserRoleType.Member) {
      this.viewOnlyBeneficiary = true;
    } else {
      this.viewOnlyBeneficiary = false;
    }
    this.loggedInUser = new User(roleOption);
    this.roleType = roleOption;
  }

  private async getRoles() {
    const response = await this.userRolesApiClient.getUserRoles();
    const roles = response.data;

    if (roles.length == 0) {
      window.location.href = `${this.config.identityConfig.appInitFailureUrl}`
    }
    else {
      if (roles.length > 0) {
        let currentRole = roles.find(role => role.currentUserRole);
        if (currentRole && currentRole.userRoleType) {
          this.setRole(currentRole.userRoleType);
        }
      }

      if (roles.length == 1) {
        this.rolesArray = EnumHelper.asList(UserRoleType).filter(role => role.name == EnumHelper.getItemMetadata(UserRoleType, this.roleType).display);
      }
      else {
        this.rolesArray = [];
        roles.map(role => {
          const matchedRole = EnumHelper.asList(UserRoleType).find(x => x.id == role.userRoleType);
          if (matchedRole) {
            this.rolesArray.push(matchedRole);
          }
        });

        // Add ChangeDefaultRole, if the number of roles is greater than 1
        if (this.rolesArray.length > 1) {
          const matchedRole = EnumHelper.asList(UserRoleType).find(x => x.id == UserRoleType.ChangeDefaultRole);
          if (matchedRole) {
            this.rolesArray.push(matchedRole);
          }
        }
      }
      await this.userMemberShipService.getSchemes(this.loggedInUser.userRoleType);
    }
  }

  public getFirstPersonAddressInfo() {
    var addressInfo = this.personContactDetailsLookup.addressInfo.find(a => StringUtils.isNullOrWhitespace(a.addressLine1));
    if (!addressInfo) {
      addressInfo = new AddressInfoLookup();
    }
    return addressInfo;
  }
}