import { ModalUtils, Routing as NeoRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import NotFound from '../Components/404NotFound';
import OidcLoginRedirect from '../Views/Security/OidcLoginRedirect';
import { AppConfig } from '../Models/AppConfig';
import InstructionsView from '../Views/InstructionsView';
import img_Calculator from "../assets/img/calculator.png";
import img_Dashboard from "../assets/img/primary-dashboard.png";
import img_Clipboard from "../assets/img/clipboard-instructions.png";
import img_Formfill from "../assets/img/form-fill.png";
import img_Reports from "../assets/img/generic.png";
import img_Phone from "../assets/img/phone-1.png";
import img_Queries from '../assets/img/icn-questionmark-circle-updated.png';
import img_Mail from '../assets/img/mail-small.png';
import QueriesView from '../Views/QueriesAndComplimentsView';
import ComplaintsView from '../Views/ComplaintsView';
import ContactUsView from '../Views/ContactUsView';
import ProfileView from '../Views/MyPortfolio/ProfileView';
import ValueAddedServicesView from '../Views/ValueAddedServicesView';
import ProductsView from '../../Dashboard/Views/Member/ProductsView';
import ReportsAndDocumentsView from '../Views/ReportsAndDocumentsView';
import NotificationsView from '../Views/NotificationsView';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import FADashboardView from '../../Dashboard/Views/FA/FADashboardView';
import StaffDashboardView from '../../Dashboard/Views/Staff/StaffDashboardView';
import MemberDashboardView from '../../Dashboard/Views/Member/MemberDashboardView';
import KnowledgeAndFormsView from '../Views/KnowledgeAndFormsView';
import LegacyEmployerDashboardView from '../../Dashboard/Views/Employer/LegacyEmployerDashboardView';
import EmployerSchemeView from '../../Dashboard/Views/Employer/EmployerSchemeView';
import BillingView from '../../Dashboard/Views/Employer/EmployerBillingView';
import EmployerReportsAndDocumentsView from '../../Dashboard/Views/Employer/EmployerReportsAndDocumentsView';
import EmployerProfileView from '../../Dashboard/Views/Employer/EmployerProfileView';
import UsefulLinksView from '../Views/UsefulLinksView';
import EducationalMaterialView from '../Views/EducationalMaterialView';
import EmployerComplaintsView from '../../Dashboard/Views/Employer/EmployerComplaintsView';
import EmployerQueriesComplimentsView from '../../Dashboard/Views/Employer/EmployerQueriesComplimentsView';
import IgniteEmployerDashboardView from '../../Dashboard/Views/Employer/IgniteEmployerDashboardView';
import LegacyMemberDashboardView from '../../Dashboard/Views/LegacyMember/LegacyMemberDashboardView';
import MyGoalsDetailsView from '../../Dashboard/Views/Member/MyGoalsDetailsView';
import img_star from '../assets/img/star.png';
import img_logout from '../assets/img/logout.png';
import UnderWritingView from '../Views/MyPortfolio/UnderWritingView';
import NonEditableProfileView from '../Views/MyPortfolio/NonEditableProfileView';
import IModalOptions from "@singularsystems/neo-core/dist/Components/Modal/ModalOptions";
import '../Styles/RouteService.scss'
import * as IdentityRoutes from '../../Identity/IdentityRoutes';
import SavingsWithdrawalClaimView from '../../Party/Views/SavingsWithdrawalClaimView';
import SavingsWithdrawalClaimOverviewView from '../../Party/Views/SavingsWithdrawalClaimOverviewView';
import EmployerMemberProfileView from '../../Dashboard/Views/Employer/EmployerMemberProfileView';
import RetirementCalculatorView from '../Views/RetirementCalculatorView';
import FAAssistantDashboardView from '../../Dashboard/Views/FAAssistant/FAAssistantDashboardView';

interface ICommonAppRouteProps {
  /** TODO: Add any custom route props here, like userType. */
}

export interface IAppRoute extends NeoRouting.IRoute, ICommonAppRouteProps {

}
export interface IAppMenuItem extends NeoRouting.IMenuRoute, ICommonAppRouteProps {
  onClick?: () => void
}

@injectable()
export class RouteService {

  private routeProvider: Routing.RouteProvider;

  constructor(private config = AppService.get(Types.App.Config),
    private authService = AppService.get(Types.App.Services.AuthenticationService)) {

    this.routeProvider = new Routing.RouteProvider(
      this.getMenuRoutes(),
      this.getPureRoutes(),
      NotFound,
    )
  }

  /**
   * Gets the routes provider
   */
  public get routes(): Routing.RouteProvider {
    return this.routeProvider;
  }

  public async showModal() {
    const modalOptions: Partial<IModalOptions> = {}
    modalOptions.closeButton = false;

    ModalUtils.showYesNo("Are you sure you want to log out?",
      <p className="body-text">By logging out you will be redirected to the inital login page.</p>, (() => this.goToLogOutPage())
    );
  }

  private goToLogOutPage() {
    this.authService.beginSignOut();
  }

  private getMenuRoutes(): IAppMenuItem[] {
    return [
      ...this.getMainMenuRoutes(),
      ...this.getSupportRoutes(),
      ...this.getSettingRoutes(),
      ...this.getEmployerRoutes(),
      ...this.getEmployerSupportRoutes(),
      ...this.getFARoutes(),
      ...this.getStaffRoutes(),
      ...this.getLegacyMemberRoutes(),
      ...this.getFAAssistantRoutes()
    ]
  }

  private getPureRoutes(): IAppRoute[] {
    const pureRoutes =
      [
        {
          path: AppConfig.loginRedirectRoute, component: OidcLoginRedirect, allowAnonymous: true
        },
        {
          path: "/Notifications", component: NotificationsView, allowAnonymous: true
        },
        {
          path: "/Reports", component: ReportsAndDocumentsView, allowAnonymous: true
        },
        {
          path: "/MyBeneficiaries", component: BeneficiariesView
        },
        {
          path: "/UsefulLinks", component: UsefulLinksView, allowAnonymous: true
        },
        {
          path: "/EducationalMaterial", component: EducationalMaterialView, allowAnonymous: true
        },
        {
          path: "/MyGoalsDetail", component: MyGoalsDetailsView, allowAnonymous: true
        },
        {
          path: "/SavingsWithdrawalClaim", component: SavingsWithdrawalClaimView, allowAnonymous: true
        },
        {
          path: "/SavingsWithdrawalClaimOverview", component: SavingsWithdrawalClaimOverviewView, allowAnonymous: true
        },
        {
          path: "/NotFound", component: NotFound, allowAnonymous: true
        },
        {
          path: "/EmployerMemberProfile", component: EmployerMemberProfileView, allowAnonymous: true
        }

        // ...DomainRoutes.PureRoutes,
        // ...ReportingRoutes.PureRoutes,
      ];

    return pureRoutes;
  }

  public getMainMenuRoutes(): IAppMenuItem[] {
    return [
      {
        name: "My dashboard", path: '/MyPortfolio', icon: img_Dashboard, component: MemberDashboardView, children: [
          { name: "My Profile", path: "/MyProfile", component: ProfileView },
          { name: "My Beneficiaries", path: "/MyBeneficiaries", component: BeneficiariesView },
          { name: "My Underwriting", path: "/MyUnderwriting", component: UnderWritingView },
          { name: "My Products", path: "/MyProducts", component: ProductsView },
        ]
      },
      {
        name: "Retirement calculator", path: '/RetirementCalculator', component: RetirementCalculatorView, icon: img_Calculator, exact: true, allowAnonymous: false
      },
      {
        name: "Instructions", path: '/Instructions', component: InstructionsView, icon: img_Clipboard
      },
      {
        name: "Knowledge centre", path: "/KnowledgeCentre", icon: img_Formfill, component: KnowledgeAndFormsView
      },
      {
        name: "Reports", path: "/Reports", component: ReportsAndDocumentsView, icon: img_Reports
      },
    ]
  }

  public getSupportRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Contact us", path: '/ContactUs', component: ContactUsView, icon: img_Phone, exact: true, allowAnonymous: true
      },
      {
        name: "Queries & compliments", path: '/QueriesAndCompliments', component: QueriesView, exact: true, icon: img_Queries, allowAnonymous: true
      },
      {
        name: "Complaints", path: '/Complaints', component: ComplaintsView, exact: true, icon: img_Mail, allowAnonymous: true
      },
      {
        name: "Value-added services", path: '/ValueAddedServices', component: ValueAddedServicesView, exact: true, allowAnonymous: true, icon: img_star
      },
    ]
  }

  public getSettingRoutes(): IAppMenuItem[] {
    let settingsRoutes = [
      {
        name: "Logout", icon: img_logout, onClick: () => { this.showModal() }
      }
    ];

    return settingsRoutes;
  }

  public getEmployerRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Dashboard", path: '/LegacyEmployerDashboard', component: LegacyEmployerDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      },
      //TODO: Uncomment in R4.2
      // {
      //   name: "Ignite Employer Dashboard", path: '/IgniteEmployerDashboard', component: IgniteEmployerDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Scheme", path: '/EmployerScheme', component: EmployerSchemeView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Billing", path: '/Billing', component: BillingView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Profile", path: '/EmployerProfile', component: EmployerProfileView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Reports & documents", path: '/EmployerReportsAndDocuments', component: EmployerReportsAndDocumentsView, icon: img_Reports, exact: true, allowAnonymous: true
      // },
    ]
  }

  public getEmployerSupportRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Queries & Compliments", path: '/EmployerQueriesAndCompliments', component: EmployerQueriesComplimentsView, icon: img_Queries, exact: true, allowAnonymous: true
      },
      {
        name: "Complaints", path: '/EmployerComplaints', component: EmployerComplaintsView, icon: img_Mail, exact: true, allowAnonymous: true
      },
    ]
  }

  public getFARoutes(): IAppMenuItem[] {
    return [
      {
        name: "Dashboard", path: '/FADashboard', component: FADashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      },
    ]
  }

  public getStaffRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Dashboard", path: '/StaffDashboard', component: StaffDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      },
    ]
  }

  public getFAAssistantRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Dashboard", path: '/FAAssistantDashboard', component: FAAssistantDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      },
    ]
  }

  public getLegacyMemberRoutes(): IAppMenuItem[] {
    return [
      {
        name: "My dashboard", path: '/', component: LegacyMemberDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true, children: [
          { name: "My profile", path: "/MyProfile", component: NonEditableProfileView },
          { name: "My products", path: "/MyProducts", component: ProductsView },
          { name: "My beneficiaries", path: "/MyBeneficiaries", component: BeneficiariesView },
        ]
      },
      {
        name: "Knowledge centre", path: "/KnowledgeCentre", icon: img_Formfill, component: KnowledgeAndFormsView
      },
      {
        name: "Reports & documents", path: "/ReportsAndDocuments", component: ReportsAndDocumentsView, icon: img_Reports
      }
    ]
  }
}