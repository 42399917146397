import { AppServices } from '@singularsystems/neo-core';
import VASQueryApiClient from './ApiClients/VAS/VASQueryApiClient';
import KCQueryApiClient from './ApiClients/KnowledgeCentre/KCQueryApiClient';
import FundFactSheetQueryApiClient from './ApiClients/FundFactSheets/FundFactSheetQueryApiClient';
import TAndCQueryApiClient from './ApiClients/TAndC/TAndCQueryApiClient';
import ContactUsQueryApiClient from './ApiClients/ContactUs/ContactUsQueryApiClient';
import PDFQueryApiClient from './ApiClients/PDF/PDFQueryApiClient';
import EducationalInformationQueryApiClient from './ApiClients/EducationalInformation/EducationalInformationQueryApiClient';
import EducationalInformationCommandApiClient from './ApiClients/EducationalInformation/EducationalInformationCommandApiClient';
import JWTTokenApiClient from './ApiClients/JWT/JWTTokenApiClient';
import ReportsApiClient from './ApiClients/Reports/ReportsApiClient';
import UserTAndCCommandApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCCommandApiClient';
import UserTAndCQueryApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCQueryApiClient';
import SystemInformationQueryApiClient from './ApiClients/SystemInformation/SystemInformationQueryApiClient';
import MemberWebInterfaceOptionsQueryApiClient from './ApiClients/WebInterfaceOptions/MemberWebInterfaceOptionsQueryApiClient';
import RiskBenefitsQueryApiClient from './ApiClients/RiskBenefits/RiskBenefitsQueryApiClient';
import SavingsWithdrawalClaimQueryApiClient from './ApiClients/SavingsWithdrawalClaim/SavingsWithdrawalClaimQueryApiClient';
import ClaimDeclarationQueryApiClient from './ApiClients/Claims/ClaimDeclarationQueryApiClient';

// Symbols to expose outside of this module
export const DomainExportedTypes = {
  ApiClients: {
    TAndCQueryApiClient: new AppServices.ServiceIdentifier<TAndCQueryApiClient>("Domain.ApiClient.TAndCQueryApiClient"),
    PDFQueryApiClient: new AppServices.ServiceIdentifier<PDFQueryApiClient>("Domain.ApiClient.PDFQueryApiClient"),
    JWTTokenApiClient: new AppServices.ServiceIdentifier<JWTTokenApiClient>("Domain.ApiClient.JWTTokenApiClient"),
    VASQueryApiClient: new AppServices.ServiceIdentifier<VASQueryApiClient>("Domain.ApiClient.VASQueryApiClient"),
    EducationalInformationQueryApiClient: new AppServices.ServiceIdentifier<EducationalInformationQueryApiClient>("Domain.ApiClient.EducationalInformationQueryApiClient"),
    EducationalInformationCommandApiClient: new AppServices.ServiceIdentifier<EducationalInformationCommandApiClient>("Domain.ApiClient.EducationalInformationCommandApiClient"),
    KCQueryApiClient: new AppServices.ServiceIdentifier<KCQueryApiClient>("Domain.ApiClient.KCQueryApiClient"),
    FundFactSheetQueryApiClient: new AppServices.ServiceIdentifier<FundFactSheetQueryApiClient>("Domain.ApiClient.FundFactSheetQueryApiClient"),
    ContactUsQueryApiClient: new AppServices.ServiceIdentifier<ContactUsQueryApiClient>("Domain.ApiClient.ContactUsQueryApiClient"),
    UserTAndCCommandApiClient: new AppServices.ServiceIdentifier<UserTAndCCommandApiClient>("Domain.ApiClient.UserTAndCCommandApiClient"),
    UserTAndCQueryApiClient: new AppServices.ServiceIdentifier<UserTAndCQueryApiClient>("Domain.ApiClient.UserTAndCQueryApiClient"),
    ReportsApiClient: new AppServices.ServiceIdentifier<ReportsApiClient>("Domain.ApiClient.ReportsApiClient"),
    SystemInformationQueryApiClient: new AppServices.ServiceIdentifier<SystemInformationQueryApiClient>("Domain.ApiClient.SystemInformationQueryApiClient"),
    MemberWebInterfaceOptionsQueryApiClient: new AppServices.ServiceIdentifier<MemberWebInterfaceOptionsQueryApiClient>("Domain.ApiClient.MemberWebInterfaceOptionsQueryApiClient"),
    RiskBenefitsQueryApiClient: new AppServices.ServiceIdentifier<RiskBenefitsQueryApiClient>("Domain.ApiClient.RiskBenefitsQueryApiClient"),
    SavingsWithdrawalClaimQueryApiClient: new AppServices.ServiceIdentifier<SavingsWithdrawalClaimQueryApiClient>("Domain.ApiClient.SavingsWithdrawalClaimQueryApiClient"),
    ClaimDeclarationQueryApiClient: new AppServices.ServiceIdentifier<ClaimDeclarationQueryApiClient>("Domain.ApiClient.ClaimDeclarationQueryApiClient"),
  },
  Services: {
  }
}