import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import checked from '../../../App/assets/img/checked.png';
import unchecked from '../../../App/assets/img/unchecked.png';

interface IMatchingKnownBeneficiariesProps {
  viewModel: BeneficiariesVM,
}

@observer
export default class MatchingKnownBeneficiaries extends React.Component<IMatchingKnownBeneficiariesProps> {

  constructor(props: IMatchingKnownBeneficiariesProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='beneficiaries-all-known'>
          <NeoGrid.Grid items={this.props.viewModel.matchingBeneficiaries} className='known-beneficiaries-table'>
            {(item, meta) => (
              <NeoGrid.Row>
                <NeoGrid.Column label='Full name' className='col-md-3 member-name' sort={meta.firstName}>
                  {item.getFullName()}{!item.isValid && "*"}
                </NeoGrid.Column>
                <NeoGrid.Column label='ID/Passport number' className='col-md-3' sort={meta.nationalIdNoOrPassportNo} display={meta.nationalIdNoOrPassportNo} />
                <NeoGrid.Column label='Relationship' sort={false} className='col-md-2' display={meta.relationship} />
                <NeoGrid.Column
                  sort={false}
                  alignment={'right'}
                  className='col-md-2' >
                  <Neo.Button
                    className='checkbox-table-bak sub-select btn-no-outline-secondary'
                    onClick={() => {
                      item.isSelected = item.isSelected ? false : true
                      if (!item.isSelected) {
                        this.props.viewModel.allBeneficiaries = false;
                      }
                    }}
                    disabled={!this.props.viewModel.canSelectMatchingBeneficiary(item)}
                  >
                    {item.isSelected ? <img src={checked} /> : <img src={unchecked} />} Select
                  </Neo.Button>
                </NeoGrid.Column>
              </NeoGrid.Row>
            )}
          </NeoGrid.Grid>
          <div className='show-on-phone-bat'>
            {this.props.viewModel.matchingBeneficiaries.map((item) => (
              <Neo.Card className='table-card-bak'>
                <div className='row'>
                  <div className='col-10'>
                    <div className='row '>
                      <span className='col-5'>
                        <span className='table-heading-text-phone-bat'>Full Name </span>
                      </span>
                      <span className='col-7'>
                        <span className='table-text-phone-bat'>{item.getFullName()}{!item.isValid && "*"}</span>
                      </span>
                    </div>
                    <div className='row '>
                      <span className='col-5 table-heading-text-phone-bat'>ID/Passport number </span>
                      <span className='col-5'>
                        <span className='table-text-phone-bat'>{item.nationalIdNoOrPassportNo}</span>
                      </span>
                    </div>
                    <div className='row id-padding '>
                      <span className='col-5 table-heading-text-phone-bat '>Relationship </span>
                      <span className='col-5 table-padding-right'>
                        <span className='table-text-phone-bat'>{item.relationship} </span>
                      </span>
                    </div>
                  </div>
                  <div className='col-2 align-checkbox-on-trusted-persons-card'>
                    <Neo.Button className='checkbox-table-bak btn-no-outline-secondary'
                      onClick={() => this.props.viewModel.handleSelectedTrustedPerson(item)}
                      disabled={!this.props.viewModel.canSelectMatchingBeneficiary(item)} >
                      {item.isSelected ? <img src={checked} className="check-box-image-size" /> : <img src={unchecked}
                        className="check-box-image-size" />}
                    </Neo.Button>

                  </div>
                </div>
              </Neo.Card>
            ))}
          </div>
          <div className='align-sub-text'>
            <span>*Please note that this trusted contact person will not be added as a valid beneficiary and cannot be nominated for any benefits.</span>
          </div>
        </div>
      </div>
    );
  }
}