import React from 'react';
import { observer } from 'mobx-react';
import { Neo, Views } from '@singularsystems/neo-react';
import successful from '../../../App/assets/img/successful.png';
import EmployerQueriesComplimentsVM from '../../Views/Employer/EmployerQueriesComplimentsVM';

interface IEmployerQueriesComplimentsSummary {
  viewModel: EmployerQueriesComplimentsVM,
}

@observer
export default class EmployerQueriesComplimentsSummary extends React.Component<IEmployerQueriesComplimentsSummary> {

  constructor(props: IEmployerQueriesComplimentsSummary) {
    super(props);
  }

  public render() {
    return (
      <div className='background-card employer-complaint-summary'>
        <div className='compliments-css'>
          <div className='show-on-web-screen'>
            <div className='row img-top-div'>
              <div className="col-md-12 img-container">
                <br /><br />
                <img className="summary-img" src={successful} />
              </div>
              {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showQueriesSchemeTabDetails) &&
                <>
                  <span className='heading-cu-summary'>Query Submitted</span>
                  <span className='card-text center'>Thank you for your query.</span>
                </>
              }
              {(this.props.viewModel.showComplimentMemberTabDetails || this.props.viewModel.showComplimentSchemeTabDetails) &&
                <>
                  <span className='heading-cu-summary'>Compliment Submitted</span>
                  <span className='card-text center'>Thank you for your compliment.</span>
                </>
              }
            </div>
            <br /><br />
            <div className='row pad-lr'>
              <div className='summary-container border-pix'>
                <h5 className='heading-cu pad-left'>Summary</h5>
                <div className='row'>
                  <div className='divider1'></div>
                </div><br />
                {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showQueriesSchemeTabDetails) &&
                  <p className='card-text'>Query</p>
                }

                {(this.props.viewModel.showComplimentMemberTabDetails || this.props.viewModel.showComplimentSchemeTabDetails) &&
                  <p className='card-text'>Compliment</p>
                }

                {this.props.viewModel.showQueriesMemberTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.queriesTextMember.compliment}</p>
                }
                {this.props.viewModel.showQueriesSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.queriesTextScheme.compliment}</p>
                }
                {this.props.viewModel.showComplimentMemberTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complimentTextMember.compliment}</p>
                }
                {this.props.viewModel.showComplimentSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complimentTextScheme.compliment}</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='queries-css pt-5'>
          <div className='align-buttons-desktop row '>
            <div className='form-group align-buttons-summary padding-fix-queries'>
              <Neo.Button text="Back to my dashboard" className='padding-fix-queries btn btn-continue btn-lg' />
            </div>
          </div>
          <div className='align-buttons-desktop row p-3'>
            <div className='form-group align-buttons-summary padding-fix-queries'>
              <div className="form-group  padding-fix-queries">
                {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showQueriesSchemeTabDetails) &&
                  <Neo.Link className='save-return-link'
                    onClick={() => this.props.viewModel.showComplimentScreen()}
                  >
                    Make another query
                  </Neo.Link>
                }
                {(this.props.viewModel.showComplimentMemberTabDetails || this.props.viewModel.showComplimentSchemeTabDetails) &&
                  <Neo.Link className='save-return-link'
                    onClick={() => this.props.viewModel.showComplimentScreen()}
                  >
                    Make another compliment
                  </Neo.Link>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}