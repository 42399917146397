import { Rules, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import Country from '../../../MasterData/Models/Country';
import { ContactDetailsFieldType } from '../../Models/Enums/ContactDetailsFieldType.enum';
import AddressInfoLookup from '../../Models/LookUps/AddressInfoLookup';
import EmailInfoLookup from '../../Models/LookUps/EmailInfoLookup';
import GetPersonContactDetailLookup from '../../Models/LookUps/GetPersonContactDetailLookup';
import PersonContactDetailsAddressCommand from '../../Models/LookUps/MemberProfileUpdates/PersonContactDetailsAddressCommand';
import UpdatePersonContactDetailsCommand from '../../Models/LookUps/MemberProfileUpdates/UpdatePersonContactDetailsCommand';
import TelephoneInfoLookup from '../../Models/LookUps/TelephoneInfoLookup';
import { AppService, Types } from '../../PartiesTypes';

export default class PersonContactDetailsComponentVM extends Views.ViewModelBase {
  @Rules.Required("Country is required")
  public countryValueId: number | null = null;
  @Rules.Required("Province/state is required")
  public provinceValueId: number | null = null;
  public personContactDetailsLookup = new GetPersonContactDetailLookup();
  public telephoneInfo = new TelephoneInfoLookup();
  public addressInfo = new AddressInfoLookup();
  public emailInfo = new EmailInfoLookup();
  public updatePersonContactDetailsCommand = new UpdatePersonContactDetailsCommand();
  public allVerified: boolean = false;

  public emailOriginal: string = "";
  public phoneOriginal: string = "";
  public originalAddressLine1: string = "";
  public originalAddressLine2: string = "";
  public originalAddressLine3: string = "";
  public originalProvince: string | null = null;
  public originalCountry: string | null = null;
  public originalPostalCode: string = "";
  public originalCity: string = "";
  public originalIsEmailAddressVerified = false;
  public originalIsPhoneAddressVerified = false;
  public originalIsAddressVerified = false;
  public originalContactNumberBase: string = "";
  public originalCountryValue: string = "";

  public showUpdateModal: boolean = false;
  public hidePhoneCheckbox: boolean = false;
  public hideEmailCheckbox: boolean = false;
  public hideAddressCheckbox: boolean = false;
  public hasEmailChanged: boolean = false;
  public hasPhoneChanged: boolean = false;
  public hasAddressChanged: boolean = false;
  public provinces = this.masterDataService.provinces;
  public countries = this.masterDataService.countries;
  public countryCodes = this.masterDataService.countryCodes;
  public southAfricanId = this.countries.find(s => s.name.toLowerCase() === "south africa")?.countryId;
  public countryCodeValueId = this.countryCodes.find(s => s.CountryCode == "+27")?.Id;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private personContactDetailsCommandApiClient = AppService.get(Types.Parties.ApiClients.PersonContactDetailsCommandApiClient),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService)
  ) {
    super(taskRunner);
    this.makeObservable();
  }

  public async initialise() {
    await this.getPersonContactDetails();
  }

  public async getPersonContactDetails() {
    await this.taskRunner.run(async () => {
      this.personContactDetailsLookup.mapFrom(this.userRoleService.personContactDetailsLookup);
      this.telephoneInfo = this.personContactDetailsLookup.telephoneInfo[0] ? this.personContactDetailsLookup.telephoneInfo[0] : new TelephoneInfoLookup();
      this.emailInfo = this.personContactDetailsLookup.emailInfo[0] ? this.personContactDetailsLookup.emailInfo[0] : new EmailInfoLookup();
      this.addressInfo = this.personContactDetailsLookup.addressInfo[0] ? this.personContactDetailsLookup.addressInfo[0] : new AddressInfoLookup();
      this.setCountryValueForProfile(this.telephoneInfo.countryValue);
      this.addressInfo.country = this.getCountry(this.addressInfo.country ?? "");
      this.addressInfo.province = this.getProvince(this.addressInfo.province ?? "");
      this.populateOriginalValues();
      this.checkAllVerified();
    });
  }

  public getProvince(province: string | null): string {
    if (province) {
      if (this.countryValueId != this.southAfricanId && StringUtils.isNullOrWhitespace(province)) {
        return "";
      }
      if (this.countryValueId == this.southAfricanId && StringUtils.isNullOrWhitespace(province)) {
        this.provinceValueId = 1;
        return this.getProvinceById(this.provinceValueId);
      }
      if (province.toLowerCase() == "kwazulu natal") {
        this.provinceValueId = 4;
        return this.getProvinceById(this.provinceValueId);
      }
      let result = this.provinces.find(x => x.province.toLowerCase() === province.toLowerCase());
      if (result) {
        this.provinceValueId = result.id;
        return result.province
      }
      else {
        return province;
      }
    }
    else {
      return "";
    }
  }

  public getProvinceById(id: number | null): string {
    if (id) {
      let result = this.provinces.find(province => province.id == id);
      if (result) {
        return result?.province;
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountry(country: string | null): string {
    if (country) {
      if (country.toLowerCase() == "sa" || country.toLowerCase() == "southafrica" || StringUtils.isNullOrWhitespace(country)) {
        this.countryValueId = this.southAfricanId ?? null;
        return this.getCountryById(this.countryValueId);
      }
      if (country.toLowerCase() == "za") {
        this.countryValueId = this.countries.find(s => s.name.toLowerCase() === "zimbabwe")?.countryId ?? null;
        return this.getCountryById(this.countryValueId);
      }

      let result = this.countries.find(s => s.name.toLowerCase() === country.toLowerCase());
      if (result) {
        this.countryValueId = result.countryId;
        return result.name
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountryById(id: number | null): string {
    if (id) {
      let result = this.countries.find(country => country.countryId == id);
      if (result) {
        return result?.name;
      }
      else {
        return "";
      }
    } else {
      return "";
    }
  }

  public populateOriginalValues() {
    this.hideEmailCheckbox = this.emailInfo.isVerified;
    this.hideAddressCheckbox = this.addressInfo.isVerified;
    this.hidePhoneCheckbox = this.telephoneInfo.isVerified;
    this.emailOriginal = this.emailInfo.emailAddress;
    this.phoneOriginal = this.telephoneInfo.telePhoneNo;
    this.originalAddressLine1 = this.addressInfo.addressLine1;
    this.originalAddressLine2 = this.addressInfo.addressLine2;
    this.originalAddressLine3 = this.addressInfo.addressLine3;
    this.originalPostalCode = this.addressInfo.postalCode;
    this.originalCity = this.addressInfo.city;
    this.originalProvince = this.getProvince(this.addressInfo.province);
    this.originalCountry = this.getCountry(this.addressInfo.country);
    this.originalCountryValue = this.telephoneInfo.countryValue;
    this.originalContactNumberBase = this.telephoneInfo.contactNumberBase;
    this.originalIsAddressVerified = this.addressInfo.isVerified;
    this.originalIsEmailAddressVerified = this.emailInfo.isVerified;
    this.originalIsPhoneAddressVerified = this.telephoneInfo.isVerified;
  }

  public checkAllVerified() {
    if (this.telephoneInfo.isVerified &&
      this.emailInfo.isVerified &&
      this.addressInfo.isVerified) {
      this.allVerified = true;
    }
    else {
      this.allVerified = false;
    }
  }

  public checkIfEmailChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.emailInfo.emailAddress == this.emailOriginal && this.emailInfo.isValid && this.originalIsEmailAddressVerified && !this.hideEmailCheckbox) {
      this.hideEmailCheckbox = true;
    }
    if (this.emailOriginal == this.emailInfo.emailAddress || this.emailInfo.emailAddress == "" || !this.emailInfo.isValid) {
      return;
    }
    if ((this.emailOriginal !== this.emailInfo.emailAddress)
      && this.emailInfo.isValid) {
      this.hideEmailCheckbox = false;
      this.emailInfo.isVerified = true;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Email);
    } else {
      this.hideEmailCheckbox = true;
      this.emailInfo.isVerified = false;
    }
  }

  public checkIfNumberChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.originalContactNumberBase == this.telephoneInfo.contactNumberBase && this.originalCountryValue == this.telephoneInfo.countryValue && this.originalIsPhoneAddressVerified && this.telephoneInfo.isValid && !this.hidePhoneCheckbox) {
      this.hidePhoneCheckbox = true;
    }
    if ((this.originalContactNumberBase == this.telephoneInfo.contactNumberBase && this.telephoneInfo.countryValue == this.originalCountryValue) || !this.telephoneInfo.isValid) {
      return;
    }
    if (((this.telephoneInfo.contactNumberBase !== this.originalContactNumberBase || this.telephoneInfo.countryValue !== this.originalCountryValue) && this.telephoneInfo.isValid)) {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = true;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Telephone);
    } else {
      this.hidePhoneCheckbox = true;
      this.telephoneInfo.isVerified = false;
    }
  }

  public onTelephoneChange(e: any) {
    const targetElement = e.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    this.hidePhoneCheckbox = false;
    this.telephoneInfo.isVerified = false
  }

  public onEmailChange(e: any) {
    const targetElement = e.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    this.hideEmailCheckbox = false;
    this.emailInfo.isVerified = false;
  }

  public onProvinceSelected(item: string) {
    this.addressInfo.province = item;
    if (this.originalProvince!.toLowerCase() === item.toLowerCase()) {
      this.hideAddressCheckbox = true;
      return;
    }
    else {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Address);
    }
  }

  public onCountrySelected(item: string) {
    this.addressInfo.country = item;
    if (item !== "South Africa") {
      this.addressInfo.province = ""
    }
    if (this.originalCountry!.toLowerCase() === item.toLowerCase() || this.originalCountry!.toLowerCase() == "sa" && item.toLowerCase() == "south africa" ||
      this.originalCountry!.toLowerCase() == "za" && item.toLowerCase() == "zimbabwe") {
      this.hideAddressCheckbox = true;
      return;
    }
    else {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Address);
    }
  }

  public onCountryCodeSelected() {
    if (this.telephoneInfo.countryValue == this.originalCountryValue && this.telephoneInfo.contactNumberBase == this.originalContactNumberBase && this.originalIsPhoneAddressVerified && this.telephoneInfo.isValid) {
      this.hidePhoneCheckbox = true;
      this.telephoneInfo.isVerified = true;
      return;
    }
    if (!this.telephoneInfo.isValid) {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = false;
    }
    else {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = true;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Telephone);
    }
  }

  public checkIfAddressChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.originalAddressLine1 == this.addressInfo.addressLine1 && this.originalAddressLine2 == this.addressInfo.addressLine2 &&
      this.originalCity == this.addressInfo.city && this.originalAddressLine3 == this.addressInfo.addressLine3 &&
      this.originalPostalCode == this.addressInfo.postalCode && this.originalProvince == this.addressInfo.province) {
      return;
    }
    if (this.originalAddressLine1 !== this.addressInfo.addressLine1 || this.originalAddressLine2 !== this.addressInfo.addressLine2 ||
      this.originalCity !== this.addressInfo.city || this.originalAddressLine3 !== this.addressInfo.addressLine3 ||
      this.originalPostalCode !== this.addressInfo.postalCode || this.originalProvince !== this.addressInfo.province) {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
      this.contactDetailsFieldHasChanged(ContactDetailsFieldType.Address);
    }
    else {
      this.hideAddressCheckbox = true;
      this.addressInfo.isVerified = false;
    }
  }

  public checkIfAddressClicked(event: any) {
    this.addressInfo.isVerified = event.target.checked;
  }

  public async updateAllDetails(): Promise<void> {
    this.checkAllVerified();

    const emailChanged = this.emailOriginal !== this.emailInfo.emailAddress &&
      this.emailInfo.isValid &&
      this.emailInfo.isVerified &&
      !this.hideEmailCheckbox;

    const phoneChanged = (this.originalContactNumberBase !== this.telephoneInfo.contactNumberBase ||
      this.originalCountryValue !== this.telephoneInfo.countryValue) &&
      this.telephoneInfo.isValid &&
      this.telephoneInfo.isVerified &&
      !this.hidePhoneCheckbox;

    const addressChanged = (this.originalAddressLine1 !== this.addressInfo.addressLine1 ||
      this.originalAddressLine2 !== this.addressInfo.addressLine2 ||
      this.originalCity !== this.addressInfo.city ||
      this.originalAddressLine3 !== this.addressInfo.addressLine3 ||
      (this.countryValueId === this.southAfricanId
        ? this.originalProvince?.toLowerCase() !== this.getProvinceById(this.provinceValueId).toLowerCase()
        : this.originalProvince?.toLowerCase() !== this.addressInfo.province?.toLowerCase()) ||
      this.originalPostalCode !== this.addressInfo.postalCode ||
      this.originalCountry?.toLowerCase() !== this.getCountryById(this.countryValueId).toLowerCase()) &&
      this.addressInfo.isValid &&
      this.addressInfo.isVerified &&
      !this.hideAddressCheckbox;

    const updateEmail = emailChanged || (this.emailInfo.isVerified && this.hideEmailCheckbox !== this.emailInfo.isVerified);
    const updatePhone = phoneChanged || (this.telephoneInfo.isVerified && this.hidePhoneCheckbox !== this.telephoneInfo.isVerified);
    const updateAddress = addressChanged || (this.addressInfo.isVerified && this.hideAddressCheckbox !== this.addressInfo.isVerified);

    this.taskRunner.run(async () => {
      if (updateEmail) {
        this.updatePersonContactDetailsCommand.emailAddress = this.emailInfo.emailAddress;
      }

      if (updatePhone) {
        this.updatePersonContactDetailsCommand.telephoneNo = `${this.telephoneInfo.countryValue} ${this.telephoneInfo.contactNumberBase}`;
      }

      if (updateAddress) {
        const addressCommand = new PersonContactDetailsAddressCommand();
        addressCommand.addressLine1 = this.addressInfo.addressLine1;
        addressCommand.addressLine2 = this.addressInfo.addressLine2;
        addressCommand.addressLine3 = this.addressInfo.addressLine3;
        addressCommand.postalCode = this.addressInfo.postalCode;
        addressCommand.suburb = "";
        addressCommand.city = this.addressInfo.city;
        addressCommand.province = this.countryValueId === this.southAfricanId
          ? this.getProvinceById(this.provinceValueId)
          : this.addressInfo.province;
        addressCommand.country = this.getCountryById(this.countryValueId);
        this.updatePersonContactDetailsCommand.addressInfo = addressCommand;
      }

      if (updateEmail || updatePhone || updateAddress) {
        this.updatePersonContactDetailsCommand.sourceId = this.personContactDetailsLookup.sourceId;
        this.updatePersonContactDetailsCommand.nameId = this.personContactDetailsLookup.nameId;

        const result = await this.personContactDetailsCommandApiClient.updatePersonContactDetails(
          this.updatePersonContactDetailsCommand.toJSObject()
        );
        if (result.data) {
          this.userRoleService.getPersonContactDetails();
          this.contactDetailsFieldHasChanged(ContactDetailsFieldType.None);
          this.showUpdateModal = true;
        }
      }
    });
  }

  public getMemberProfileCompleteness() {
    this.checkAllVerified();

    let percentageComplete = 0;
    const oneThird = 33.33;
    const oneSixth = 16.67;

    if (this.allVerified) {
      percentageComplete = 100;
      return percentageComplete;
    }

    if (this.addressInfo.isVerified) {
      percentageComplete += oneThird;
    } else if (!this.addressInfo.isVerified && this.addressInfo !== null && (!StringUtils.isNullOrWhitespace(this.addressInfo.addressLine1))) {
      percentageComplete += oneSixth;
    }

    if (this.telephoneInfo.isVerified) {
      if (this.telephoneInfo.telephoneType === "Member Personal Cell Phone" || this.telephoneInfo.telephoneType.includes("Cell Phone")
        && (!StringUtils.isNullOrWhitespace(this.telephoneInfo.telePhoneNo))) {
        percentageComplete += oneThird;
      }
    } else if (!this.telephoneInfo.isVerified && this.telephoneInfo != null && (!StringUtils.isNullOrWhitespace(this.telephoneInfo.telePhoneNo))) {
      percentageComplete += oneSixth;
    }

    if (this.emailInfo.isVerified) {
      percentageComplete += oneThird;
    } else if (!this.emailInfo.isVerified && this.emailInfo != null && (!StringUtils.isNullOrWhitespace(this.emailInfo.emailAddress))) {
      percentageComplete += oneSixth;
    }
    return percentageComplete;
  }

  public async cancelChanges() {
    this.emailInfo.emailAddress = this.emailOriginal;
    this.telephoneInfo.telePhoneNo = this.phoneOriginal;
    this.telephoneInfo.contactNumberBase = this.originalContactNumberBase;
    this.setCountryValueForProfile(this.originalCountryValue);
    this.addressInfo.addressLine1 = this.originalAddressLine1;
    this.addressInfo.addressLine2 = this.originalAddressLine2;
    this.addressInfo.addressLine3 = this.originalAddressLine3;
    this.addressInfo.postalCode = this.originalPostalCode;
    this.addressInfo.city = this.originalCity;
    this.addressInfo.province = this.originalProvince;
    this.addressInfo.country = this.originalCountry;
    this.getCountry(this.originalCountry);
    this.getProvince(this.originalProvince);
    this.emailInfo.isVerified = this.originalIsEmailAddressVerified;
    this.telephoneInfo.isVerified = this.originalIsPhoneAddressVerified;
    this.addressInfo.isVerified = this.originalIsAddressVerified;

    this.checkAllVerified();
    if (this.addressInfo.isVerified == true) {
      this.hideAddressCheckbox = true;
    }
    if (this.telephoneInfo.isVerified == true) {
      this.hidePhoneCheckbox = true;
    }
    if (this.emailInfo.isVerified == true) {
      this.hideEmailCheckbox = true;
    }
  }

  public setCountryValueForProfile(countryCode: string) {
    let result = this.masterDataService.countryCodes.find(code => code.CountryCode == countryCode);
    if (result) {
      this.countryCodeValueId = result.Id;
      this.telephoneInfo.countryValue = result.CountryCode;
    }
    else {
      this.countryCodeValueId = this.countryCodes.find(s => s.CountryCode == "+27")?.Id;
      this.telephoneInfo.countryValue = '+27';
    }
  }

  public contactDetailsFieldHasChanged(fieldType: number) {
    if (fieldType == ContactDetailsFieldType.Telephone) {
      this.hasPhoneChanged = true;
    }
    if (fieldType == ContactDetailsFieldType.Email) {
      this.hasEmailChanged = true;
    }
    if (fieldType == ContactDetailsFieldType.Address) {
      this.hasAddressChanged = true;
    }
    if (fieldType == ContactDetailsFieldType.None) {
      this.hasPhoneChanged = false;
      this.hasEmailChanged = false;
      this.hasAddressChanged = false;
    }
  }
}