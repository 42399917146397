import { AppServices } from '@singularsystems/neo-core';
import EmployerDashboardApiClient from './ApiClients/EmployerDashboard/EmployerDashboardApiClient';
import EmployerReportsAndDocumentsApiClient from './ApiClients/EmployerDashboard/EmployerReportsAndDocumentsApiClient';
import DocumentsApiClient from './ApiClients/Documents/DocumentsApiClient';
import EmployerComplaintsApiClient from './ApiClients/EmployerDashboard/EmployerComplaintsApiClient';
import LegacyMemberDashboardApiClient from './ApiClients/LegacyMemberDashboard/LegacyMemberDashboardApiClient';
import MemberDashboardApiClient from './ApiClients/MemberDashboard/MemberDashboardApiClient';
import CallsAndNotificationsApiClient from './ApiClients/Notifications/CallsAndNotificationsApiClient';
import CallsAndInstructionsApiClient from './ApiClients/Instructions/CallsAndInstructionsApiClient';
import SearchWidgetApiClient from './ApiClients/SearchWidget/SearchWidgetApiClient';
import PermissionQueryApiClient from './ApiClients/Permissions/PermissionQueryApiClient';

export const DashboardExportedTypes = {
  ApiClients: {
    EmployerDashboardApiClient: new AppServices.ServiceIdentifier<EmployerDashboardApiClient>("Dashboard.ApiClient.EmployerDashboardApiClient"),
    EmployerComplaintsApiClient: new AppServices.ServiceIdentifier<EmployerComplaintsApiClient>("Dashboard.ApiClient.EmployerComplaintsApiClient"),
    DocumentsApiClient: new AppServices.ServiceIdentifier<DocumentsApiClient>("Dashboard.ApiClient.DocumentsApiClient"),
    EmployerReportsAndDocumentsApiClient: new AppServices.ServiceIdentifier<EmployerReportsAndDocumentsApiClient>("Dashboard.ApiClient.EmployerReportsAndDocumentsApiClient"),
    LegacyMemberDashboardApiClient: new AppServices.ServiceIdentifier<LegacyMemberDashboardApiClient>("LegacyMemberDashboard.ApiClient.LegacyMemberDashboardApiClient"),
    MemberDashboardApiClient: new AppServices.ServiceIdentifier<MemberDashboardApiClient>("MemberDashboard.ApiClient.MemberDashboardApiClient"),
    CallsAndNotificationsApiClient: new AppServices.ServiceIdentifier<CallsAndNotificationsApiClient>("MemberDashboard.ApiClient.CallsAndNotificationsApiClient"),
    CallsAndInstructionsApiClient: new AppServices.ServiceIdentifier<CallsAndInstructionsApiClient>("MemberDashboard.ApiClient.CallsAndInstructionsApiClient"),
    SearchWidgetApiClient: new AppServices.ServiceIdentifier<SearchWidgetApiClient>("Dashboard.ApiClient.SearchWidgetApiClient"),
    PermissionQueryApiClient: new AppServices.ServiceIdentifier<PermissionQueryApiClient>("Dashboard.ApiClient.PermissionQueryApiClient"),
  },
  Services: {
    // Add Services here
  },
};