import { EnumHelper } from "@singularsystems/neo-core";

export enum SchemeStatusGroup {
  Active = 1,
  PendingTermination = 2,
  Terminated = 3,
}

EnumHelper.decorateEnum(SchemeStatusGroup, e => {
  e.describe(SchemeStatusGroup.Active, "Active", ""),
    e.describe(SchemeStatusGroup.PendingTermination, "Pending termination", ""),
    e.describe(SchemeStatusGroup.Terminated, "Terminated", "");
});