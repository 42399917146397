import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import SavingsWithdrawalClaimTemplate from '../../Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplate';
import SavingsWithdrawalClaimTemplateLookup from '../../Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplateLookup';

export interface ISavingsWithdrawalClaimQueryApiClient {

  /**
   * Gets a SavingsWithdrawalClaim template.
   * @returns A SavingsWithdrawalClaim template.
   */
  getSavingsWithdrawalClaimTemplate(): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>>;

  /** 
   * GetSavingsWithdrawalClaimTemplateById
   */
  getSavingsWithdrawalClaimTemplateById(templateId: number): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>>;

  /** 
   * GetSavingsWithdrawalClaimTemplateLookup
   */
  getSavingsWithdrawalClaimTemplateLookup(): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>>;

  // Client only properties / methods
}

@injectable()
export default class SavingsWithdrawalClaimQueryApiClient extends Data.ApiClientBase implements ISavingsWithdrawalClaimQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/savings-withdrawal-claim`);
  }

  public getSavingsWithdrawalClaimTemplate(): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>> {
    return this.axios.get(`${this.apiPath}/savings-template`);
  }

  public getSavingsWithdrawalClaimTemplateById(templateId: number): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>> {
    return this.axios.get(`${this.apiPath}/savings-template/${templateId}`);
  }

  public getSavingsWithdrawalClaimTemplateLookup(): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/savings-template-lookup`);
  }

  // Client only properties / methods
}