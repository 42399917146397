import { Model } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimTemplateLookup from '../../Domain/Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplateLookup';
import { AppService, Types } from '../../Dashboard/DashboardTypes';
import MemberCriteriaLookup from '../Models/LookUps/MemberClaims/MemberCriteriaLookup';
import MemberEligibilityLookup from '../Models/LookUps/MemberClaims/MemberEligibilityLookup';
import SavingsClaimDataCaptureLookup from '../Models/LookUps/MemberClaims/SavingsClaimDataCaptureLookup';
import SavingsWithdrawalClaimView from './SavingsWithdrawalClaimView';

export default class SavingsWithdrawalClaimOverviewVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    public memberSavingsClaimEligibilityQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberSavingsClaimEligibilityQueryApiClient),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private savingsClaimQueryApiClient = AppService.get(Types.Parties.ApiClients.SavingsClaimQueryApiClient),
    private savingsWithdrawalClaimQueryApiClient = AppService.get(Types.Domain.ApiClients.SavingsWithdrawalClaimQueryApiClient)) {

    super(taskRunner);
    this.makeObservable();
  }

  public savingsWithdrawalClaimTemplate = new SavingsWithdrawalClaimTemplateLookup();
  public eligibilityData: MemberEligibilityLookup = new MemberEligibilityLookup();
  public savingsWithdrawalClaimCapture = new SavingsClaimDataCaptureLookup();
  public memberCriteriaLookup = new MemberCriteriaLookup();

  public minimumSofSavingsPotAmount = 2000;

  public async initialise() {
    await this.getLatestSavingsWithdrawalClaimTemplate();
    await this.getMemberEligibilityData();
    await this.getMemberSavingsClaimActiveStatus();
  }

  public getLatestSavingsWithdrawalClaimTemplate = async () => {
    const response = await this.savingsWithdrawalClaimQueryApiClient.getSavingsWithdrawalClaimTemplateLookup();
    const latestSavingsWithdrawalClaimTemplate = response.data;
    this.savingsWithdrawalClaimTemplate = SavingsWithdrawalClaimTemplateLookup.fromJSObject<SavingsWithdrawalClaimTemplateLookup>(latestSavingsWithdrawalClaimTemplate);
  }

  public setParentCardShowMoreShowLess(e: any, isExpanded: Model.ObservableValue<boolean>) {
    if (e.target.className.indexOf("card-header") >= 0) {
      isExpanded.value = !isExpanded.value;
    }
  }

  public navigateToActiveSavingsClaim() {
    this.navigation.navigateToView(SavingsWithdrawalClaimView);
  }

  public navigateToSavingsClaim() {
    this.navigation.navigateToView(SavingsWithdrawalClaimView);
  }

  public navigateToDashboard() {
    this.userRoleService.navigateToDashboard();
  }

  public async getMemberEligibilityData() {
    const response = await this.taskRunner.waitFor(
      this.memberSavingsClaimEligibilityQueryApiClient.getMemberEligibilityLookupById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));
    if (response.data) {
      this.eligibilityData = MemberEligibilityLookup.fromJSObject(response.data);
    } else {
      this.eligibilityData = new MemberEligibilityLookup();
    }
  }

  public async getMemberSavingsClaimActiveStatus() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && this.savingsWithdrawalClaimCapture.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId &&
      this.userMemberSchemeService.currentSchemeSelected.sourceId &&
      this.userMemberSchemeService.currentSchemeSelected.schemeId &&
      this.userMemberSchemeService.currentSchemeSelected.nameId) {
        this.memberCriteriaLookup.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
        this.memberCriteriaLookup.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        this.memberCriteriaLookup.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
        this.memberCriteriaLookup.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        
        let result = await this.taskRunner.waitFor(this.savingsClaimQueryApiClient.isMemberSavingsClaimActive(this.memberCriteriaLookup.toQueryObject()));
        this.savingsWithdrawalClaimCapture.isActive = result.data;
    }
  }
}