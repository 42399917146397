import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { BenefitGroup } from "../../../Party/Models/LookUps/MemberBenefits/BenefitGroup";
import ProductDisabilityTable from "./RiskBenefits/ProductDisabilityTable";
interface IProductDisabilityBenefitCardProps {
  benefitGroup: BenefitGroup
  disclaimer: string
  tableText: string
}

@observer
export default class ProductDisabilityBenefitCard extends React.Component<IProductDisabilityBenefitCardProps> {

  constructor(props: IProductDisabilityBenefitCardProps) {
    super(props);
  }

  public render() {
    return (
      <Neo.Card
        title={this.props.benefitGroup.benefitType}
        className='cards-sub-risk cards-sub-headings-risk'
        isExpanded={this.props.benefitGroup.isExpanded}>
        <ProductDisabilityTable
          benefitGroup={this.props.benefitGroup}
          disclaimer={this.props.disclaimer}
          tableText={this.props.tableText} />
      </Neo.Card>
    );
  }
}