import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import NotificationsSearchCriteria from '../../Models/Lookups/Notifications/NotificationsSearchCriteria';
import NotificationsSearchResultsLookup from '../../Models/Lookups/Notifications/NotificationsSearchResultsLookup';
import { AppService, Types } from '../../../Dashboard/DashboardTypes';


export interface ICallsAndNotificationsApiClient {

  /**
   * Returns the NotificationsSearchResultsLookupListPaged .
   * @returns Paged List Of NotificationsSearchResults.
   */
  getNotificationsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<NotificationsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<NotificationsSearchResultsLookup>>>;

  /** 
   * Returns the NotificationsSearchResultsLookupListPaged .
   * @returns Paged List Of NotificationsSearchResults.
   */
  getGeneralSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<NotificationsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<NotificationsSearchResultsLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class CallsAndNotificationsApiClient extends Data.ApiClientBase implements ICallsAndNotificationsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/CallsAndNotifications`);
  }

  public getNotificationsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<NotificationsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<NotificationsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/notifications-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getGeneralSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<NotificationsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<NotificationsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/notifications-general-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}