import { AppServices } from '@singularsystems/neo-core';
import { NeoReactTypes } from '@singularsystems/neo-react';
import { AppConfig } from './Models/AppConfig';
import { RouteService } from './Services/RouteService';
import { IAppLayout } from './Services/AppLayout';
import { OidcAuthService } from './Services/AuthenticationService';
import { NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { ReportingTypes } from '@singularsystems/neo-reporting';
import { DomainExportedTypes } from '../Domain/DomainExportedTypes';
import MemberDataService from './Services/MemberDataService';
import UserRoleService from './Services/UserRoleService';
import HelpersService from './Services/HelpersService';
import UserMemberSchemeService from './Services/UserMemberSchemeService';
import { IdentitySharedTypes } from '../Identity/IdentitySharedTypes';
import { PartiesExportedTypes } from '../Party/PartiesExportedTypes';
import { DashboardExportedTypes } from '../Dashboard/DashboardExportedTypes';
import { MasterDataExportedTypes } from '../MasterData/MasterDataExportedTypes';
import MasterDataService from './Services/MasterDataService';

const Types = {
  App: {
    Services: {
      AuthenticationService: AppServices.NeoTypes.Security.AuthenticationService.asType<OidcAuthService>(),
      AppLayout: new AppServices.ServiceIdentifier<IAppLayout>("Services.AppLayout"),
      MemberDataService: new AppServices.ServiceIdentifier<MemberDataService>("Services.MemberDataService"),
      RouteService: new AppServices.ServiceIdentifier<RouteService>("Services.RouteService"),
      UserRoleService: new AppServices.ServiceIdentifier<UserRoleService>("Services.UserRoleService"),
      HelpersService: new AppServices.ServiceIdentifier<HelpersService>("Services.HelpersService"),
      UserMemberSchemeService: new AppServices.ServiceIdentifier<UserMemberSchemeService>("Services.UserMemberSchemeService"),
      MasterDataService: new AppServices.ServiceIdentifier<MasterDataService>("Services.MasterDataService"),
    },
    Config: AppServices.NeoTypes.Config.ConfigModel.asType<AppConfig>(),
  },
  Neo: NeoReactTypes,
  Notifications: NotificationServiceTypes,
  Reporting: ReportingTypes,
  Domain: DomainExportedTypes,
  Identity: IdentitySharedTypes,
  Parties: PartiesExportedTypes,
  Dashboard: DashboardExportedTypes,
  MasterData: MasterDataExportedTypes
};

export default Types;