import React from 'react';
import { observer } from 'mobx-react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerQueriesComplimentsVM from '../../Views/Employer/EmployerQueriesComplimentsVM';

interface IEmployerQueriesComplimentsReview {
  viewModel: EmployerQueriesComplimentsVM,
}

@observer
export default class EmployerQueriesComplimentsReview extends React.Component<IEmployerQueriesComplimentsReview> {

  constructor(props: IEmployerQueriesComplimentsReview) {
    super(props);

  }

  public render() {
    return (
      <div className='compliments-css'>
        <div className='background-card'>
          <div className='show-on-web-screen'>
            {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showQueriesSchemeTabDetails) &&
              <div className='heading-co pad-left'>Review query information</div>
            }
            {(this.props.viewModel.showComplimentMemberTabDetails || this.props.viewModel.showComplimentSchemeTabDetails) &&
              <div className='heading-co pad-left'>Review compliment information</div>
            }
            <br />
            <div className='row'>
              <div className='divider1 col-md-12'></div>
            </div><br />
            <div className='pad-rl'>
              <div className='border'>
                <div className='heading-cu pad-left'>Employer information</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  <span className='col-3 card-text'>Employer</span>
                  <span className='col-3 card-text'>Cell number</span>
                  <span className='col-3 card-text'>Email address</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-subheading-text '>John Doe</span>
                  <span className='col-3 card-subheading-text '>+27 83 901 2109</span>
                  <span className='col-3 card-subheading-text '>john.doe@gmail.com</span>
                </div><br />
                <div>
                  <div className='divider1'></div>
                </div>

                <div className='heading-cu pad-left'>Member/scheme information </div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showComplimentMemberTabDetails) &&
                    <span className='col-3 card-text'>Membership details</span>
                  }
                  <span className='col-3 card-text'>Scheme details</span>
                </div>
                {this.props.viewModel.showQueriesMemberTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedQueriesMember.firstName} {this.props.viewModel.selectedQueriesMember.lastName}: {this.props.viewModel.selectedQueriesMember.memberNumber}</span>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedQueriesMember.schemeName}: {this.props.viewModel.selectedQueriesMember.schemeNumber} </span>
                  </div>}
                {this.props.viewModel.showQueriesSchemeTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedQueriesScheme.schemeName}: {this.props.viewModel.selectedQueriesScheme.schemeNumber} </span>
                  </div>}
                {this.props.viewModel.showComplimentMemberTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedComplimentMember.firstName} {this.props.viewModel.selectedComplimentMember.lastName}: {this.props.viewModel.selectedComplimentMember.memberNumber}</span>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedComplimentMember.schemeName}: {this.props.viewModel.selectedComplimentMember.schemeNumber} </span>
                  </div>}
                {this.props.viewModel.showComplimentSchemeTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedComplimentScheme.schemeName}: {this.props.viewModel.selectedComplimentScheme.schemeNumber} </span>
                  </div>}
                <br />
                <div>
                  <div className='divider1'></div>
                </div>
                {(this.props.viewModel.showQueriesMemberTabDetails || this.props.viewModel.showQueriesSchemeTabDetails) &&
                  <span>
                    <div className='heading-cu pad-left'>Query Note</div>
                    <div>
                      <div className='divider1'></div>
                    </div><br />
                    <p className='card-text'>Query</p>
                    {this.props.viewModel.showQueriesMemberTabDetails &&
                      <p className='card-summary-text'>{this.props.viewModel.queriesTextMember.compliment}</p>
                    }
                    {this.props.viewModel.showQueriesSchemeTabDetails &&
                      <p className='card-summary-text'>{this.props.viewModel.queriesTextScheme.compliment}</p>
                    }
                  </span>
                }
                {(this.props.viewModel.showComplimentMemberTabDetails || this.props.viewModel.showComplimentSchemeTabDetails) &&
                  <span>
                    <div className='heading-cu pad-left'>Compliment Note</div>
                    <div>
                      <div className='divider1'></div>
                    </div><br />
                    <p className='card-text'>Compliment</p>
                    {this.props.viewModel.showComplimentMemberTabDetails &&
                      <p className='card-summary-text'>{this.props.viewModel.complimentTextMember.compliment}</p>
                    }
                    {this.props.viewModel.showComplimentSchemeTabDetails &&
                      <p className='card-summary-text'>{this.props.viewModel.complimentTextScheme.compliment}</p>
                    }
                  </span>
                }
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Document </div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  <span className='col-3 card-text'>Uploaded document</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-subheading-text '>KFC.pdf</span>
                </div><br />

              </div>
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='divider1 margin-top'></div>
          </div>
          <div className='queries-css'>
            <div className='align-buttons-desktop row p-3'>
              <div className="form-group align-link col-12 padding-fix-queries col-md-6">
                <Neo.Link className='save-return-link pad-left'>Save & exit</Neo.Link>
              </div>
              <div className='form-group align-buttons col-12 padding-fix-queries col-md-6'>
                <Neo.Button text="Back" className='col-12 padding-fix-queries btn btn-cancel btn-lg' onClick={() => this.props.viewModel.showComplimentScreen()}
                />
                <Neo.Button text="Submit" className='col-12 padding-fix-queries btn btn-continue btn-lg' onClick={() => this.props.viewModel.showComplimentSummary()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}