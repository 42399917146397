import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo } from '@singularsystems/neo-react';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';

interface IBeneficiaryDetailsProps {
  viewModel: BeneficiariesVM
  currentBeneficiary: MemberBeneficiaryLookup,
}

@observer
export default class BeneficiaryDetails extends React.Component<IBeneficiaryDetailsProps> {

  constructor(props: IBeneficiaryDetailsProps) {
    super(props);
  }

  public render() {
    return (
      <div className='add-new-beneficiaries'>
        <div className='center-form'>
          <Neo.GridLayout>
            <Neo.FormGroup className='form-text' href='Firstname' bind={this.props.currentBeneficiary.meta.firstName} placeholder={"Enter name"}
              label={
                <div>
                  First name
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <Neo.FormGroup className='form-text' href='Lastname' bind={this.props.currentBeneficiary.meta.lastName} placeholder={"Enter last name"}
              label={
                <div>
                  Last name
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <div className="align-communication-method">
              <label className='identification-title-text'> Identification type </label>
              <Neo.RadioList className='radio-option'
                bind={this.props.currentBeneficiary.meta.isIDNumber}
                radioList={{ items: [{ id: true, text: "RSA ID number" }, { id: false, text: "Passport number" }], inline: true }}
              />
            </div>
            {!this.props.currentBeneficiary.isIDNumber &&
              <Neo.FormGroup className='form-text' href='RSA ID number' bind={this.props.currentBeneficiary.meta.passportNo} placeholder={"Enter number"}
                label={
                  <div>
                    Passport number
                    <span className='display-asterisk'>*</span>
                  </div>
                } />
            }
            {!this.props.currentBeneficiary.isIDNumber &&
              <div className='province-dropdown'>
                <label className='custom-label'> Country of issue</label>
                <Neo.AutoCompleteDropDown
                  bind={this.props.currentBeneficiary.meta.countryOfIssueValueId}
                  items={this.props.viewModel.countries}
                  onItemSelected={() => this.props.currentBeneficiary.passportCountryOfIssue = this.props.viewModel.getCountryById(this.props.currentBeneficiary.meta.countryOfIssueValueId.value)}
                  placeholder="Select country of issue" />
              </div>
            }
            {this.props.currentBeneficiary.isIDNumber &&
              <Neo.FormGroup className='form-text' href='RSA ID number' bind={this.props.currentBeneficiary.meta.nationalIdNo} placeholder={"Enter number"}
                label={
                  <div>
                    RSA ID number
                    <span className='display-asterisk'>*</span>
                  </div>
                } />
            }
            <div>
              <div className='date-of-birth-label'>
                Date of birth
                <span className='display-asterisk'>*</span>
              </div>
              <Neo.DatePicker
                className='form-text'
                href='BirthDate'
                bind={this.props.currentBeneficiary.meta.birthDate}
                placeholder={"Enter date of birth"}
              />
            </div>
            <Neo.FormGroup className='form-text' href='Relationship' onBlur={() => this.props.currentBeneficiary.relationship = this.props.currentBeneficiary.relationship.trim()}
              bind={this.props.currentBeneficiary.meta.relationship} placeholder={"Enter relationship"}
              label={
                <div>
                  Relationship
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <div className='cell-number-row'>
              <label className='custom-label'>Cell number</label>
              <div className='row '>
                <div className='col-4'>
                  <Neo.AutoCompleteDropDown
                    maxMenuHeight={200}
                    bind={this.props.currentBeneficiary.meta.countryCodeId}
                    bindDisplay={this.props.currentBeneficiary.meta.countryCode}
                    items={this.props.viewModel.countryCodes}
                  />
                </div>
                <div className='col-8'>
                  <Neo.FormGroup className='form-text cell-number'
                    onChange={() => this.props.viewModel.helpersService.splitContactNumber(this.props.currentBeneficiary)}
                    bind={this.props.currentBeneficiary.meta.telephoneNo}
                    placeholder="Enter number"
                    suppressLabel={true} />
                </div>
              </div>
            </div>
            <Neo.FormGroup className='form-text' href='Email address' bind={this.props.currentBeneficiary.meta.emailAddress} placeholder={"Enter address"} />
            <Neo.FormGroup className='form-text' href='Address line 1' bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.addressLine1} placeholder={"Enter address"}
              label={
                <div>
                  Street name & number
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <Neo.FormGroup className='form-text' href='Address line 2' bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.addressLine2} placeholder={"Optional"} />
            <Neo.FormGroup className='form-text' href='Suburb' bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.addressLine3} placeholder={"Enter suburb"}
              label={
                <div>
                  Suburb
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <Neo.FormGroup className='form-text' href='City/Town' bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.city} placeholder={"Enter city/town"}
              label={
                <div>
                  City/Town
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            {this.props.currentBeneficiary.beneficiaryAddressInfo?.country === "South Africa" &&
              <div className="province-dropdown">
                <label className='custom-label'>
                  Province/State
                  <span className='display-asterisk'>*</span>
                </label>
                <Neo.AutoCompleteDropDown className='mt-0 mb-1'
                  bind={this.props.currentBeneficiary.meta.provinceValueId}
                  displayMember='province'
                  items={this.props.viewModel.provinces}
                  onItemSelected={() => this.props.currentBeneficiary.beneficiaryAddressInfo.province = this.props.viewModel.getProvinceById(this.props.currentBeneficiary.meta.provinceValueId.value)}
                  placeholder="Select province/state" />
              </div>
            }
            {this.props.currentBeneficiary.beneficiaryAddressInfo?.country !== "South Africa" &&
              <Neo.FormGroup className='form-text form-group' href='Province/state' bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.province} placeholder={"Enter province/state"}
                label={
                  <div>
                    Province/state
                    <span className='display-asterisk'>*</span>
                  </div>
                } />
            }
            <div className="province-dropdown">
              <label className='custom-label'> Country
                <span className='display-asterisk'>*</span>
              </label>
              <Neo.AutoCompleteDropDown className='mt-0 mb-1'
                bind={this.props.currentBeneficiary.meta.countryValueId}
                items={this.props.viewModel.countries}
                onItemSelected={() => this.props.currentBeneficiary.beneficiaryAddressInfo.country = this.props.viewModel.getCountryById(this.props.currentBeneficiary.meta.countryValueId.value)}
                placeholder="Select Country"
              />
            </div>
            <Neo.FormGroup
              className='form-text'
              href='Postal code'
              bind={this.props.currentBeneficiary.beneficiaryAddressInfo?.meta.postalCode}
              placeholder={"Eg: 2199"}
              label={<div>
                Postal code
                <span className='display-asterisk'>*</span>
              </div>} />
          </Neo.GridLayout>
        </div >
      </div >
    );
  }
}