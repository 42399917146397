import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import ProductsVM from '../../../Views/Member/ProductsVM';
import ReportsAndDocumentsView from '../../../../App/Views/ReportsAndDocumentsView';
import { ReportType } from '../../../../Enums/ReportType.enum';


interface IRiskBenefitsModalProps {
  viewModel: ProductsVM,
  navigation: any
}

@observer
export default class RiskBenefitsModal extends React.Component<IRiskBenefitsModalProps> {

  constructor(props: IRiskBenefitsModalProps) {
    super(props);
  }
  public viewModel = this.props.viewModel;

  public render() {
    return (
      <Neo.Modal

        bind={this.viewModel.meta.showRiskBenefitModal}

        title='Please take note'

        acceptButton={{ text: "Ok", variant: "primary" }}

        closeButton={{ text: "Request Now", variant: "light", onClick: () => this.viewModel.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.MemberBenefitStatement }) }}>

        The information that will be displayed is a summarised view of your Risk benefits. For a detailed view, please view your Member benefit statement.

      </Neo.Modal>
    );
  }
}