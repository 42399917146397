import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class EmployerSchemeMembershipLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    @Attributes.Nullable()
    public readonly schemeId: number | null = null;

    public readonly sourceId: string = "";

    public readonly schemeName: string = "";

    public readonly schemeStatus: string = "";

    public readonly schemeStatusGroup: string = "";

    public readonly paypointIds: number[] = [];

    public readonly paypoints: string[] = [];

    public readonly participationLevelRole: string = "";

    public readonly roleTypes: string[] = [];

    public readonly permissions: string[] = [];

    // Client only properties / methods
}