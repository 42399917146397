import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import RetirementCalculatorVM from './RetirementCalculatorVM';

@observer
export default class RetirementCalculatorView extends Views.ViewBase<RetirementCalculatorVM> {

  constructor(props: unknown) {
    super("Retirement Calculator", RetirementCalculatorVM, props);
  }

  public render() {
    return (
      <div>
        <Neo.Card title="Retirement Retirement">

        </Neo.Card>
      </div>
    );
  }
}