import { Neo, Views } from '@singularsystems/neo-react';
import EmployerMemberProfileVM from './EmployerMemberProfileVM';
import { observer } from 'mobx-react';
import '../../Styles/EmployerMemberProfile.scss';
import BeneficiariesView from '../../../Party/Views/Beneficiaries/BeneficiariesView';
import { ProfileSection } from '../../../Common/Models/Enum/ProfileSection.enum';
import { EnumHelper } from '@singularsystems/neo-core';
import EmployerMemberProfileComponent from '../../Components/EmployerMemberProfileComponent';


class EmployerMemberProfileParams {
  public profileSection = { required: false, isQuery: true };
}

@observer
export default class EmployerMemberProfileView extends Views.ViewBase<EmployerMemberProfileVM, EmployerMemberProfileParams> {
  public static params = new EmployerMemberProfileParams();

  constructor(props: unknown) {
    super("View member information", EmployerMemberProfileVM, props);
  }

  viewParamsUpdated() {
    if (this.viewParams.profileSection.value) {
      this.viewModel.profileSectionId = Number.parseInt(this.viewParams.profileSection.value as string);
      this.viewModel.profileSectionName = EnumHelper.getItemMetadata(ProfileSection, this.viewModel.profileSectionId).name;
      this.viewParams.profileSection.reset();
      this.viewModel.profileSectionSelected(this.viewModel.profileSectionId);
    }
  }

  public render() {
    return (
      <div className='employer-member-profile-view-css'>
        <div className='scheme-information-header-employer'>
          <div className='row'>
            <span className='scheme-card-header'>Review information on behalf of <span className='scheme-card-header-bold'>{this.viewModel.memberDataService.personContactDetailsLookup.personFullName} - {this.viewModel.memberDataService.currentSchemeSelected.memberNumber}</span></span>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className="employer-member-profile-dropdown">
                <div className='employer-card-header'>Select a scheme for more information.</div>
                <label className='dropdowm-label'> Scheme </label>
                <Neo.AutoCompleteDropDown
                  bind={this.viewModel.memberDataService.currentSchemeSelected.meta.schemeId}
                  bindDisplay={this.viewModel.memberDataService.currentSchemeSelected.meta.schemeName}
                  isClearable={false}
                  items={this.viewModel.memberDataService.namesArray}
                />
              </div>
            </div>
            {this.viewModel.profileSectionId !== ProfileSection.Beneficiaries &&
              <div className='col-12 col-md-6'>
                <div className="employer-member-profile-dropdown">
                  <div className='employer-card-header'>Please select a section to view member information.</div>
                  <label className='dropdowm-label'> Select the page you want to view </label>
                  <Neo.AutoCompleteDropDown
                    bind={this.viewModel.meta.profileSectionId}
                    bindDisplay={this.viewModel.meta.profileSectionName}
                    items={this.viewModel.profileSections}
                    onItemSelected={(item) => this.viewModel.profileSectionSelected(item ?? 1)}
                    disabled={this.viewModel.profileSections.length === 1}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        {this.viewModel.showMemberProfile &&
          <EmployerMemberProfileComponent viewModel={this.viewModel} />
        }
        {this.viewModel.showMemberBeneficiary &&
          <BeneficiariesView />
        }
      </div >
    );
  }
}