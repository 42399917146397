import React from 'react';
import { observer } from 'mobx-react';
import QueriesVM from '../QueriesVM';
import { Neo } from '@singularsystems/neo-react';
import "../../../App/Styles/Components/Queries.scss";
import ContainerFooterComponent from './ContainerFooterComponent';

interface IComplimentReviewProps {
  viewModel: QueriesVM
}

@observer
export default class ComplimentReview extends React.Component<IComplimentReviewProps> {

  constructor(props: IComplimentReviewProps) {
    super(props);
  }

  public render() {
    return (
      <div className='compliments-css'>
        <div className='background-card'>
          <div className='show-on-web-screen'>
            <div className='heading-co pad-left'>Review compliment information</div><br />
            <div className='row'>
              <div className='divider1 col-md-12'></div>
            </div><br />
            <div className='pad-rl'>
              <div className='border'>
                <div className='heading-cu pad-left'>Member information</div>
                <div className='divider1' />
                <br /><br />
                <div className='row table-heading-badb'>
                  <span className='col-4 card-text'>Member number</span>
                  <span className='col-4 card-text'>Scheme name</span>
                  <span className='col-4 card-text'>Scheme number</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.memberNo}</span>
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.schemeName}</span>
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.schemeNumber}</span>
                </div><br />
                <div className='row table-heading-badb mt-4'>
                  <span className='col-4 card-text'>Cell number</span>
                  <span className='col-4 card-text'>Email address</span>
                </div>
                <div className='row table-heading-badb'>
                  {this.props.viewModel.memberCompliment.contactNumber.length > 6 &&
                    <span className='col-4 card-subheading-text '>{this.props.viewModel.memberCompliment.contactNumber}</span>
                  }
                  {this.props.viewModel.memberCompliment.contactNumber.length <= 6 &&
                    <span className='col-4 card-subheading-text '></span>
                  }
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.memberCompliment.emailAddress}</span>
                </div><br />
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Compliment note</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <p className='card-summary-text'>{this.props.viewModel.memberCompliment.description}</p>
              </div>
            </div>
          </div>
          <div className='show-on-phone-screen'>
            <div className='heading-cu-phone page-label-heading'>Review compliment information</div><br />
            <div className='row'>
              <div className='divider1'></div>
            </div><br />
            <Neo.Card>
              <div className='heading-cu-phone'>Member information</div>
              <div className='row'>
                <div className='divider1'></div>
              </div>
              <div className='row'>
                <span className='phone-heading'>Member number</span>
                <span className='phone-value'>{this.props.viewModel.memberNo}</span>
                <span className='phone-heading'>Scheme name</span>
                <span className='phone-value'>{this.props.viewModel.schemeName}</span>
                <span className='phone-heading'>Scheme number</span>
                <span className='phone-value'>{this.props.viewModel.schemeNumber}</span>
                <span className='phone-heading'>Cell number</span>
                {this.props.viewModel.memberCompliment.contactNumber.length > 6 &&
                  <span className='phone-value'>{this.props.viewModel.memberCompliment.contactNumber}</span>
                }
                {this.props.viewModel.memberCompliment.contactNumber.length <= 6 &&
                  <span className='phone-value'></span>
                }
                <span className='phone-heading'>Email address</span>
                <span className='phone-value'>{this.props.viewModel.memberCompliment.emailAddress}</span>
              </div>
              <br />
              <div className='row'>
                <div className='divider1'></div>
              </div>
              <div className='heading-cu-phone query-note'>Compliment note</div><br />
              <div className='row'>
                <div className='divider1'></div>
              </div><br />
              <p className='card-summary-text-phone'>{this.props.viewModel.memberCompliment.description}</p>
            </Neo.Card>
          </div><br />
          <ContainerFooterComponent
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonText='Continue'
            secondaryButtonText='Back'
            backLinkText='Save & exit'
            secondaryButtonCallback={() => this.props.viewModel.complimentReview = false}
            primaryButtonCallback={() => this.props.viewModel.submitQueryOrCompliment()}
          />
        </div>
      </div>
    );
  }
}