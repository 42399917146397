import React from "react";
import { AppService, Types } from "../Services/AppService";
import NotificationsView from "../Views/NotificationsView";
import { Neo } from "@singularsystems/neo-react";
import { UserRoleType } from "../../Common/Models/Enum/UserRoleType.enum";
import { EnumHelper } from "@singularsystems/neo-core";

export default class UserStatus extends React.Component {
  public isExpanded = false;
  public headerRightClass = "role-codes-dropdown";
  private authService = AppService.get(Types.App.Services.AuthenticationService);
  private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
  private userRoleService = AppService.get(Types.App.Services.UserRoleService);
  config = AppService.get(Types.App.Config)

  constructor(props: any) {
    super(props);

    this.logoutClicked = this.logoutClicked.bind(this);
  }

  private logoutClicked(e: React.MouseEvent) {
    e.preventDefault();
    this.authService.beginSignOut();
  }

  public navigateNotification(): void {
    this.navigation.navigateToView(NotificationsView);
  }

  private selectedUserRole(roleType: UserRoleType) {
    if (roleType == UserRoleType.ChangeDefaultRole) {
      window.location.href = `${this.config.identityConfig.roleSelectorUrl}`
    }
    else {
      window.location.href = `${this.config.identityConfig.changeRoleUrl}/?Role=${EnumHelper.getItemMetadata(UserRoleType, roleType).name}`;
    }
  }

  public render() {
    const user = this.authService.user;
    return (
      user &&
      <div className="app-user-icon">
        <div className="role-codes-dropdown">
          <Neo.AutoCompleteDropDown
            bind={this.userRoleService.meta.roleType}
            isClearable={false}
            items={this.userRoleService.rolesArray}
            onItemSelected={(item) => {
              if (item) {
                this.selectedUserRole(item.id as UserRoleType)
              }
            }}
          />
        </div>
      </div>
    )
  }
}