import React from 'react';
import { observer } from 'mobx-react';
import LibertyPager from '../../../Dashboard/Components/LibertyPager';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import InstructionsSearchResultsLookup from '../../../Dashboard/Models/Lookups/Instructions/InstructionsSearchResultsLookUp';
import { Data } from '@singularsystems/neo-core';
import InstructionsSearchCriteria from '../../../Dashboard/Models/Lookups/Instructions/IntructionsSearchCriteria';
import InstructionsVM from '../InstructionsVM';

interface IInstructionsWebSchemeTableProps {
  sort_img: string,
  pageManagerScheme: Data.PageManager<InstructionsSearchCriteria, InstructionsSearchResultsLookup>,
  gotToInstructionView(instruction: InstructionsSearchResultsLookup): void
  viewModel: InstructionsVM
}

@observer
export default class InstructionsWebSchemeTable extends React.Component<IInstructionsWebSchemeTableProps> {

  constructor(props: IInstructionsWebSchemeTableProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-web-screen '>
        <div className='row table-heading-badb'>
          <span onClick={() => { this.props.viewModel.SortItems("Name") }} className='col-2 card-heading-text '>Name
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Number") }} className='col-2 card-heading-text '>Number
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span className='col-2 card-heading-text '>No. of Members
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Instruction") }} className='col-2 card-heading-text '>Instruction
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Status") }} className='col-4 card-heading-text '>Status
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
        </div>
        <LibertyPager
          maxPagesToShow={2}
          pageManager={this.props.pageManagerScheme}
          pageControlProps={{
            pageSizeLabel: " ",
            lastText: " ", totalRecords: this.props.pageManagerScheme.totalRecords
          }}>
          <NeoGrid.Grid<InstructionsSearchResultsLookup>>
            {(item) => (
              item.instructionType == "Scheme" && item.status !== "Completed" &&
              <div
                className='table-row-cards-investment'
                onClick={() => this.props.gotToInstructionView(item)}
              >
                <div className='row instruction-card'>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.name}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.instructionNumber}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <Neo.Link className='truncate' href=''>{item.numberOfMembers}</Neo.Link>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.instruction}</span>
                    </div>
                  </div>
                  <div className='col-2 pill-container'>
                    <div className={item.status == 'In progress' ? 'in-progress-pill' : item.status == 'Incomplete' || item.status == 'Requires documents' ? 'incomplete-pill' : 'completed-pill'}>
                      <span className='truncate'>{item.status}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </NeoGrid.Grid>
        </LibertyPager>
      </div>
    );
  }
}