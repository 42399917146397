import { FileUtils, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import EmployerComplaintsResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerComplaintsResultsLookup';
import EmployerComplaintsSchemeResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerComplaintsSchemeResultsLookup';

@NeoModel
export class Compliment extends ModelBase {
  public compliment: string = '';
}

@NeoModel
export default class EmployerQueriesComplimentsVM extends Views.ViewModelBase {
  public countryValueId: number | null = 1;
  public countryValue: string = '+27';
  public displayCompliment: boolean = false;
  public showBlue: boolean = false;
  public cellNumber: string = "";
  public showGreyQueryBoxMember = false;
  public showGreyQueryBoxScheme = false;
  public showGreyComplimentBoxMember = false;
  public showGreyComplimentBoxScheme = false;
  public complimentReview = false;
  public complimentSummary = false;
  public showQueriesMemberTabDetails = false;
  public showQueriesSchemeTabDetails = false;
  public showComplimentMemberTabDetails = false;
  public showComplimentSchemeTabDetails = false;
  public complimentTextMember = new Compliment();
  public complimentTextScheme = new Compliment();
  public queriesTextMember = new Compliment();
  public queriesTextScheme = new Compliment();
  public memberQueriesId: string | null = null;
  public memberQueriesName: string = "";
  public memberComplimentId: string | null = null;
  public memberComplimentName: string = "";
  public selectedQueriesMember: EmployerComplaintsResultsLookup = new EmployerComplaintsResultsLookup;
  public selectedComplimentMember: EmployerComplaintsResultsLookup = new EmployerComplaintsResultsLookup;
  public schemeQueriesId: string | null = null;
  public schemeComplimentId: string | null = null;
  public schemeQueriesName: string = "";
  public schemeComplimentName: string = "";
  public selectedQueriesScheme: EmployerComplaintsSchemeResultsLookup = new EmployerComplaintsSchemeResultsLookup;
  public selectedComplimentScheme: EmployerComplaintsSchemeResultsLookup = new EmployerComplaintsSchemeResultsLookup;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public employerComplaintsApiClient = AppService.get(Types.Dashboard.ApiClients.EmployerComplaintsApiClient),
    public documentsApiClient = AppService.get(Types.Dashboard.ApiClients.DocumentsApiClient),
  ) {

    super(taskRunner);
  }

  public async initialise() {

  }

  public async showCompliment(e: React.MouseEvent) {
    e.stopPropagation();
    this.displayCompliment = !this.displayCompliment;
    this.showBlue = !this.showBlue;
  }

  public showComplimentReview() {
    this.complimentReview = true;
    this.complimentSummary = false;
  }

  public showComplimentSummary() {
    this.complimentReview = false;
    this.complimentSummary = true;
  }

  public showComplimentScreen() {
    this.complimentReview = false;
    this.complimentSummary = false;
  }

  public handleMemberQueryChange = (event: any) => {
    this.queriesTextMember.compliment = event.target.value;
  };

  public handleSchemeQueriesChange = (event: any) => {
    this.queriesTextScheme.compliment = event.target.value;
  };

  public handleMemberComplimentChange = (event: any) => {
    this.complimentTextMember.compliment = event.target.value;
  };

  public handleSchemeComplimentChange = (event: any) => {
    this.complimentTextScheme.compliment = event.target.value;
  };

  public queryMemberSelected(item: EmployerComplaintsResultsLookup | undefined) {
    if (item) {
      this.selectedQueriesMember = item;
      this.showQueriesMemberTabDetails = true;
      this.showQueriesSchemeTabDetails = false;
      this.showComplimentMemberTabDetails = false;
      this.showComplimentSchemeTabDetails = false;
    }
    else {
      this.selectedQueriesMember = new EmployerComplaintsResultsLookup;
    }
  }

  public querySchemeSelected(item: EmployerComplaintsSchemeResultsLookup | undefined) {
    if (item) {
      this.selectedQueriesScheme = item;
      this.showQueriesMemberTabDetails = false;
      this.showQueriesSchemeTabDetails = true;
      this.showComplimentMemberTabDetails = false;
      this.showComplimentSchemeTabDetails = false;
    }
    else {
      this.selectedQueriesScheme = new EmployerComplaintsSchemeResultsLookup;
    }
  }

  public complimentMemberSelected(item: EmployerComplaintsResultsLookup | undefined) {
    if (item) {
      this.selectedComplimentMember = item;
      this.showQueriesMemberTabDetails = false;
      this.showQueriesSchemeTabDetails = false;
      this.showComplimentMemberTabDetails = true;
      this.showComplimentSchemeTabDetails = false;
    }
    else {
      this.selectedComplimentMember = new EmployerComplaintsResultsLookup;
    }
  }

  public complimentSchemeSelected(item: EmployerComplaintsSchemeResultsLookup | undefined) {
    if (item) {
      this.selectedComplimentScheme = item;
      this.showQueriesMemberTabDetails = false;
      this.showQueriesSchemeTabDetails = false;
      this.showComplimentMemberTabDetails = false;
      this.showComplimentSchemeTabDetails = true;
    }
    else {
      this.selectedComplimentScheme = new EmployerComplaintsSchemeResultsLookup;
    }
  }

  public async downloadQueryDocument() {
    const response = await this.taskRunner.waitFor(this.documentsApiClient.getQueryDocument());
    FileUtils.showSaveFile(response);
  }

  public async downloadQueryDocumentFromUrl() {
    const result = await this.documentsApiClient.getQueryDocumentDownloadUrl();
    if (result.data) {
      window.location.href = result.data;
    }
  }

  public async downloadSecureDocument() {
    const response = await this.taskRunner.waitFor(this.documentsApiClient.getSecureDocument());
    FileUtils.showSaveFile(response);
  }
}