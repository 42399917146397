import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import RiskBenefitTable from './RiskBenefitTable';
import { BenefitGroup } from '../../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';

interface IRiskBenefitCardProps {
  benefitGroup: BenefitGroup
  disclaimer: string
  tableText: string
}

@observer
export default class RiskBenefitCard extends React.Component<IRiskBenefitCardProps> {

  constructor(props: IRiskBenefitCardProps) {
    super(props);
  }

  public render() {
    return (
      <Neo.Card
        title={this.props.benefitGroup.benefitType}
        className='cards-sub-risk cards-sub-headings-risk'
        isExpanded={this.props.benefitGroup.isExpanded}>
        <RiskBenefitTable
          benefitGroup={this.props.benefitGroup}
          disclaimer={this.props.disclaimer}
          tableText={this.props.tableText} />
      </Neo.Card>
    );
  }
}