import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import edit from '../../../App/assets/img/edit.png';
import bin from '../../../App/assets/img/bin.png';
import valid from '../../../App/assets/img/img-valid.png';
import warningYellow from '../../../App/assets/img/warning-yellow.png';
import group from '../../../App/assets/img/group.png';
import { Checkbox, FormGroupInline } from '@singularsystems/neo-react/dist/ReactComponents/_Exports';
import NoBeneficiaries from './NoBeneficiaries';
import "../../Styles/Beneficiaries.scss";
import information_icon from '../../../App/assets/img/information-small.png';
import ProgressStep from '../../../Dashboard/Models/ProgressStep';
import FileUploadBeneficiaries from './FileUploadBeneficiaries';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import { NotifyUtils } from '@singularsystems/neo-core';
import { NotificationDuration } from '../../../App/Models/NotificationDuration';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';

interface IBeneficiariesApprovedDeathBenefitsProps {
  viewModel: BeneficiariesVM
  currentStep?: ProgressStep
}

@observer
export default class BeneficiariesApprovedDeathBenefits extends React.Component<IBeneficiariesApprovedDeathBenefitsProps> {

  constructor(props: IBeneficiariesApprovedDeathBenefitsProps) {
    super(props);
  }

  public render() {
    return (
      <div className='beneficiaries-approved-death-benefits'>
        <div className='adding-margin-to-the-sides'>
          {this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member &&
            <div className='row alert-margin'>
              <div className='col-9 col-md-9'></div>
              <div className='col-3 col-md-3'>
                <div className='checkbox-container verify-checkbox-container'>
                  <input
                    type="checkbox"
                    id="confirmationCheckbox"
                    className='form-check-input'
                    checked={this.props.viewModel.verifyApprovedDeathBenefits}
                    onClick={this.props.viewModel.receivedNominationForm ? () => this.props.viewModel.updateVerifiedStatus(this.props.viewModel.tabValue.toString()) : () => NotifyUtils.addWarning("Beneficiary nomination form", "Please upload a valid beneficiary nomination form", NotificationDuration.Long)}
                  />
                  <label className='address-tick-section beneficiaries-verification'>Verify that this tab has been checked</label>
                </div>
              </div>
            </div>
          }
          <div className='row alert-margin'>
            <div className='col-md-6'>
              <div className='benefit-info-link' onClick={() => this.props.viewModel.showApprovedDeathBenefitInfoModal = true}>
                <img className='alert-approved-badb' src={information_icon} />  What is approved death benefits?
              </div>
            </div>
            {this.props.viewModel.approvedTotalShareValue === 100 &&
              <div className='col-md-3'>
                <div className='details-outstanding green-status-text'>
                  <img className='alert-approved-badb' src={valid} /> Total share value equals 100%
                </div>
              </div>
            }
            {this.props.viewModel.approvedTotalShareValue !== 100 &&
              <div className='col-md-3'>
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Total share value must equal 100%
                </div>
              </div>
            }
            <div className='col-md-3'>
              <div className={this.props.viewModel.approvedTotalShareValue === 100 ? 'total-share-value total-share-value-border' : 'total-share-value'} >
                <span className='total-share-value-text'>Total share value: {' '}
                  <span className={this.props.viewModel.approvedTotalShareValue === 100 ? 'green-total-percentage' : 'yellow-total-percentage'} >
                    {this.props.viewModel.approvedTotalShareValue} %
                  </span>
                </span>
              </div>
            </div>
          </div>
          {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
            <NoBeneficiaries viewModel={this.props.viewModel} />
          }
          {!this.props.viewModel.viewOnlyBeneficiary &&
            <div>
              <div className='adding-margin-to-the-sides'>
                {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
                  <span>
                    <div className='show-on-web-screen'>
                      <div className='row table-heading-badb'>
                        <span className='col-3 card-heading-text '>Full name</span>
                        <span className='col-2 card-heading-text '>ID/Passport Number</span>
                        <span className='col-2 card-heading-text '>Relationship</span>
                        <span className='col-2 card-heading-text '>Share %</span>
                        <span className='col-3 card-heading-text ' />
                      </div>
                      {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                        <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                          <div className='row'>
                            <span className='col-3'>
                              <span className='table-card-name'>{item.firstName} {item.lastName} </span>
                            </span>
                            <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                            <span className='col-2 card-items-padding relationship'>{item.relationshipType}</span>
                            <div className='col-1'>
                              <FormGroupInline bind={item.meta.nominationPercentage}
                                onBlur={() => this.props.viewModel.updateInterimNomination(item)}
                                suppressLabel
                                className='share-input-field'
                                type='number'
                                disabled={(this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member) && !this.props.viewModel.receivedNominationForm}
                                editorTooltip={(this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member) && !this.props.viewModel.receivedNominationForm ? "Please upload the required nomination form" : ""}>
                              </FormGroupInline>
                            </div>
                            <div className='col-1 card-items-padding'>
                              {!this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId)?.isValid &&
                                <Neo.Button tooltip='Some details for this beneficiary are invalid' className='btn-no-outline-secondary fake-button' variant='secondary'>
                                  <img className='alert-warning' src={warningYellow} />
                                </Neo.Button>
                              }
                            </div>
                            <span className='col-1 card-items-padding-buttons'>
                              <Neo.Button
                                tooltip={'Edit'}
                                className='btn-no-outline-secondary fake-button'
                                onClick={
                                  () => {
                                    if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                      this.props.viewModel.editBeneficiary(item)
                                    } else {
                                      NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                    }
                                  }} >
                                <img src={edit} className='edit-image-size' />
                              </Neo.Button>
                            </span>
                            <span className='col-1 card-items-padding-buttons delete'>
                              <Neo.Button
                                tooltip={'Remove'}
                                className='btn-no-outline-secondary fake-button'
                                onClick={() => {
                                  var approvedBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                  if (approvedBeneficiary) {
                                    if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                      this.props.viewModel.selectedApprovedBeneficiary = approvedBeneficiary;
                                      this.props.viewModel.showDeleteModal = true;
                                    } else {
                                      NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                    }
                                  }
                                }}>
                                <img src={bin} className='edit-image-size' />
                              </Neo.Button>
                            </span>
                          </div>
                        </Neo.Card>
                      ))}
                    </div>
                    <div className='show-on-phone-screen'>
                      {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                        <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                          <div className='row'>
                            <span className='col-5 card-text-phone-screen'>Fullname </span><span className='col-7 padding-phone'>{item.firstName} {item.lastName}</span>
                            <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                            <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                            <span className='col-5 card-text-phone-screen share'>Share %</span>
                            <FormGroupInline bind={item.meta.nominationPercentage}
                              onBlur={() => {
                                this.props.viewModel.updateInterimNomination(item);
                              }}
                              suppressLabel
                              type='number'
                              disabled={(this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member) && !this.props.viewModel.receivedNominationForm}
                              editorTooltip={(this.props.viewModel.currentUser.userRoleType !== UserRoleType.Member) && !this.props.viewModel.receivedNominationForm ? "Please upload the required nomination form" : ""}
                              className='share-input-field col-7 padding-phone share' />
                            <div className='line-badb' />
                            <div className='col-3 card-items-padding'>
                              {!this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId)?.isValid &&
                                <Neo.Button tooltip='Some details for this beneficiary are invalid' className='btn-no-outline-secondary fake-button' variant='secondary'>
                                  <img className='alert-warning' src={warningYellow} />
                                </Neo.Button>
                              }
                            </div>
                            <span className='col-4 mt-2'>
                              <img src={edit} className='edit-image-size' />
                              <Neo.Link className='link-format'
                                onClick={
                                  () => {
                                    if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                      this.props.viewModel.editBeneficiary(item)
                                    } else {
                                      NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                    }
                                  }}>
                                Edit
                              </Neo.Link>
                            </span>
                            <span className='col-5 mt-2'>
                              <img src={bin} className='edit-image-size' />
                              <Neo.Link className='link-format'
                                onClick={() => {
                                  var approvedBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                  if (approvedBeneficiary) {
                                    if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                      this.props.viewModel.selectedApprovedBeneficiary = approvedBeneficiary;
                                      this.props.viewModel.showDeleteModal = true
                                    } else {
                                      NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                    }
                                  }
                                }}>
                                Remove
                              </Neo.Link>
                            </span>
                          </div>
                        </Neo.Card>
                      ))}
                    </div>
                    <div className='blue-block'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <Neo.Link className='blue-block-text'
                            onClick={
                              () => {
                                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                  this.props.viewModel.selectExistingBeneficiary();
                                } else {
                                  NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                }
                              }}>
                            Select existing beneficiary
                          </Neo.Link>
                          <span className='line-break'><br></br></span>
                          <span className='or-text'> or</span>
                          <span className='line-break'><br></br></span>
                          <img src={group} className="edit-image-size padding" />
                          <Neo.Link
                            onClick={
                              () => {
                                if (this.props.viewModel.currentUser.userRoleType === UserRoleType.Member || this.props.viewModel.receivedNominationForm) {
                                  this.props.viewModel.showAddBeneficiariesModal = true;
                                } else {
                                  NotifyUtils.addOrUpdate("NominationFormRequired", "Beneficiary nomination form", "Please upload a valid beneficiary nomination form", "warning", NotificationDuration.Long);
                                }
                              }}
                            className='add-text'>
                            Add new beneficiary
                          </Neo.Link>
                        </div>
                      </div>
                    </div>
                  </span>
                }
                <FileUploadBeneficiaries viewModel={this.props.viewModel} />
                <div className='last-updated-by'>
                  Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedApproved.Role + ' - ' + this.props.viewModel.lastModifiedApproved.UserName}</span>
                </div>
                {this.props.viewModel.approvedBeneficiaryGrouping.isActiveInterim && this.props.viewModel.currentUser.userRoleType === UserRoleType.Member &&
                  <div className='align-changes-text'>
                    The values on this screen are incomplete and unsubmitted
                  </div>
                }
              </div>
            </div>
          }
          {this.props.viewModel.viewOnlyBeneficiary &&
            <div>
              <div className='adding-margin-to-the-sides adding-margin-bottom'>
                {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
                  <span>
                    <div className='show-on-web-screen'>
                      <div className='row table-heading-badb'>
                        <span className='col-3 card-heading-text '>Full name</span>
                        <span className='col-2 card-heading-text '>ID Number</span>
                        <span className='col-2 card-heading-text '>Relationship</span>
                        <span className='col-2 card-heading-text '>Share %</span>
                        <span className='col-3 card-heading-text ' />
                      </div>
                      {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                        <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                          <div className='row'>
                            <span className='col-3'>
                              <span className='table-card-name'>{item.firstName} {item.lastName} </span>
                            </span>
                            <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                            <span className='col-2 card-items-padding relationship'>{item.relationshipType}</span>
                            <span className='col-2 card-items-padding relationship'>{item.nominationPercentage}</span>
                          </div>
                        </Neo.Card>
                      ))}
                    </div>
                  </span>
                }
              </div>
              <div className='show-on-phone-screen'>
                {this.props.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                  <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                    <div className='row'>
                      <span className='col-5 card-text-phone-screen'>Fullname </span><span className='col-7 padding-phone'>{item.firstName} {item.lastName}</span>
                      <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                      <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                      <span className='col-5 card-text-phone-screen'>Share %</span><span className='col-7 padding-phone'>{item.nominationPercentage}</span>
                    </div>
                  </Neo.Card>
                ))}
              </div>
              <div className='last-updated-by'>
                Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedApproved.Role + ' - ' + this.props.viewModel.lastModifiedApproved.UserName}</span>
              </div>
            </div>
          }
          {this.props.viewModel.viewOnlyBeneficiary &&
            <div>
              <ContainerFooterComponent
                showPrimaryButton={false}
                showSecondaryButton={false}
                backLinkText='Back to dashboard'
              />
            </div>
          }
          {!this.props.viewModel.viewOnlyBeneficiary &&
            <ContainerFooterComponent
              showPrimaryButton={true}
              primaryButtonText="Update"
              showSecondaryButton={true}
              secondaryButtonText={this.props.viewModel.approvedBeneficiaryGrouping.isDirty ? "save & exit" : "cancel"}
              disablePrimaryButton={this.props.viewModel.approvedTotalShareValue === 100 ? false :
                this.props.viewModel.receivedNominationForm ? false : true}
              primaryButtonCallback={() => {
                if (this.props.viewModel.allTabsChecked() === false) {
                  NotifyUtils.addDanger("Unchecked tab", "Please ensure that you double check and verify all tabs before submitting updates", NotificationDuration.Long);
                  return;
                } else {
                  this.props.viewModel.verifyUpdatedNominations();
                }
              }}
              secondaryButtonCallback={() => {
                if (this.props.viewModel.approvedBeneficiaryGrouping.isDirty ||
                  this.props.viewModel.approvedBeneficiaryGrouping.isActiveInterim ||
                  this.props.viewModel.receivedNominationForm) {
                  this.props.viewModel.showConfirmSaveChangesModal = true
                }
              }}
            />
          }
          <Neo.Modal title='What is approved death benefits? '
            acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showApprovedDeathBenefitInfoModal = false }}
            closeButton={{ text: "Watch explainer video", variant: "danger", onClick: () => window.open('https://www.youtube.com/watch?v=Fz-TCH4Bncw', '_blank') }}
            bind={this.props.viewModel.meta.showApprovedDeathBenefitInfoModal}>
            Some benefits payable on your death are "approved" while others are "unapproved". Your retirement benefit is approved, and some/all of your risk
            benefits may be too.
            If you pass away and your approved death benefits become payable, the trustees will refer to your beneficiary nomination form.
            The trustees will take this information into account, but will ultimately decide on how the benefits will be distributed to your beneficiaries and
            dependents.
            <br /> <br />
            This is because the trustees have the duty to oversee that approved benefits are paid fairly between all of your beneficiaries and
            dependents (in line with section 37C of the Pension Funds Act).
            This is different to unapproved risk benefits, where the insurer (e.g. Liberty)
            will pay the benefit according to your beneficiary nomination form, and neither your employer nor the trustees can override your decision.
          </Neo.Modal>
          <Neo.Modal
            bind={this.props.viewModel.meta.showDeleteModal}
            title='Remove Beneficiary'
            acceptButton={{
              text: "Yes", variant: "primary",
              onClick: () => this.props.viewModel.deleteApprovedBeneficiary(this.props.viewModel.selectedApprovedBeneficiary)
            }}
            closeButton={{ text: "No", variant: "danger", onClick: () => this.props.viewModel.showDeleteModal = false }}>
            Are you sure you want to remove this nominated beneficiary for this benefit?
            All beneficaries will remain in existing beneficiaries for future use.
          </Neo.Modal>
        </div >
      </div >
    );
  }
}