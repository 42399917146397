import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import NotificationsVM from './NotificationsVM';
import { observer } from 'mobx-react';
import '../Styles/Components/Notifications.scss';
import img_ellipse from '../assets/img/icn-ellipse.png';
import NotificationsSearchResultsLookup, { NotificationType } from '../../Dashboard/Models/Lookups/Notifications/NotificationsSearchResultsLookup';
import LibertyPager from '../../Dashboard/Components/LibertyPager';
import ActionableNotificationView from './Components/ActionableNotificationView';
import GeneralBroadcastNotificationView from './Components/GeneralBroadcastNotificationView';
import GeneralNotificationView from './Components/GeneralNotificationView ';

@observer
export default class NotificationsView extends Views.ViewBase<NotificationsVM> {

  constructor(props: unknown) {
    super("Notifications", NotificationsVM, props);
  }

  public render() {
    return (
      <div>
        {!this.viewModel.showNotificationInformation && !this.viewModel.showGeneralInformation && !this.viewModel.isLibertyNewsletter &&
          <div className='notifications-container'>
            <div>
              <div className='mt-4 notification-card'>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <p className='card-heading-notifications mt-2'>Notifications</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='line-notifications'></div>
                </div>
                <div className='row'>
                  <Neo.TabContainer
                    selectedTab={this.viewModel.meta.tabValue}
                    className='ps-4 pe-3 notifications-tab-container'
                    variant="pills">
                    <Neo.Tab header={
                      <div className='card-tab-text-notifications'>
                        Actionable
                        <i><img className='align-ellipse' src={img_ellipse} /></i>
                        <span className='align-number'>12</span>
                      </div>
                    } name="Actions">
                      {this.viewModel.showNotificationCards &&
                        <div className='actions-container'>
                          <div className='row mt-4'>
                            <Neo.FormGroup
                              className='notifications-form-group col-md-10 mb-2'
                              placeholder="Search by..."
                              bind={this.viewModel.meta.search} />
                            <Neo.Button text="Search" className='col-md-2 btn btn-search' />
                            <div className='row'>
                              <div className='line-notifications-mobile'></div>
                            </div>
                            <div className='row mt-4'>
                              <div className='col-12 col-md-12'>
                                <Neo.Alert variant="primary" className='display-alert' heading="Notifications that are older than 30 days will move into the history section below.">
                                </Neo.Alert>
                              </div>
                            </div>
                          </div>
                          <div className='action-header'>
                            Recent Notification/s
                          </div>
                          <div className='row show-on-desktop'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManager}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmit(item)}>

                                    <div className='action-text'>
                                      {item.notificationText}
                                    </div>
                                    <div className='align-info-status'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info'>
                                        {item.notificationInformation}
                                      </span>}

                                      {item.notificationMemberInformation != '' && <span className='action-text-info'>
                                        {item.notificationMemberInformation + '|'}  {item.notificationInformation}
                                      </span>}
                                      <span className={item.status == 'Unseen' ? 'status-text-box' : 'read-status-box'}>
                                        <p className='status-text'>{item.status}</p>
                                      </span>
                                    </div>
                                    <div className='action-date'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='row show-on-mobile'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManager}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmit(item)}>

                                    <div className='align-text-status'>
                                      <span className='action-text-mobile'>
                                        {item.notificationText}
                                      </span>
                                      <span className={item.status == 'Unseen' ? 'status-text-box-mobile' : 'read-status-box-mobile'}>
                                        {item.status}
                                      </span>
                                    </div>
                                    <div className='align-scheme'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info-mobile'>
                                        {item.notificationInformation}
                                      </span>}
                                      {item.notificationMemberInformation != '' && <span className='action-text-info-mobile'>
                                        {item.notificationMemberInformation}
                                        <div className='align-scheme'>
                                          {item.notificationInformation}
                                        </div>
                                      </span>}
                                    </div>
                                    <div className='action-date mt-2'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='mt-4 row'>
                            <div className='line-notifications-thin'></div>
                          </div>
                          <div className='row'>
                            <div className='action-header'>
                              History
                            </div>
                          </div>
                          <div className='row show-on-desktop'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManager}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmit(item)}>
                                    <div className='action-text'>
                                      {item.notificationText}
                                    </div>
                                    <div className='align-info-status'>

                                      {item.notificationMemberInformation == '' && <span className='action-text-info'>
                                        {item.notificationInformation}
                                      </span>}

                                      {item.notificationMemberInformation != '' && <span className='action-text-info'>
                                        {item.notificationMemberInformation + '|'}  {item.notificationInformation}
                                      </span>}
                                      <span className={item.status == 'Unseen' ? 'status-text-box' : 'read-status-box'}>
                                        <p className='status-text'>{item.status}</p>
                                      </span>
                                    </div>
                                    <div className='action-date'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>

                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='row show-on-mobile'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManager}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmit(item)}>

                                    <div className='align-text-status'>
                                      <span className='action-text-mobile'>
                                        {item.notificationText}
                                      </span>
                                      <span className={item.status == 'Unseen' ? 'status-text-box-mobile' : 'read-status-box-mobile'}>
                                        {item.status}
                                      </span>
                                    </div>
                                    {item.notificationStatus != "" &&
                                      <div className='action-text'>
                                        <b> {"-" + item.notificationStatus}</b>
                                      </div>
                                    }
                                    <div className='align-scheme'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info-mobile'>
                                        {item.notificationInformation}
                                      </span>}
                                      {item.notificationMemberInformation != '' && <span className='action-text-info-mobile'>
                                        {item.notificationMemberInformation}
                                        <div className='align-scheme'>
                                          {item.notificationInformation}
                                        </div>
                                      </span>}
                                    </div>
                                    <div className='action-date mt-2'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                        </div>
                      }
                    </Neo.Tab>
                    <Neo.Tab header={
                      <div className='card-tab-text-notifications'>General
                        <i><img className='align-ellipse' src={img_ellipse} /></i>
                        <span className='align-number'>12</span>
                      </div>
                    } name="General">
                      General

                      {this.viewModel.showGeneralCards &&
                        <div className='actions-container'>
                          <div className='row mt-0'>
                            <Neo.FormGroup
                              className='notifications-form-group col-md-10 mb-2 col-12'
                              placeholder="Search by..."
                              bind={this.viewModel.meta.search} />
                            <Neo.Button text="Search" className='col-md-2 col-12 btn btn-search' />
                            <div className='row mt-4'>
                              <div className='col-12 col-md-12'>
                                <Neo.Alert variant="primary" className='display-alert' heading="Notifications that are older than 30 days will move into the history section below.">
                                </Neo.Alert>
                              </div>
                            </div>
                          </div>
                          <div className='action-header'>
                            Recent Notification/s
                          </div>
                          <div className='row show-on-desktop'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManagerGeneral}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmitGeneral(item)}>

                                    <div className='action-text'>
                                      {item.notificationText}

                                      {item.notificationStatus != "" &&
                                        <strong> - {item.notificationStatus}</strong>
                                      }
                                    </div>
                                    <div className='align-info-status'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info'>
                                        {item.notificationInformation}
                                      </span>}

                                      {item.notificationMemberInformation != '' && <span className='action-text-info'>
                                        {item.notificationMemberInformation + '|'}  {item.notificationInformation}
                                      </span>}
                                      <span className={item.status == 'Unseen' ? 'status-text-box' : 'read-status-box'}>
                                        <p className='status-text'>{item.status}</p>
                                      </span>
                                    </div>
                                    <div className='action-date'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='row show-on-mobile'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManagerGeneral}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmitGeneral(item)}>

                                    <div className='align-text-status'>
                                      <span className='action-text-mobile'>
                                        {item.notificationText}
                                        {item.notificationStatus != "" &&
                                          <span>-</span>
                                        }
                                      </span>
                                      <span className={item.status == 'Unseen' ? 'status-text-box-mobile' : 'read-status-box-mobile'}>
                                        {item.status}
                                      </span>
                                    </div>
                                    {item.notificationStatus != "" &&

                                      <div className='action-text'>
                                        <b> {item.notificationStatus}</b>
                                      </div>
                                    }
                                    <div className='align-scheme'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info-mobile'>
                                        {item.notificationInformation}
                                      </span>}
                                      {item.notificationMemberInformation != '' && <span className='action-text-info-mobile'>
                                        {item.notificationMemberInformation}
                                        <div className='align-scheme'>
                                          {item.notificationInformation}
                                        </div>
                                      </span>}
                                    </div>
                                    <div className='action-date mt-2'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>

                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='mt-4 row'>
                            <div className='line-notifications'></div>
                          </div>

                          <div className='row'>
                            <div className='action-header'>
                              History
                            </div>
                          </div>
                          <div className='row show-on-desktop'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManagerGeneral}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmitGeneral(item)}>

                                    <div className='action-text'>
                                      {item.notificationText}

                                      {item.notificationStatus != "" &&
                                        <strong> - {item.notificationStatus}</strong>
                                      }
                                    </div>
                                    <div className='align-info-status'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info'>
                                        {item.notificationInformation}
                                      </span>}

                                      {item.notificationMemberInformation != '' && <span className='action-text-info'>
                                        {item.notificationMemberInformation + '|'}  {item.notificationInformation}
                                      </span>}
                                      <span className={item.status == 'Unseen' ? 'status-text-box' : 'read-status-box'}>
                                        <p className='status-text'>{item.status}</p>
                                      </span>
                                    </div>
                                    <div className='action-date'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>
                          <div className='row show-on-mobile'>
                            <LibertyPager
                              maxPagesToShow={2}
                              pageManager={this.viewModel.pageManagerGeneral}
                              pageControlProps={{
                                pageSizeLabel: " ",
                                lastText: " ", totalRecords: 10
                              }}>
                              <NeoGrid.Grid<NotificationsSearchResultsLookup>>
                                {(item) => (
                                  <Neo.Card
                                    className={item.notificationType == NotificationType.Broadcast ? 'action-cards-member action-cards-broadcast' :
                                      item.notificationType == NotificationType.Scheme ? 'action-cards-member action-cards-scheme' :
                                        'action-cards-member'}
                                    onClick={() => this.viewModel.onSubmitGeneral(item)}>

                                    <div className='align-text-status'>
                                      <span className='action-text-mobile'>
                                        {item.notificationText}
                                        {item.notificationStatus != "" &&
                                          <span>-</span>
                                        }
                                      </span>
                                      <span className={item.status == 'Unseen' ? 'status-text-box-mobile' : 'read-status-box-mobile'}>
                                        {item.status}
                                      </span>
                                    </div>
                                    {item.notificationStatus != "" &&
                                      <div className='action-text'>
                                        - <b> {item.notificationStatus}</b>
                                      </div>
                                    }
                                    <div className='align-scheme'>
                                      {item.notificationMemberInformation == '' && <span className='action-text-info-mobile'>
                                        {item.notificationInformation}
                                      </span>}
                                      {item.notificationMemberInformation != '' && <span className='action-text-info-mobile'>
                                        {item.notificationMemberInformation}
                                        <div className='align-scheme'>
                                          {item.notificationInformation}
                                        </div>
                                      </span>}
                                    </div>
                                    <div className='action-date mt-2'>
                                      {item.notificationDate}
                                    </div>
                                  </Neo.Card>
                                )}
                              </NeoGrid.Grid>
                            </LibertyPager>
                          </div>

                        </div>
                      }
                    </Neo.Tab>
                  </Neo.TabContainer>
                </div>
              </div>
            </div>
          </div >
        }
        {this.viewModel.showNotificationInformation &&
          <ActionableNotificationView viewModel={this.viewModel} />
        }
        {this.viewModel.showGeneralInformation &&
          <GeneralNotificationView viewModel={this.viewModel} />
        }
        {this.viewModel.isLibertyNewsletter &&
          <GeneralBroadcastNotificationView viewModel={this.viewModel} />
        }
      </div>
    );
  }
}