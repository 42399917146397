import { List, Validation, ValueObject } from '@singularsystems/neo-core';
import MemberBeneficiaryGroupingDataLookup from '../MemberNominations/MemberBeneficiaryGroupingDataLookup';

export default class UpdateThirdPartyNominationsCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public memberId: string = "";

  public sourceId: string = "";

  public nominations = new List(MemberBeneficiaryGroupingDataLookup);

  public roleName: string = "";

  public memberNo: string = "";

  public schemeNo: string = "";

  // Client only properties / methods

  protected static addBusinessRules(rules: Validation.Rules<UpdateThirdPartyNominationsCommand>) {
  }

  public toString(): string {
    return "Update Third Party Nominations Command";
  }
}