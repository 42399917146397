import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import '../../Styles/ThirdPartyDashboard.scss';
import img_info from '../../../App/assets/img/information.png';
import member_profile from '../../../App/assets/img/member_profile.png';
import fact_check from '../../../App/assets/img/fact_check.png';
import img_arrow from '../../../App/assets/img/drop-down-arrow.png';
import LibertyPager from '../../Components/LibertyPager';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import FADashboardVM from './FADashboardVM';
import SchemeSearchDataLookup from '../../Models/Lookups/SearchWidget/SchemeSearchDataLookup';
import MemberSearchDataLookup from '../../Models/Lookups/SearchWidget/MemberSearchDataLookup';
import { MemberStatusGroup } from '../../../Common/Models/Enum/MemberStatusGroup.enum';
import { SchemeStatusGroup } from '../../../Common/Models/Enum/SchemeStatusGroup.enum';

@observer
export default class FADashboardView extends Views.ViewBase<FADashboardVM> {

  private handleScroll() {
    let modalElement = document.getElementsByClassName('terms-conditions-modal');
    if (modalElement.length === 1) {
      let modalContentContainer = document.getElementById('modal-tc');
      if (modalContentContainer) {
        const contentContainerHeight = modalContentContainer.clientHeight;
        const contentContainerScrollTop = modalContentContainer.scrollTop;
        const ourContent = document.getElementById('modal-tc-content');
        const ourContentHeight = ourContent!.offsetHeight;
        const hasScrolledAll = ((contentContainerHeight + contentContainerScrollTop) > ourContentHeight);
        if (hasScrolledAll) {
          this.viewModel.agreeButtonCss = 'btn-primary';
        }
      }
    }
  }

  constructor(props: unknown) {
    super("Dashboard", FADashboardVM, props);
  }

  public render() {
    return (
      <div className='third-party-dashboard-container'>
        <div>
          {!this.viewModel.acceptedTAndC &&
            <div>
              <Neo.Modal
                size="lg"
                title='Liberty Corporate Terms & Conditions:'
                bind={this.viewModel.meta.showTermsModal}
                buttons={[
                  { text: "Download T&C's", className: 'btn-download', onClick: () => this.viewModel.downloadTermsAndConditions() },
                  { text: "Cancel", variant: "secondary", onClick: () => { this.viewModel.showTermsLogoutModal = true, this.viewModel.showTermsModal = false; } },
                  { text: "I agree", variant: "primary", onClick: () => this.viewModel.acceptTAndC(), className: this.viewModel.agreeButtonCss },
                ]}
                className='terms-conditions-modal'
                showCancelButton={false}>
                <div className='modal-content'>
                  {/* Commented for now */}
                  {/* <div className='modal-header'>
                    <Neo.Alert variant="primary" className='display-alert-terms-conditions' heading="Our Terms & Conditions have been updated.">
                    </Neo.Alert>
                  </div> */}
                  <p className='modal-text' id='modal-tc' onScroll={() => this.handleScroll()}>
                    <div id='modal-tc-content' dangerouslySetInnerHTML={{ __html: this.viewModel.tAndCTemplate.templateContent }} />
                  </p>
                </div>
              </Neo.Modal>

              <Neo.Modal
                bind={this.viewModel.meta.showTermsLogoutModal}
                title=' Are you sure you want to cancel?'
                acceptButton={{ text: "Yes", variant: "primary", onClick: () => this.viewModel.navigateToLogoutPage() }}
                closeButton={{ text: "No", variant: "danger", onClick: () => { this.viewModel.showTermsModal = true, this.viewModel.showTermsLogoutModal = false } }}
              >
                By doing so, you will be logged out and asked to log in again.

              </Neo.Modal>
            </div>
          }
        </div>
        <div className='mt-4 third-party-dashboard-card'>
          <div className='row'>
            <div className='align-profile-info'>
              <div className='col-md-6 col-12'>
                <p className='third-party-dashboard-card-heading'>Search</p>
                <p className='third-party-dashboard-card-sub-heading'>Search for member & scheme information</p>
              </div>
            </div>
          </div>
          <Neo.TabContainer
            className='third-party-dashboard-tabs'
            selectedTab={this.viewModel.meta.selectedTab}>
            <Neo.Tab
              header={"Member search"}
              name={"Member search"}>
              <div className='row search-by'>
                <Neo.Card className='col-md-9'>
                  <Neo.FormGroup
                    className='search-dropdown'
                    bind={this.viewModel.memberCriteria.meta.keyword}
                    placeholder="Search by..."
                    onChange={() => this.viewModel.updateMembersGrid()}>
                  </Neo.FormGroup>
                </Neo.Card>
                <Neo.Card
                  className="col-md-2 scheme-filter-card"
                  onClick={() => { this.viewModel.showMembersTabFilterCard = !this.viewModel.showMembersTabFilterCard }}>
                  <div className='align-scheme-title'>
                    <div className='filter-by-status-heading'>
                      Status filter
                      {this.viewModel.getSelectedItemsCount() > 0 &&
                        <span className='square-box'>{this.viewModel.getSelectedItemsCount()}</span>
                      }
                    </div>
                    <i className='align-arrow'><img className='align-arrow' src={img_arrow} /> </i>
                  </div>
                </Neo.Card>
                <Neo.Button className="col-md-1 btn-filter" onClick={() => { this.viewModel.updateMembersGrid() }}>Search</Neo.Button>
              </div>
              {this.viewModel.showMembersTabFilterCard &&
                <div className='float-content'>
                  <div className='row adjust-row'>
                    <div className='col-md-11 adjust-col'>
                      <div className='show-dropdown'>
                        <div className='row padding-bottom-radio'>
                          <div className='col-md-6'>
                            <div className='filter-header'>
                              Member status:
                            </div>
                            <div className='filter-inline-checkboxes'>
                              {this.viewModel.membersTabMemberStatusList.map(element => {
                                return [
                                  <Neo.Checkbox onClick={() => { this.viewModel.resetFilterAndClearAll() }} bind={element.meta.isSelected} label={element.name}></Neo.Checkbox>
                                ]
                              })}
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='filter-header'>
                              Scheme status:
                            </div>
                            <div className='filter-inline-checkboxes'>
                              {this.viewModel.membersTabSchemeStatusList.map(element => {
                                return [
                                  <Neo.Checkbox bind={element.meta.isSelected} onClick={() => { this.viewModel.resetFilterAndClearAll() }} label={element.name}></Neo.Checkbox>
                                ]
                              })}
                            </div>
                          </div>
                        </div>
                        <ContainerFooterComponent
                          hideBackLink
                          showPrimaryButton
                          showSecondaryButton
                          secondaryButtonText='Cancel'
                          primaryButtonText='Apply'
                          primaryButtonCallback={() => this.viewModel.showSelectedFilters()}
                          secondaryButtonCallback={() => {
                            this.viewModel.showMembersTabFilterCard = false
                            this.viewModel.resetMembersTabFilterChanges();
                          }}
                        ></ContainerFooterComponent>
                      </div>
                    </div>
                    <div className='col-md-1'></div>
                  </div>
                </div>
              }
              {this.viewModel.memberCriteria.schemeNo !== "" &&
                <div className='row mb-2 mt-4'>
                  <div className='col-md-12'>
                    <span className='selected-pill-items'>
                      <span className='background-pill'>
                        <span className='align-pill-inner-text'>
                          {this.viewModel.memberCriteria.schemeName}
                        </span>
                        - {this.viewModel.memberCriteria.schemeNo}
                        <Neo.Button className='close-button' onClick={() => this.viewModel.clearMemberSchemeNoFilter()} />
                      </span>
                    </span>
                  </div>
                </div>
              }
              <div className='mt-2'>
                <hr />
              </div>
              <div className='row mt-2'>
                <div className='col-12 col-md-12'>
                  {!this.viewModel.showMembersTabGrid &&
                    <div className='empty-grid-container'>
                      <i> <img src={img_info}></img></i>
                      <div className='grid-info'>Results will appear once you have made your selections</div>
                    </div>
                  }
                  {this.viewModel.showMembersTabGrid &&
                    <div className='report-grid'>
                      <div className='row'>
                        <LibertyPager
                          maxPagesToShow={2}
                          pageManager={this.viewModel.memberPageManager}
                          pageControlProps={{
                            pageSizeLabel: " ",
                            lastText: " ",
                            totalRecords: this.viewModel.memberPageManager.totalRecords
                          }}
                          noItemsFoundElement={
                            <div className='empty-grid-container'>
                              <i> <img src={img_info}></img></i>
                              <div className='grid-info'>No results found</div>
                            </div>
                          }>
                          <NeoGrid.Grid<MemberSearchDataLookup>>
                            {(item, meta) => (
                              <NeoGrid.Row>
                                <NeoGrid.Column label='Member name' sort={meta.firstName} cellTooltip={item.firstName + ' ' + item.lastName}>
                                  <span className='set-member-length'>{item.firstName}  {item.lastName}</span>
                                </NeoGrid.Column>
                                <NeoGrid.Column cellTooltip={item.memberStatus?.toString()} label='Member status' className='pill-container'>
                                  <span className={item.memberStatusGroup === MemberStatusGroup.Active ? 'pills-active'
                                    : item.memberStatusGroup === MemberStatusGroup.PendingClaim ? 'pills-pending'
                                      : item.memberStatusGroup === MemberStatusGroup.Terminated ? 'pills-terminated' : ''}>
                                    {item.memberStatus}
                                  </span>
                                </NeoGrid.Column>
                                <NeoGrid.Column label='Member number' display={meta.memberNo} />
                                <NeoGrid.Column label='ID/passport number' className='id-num-col' display={meta.nationalIdNoOrPassportNo} numProps={{ formatString: "0" }} />
                                <NeoGrid.Column label='Scheme name' sort={meta.schemeName} cellTooltip={item.schemeName}>
                                  <span className='set-scheme-length'>{item.schemeName}</span>
                                </NeoGrid.Column>
                                <NeoGrid.Column label='Scheme status' display={meta.schemeStatus} />
                                <NeoGrid.Column label='Paypoint' sort={meta.paypoint}>
                                  <Neo.Tooltip content={item.paypoint} position='top' alignment='start'>
                                    <span className='set-scheme-length'>{item.paypoint}</span>
                                  </Neo.Tooltip>
                                </NeoGrid.Column>
                                <NeoGrid.Column className='border-three-dots'>
                                  <span className='dropdown-three-dots'>
                                    <Neo.Button
                                      menuAlignment="right"
                                      variant='secondary'
                                      onClick={() => alert("This won't appear")}
                                      menuItems={
                                        [{
                                          icon: <img className='align-kebab-image' src={fact_check}></img>,
                                          text: "Member beneficiaries",
                                          onClick: (evt) => { this.viewModel.changeToBenefeciariesView(item) }
                                        },
                                        {
                                          icon: <img className='align-kebab-image' src={member_profile}></img>,
                                          text: "View member information",
                                          onClick: (evt) => { this.viewModel.changeToProfileView(item) }
                                        },
                                        ]
                                      }>
                                    </Neo.Button>
                                  </span>
                                </NeoGrid.Column>
                              </NeoGrid.Row>
                            )}
                          </NeoGrid.Grid>
                        </LibertyPager>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Neo.Tab>
            <Neo.Tab
              onInitialise={() => this.viewModel.showSelectedFilters()}
              header={"Scheme search"}
              name={"Scheme search"}>
              <div className='row search-by'>
                <Neo.Card className='col-md-9'>
                  <Neo.FormGroup
                    className='search-dropdown'
                    bind={this.viewModel.schemeCriteria.meta.keyword}
                    placeholder="Search by..."
                    onChange={() => this.viewModel.updateSchemesGrid()}>
                  </Neo.FormGroup>
                </Neo.Card>
                <Neo.Card
                  className="col-md-2 scheme-filter-card"
                  onClick={() => { this.viewModel.showSchemesTabFilterCard = !this.viewModel.showSchemesTabFilterCard }}>
                  <div className='align-scheme-title'>
                    <div className='filter-by-status-heading'>
                      Status filter
                      {this.viewModel.getSelectedItemsCount() > 0 &&
                        <span className='square-box'>{this.viewModel.getSelectedItemsCount()}</span>
                      }
                    </div>
                    <i className='align-arrow'><img className='align-arrow' src={img_arrow} /> </i>
                  </div>
                </Neo.Card>
                <Neo.Button className="col-md-1 btn-filter" onClick={() => { this.viewModel.updateSchemesGrid() }}>Search</Neo.Button>
              </div>
              {this.viewModel.showSchemesTabFilterCard &&
                <div className='float-content'>
                  <div className='row adjust-row'>
                    <div className='col-md-11 adjust-col'>
                      <div className='show-dropdown'>
                        <div className='row padding-bottom-radio'>
                          <div className='col-md-6 scheme-options-align'>
                            <div className='filter-header'>
                              Scheme status:
                            </div>
                            <div className='filter-inline-checkboxes'>
                              {this.viewModel.schemesTabStatusList.map(element => {
                                return [
                                  <Neo.Checkbox onClick={() => { this.viewModel.resetFilterAndClearAll() }} bind={element.meta.isSelected} label={element.name}></Neo.Checkbox>
                                ]
                              })}
                            </div>
                          </div>
                        </div>
                        <ContainerFooterComponent
                          hideBackLink
                          showPrimaryButton
                          showSecondaryButton
                          secondaryButtonText='Cancel'
                          primaryButtonText='Apply'
                          primaryButtonCallback={() => this.viewModel.showSelectedFilters()}
                          secondaryButtonCallback={() => {
                            this.viewModel.showSchemesTabFilterCard = false
                            this.viewModel.resetSchemesTabFilterChanges();
                          }}
                        ></ContainerFooterComponent>
                      </div>
                    </div>
                    <div className='col-md-1'></div>
                  </div>
                </div>
              }
              <div className='mt-2'>
                <hr />
              </div>
              <div className='row mt-2'>
                <div className='col-12 col-md-12'>
                  {!this.viewModel.showSchemesTabGrid &&
                    <div className='empty-grid-container'>
                      <i> <img src={img_info}></img></i>
                      <div className='grid-info'>Results will appear once you have made your selections</div>
                    </div>
                  }
                  {this.viewModel.showSchemesTabGrid &&
                    <div className='report-grid'>
                      <div className='row'>
                        <LibertyPager
                          maxPagesToShow={2}
                          pageManager={this.viewModel.schemePageManager}
                          pageControlProps={{
                            pageSizeLabel: " ",
                            lastText: " ", totalRecords: this.viewModel.schemePageManager.totalRecords
                          }}
                          noItemsFoundElement={
                            <div className='empty-grid-container'>
                              <i> <img src={img_info}></img></i>
                              <div className='grid-info'>No results found</div>
                            </div>
                          }>
                          <NeoGrid.Grid<SchemeSearchDataLookup>>
                            {(item, meta) => (
                              <NeoGrid.Row >
                                <NeoGrid.Column label='Scheme name' sort={meta.schemeName} cellTooltip={item.schemeName}>
                                  <span className='set-scheme-length'>{item.schemeName}</span>
                                </NeoGrid.Column>
                                <NeoGrid.Column label='Scheme number' display={meta.schemeNo} />
                                <NeoGrid.Column label='No. of members' sort={meta.noOfMembers} >
                                  <Neo.Link onClick={() => this.viewModel.allSchemeMembers(item, "Scheme search")}>
                                    {item.noOfMembers}
                                  </Neo.Link>
                                </NeoGrid.Column>
                                <NeoGrid.Column cellTooltip={item.schemeStatus?.toString()} label='Scheme status' className='pill-container'>
                                  <span className={item.schemeStatusGroup === SchemeStatusGroup.Active ? 'pills-active'
                                    : item.schemeStatusGroup === SchemeStatusGroup.PendingTermination ? 'pills-pending'
                                      : item.schemeStatusGroup === SchemeStatusGroup.Terminated ? 'pills-terminated' : ''}>
                                    {item.schemeStatus}
                                  </span>
                                </NeoGrid.Column>
                              </NeoGrid.Row>
                            )}
                          </NeoGrid.Grid>
                        </LibertyPager>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Neo.Tab>
          </Neo.TabContainer >
        </div >
      </div >
    );
  }
}