import { ModelBase } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import Bank from "../../MasterData/Models/Bank";
import Country from "../../MasterData/Models/Country";
import Title from "../../MasterData/Models/Title";
import { AppService, Types } from "./AppService";

@injectable()
export default class MasterDataService extends ModelBase {
  private hasInitialised = false;

  public titles: Title[] = [];
  public banks: Bank[] = [];
  public countries: Country[] = [];
  public countryCodes: { Id: number, CountryCode: string }[] = [];

  public provinces = [
    { id: 1, province: "Eastern Cape" },
    { id: 2, province: "Free State" },
    { id: 3, province: "Gauteng" },
    { id: 4, province: "KwaZulu-Natal" },
    { id: 5, province: "Limpopo" },
    { id: 6, province: "Mpumalanga" },
    { id: 7, province: "Northern Cape" },
    { id: 8, province: "North West" },
    { id: 9, province: "Western Cape" },
  ];

  constructor(private titleQueryApiClient = AppService.get(Types.MasterData.ApiClients.TitleQueryApiClient),
    private bankQueryApiClient = AppService.get(Types.MasterData.ApiClients.BankQueryApiClient),
    private countryApiClient = AppService.get(Types.MasterData.ApiClients.CountryQueryApiClient)) {
    super();
    this.makeObservable();
  }

  public async populateMasterData() {
    if (!this.hasInitialised) {
      await this.getTitles();
      await this.getBanks();
      await this.getCountries();
      await this.populateCountryCodes();
      this.hasInitialised = true;
    }
  }

  public async getTitles() {
    if (this.titles.length === 0) {
      var result = await this.titleQueryApiClient.getTitles();
      const titles = result.data as Title[]

      if (result.data) {
        this.titles = titles.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getBanks() {
    if (this.banks.length === 0) {
      var result = await this.bankQueryApiClient.getBanks();
      const banks = result.data as Bank[]

      if (result.data) {
        this.banks = banks.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getCountries() {
    if (this.countries.length === 0) {
      var result = await this.countryApiClient.getCountries();
      const countries = result.data as Country[]

      if (result.data) {
        this.countries = countries.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async populateCountryCodes() {
    if (this.countryCodes.length === 0) {
      this.countryCodes = this.countries.map(c => { return { Id: c.countryId, CountryCode: c.dialCode } });
    }
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }
}