import { EnumHelper } from "@singularsystems/neo-core";

export enum MemberStatusGroup {
  Active = 1,
  PendingClaim = 2,
  Terminated = 3,
}

EnumHelper.decorateEnum(MemberStatusGroup, e => {
  e.describe(MemberStatusGroup.Active, "Active", ""),
    e.describe(MemberStatusGroup.PendingClaim, "Pending claim", ""),
    e.describe(MemberStatusGroup.Terminated, "Terminated", "");
});