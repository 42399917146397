import { Components, EnumHelper, FileUtils, List, NeoModel, NotifyUtils, StringUtils, Validation } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import { User } from '../../../UserModel/User';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';
import { BeneficiaryGroupingType } from '../../Models/LookUps/BeneficiaryGroupingType.enum';
import MemberBeneficiaryGroupingDataLookup from '../../Models/LookUps/MemberNominations/MemberBeneficiaryGroupingDataLookup';
import MemberNominationDataLookup from '../../Models/LookUps/MemberNominations/MemberNominationDataLookup';
import UpdateMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateMemberNominationCommand';
import UpdateMemberBeneficiariesCommand from '../../Models/LookUps/MemberBeneficiaries/UpdateMemberBeneficiariesCommand';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';
import { NotificationDuration } from '../../../App/Models/NotificationDuration';
import UpdateInterimMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateInterimMemberNominationCommand';
import PartyFileDescriptorLookup from '../../Models/LookUps/Files/PartyFileDescriptorLookup';
import UpdateThirdPartyInterimNominationCommand from '../../Models/LookUps/ThirdPartyNominations/UpdateThirdPartyInterimNominationCommand';
import ThirdPartyNominationLookup from '../../Models/LookUps/ThirdPartyNominations/ThirdPartyNominationLookup';
import UpdateThirdPartyNominationsCommand from '../../Models/LookUps/ThirdPartyNominations/UpdateThirdPartyNominationsCommand';
import MemberSchemeMembershipLookup from '../../Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import MemberBenefitsLookup from '../../Models/LookUps/MemberBenefits/MemberBenefitsLookup';
import Permissions from '../../../Common/Models/Roles/Permissions';

@NeoModel
export default class BeneficiariesVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private memberNominationsService = AppService.get(Types.Parties.ApiClients.MemberNominationsCommandApiClient),
    private memberBeneficiariesQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberBeneficiariesQueryApiClient),
    private memberNominationsQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberNominationsQueryApiClient),
    private memberBeneficiariesCommandApiClient = AppService.get(Types.Parties.ApiClients.MemberBeneficiariesCommandApiClient),
    private authService = AppService.get(Types.App.Services.AuthenticationService),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService),
    private thirdPartyNominationsCommandApiClient = AppService.get(Types.Parties.ApiClients.ThirdPartyNominationsCommandApiClient),
    private thirdPartyQueryApiClient = AppService.get(Types.Parties.ApiClients.ThirdPartyNominationsQueryApiClient),
    private memberDataService = AppService.get(Types.App.Services.MemberDataService),
    private memberBenefitsApiClient = AppService.get(Types.Parties.ApiClients.MemberBenefitsApiClient),
    public helpersService = AppService.get(Types.App.Services.HelpersService)) {
    super(taskRunner);
  }

  public allBeneficiaries: boolean = false;
  public tabValueId: number = 1;
  public approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public approvedOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public unApprovedOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public funeralOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public nominationsDataArray = new List(UpdateInterimMemberNominationCommand);

  public knownBeneficiaries = new List(MemberBeneficiaryLookup);
  public trustedContactPerson: MemberBeneficiaryLookup = new MemberBeneficiaryLookup();
  public matchingBeneficiaries = new List(MemberBeneficiaryLookup);

  public newBeneficiary: MemberBeneficiaryLookup = new MemberBeneficiaryLookup();

  public selectedBeneficiaryToEdit = new MemberBeneficiaryLookup();
  public selectedApprovedBeneficiary = new MemberBeneficiaryLookup();
  public selectedUnapprovedBeneficiary = new MemberBeneficiaryLookup();
  public selectedFuneralBenefitBeneficiary = new MemberBeneficiaryLookup();
  public showApprovedAddedAlert: boolean = false;
  public showUserAddedAsThirdParty: boolean = false;
  public showNotHundredShareAlert: boolean = false;

  public receivedNominationForm: boolean = false;
  public showNeedsNominatedFormAlert: boolean = false;
  public requiresNominatedForm: boolean = false;
  public showAuthorisationFormRequiredModal: boolean = false;

  public showBeneficiariesApprovedDeathBenefit: boolean = false;
  public showBeneficiariesUnApprovedDeathBenefit: boolean = false;
  public showBeneficiariesFuneralBenefits: boolean = false;
  public showAddApprovedBeneficiariesForm: boolean = false;
  public showAddUnApprovedBeneficiariesForm: boolean = false;
  public showAddFuneralDeathBenefitForm: boolean = false;
  public showBeneficiariesAddKnown: boolean = false;
  public showExceedShareLimitModal: boolean = false;

  public showBeneficiariesEdit: boolean = false;

  public showEditBeneficiaryModal: boolean = false;
  public showSelectExistingBeneficiariesModal: boolean = false;
  public showKnowBeneficiariesModalForTrustedPerson: boolean = false;
  public showMatchingKnownBeneficiariesModal: boolean = false;
  public showAddBeneficiariesModal: boolean = false;
  public showFAEmployerStaffModal: boolean = true;
  public showDeleteModal: boolean = false;
  public showFuneralDeletedModal: boolean = false;
  public memberNominationsForAllBenefits = new List(MemberBeneficiaryGroupingDataLookup);

  public tabValue: string = 'Trusted Contact Person';
  public showApprovedDeathBenefitInfoModal: boolean = false;
  public showUnapprovedDeathBenefitInfoModal: boolean = false;
  public showFuneralBenefitInfoModal: boolean = false;
  public showTrustedContactPersonModal: boolean = false;
  public showConfirmSaveChangesModal: boolean = false;
  public showDashboardNavigationModal: boolean = false;
  public showConfirmSwitchSchemeModal: boolean = false;

  public memberName: string = '';
  public dontShowMessageAgain: boolean = false;
  public currentUser = new User(UserRoleType.LibertyNonLegacyMember);
  public viewOnlyBeneficiary: boolean = false;

  public effectiveDate: Date | null = null;
  public lastModifiedApproved = { Role: '', UserName: '' };
  public lastModifiedUnApproved = { Role: '', UserName: '' };
  public lastModifiedFuneral = { Role: '', UserName: '' };
  public lastModifiedTrustedPerson = { Role: '', UserName: '' };
  private hasInitialised: boolean = false;
  public lastModified = { Role: '', UserName: '' };

  public currentSchemeSelected = new MemberSchemeMembershipLookup();
  public memberSchemes = new List(MemberSchemeMembershipLookup);
  public memberBenefitsLookup = new MemberBenefitsLookup();

  //TODO: province list should be coming from a class - MASTERDATA.
  public countries = this.masterDataService.countries;
  public provinces = this.masterDataService.provinces;
  public countryCodes = this.masterDataService.countryCodes;

  public thirdPartyNomination = new ThirdPartyNominationLookup();
  public cnstMegabyte: number = 1048576;
  public showFileSizeModal: boolean = false;
  public showFileSelectModal: boolean = false;
  public showNominationFormDateModal: boolean = false;
  public verifyApprovedDeathBenefits: boolean = false;
  public verifyUnapprovedDeathBenefits: boolean = false;
  public verifyFuneralBenefits: boolean = false;
  public notAllTabsChecked: boolean = false;
  public viewTabs: { id: number, tabCodes: string }[] = [];
  public testDate = new Date();
  public get approvedTotalShareValue() {
    let total: number = 0;
    this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(c => {
      total += c.nominationPercentage;
    });
    return total;
  }

  public get unapprovedTotalShareValue() {
    let total: number = 0;
    this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(c => {
      total += c.nominationPercentage;
    });
    return total;
  }

  public async initialise() {
    this.currentUser = this.userRoleService.loggedInUser;
    this.viewOnlyBeneficiary = this.userRoleService.viewOnlyBeneficiary;

    await Promise.all([this.getMemberBeneficiariesLookups(), this.getMemberNominationsLookups(), this.getMemberBenefits()]);
    this.getTabCodes();
    this.hasInitialised = true;
  }

  public async getMemberBenefits() {
    if (!this.currentSchemeSelected.nameId) {
      this.setCurrentSchemeMember();
    }

    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberBenefitsApiClient.getMemberBenefits(Number(this.currentSchemeSelected.memberId), this.currentSchemeSelected.sourceId)
      );
      this.memberBenefitsLookup.mapFrom(response.data);
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member benefit details.", "warning", NotificationDuration.Long);
    }
  }

  public setCurrentSchemeMember() {
    switch (this.userRoleService.loggedInUser.userRoleType) {
      case UserRoleType.FinancialAdviser:
        this.currentSchemeSelected = this.memberDataService.currentSchemeSelected;
        break;
      case UserRoleType.Employer:
        this.currentSchemeSelected = this.memberDataService.currentSchemeSelected;
        break;
      case UserRoleType.Staff:
        this.currentSchemeSelected = this.memberDataService.currentSchemeSelected;
        this.memberSchemes = this.memberDataService.memberSchemes;
        break;
      case UserRoleType.FinancialAdviserAssistant:
        this.currentSchemeSelected = this.memberDataService.currentSchemeSelected;
        this.memberSchemes = this.memberDataService.memberSchemes;
        break;
      default:
        this.currentSchemeSelected = this.userMemberSchemeService.currentSchemeSelected;
        this.memberSchemes = this.userMemberSchemeService.memberSchemes;
        break;
    }
  }

  public async getMemberBeneficiariesLookups() {
    if (!this.currentSchemeSelected.nameId) {
      this.setCurrentSchemeMember();
    }

    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      var memberBeneficiaries = await this.taskRunner.waitFor(this.memberBeneficiariesQueryApiClient.getMemberBeneficiariesLookups(this.currentSchemeSelected.memberId, this.currentSchemeSelected.sourceId));

      if (memberBeneficiaries.data.length != 0) {
        this.knownBeneficiaries.set(memberBeneficiaries.data);
        let trustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
        if (trustedPerson) {
          this.trustedContactPerson.mapFrom(trustedPerson);
          this.lastModifiedTrustedPerson.Role = trustedPerson.lastModifiedByRole;
          this.lastModifiedTrustedPerson.UserName = trustedPerson.lastModifiedUser;
        } else {
          this.trustedContactPerson.trustedPerson = true;
          this.trustedContactPerson.markOld();
        }
        //foreach beneficiary set the country and province
        this.knownBeneficiaries.forEach(beneficiary => {
          beneficiary.countryValueId = this.getCountry(beneficiary.beneficiaryAddressInfo.country);
          beneficiary.provinceValueId = this.getProvince(beneficiary.beneficiaryAddressInfo.province, beneficiary.countryValueId);
          beneficiary.countryOfIssueValueId = this.getCountry(beneficiary.passportCountryOfIssue);

          if (beneficiary.nationalIdNo) {
            beneficiary.nationalIdNoOrPassportNo = beneficiary.nationalIdNo;
            beneficiary.isIDNumber = true;
          } else {
            beneficiary.nationalIdNoOrPassportNo = beneficiary.passportNo ?? "";
            beneficiary.isIDNumber = false;
          }
          this.helpersService.splitContactNumber(beneficiary);
        });
      }
      else {
        this.knownBeneficiaries.set([]);
      }
      this.trustedContactPerson.markOld();
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Beneficiaries", "There was an issue fetching beneficiaries.", "warning", NotificationDuration.Long);
    }
  }

  public async confirmSwitchScheme() {
    if (this.hasInitialised && this.hasUnsubmittedChanges() && this.memberSchemes.length > 1) {
      this.showConfirmSwitchSchemeModal = true;
    }
    else {
      await this.taskRunner.waitFor(this.getMemberNominationsLookups());
    }
  }

  public async getMemberNominationsLookups() {
    if (!this.currentSchemeSelected.nameId) {
      this.setCurrentSchemeMember();
    }

    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      let memberNominations = new List(MemberBeneficiaryGroupingDataLookup);
      if (this.currentUser.userRoleType === UserRoleType.Member || this.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) {
        let memberResult = await this.taskRunner.waitFor(this.memberNominationsQueryApiClient.getMemberNominationLookups(this.currentSchemeSelected.memberId, this.currentSchemeSelected.sourceId));
        memberNominations.set(memberResult.data);
      } else {
        if (this.currentUser.userRoleType === UserRoleType.Employer) {
          let employerSchemes = this.userMemberSchemeService.employerSchemeMembershipLookups;
          const permissions = employerSchemes.find(scheme => scheme.schemeId === this.currentSchemeSelected.schemeId)?.permissions ?? [];
          this.viewOnlyBeneficiary = permissions.includes(Permissions.viewOnlyBeneficiaries) && !permissions.includes(Permissions.updateBeneficiaries);
        }
        else {
          this.viewOnlyBeneficiary = false;
        }
        let thirdPartyResult = await this.taskRunner.waitFor(this.thirdPartyQueryApiClient.getThirdPartyMemberNominationLookups(this.currentSchemeSelected.memberId!, this.currentSchemeSelected.sourceId));
        this.thirdPartyNomination.set(thirdPartyResult.data);

        memberNominations = this.thirdPartyNomination.memberBeneficiaryGroupingDataLookups;
      }

      if (memberNominations.length != 0) {
        this.memberNominationsForAllBenefits = memberNominations;
      }
      else {
        this.memberNominationsForAllBenefits.set([]);
      }

      if (this.memberNominationsForAllBenefits.length != 0) {
        let approvedBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.ApprovedBenefits);
        let unApprovedBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.UnapprovedBenefits);
        let funeralBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.FuneralBenefits);

        if (approvedBeneficiaryGrouping) {
          this.approvedBeneficiaryGrouping = approvedBeneficiaryGrouping;

          let lastModified = approvedBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedApproved.Role = lastModified.lastModifiedByRole;
            this.lastModifiedApproved.UserName = lastModified.lastModifiedUser;
          }
          approvedBeneficiaryGrouping.nominationData.forEach(nomination => {
            if (this.effectiveDate == null || (nomination.effectiveDate != null && nomination.effectiveDate > this.effectiveDate)) {
              this.effectiveDate = nomination.effectiveDate!;
              if (!StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
                !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser)) {
                this.lastModified.Role = nomination.lastModifiedByRole;
                this.lastModified.UserName = nomination.lastModifiedUser;
              }
            }
          });
        } else {
          this.approvedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.ApprovedBenefits;
          this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name
          this.approvedBeneficiaryGrouping.nominationData.set([]);
        }

        if (unApprovedBeneficiaryGrouping) {
          this.unApprovedBeneficiaryGrouping = unApprovedBeneficiaryGrouping;

          let lastModified = this.unApprovedBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedUnApproved.Role = lastModified.lastModifiedByRole;
            this.lastModifiedUnApproved.UserName = lastModified.lastModifiedUser;
          }
          unApprovedBeneficiaryGrouping.nominationData.forEach(nomination => {
            if (this.effectiveDate == null || (nomination.effectiveDate != null && nomination.effectiveDate > this.effectiveDate)) {
              this.effectiveDate = nomination.effectiveDate!;
              if (!StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
                !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser)) {
                this.lastModified.Role = nomination.lastModifiedByRole;
                this.lastModified.UserName = nomination.lastModifiedUser;
              }
            }
          });
        } else {
          this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
          this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name
          this.unApprovedBeneficiaryGrouping.nominationData.set([]);
        }

        if (funeralBeneficiaryGrouping) {
          this.funeralBeneficiaryGrouping = funeralBeneficiaryGrouping;

          let lastModified = this.funeralBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedFuneral.Role = lastModified.lastModifiedByRole;
            this.lastModifiedFuneral.UserName = lastModified.lastModifiedUser;
          }
          funeralBeneficiaryGrouping.nominationData.forEach(nomination => {
            if (this.effectiveDate == null || (nomination.effectiveDate != null && nomination.effectiveDate > this.effectiveDate)) {
              this.effectiveDate = nomination.effectiveDate!;
              if (!StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
                !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser)) {
                this.lastModified.Role = nomination.lastModifiedByRole;
                this.lastModified.UserName = nomination.lastModifiedUser;
              }
            }
          });
          this.sortFuneralBeneficiaries();
          // Assign sequence numbers to nominationData
          this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach((nomination, index) => {
            nomination.sequence = index + 1;
          });
        } else {
          this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
          this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name
          this.funeralBeneficiaryGrouping.nominationData.set([]);
        }
        let customEffectiveDate = new Date();
        if (this.effectiveDate) {
          if (this.lastModified.Role == EnumHelper.getItemMetadata(UserRoleType, UserRoleType.Member).name) {
            customEffectiveDate.setDate(this.effectiveDate.getDate() + 1);
          } else {
            customEffectiveDate.setDate(this.effectiveDate.getDate());
          }
        }
        if (this.thirdPartyNomination.file != null && this.thirdPartyNomination.signedDate != null && (!this.effectiveDate || this.thirdPartyNomination.signedDate >= customEffectiveDate)) {
          this.receivedNominationForm = true;
        }

      }
      else {
        this.approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;

        this.unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
        this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name

        this.funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
        this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name

        let customEffectiveDate = new Date();
        if (this.effectiveDate) {
          if (this.lastModified.Role == EnumHelper.getItemMetadata(UserRoleType, UserRoleType.Member).name) {
            customEffectiveDate.setDate(this.effectiveDate.getDate() + 1);
          } else {
            customEffectiveDate.setDate(this.effectiveDate.getDate());
          }
        }
        if (this.thirdPartyNomination.file != null && this.thirdPartyNomination.signedDate != null && (!this.effectiveDate || this.thirdPartyNomination.signedDate >= customEffectiveDate)) {
          this.receivedNominationForm = true;
        }
      }
    } else {
      this.memberNominationsForAllBenefits.set([]);
      this.approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;

      this.unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
      this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name

      this.funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
      this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Beneficiaries", "There was an issue fetching beneficiaries.", "warning", NotificationDuration.Long);
    }
    this.approvedBeneficiaryGrouping.markOld();
    this.unApprovedBeneficiaryGrouping.markOld();
    this.funeralBeneficiaryGrouping.markOld();
    this.trustedContactPerson.markOld();
  }

  public selectAllBtnCssClassName() {
    if (this.tabValue == "Funeral Benefits" && this.knownBeneficiaries.length > 2) {
      return 'checkbox-disabled';
    }
    return '';
  }

  public selectAllBeneficiaries() {
    if (this.tabValue == "Funeral Benefits" && this.knownBeneficiaries.length > 2) {
      NotifyUtils.addWarning("Too Many Beneficiaries", "You may nominate only 2 beneficiaries for funeral Benefits", NotificationDuration.Long);
    } else {
      if (this.allBeneficiaries == false) {
        this.allBeneficiaries = true;
      } else {
        this.allBeneficiaries = false;
      }
      this.knownBeneficiaries.forEach(beneficiary => {
        if (beneficiary.canChangeSelection) {
          beneficiary.isSelected = this.allBeneficiaries
        }
      });
    }
  }

  public selectExistingBeneficiary() {
    if (this.tabValue == "Approved Death Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Unapproved Death Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Funeral Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Trusted Contact Person") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.trustedContactPerson.beneficiaryId == beneficiary.beneficiaryId) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showKnowBeneficiariesModalForTrustedPerson = true;
    }
  }

  public canSelectExistingBeneficiary(existingBeneficiary: MemberBeneficiaryLookup) {
    if (this.tabValue == "Funeral Benefits") {
      return existingBeneficiary.isSelected || this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1;
    } else if (this.tabValue == "Trusted Contact Person") {
      return existingBeneficiary.isSelected || this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length < 1;
    } else {
      return existingBeneficiary.canChangeSelection;
    }
  }

  public canSelectMatchingBeneficiary(existingBeneficiary: MemberBeneficiaryLookup) {
    return existingBeneficiary.isSelected || this.matchingBeneficiaries.filter(beneficiary => beneficiary.isSelected).length < 1;
  }

  public async addSelectedExistingBeneficiaries() {
    if (this.tabValue == "Approved Death Benefits") {
      this.knownBeneficiaries.forEach(async beneficiary => {
        if (beneficiary.isSelected) {
          if (!this.approvedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
            if (!this.approvedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              let newApprovedNomination = this.approvedBeneficiaryGrouping.nominationData.addNew();
              newApprovedNomination.mapFrom(beneficiary);
              newApprovedNomination.isDeleted = false;
              newApprovedNomination.relationshipType = beneficiary.relationship;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.approvedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newApprovedNomination));
            }
            else {
              let deletedNomination = this.approvedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
              let newApprovedNomination = this.approvedBeneficiaryGrouping.nominationData.addNew();
              if (deletedNomination) {
                newApprovedNomination.mapFrom(deletedNomination);
                this.approvedBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
              }
              newApprovedNomination.nominationPercentage = 0;
              newApprovedNomination.isDeleted = false;
              newApprovedNomination.expiryDate = null;
              this.approvedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newApprovedNomination));
            }
          }
        } else {
          var nominationToRemove = this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
          if (nominationToRemove) {
            this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
          }
        }
      });
    } else if (this.tabValue == "Unapproved Death Benefits") {
      this.knownBeneficiaries.forEach(async beneficiary => {
        if (beneficiary.isSelected) {
          if (!this.unApprovedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
            if (!this.unApprovedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              let newUnApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.addNew();
              newUnApprovedNomination.mapFrom(beneficiary);
              newUnApprovedNomination.isDeleted = false;
              newUnApprovedNomination.relationshipType = beneficiary.relationship;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newUnApprovedNomination));
            }
            else {
              let deletedNomination = this.unApprovedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
              let newunApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.addNew();
              if (deletedNomination) {
                newunApprovedNomination.mapFrom(deletedNomination);
                this.unApprovedBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
              }
              newunApprovedNomination.nominationPercentage = 0;
              newunApprovedNomination.isDeleted = false;
              newunApprovedNomination.expiryDate = null;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newunApprovedNomination));
            }
          }
        } else {
          var nominationToRemove = this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
          if (nominationToRemove) {
            this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
          }
        }
      });
    } else if (this.tabValue == "Funeral Benefits") {
      if (this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 2) {
        this.knownBeneficiaries.forEach(async beneficiary => {
          if (beneficiary.isSelected) {
            if (!this.funeralBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              if (!this.funeralBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
                let newFuneralNomination = this.funeralBeneficiaryGrouping.nominationData.addNew();
                newFuneralNomination.mapFrom(beneficiary);
                newFuneralNomination.isDeleted = false;
                newFuneralNomination.relationshipType = beneficiary.relationship;
                newFuneralNomination.sequence = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length;
                NotifyUtils.addSuccess("Existing beneficiary added", "The beneficiary has been added.", NotificationDuration.Long);
                this.funeralBeneficiaryGrouping.isActiveInterim = true;
                await this.taskRunner.run(async () => this.addInterimNomination(newFuneralNomination));
              }
              else {
                let deletedNomination = this.funeralBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
                let newfuneralNomination = this.funeralBeneficiaryGrouping.nominationData.addNew();
                if (deletedNomination) {
                  newfuneralNomination.mapFrom(deletedNomination);
                  this.funeralBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
                }
                newfuneralNomination.nominationPercentage = 0;
                newfuneralNomination.isDeleted = false;
                newfuneralNomination.expiryDate = null;
                NotifyUtils.addSuccess("Existing beneficiary added", "The beneficiary has been added.", NotificationDuration.Long);
                this.funeralBeneficiaryGrouping.isActiveInterim = true;
                await this.taskRunner.run(async () => this.addInterimNomination(newfuneralNomination));
              }
            }
          } else {
            var nominationToRemove = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
            if (nominationToRemove) {
              this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
            }
          }
        });
      } else {
        NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select two beneficiaries for funeral Benefits", NotificationDuration.Long);
      }
    } else if (this.tabValue == "Trusted Contact Person") {
      if (this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1) {
        let selectedTrustedBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.isSelected);
        if (selectedTrustedBeneficiary) {
          if (!selectedTrustedBeneficiary.trustedPerson) {
            let currentTrustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
            if (currentTrustedPerson) {
              currentTrustedPerson.trustedPerson = false;
            }
          }
          selectedTrustedBeneficiary.trustedPerson = true;
          this.trustedContactPerson.mapFrom(selectedTrustedBeneficiary);
          this.trustedContactPerson.markOld();
        }
      } else {
        NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select one existing beneficiary to be your trusted contact person", NotificationDuration.Long);
      }
    }
    this.showSelectExistingBeneficiariesModal = false;
  }

  public addSelectedMatchingBeneficiary() {
    if (this.matchingBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1) {
      let selectedTrustedBeneficiary = this.matchingBeneficiaries.find(beneficiary => beneficiary.isSelected);
      if (selectedTrustedBeneficiary) {
        if (!selectedTrustedBeneficiary.trustedPerson) {
          let currentTrustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
          if (currentTrustedPerson) {
            currentTrustedPerson.trustedPerson = false;
          }
        }
        selectedTrustedBeneficiary.trustedPerson = true;
        this.trustedContactPerson.mapFrom(selectedTrustedBeneficiary);
        this.trustedContactPerson.markOld();
      }
      this.updateTrustedPerson();
    } else {
      NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select one existing beneficiary to be your trusted contact person", NotificationDuration.Long);
    }
  }

  public initiateAddNewBeneficiary() {
    this.newBeneficiary = new MemberBeneficiaryLookup();
    this.showAddBeneficiariesModal = true;
  }

  public async addNewBeneficiary() {
    if (this.knownBeneficiaries.find(beneficiary =>
      (!StringUtils.isNullOrWhitespace(this.newBeneficiary.nationalIdNo) && beneficiary.nationalIdNo === this.newBeneficiary.nationalIdNo) ||
      (!StringUtils.isNullOrWhitespace(this.newBeneficiary.passportNo) && beneficiary.passportNo === this.newBeneficiary.passportNo))) {
      NotifyUtils.addWarning("Duplicate Beneficiary", "A beneficiary with matching unique details already exists.", NotificationDuration.Long);
      return;
    }

    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateMemberBeneficiariesCommand();

      updateCommand.memberId = this.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.currentSchemeSelected.sourceId;
      updateCommand.nameId = this.currentSchemeSelected.nameId;

      if (this.currentSchemeSelected.memberNumber && this.currentSchemeSelected.schemeNumber) {
        updateCommand.memberNo = this.currentSchemeSelected.memberNumber;
        updateCommand.schemeNo = this.currentSchemeSelected.schemeNumber;
      }

      if (!this.newBeneficiary.isIDNumber) {
        this.newBeneficiary.passportCountryOfIssue = this.getCountryById(this.newBeneficiary.countryOfIssueValueId);
      }

      this.newBeneficiary.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
      this.newBeneficiary.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';

      updateCommand.memberBeneficiaries.push(this.newBeneficiary);
      updateCommand.memberBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId)!.telephoneNo = `${this.newBeneficiary.countryCode} ${this.newBeneficiary.telephoneNo}`;

      const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

      let newBeneficiaryResult = response.data.find(beneficiary => beneficiary.message == "Inserted a new record");

      if (newBeneficiaryResult) {
        this.newBeneficiary.beneficiaryId = newBeneficiaryResult.beneficiaryId;

        if (this.newBeneficiary.nationalIdNo) {
          this.newBeneficiary.nationalIdNoOrPassportNo = this.newBeneficiary.nationalIdNo;
        } else {
          this.newBeneficiary.nationalIdNoOrPassportNo = this.newBeneficiary.passportNo ?? "";
        }
      }

      this.knownBeneficiaries.push(this.newBeneficiary);

      this.showAddBeneficiariesModal = false;
      NotifyUtils.addSuccess("New beneficiary added", this.tabValue == "Funeral Benefits" ? "A new beneficiary has been added." : "A new beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);

      if (this.tabValue == "Approved Death Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        this.approvedBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.approvedBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      } else if (this.tabValue == "Unapproved Death Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        this.unApprovedBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      } else if (this.tabValue == "Funeral Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        newBeneficiary.sequence = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length + 1;
        this.funeralBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.funeralBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      }
    } else {
      NotifyUtils.addWarning("Issue Adding Beneficiary", "There was an issue trying to add a new beneficiary.", NotificationDuration.Long);
    }
  }

  public handleSelectedTrustedPerson(item: MemberBeneficiaryLookup) {
    item.isSelected = !item.isSelected;

    // Update the selected status for other items
    this.knownBeneficiaries.forEach((otherItem) => {
      if (otherItem !== item) {
        otherItem.isSelected = false;
      }
    });
  }

  public clearTrustedPersonDetails() {
    this.trustedContactPerson = new MemberBeneficiaryLookup();
    this.trustedContactPerson.trustedPerson = true;
  }

  public switchBeneficiariesAround() {
    if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1) {
      const primaryBeneficiary = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find((item) => {
        return item.sequence === 1;
      });

      const nonPrimaryBeneficiary = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find((item) => {
        return item.sequence !== 1;
      })

      primaryBeneficiary!.sequence = 2;
      nonPrimaryBeneficiary!.sequence = 1;
    }
    this.sortFuneralBeneficiaries();
    this.funeralBeneficiaryGrouping.markOld();
  }

  private sortFuneralBeneficiaries() {
    this.funeralBeneficiaryGrouping.nominationData
      .sort((item1, item2) => {
        return item1.sequence - item2.sequence;
      })
  }

  public verifyUpdatedNominations() {
    if (this.currentUser.userRoleType === UserRoleType.Member || this.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) {
      if (this.tabValue === "Approved Death Benefits") {
        if (this.approvedTotalShareValue === 100) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("Total share value", "Please ensure that the total share value is equal to 100%.", NotificationDuration.Long);
        }
      } else if (this.tabValue == "Unapproved Death Benefits") {
        if (this.unapprovedTotalShareValue === 100) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("Total share value", "Please ensure that the total share value is equal to 100%.", NotificationDuration.Long);
        }
      } else if (this.tabValue == "Funeral Benefits") {
        if (this.funeralBeneficiaryGrouping.nominationData) {
          if (this.funeralBeneficiaryGrouping.nominationData[0].birthDate && new Date().getFullYear() - this.funeralBeneficiaryGrouping.nominationData[0].birthDate.getFullYear() < 18) {
            NotifyUtils.addDanger("Beneficiary Age", "The primary beneficiary must be at least 18 years old.", NotificationDuration.Long);
            return;
          }
        }
        if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("No Beneficiary Selected", "Please select a beneficiary.", NotificationDuration.Long);
        }
      }
    } else if (this.currentUser.userRoleType === UserRoleType.Staff
      || this.currentUser.userRoleType === UserRoleType.Employer
      || this.currentUser.userRoleType === UserRoleType.FinancialAdviser
      || this.currentUser.userRoleType === UserRoleType.FinancialAdviserAssistant) {
      if (this.receivedNominationForm) {
        this.showUserAddedAsThirdParty = true;
        if (this.allTabsChecked()) {
          this.receivedNominationForm = false;
          this.updateThirdPartyAllocations();
        }
      } else if (this.requiresNominatedForm && !this.receivedNominationForm) {
        this.showNeedsNominatedFormAlert = true;
      }
    }
  }

  private async updateAllocations() {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      var command = new UpdateMemberNominationCommand();
      command.memberId = this.currentSchemeSelected.memberId;
      command.sourceId = this.currentSchemeSelected.sourceId;

      if (this.currentSchemeSelected.memberNumber && this.currentSchemeSelected.schemeNumber) {
        command.memberNo = this.currentSchemeSelected.memberNumber;
        command.schemeNo = this.currentSchemeSelected.schemeNumber;
      }

      if (this.tabValue === "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });
        command.memberNominations = this.approvedBeneficiaryGrouping

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedApproved.Role = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
        this.lastModifiedApproved.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      } else if (this.tabValue === "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });
        command.memberNominations = this.unApprovedBeneficiaryGrouping;

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedUnApproved.Role = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
        this.lastModifiedUnApproved.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      } else if (this.tabValue === "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.nominationData.forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });

        command.memberNominations = this.funeralBeneficiaryGrouping;

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedFuneral.Role = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
        this.lastModifiedFuneral.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      }

      NotifyUtils.addSuccess("Beneficiary allocation", "The total share value is now fully allocated between your below beneficiaries.", NotificationDuration.Long);
    } else {
      NotifyUtils.addWarning("Issue Updating Nominations", "There was an issue updating your nominations.", NotificationDuration.Long);
    }
  }

  private async updateThirdPartyAllocations() {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      var command = new UpdateThirdPartyNominationsCommand();

      command.memberId = this.currentSchemeSelected.memberId.toString();
      command.sourceId = this.currentSchemeSelected.sourceId;

      if (this.currentSchemeSelected.memberNumber && this.currentSchemeSelected.schemeNumber) {
        command.memberNo = this.currentSchemeSelected.memberNumber;
        command.schemeNo = this.currentSchemeSelected.schemeNumber;
      }

      if (this.memberBenefitsLookup.hasApprovedBenefit) {
        command.nominations.push(this.approvedBeneficiaryGrouping);
      }

      if (this.memberBenefitsLookup.hasUnapprovedBenefit) {
        command.nominations.push(this.unApprovedBeneficiaryGrouping);
      }

      if (this.memberBenefitsLookup.hasFuneralBenefit) {
        command.nominations.push(this.funeralBeneficiaryGrouping);
      }

      command.nominations.forEach(nomination => {
        nomination.nominationData.forEach(nominationData => {
          nominationData.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
          nominationData.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });
      });

      command.nominations.forEach(nomination => {
        nomination.nominationData.forEach(nominationData => {
          if (nominationData.isDeleted) {
            nominationData.expiryDate = new Date();
          }
        });
      });

      const response = await this.taskRunner.run(async () => this.thirdPartyNominationsCommandApiClient.postThirdPartyNominations(command.toJSObject({ includeClean: true })));

      let nominationsResponse = response.data;
      if (nominationsResponse === "Case added successfully") {
        await this.deleteThirdPartyInterimNomination();
        NotifyUtils.addSuccess("Beneficiary nomination", "The nominations were uploaded successfully.", NotificationDuration.Long);
      } else {
        NotifyUtils.addWarning("Issue Updating Nominations", "There was an issue uploading your nominations.", NotificationDuration.Long);
      }
    }
  }


  public allTabsChecked() {
    if (this.currentUser.userRoleType === UserRoleType.Staff || this.currentUser.userRoleType === UserRoleType.Employer || this.currentUser.userRoleType === UserRoleType.FinancialAdviser || this.currentUser.userRoleType === UserRoleType.FinancialAdviserAssistant) {
      if (
        (this.memberBenefitsLookup.hasApprovedBenefit && !this.verifyApprovedDeathBenefits) ||
        (this.memberBenefitsLookup.hasUnapprovedBenefit && !this.verifyUnapprovedDeathBenefits) ||
        (this.memberBenefitsLookup.hasFuneralBenefit && !this.verifyFuneralBenefits)
      ) {
        return false;
      }
    }
    return true;
  }

  public checkFuneralBeneficiariesOver18(beneficiaryId: string = ""): boolean {
    let beneficiaryToCheckAge = this.funeralBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiaryId);
    let allBeneficiariesOver18 = true;

    if (beneficiaryToCheckAge && beneficiaryToCheckAge.birthDate) {
      const today = new Date();
      const month = today.getMonth() - beneficiaryToCheckAge.birthDate.getMonth();
      const day = today.getDate() - beneficiaryToCheckAge.birthDate.getDate();
      let age = today.getFullYear() - beneficiaryToCheckAge.birthDate.getFullYear();
      if ((month < 0 || (month === 0 && today.getDate() < beneficiaryToCheckAge.birthDate.getDate()))
        || (day < 0 || (day === 0 && today.getDate() < beneficiaryToCheckAge.birthDate.getDate()))) {
        age--;
      }
      if (age < 18) {
        return false;
      }
      return true;
    }
    this.funeralBeneficiaryGrouping.nominationData.filter(item => item.isDeleted === false).forEach(nomination => {
      if (nomination.birthDate) {
        const today = new Date();
        const month = today.getMonth() - nomination.birthDate.getMonth();
        const day = today.getDate() - nomination.birthDate.getDate();
        let age = today.getFullYear() - nomination.birthDate.getFullYear();
        if ((month < 0 || (month === 0 && today.getDate() < nomination.birthDate.getDate()))
          || (day < 0 || (day === 0 && today.getDate() < nomination.birthDate.getDate()))) {
          age--;
        }
        if (age < 18) {
          allBeneficiariesOver18 = false;
        }
      } else {
        allBeneficiariesOver18 = false;
      }
    });

    return allBeneficiariesOver18;
  }

  public async deleteApprovedBeneficiary(item: MemberBeneficiaryLookup) {
    var approvedBeneficiaryItem = this.approvedBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (approvedBeneficiaryItem) {
      approvedBeneficiaryItem.isDeleted = true;
      approvedBeneficiaryItem.expiryDate = new Date();
      approvedBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
      approvedBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      this.approvedBeneficiaryGrouping.nominationData.remove(approvedBeneficiaryItem);
      await this.deleteInterimBeneficiaryNomination(approvedBeneficiaryItem);
      this.showDeleteModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public async deleteUnapprovedBeneficiary(item: MemberBeneficiaryLookup) {
    var unApprovedBeneficiaryItem = this.unApprovedBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (unApprovedBeneficiaryItem) {
      unApprovedBeneficiaryItem.isDeleted = true;
      unApprovedBeneficiaryItem.expiryDate = new Date();
      unApprovedBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
      unApprovedBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      this.unApprovedBeneficiaryGrouping.nominationData.remove(unApprovedBeneficiaryItem);
      await this.deleteInterimBeneficiaryNomination(unApprovedBeneficiaryItem);
      this.showDeleteModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public async deleteFuneralBeneficiary(item: MemberBeneficiaryLookup) {
    var funeralBeneficiaryItem = this.funeralBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (funeralBeneficiaryItem) {
      funeralBeneficiaryItem.isDeleted = true;
      funeralBeneficiaryItem.expiryDate = new Date();
      funeralBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
      funeralBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      await this.deleteInterimBeneficiaryNomination(funeralBeneficiaryItem);
      this.funeralBeneficiaryGrouping.nominationData.remove(funeralBeneficiaryItem);

      // Reset sequence numbers
      this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach((nomination, index) => {
        nomination.sequence = index + 1;
      });

      this.showFuneralDeletedModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public editBeneficiary(item: MemberNominationDataLookup) {
    let knownBeneficiaryToEdit = this.knownBeneficiaries.find(x => x.beneficiaryId === item.beneficiaryId);

    if (knownBeneficiaryToEdit) {
      this.selectedBeneficiaryToEdit.mapFrom(knownBeneficiaryToEdit);

      if (StringUtils.isNullOrWhitespace(this.selectedBeneficiaryToEdit.nationalIdNo)) {
        this.selectedBeneficiaryToEdit.isIDNumber = false;
      }
      else {
        this.selectedBeneficiaryToEdit.isIDNumber = true;
      }

      this.helpersService.splitContactNumber(this.selectedBeneficiaryToEdit);

      this.showEditBeneficiaryModal = true;
    }
  }

  public async submitUpdatedBeneficiary() {
    if (this.selectedBeneficiaryToEdit.isValid && this.currentSchemeSelected.memberId &&
      !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      if (this.selectedBeneficiaryToEdit.isIDNumber) {
        this.selectedBeneficiaryToEdit.nationalIdNoOrPassportNo = this.selectedBeneficiaryToEdit.nationalIdNo ?? '';
        this.selectedBeneficiaryToEdit.passportNo = '';
        this.selectedBeneficiaryToEdit.passportCountryOfIssue = '';
      } else {
        this.selectedBeneficiaryToEdit.nationalIdNoOrPassportNo = this.selectedBeneficiaryToEdit.passportNo ?? '';
        this.selectedBeneficiaryToEdit.nationalIdNo = '';
        this.selectedBeneficiaryToEdit.passportCountryOfIssue = this.getCountryById(this.selectedBeneficiaryToEdit.countryOfIssueValueId);
      }

      let updateCommand = new UpdateMemberBeneficiariesCommand();

      updateCommand.memberId = this.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.currentSchemeSelected.sourceId;
      updateCommand.nameId = this.currentSchemeSelected.nameId;

      if (this.currentSchemeSelected.memberNumber && this.currentSchemeSelected.schemeNumber) {
        updateCommand.memberNo = this.currentSchemeSelected.memberNumber;
        updateCommand.schemeNo = this.currentSchemeSelected.schemeNumber;
      }

      this.selectedBeneficiaryToEdit.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
      this.selectedBeneficiaryToEdit.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';

      updateCommand.memberBeneficiaries.push(this.selectedBeneficiaryToEdit);
      updateCommand.memberBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId)!.telephoneNo = `${this.selectedBeneficiaryToEdit.countryCode} ${this.selectedBeneficiaryToEdit.telephoneNo}`;

      const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

      let updatedBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (updatedBeneficiary) {
        updatedBeneficiary.mapFrom(this.selectedBeneficiaryToEdit);
        updatedBeneficiary.relationship = this.selectedBeneficiaryToEdit.relationship;
        updatedBeneficiary.markOld();
      }

      let approvedNomination = this.approvedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (approvedNomination) {
        approvedNomination.mapFrom(this.selectedBeneficiaryToEdit);
        approvedNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.approvedBeneficiaryGrouping.markOld();
      }
      let unApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (unApprovedNomination) {
        unApprovedNomination.mapFrom(this.selectedBeneficiaryToEdit);
        unApprovedNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.unApprovedBeneficiaryGrouping.markOld();
      }
      let funeralNomination = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (funeralNomination) {
        funeralNomination.mapFrom(this.selectedBeneficiaryToEdit);
        funeralNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.funeralBeneficiaryGrouping.markOld();
      }
      this.helpersService.splitContactNumber(this.selectedBeneficiaryToEdit);
      this.showEditBeneficiaryModal = false;
      if (this.tabValue === "Funeral Benefits") {
        if (!this.checkFuneralBeneficiariesOver18(this.selectedBeneficiaryToEdit.beneficiaryId)) {
          NotifyUtils.addWarning("Beneficiary Age", "Beneficiaries in this list must be 18 years or older", NotificationDuration.Long);
        }
      }
      this.selectedBeneficiaryToEdit = new MemberBeneficiaryLookup();
      NotifyUtils.addPrimary("Beneficiary updated", "Your beneficiary information has been updated.", NotificationDuration.Long);
    } else {
      NotifyUtils.addWarning("Issue Updating Beneficiary", "There was an issue updating the beneficiary.", NotificationDuration.Long);
    }
  }

  public trustedContactPersonHasData() {
    return !StringUtils.isNullOrWhitespace(this.trustedContactPerson.firstName) ||
      !StringUtils.isNullOrWhitespace(this.trustedContactPerson.lastName) ||
      !StringUtils.isNullOrWhitespace(this.trustedContactPerson.telephoneNo);
  }

  public verifyTrustedPerson() {
    if (this.currentUser.userRoleType === UserRoleType.Member || this.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) {
      if (this.trustedContactPerson.isValidTrustedPerson()) {
        this.matchingBeneficiaries.set(this.findMatchingBeneficiaries().map(beneficiary => beneficiary.toJSObject()));
        if (this.matchingBeneficiaries && this.matchingBeneficiaries.length > 1) {
          this.showMatchingKnownBeneficiariesModal = true;
        } else {
          this.updateTrustedPerson();
        }
      }
    } else if (this.currentUser.userRoleType === UserRoleType.Staff || this.currentUser.userRoleType === UserRoleType.Employer || this.currentUser.userRoleType === UserRoleType.FinancialAdviser || this.currentUser.userRoleType === UserRoleType.FinancialAdviserAssistant) {
      NotifyUtils.addWarning("Not Available", "Third Party Users may not update trusted contact person", NotificationDuration.Long);
    }
  }

  public findMatchingBeneficiaries() {
    let matchingBeneficiaries = this.knownBeneficiaries.filter(beneficiary =>
      beneficiary.beneficiaryId !== this.trustedContactPerson.beneficiaryId &&
      beneficiary.firstName.toLocaleLowerCase() === this.trustedContactPerson.firstName.toLocaleLowerCase() &&
      beneficiary.lastName.toLocaleLowerCase() === this.trustedContactPerson.lastName.toLocaleLowerCase()
    );
    matchingBeneficiaries.push(this.trustedContactPerson);
    return matchingBeneficiaries;
  }

  public async updateTrustedPerson() {
    if (this.currentUser.userRoleType === UserRoleType.Member || this.currentUser.userRoleType === UserRoleType.LibertyNonLegacyMember) {
      if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
        if (this.trustedContactPerson.isValidTrustedPerson()) {
          let updateCommand = new UpdateMemberBeneficiariesCommand();

          updateCommand.memberId = this.currentSchemeSelected.memberId;
          updateCommand.sourceId = this.currentSchemeSelected.sourceId;
          updateCommand.nameId = this.currentSchemeSelected.nameId;

          if (this.currentSchemeSelected.memberNumber && this.currentSchemeSelected.schemeNumber) {
            updateCommand.memberNo = this.currentSchemeSelected.memberNumber;
            updateCommand.schemeNo = this.currentSchemeSelected.schemeNumber;
          }

          this.knownBeneficiaries.forEach(beneficiary => {
            if (beneficiary.beneficiaryId === this.trustedContactPerson.beneficiaryId) {
              beneficiary.trustedPerson = true;
              beneficiary.firstName = this.trustedContactPerson.firstName;
              beneficiary.lastName = this.trustedContactPerson.lastName;
              beneficiary.telephoneNo = this.trustedContactPerson.telephoneNo;
              beneficiary.emailAddress = this.trustedContactPerson.emailAddress;
              if (beneficiary.isValid) {
                if (this.trustedContactPerson.relationship === "") {
                  this.trustedContactPerson.relationship = beneficiary.relationship;
                  NotifyUtils.addWarning("Relationship Required", "Relationship can't be empty for an existing linked beneficiary.", NotificationDuration.Long);
                }
              }
              beneficiary.relationship = this.trustedContactPerson.relationship;
            } else {
              beneficiary.trustedPerson = false;
            }
            beneficiary.lastModifiedByRole = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
            beneficiary.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
            updateCommand.memberBeneficiaries.push(beneficiary);
          });

          if (updateCommand.memberBeneficiaries && !updateCommand.memberBeneficiaries.find(beneficiary => beneficiary.trustedPerson)) {
            updateCommand.memberBeneficiaries.push(this.trustedContactPerson);
          }

          const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

          let newKnownBeneficiaryResult = response.data.find(beneficiary => beneficiary.message == "Inserted a new record");

          if (newKnownBeneficiaryResult && !this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.trustedContactPerson.beneficiaryId)) {
            this.trustedContactPerson.beneficiaryId = newKnownBeneficiaryResult.beneficiaryId;
            let newTrustedContactPerson = new MemberBeneficiaryLookup();
            newTrustedContactPerson.mapFrom(this.trustedContactPerson);
            this.knownBeneficiaries.push(newTrustedContactPerson);
          }

          // Remove any expired beneficiaries
          this.knownBeneficiaries.set(this.knownBeneficiaries.filter(beneficiary => beneficiary.expiryDate == null).map(beneficiary => beneficiary.toJSObject()));

          this.lastModifiedTrustedPerson.Role = EnumHelper.getItemMetadata(UserRoleType, this.currentUser.userRoleType).name;
          this.lastModifiedTrustedPerson.UserName = this.authService.user != null ? this.authService.user?.userName : '';

          NotifyUtils.addPrimary("Updated trusted contact person", "You have successfully updated the details of your trusted contact person.", NotificationDuration.Long);
        } else {
          this.trustedContactPerson.validator.forceShowErrors = true;
          NotifyUtils.addWarning("Missing Details", "Your trusted contact person is missing required information.", NotificationDuration.Long);
        }
      } else {
        NotifyUtils.addWarning("Issue Updating Trusted Contact Person", "There was an issue trying to update the trusted contact person.", NotificationDuration.Long);
      }
    } else if (this.currentUser.userRoleType === UserRoleType.Staff || this.currentUser.userRoleType === UserRoleType.Employer || this.currentUser.userRoleType === UserRoleType.FinancialAdviser || this.currentUser.userRoleType === UserRoleType.FinancialAdviserAssistant) {
      NotifyUtils.addWarning("Not Available", "Third Party Users may not update trusted contact person", NotificationDuration.Long);
    }
  }

  private beneficiaryIsNominated(beneficiary: MemberBeneficiaryLookup) {
    let nominated = false;
    nominated = this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined ||
      this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined ||
      this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined;
    return nominated;
  }

  public navigateToDashboard() {
    this.showDashboardNavigationModal = false;
    this.userRoleService.navigateToDashboard();
  }

  private hasUnsubmittedChanges() {
    return this.approvedBeneficiaryGrouping.nominationData.isDirty
      || this.unApprovedBeneficiaryGrouping.nominationData.isDirty
      || this.funeralBeneficiaryGrouping.nominationData.isDirty
      || this.trustedContactPerson.isDirty;
  }

  public getProvinceById(id: number | null): string {
    if (id) {
      let result = this.provinces.find(province => province.id == id);
      if (result) {
        return result?.province;
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountryById(id: number): string | null {
    let result = this.countries.find(country => country.countryId == id);
    if (result) {
      return result?.name;
    }
    else {
      return null;
    }
  }

  public async updateVerifiedStatus(tabValue: string) {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      if (tabValue == "Approved Death Benefits") {
        this.verifyApprovedDeathBenefits == true ? this.verifyApprovedDeathBenefits = false : this.verifyApprovedDeathBenefits = true;
      }
      if (tabValue == "Unapproved Death Benefits") {
        this.verifyUnapprovedDeathBenefits == true ? this.verifyUnapprovedDeathBenefits = false : this.verifyUnapprovedDeathBenefits = true;
      }
      if (tabValue == "Funeral Benefits") {
        this.verifyFuneralBenefits == true ? this.verifyFuneralBenefits = false : this.verifyFuneralBenefits = true;
      }
      let updateCommand = new UpdateThirdPartyInterimNominationCommand();
      updateCommand.thirdPartyNominationLookup = new ThirdPartyNominationLookup();
      updateCommand.thirdPartyNominationLookup.isApprovedVerified = this.verifyApprovedDeathBenefits;
      updateCommand.thirdPartyNominationLookup.isUnapprovedVerified = this.verifyUnapprovedDeathBenefits;
      updateCommand.thirdPartyNominationLookup.isFuneralVerified = this.verifyFuneralBenefits;
      updateCommand.memberId = this.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.currentSchemeSelected.sourceId;
      updateCommand.thirdPartyNominationLookup.signedDate = this.thirdPartyNomination.signedDate;
      updateCommand.nominationType = this.tabValue == "Approved Death Benefits" ? EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name
        : this.tabValue == "Unapproved Death Benefits" ? EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name
          : EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
      const updateInterimMemberNominationCommand = updateCommand.toJSObject()
      await this.thirdPartyNominationsCommandApiClient.updateVerifiedStatus(updateInterimMemberNominationCommand, this.tabValue.toString());
    }
  }
  public async updateInterimNomination(item: MemberNominationDataLookup) {
    if (!this.viewOnlyBeneficiary) {
      if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
        let updateCommand = new UpdateInterimMemberNominationCommand();
        updateCommand.beneficiaryId = item.beneficiaryId;
        updateCommand.memberId = this.currentSchemeSelected.memberId;
        updateCommand.sourceId = this.currentSchemeSelected.sourceId;
        updateCommand.nominationPercentage = item.nominationPercentage;
        updateCommand.nominationId = item.nominationId;
        updateCommand.isDeleted = item.isDeleted;

        if (this.tabValue == "Approved Death Benefits") {
          this.approvedBeneficiaryGrouping.isActiveInterim = true;
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
          const interimBeneficiaryNominationRequestLookup = updateCommand.toJSObject()

          await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup);
        }
        else if (this.tabValue == "Unapproved Death Benefits") {
          this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
          const interimBeneficiaryNominationRequestLookup = updateCommand.toJSObject()
          await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup);
        }
      }
    }
  }

  public async deleteInterimBeneficiaryNomination(item: MemberNominationDataLookup) {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      let request = new UpdateInterimMemberNominationCommand();
      request.beneficiaryId = item.beneficiaryId;
      request.memberId = this.currentSchemeSelected.memberId;
      request.sourceId = this.currentSchemeSelected.sourceId;
      request.isDeleted = true;
      request.nominationId = item.nominationId;
      request.nominationPercentage = 0;
      if (this.tabValue == "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
    }
  }

  public async addInterimNomination(item: MemberNominationDataLookup) {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateInterimMemberNominationCommand();
      updateCommand.beneficiaryId = item.beneficiaryId;
      updateCommand.memberId = this.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.currentSchemeSelected.sourceId;
      updateCommand.nominationPercentage = item.nominationPercentage;
      updateCommand.nominationId = item.nominationId;

      if (this.tabValue == "Approved Death Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Funeral Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
    }
  }

  public async deleteInterimNomination() {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      if (this.tabValue == "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name,
          this.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.approvedBeneficiaryGrouping.markOld();
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name,
          this.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.unApprovedBeneficiaryGrouping.markOld();
      }
      else if (this.tabValue == "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name,
          this.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.funeralBeneficiaryGrouping.markOld();
      }
    }
  }

  public async deleteThirdPartyInterimNomination() {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      this.approvedBeneficiaryGrouping.isActiveInterim = false;
      this.unApprovedBeneficiaryGrouping.isActiveInterim = false;
      this.funeralBeneficiaryGrouping.isActiveInterim = false;

      await this.thirdPartyNominationsCommandApiClient.deleteInterimThirdPartyNomination(this.currentSchemeSelected.memberId, this.currentSchemeSelected.sourceId);
      await this.getMemberNominationsLookups();

      this.approvedBeneficiaryGrouping.markOld();
      this.unApprovedBeneficiaryGrouping.markOld();
      this.funeralBeneficiaryGrouping.markOld();
      this.receivedNominationForm = false;
    }
  }

  //This code is currently unused but may be useful in the future.
  //Executed when click accept yes on cancel 
  public async resetInterimNominations() {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateInterimMemberNominationCommand();
      updateCommand.memberId = this.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.currentSchemeSelected.sourceId;

      if (this.tabValue == "Approved Death Benefits") {
        if (this.approvedOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.approvedOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.currentSchemeSelected.sourceId;
            if (this.currentSchemeSelected.memberId)
              nomination.memberId = this.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        if (this.unApprovedOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.unApprovedOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.currentSchemeSelected.sourceId;
            if (this.currentSchemeSelected.memberId)
              nomination.memberId = this.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
      else if (this.tabValue == "Funeral Benefits") {
        if (this.funeralOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.funeralOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.currentSchemeSelected.sourceId;
            if (this.currentSchemeSelected.memberId)
              nomination.memberId = this.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
    }
  }

  //TODO: Add this logic to the masterdata service in R2.3
  public getProvince(province: string | null, countryValueId: number | null): number {
    let southAfricanId = this.countries.find(s => s.name.toLowerCase() === "south africa")?.countryId;

    if (province) {
      if (!countryValueId || (countryValueId != southAfricanId && StringUtils.isNullOrWhitespace(province))) {
        return 0;
      }
      if (countryValueId && countryValueId == southAfricanId && StringUtils.isNullOrWhitespace(province)) {
        return 1;
      }
      if (province.toLowerCase() == "kwazulu natal") {
        return 4;
      }
      let result = this.provinces.find(x => x.province.toLowerCase() === province.toLowerCase());
      if (result) {
        return result.id;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }

  public getCountry(country: string | null): number {
    let southAfricanId = this.countries.find(s => s.name.toLowerCase() === "south africa")?.countryId;
    let zimbabweId = this.countries.find(s => s.name.toLowerCase() === "zimbabwe")?.countryId;

    if (country) {
      if (country.toLowerCase() == "sa" || country.toLowerCase() == "southafrica" || StringUtils.isNullOrWhitespace(country)) {
        return southAfricanId ?? this.countries[0]?.countryId;
      }
      if (country.toLowerCase() == "za") {
        return zimbabweId ?? this.countries[0]?.countryId;
      }
      let result = this.countries.find(s => s.name.toLowerCase() === country.toLowerCase());
      if (result) {
        return result.countryId
      }
      else {
        return this.countries[0]?.countryId;
      }
    }
    else {
      return this.countries[0]?.countryId;
    }
  }

  //This code is currently unused but may be useful in the future.
  public convertToInterimNomination(memberNominationData: List<MemberNominationDataLookup>) {
    this.nominationsDataArray.set([]);
    if (memberNominationData) {
      memberNominationData.forEach((item) => {
        let memberNomination = new UpdateInterimMemberNominationCommand();
        memberNomination.beneficiaryId = item.beneficiaryId;
        memberNomination.nominationId = item.nominationId;
        memberNomination.nominationPercentage = item.nominationPercentage;
        memberNomination.isDeleted = item.isDeleted;
        memberNomination.nominationType = item.nominationType;
        this.nominationsDataArray.push(memberNomination);
      });
      return this.nominationsDataArray;
    }
    return null;
  }

  // File upload functionality

  public fileManager = new Components.FileManager(
    this.thirdPartyNominationsCommandApiClient.getUploadThirdPartyBeneficiaryNominationFormSecureDocumentUrl(),
    {
      multiple: true,
      allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".tif", ".tiff", ".heic"],
      onFilesSelected: this.setDocuments.bind(this),
      afterUpload: this.afterDocumentUpload.bind(this),
      additionalData: { memberId: this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0 },
    });

  public document: File | undefined;

  public setDocuments(fileList: File[] | FileList) {
    if (this.thirdPartyNomination.file != null || this.receivedNominationForm) {
      this.showFileSizeModal = true;
      return;
    }
    this.document = fileList[0];
    this.showSignedDateModal();
  }

  public afterDocumentUpload(uploadedFiles: any) {
    let file = uploadedFiles.file as PartyFileDescriptorLookup;
    if (file) {
      if (!this.thirdPartyNomination.files.find(f => f.fileName === file.fileName)) {
        let partyFileDescriptorLookup = new PartyFileDescriptorLookup();
        partyFileDescriptorLookup.mapFrom(file);
        this.thirdPartyNomination.file = partyFileDescriptorLookup;
      }
    }
    this.receivedNominationForm = true
  }

  public async onDocumentsSelected() {
    //check the signedDate against the current date
    let currentDate = new Date();
    let signedDate = this.thirdPartyNomination.signedDate;

    var approvedLatestDate = this.approvedBeneficiaryGrouping.nominationData.map(nomination => nomination.effectiveDate).sort().reverse()[0];
    var unapprovedLatestDate = this.unApprovedBeneficiaryGrouping.nominationData.map(nomination => nomination.effectiveDate).sort().reverse()[0];
    var funeralLatestDate = this.funeralBeneficiaryGrouping.nominationData.map(nomination => nomination.effectiveDate).sort().reverse()[0];

    if (this.lastModifiedApproved.Role === EnumHelper.getItemMetadata(UserRoleType, UserRoleType.Member).name && approvedLatestDate) {
      approvedLatestDate.setDate(approvedLatestDate.getDate() + 1);
    }
    if (this.lastModifiedUnApproved.Role === EnumHelper.getItemMetadata(UserRoleType, UserRoleType.Member).name && unapprovedLatestDate) {
      unapprovedLatestDate.setDate(unapprovedLatestDate.getDate() + 1);
    }
    if (this.lastModifiedFuneral.Role === EnumHelper.getItemMetadata(UserRoleType, UserRoleType.Member).name && funeralLatestDate) {
      funeralLatestDate.setDate(funeralLatestDate.getDate() + 1);
    }

    if (signedDate && approvedLatestDate && (signedDate <= approvedLatestDate)) {
      NotifyUtils.addWarning("Invalid Date", "The signed date must be after the latest nomination effective date.", NotificationDuration.Long);
      return;
    }
    if (signedDate && unapprovedLatestDate && (signedDate <= unapprovedLatestDate)) {
      NotifyUtils.addWarning("Invalid Date", "The signed date must be after the latest nomination effective date.", NotificationDuration.Long);
      return;
    }
    if (signedDate && funeralLatestDate && (signedDate <= funeralLatestDate)) {
      NotifyUtils.addWarning("Invalid Date", "The signed date must be after the latest nomination effective date.", NotificationDuration.Long);
      return;
    }

    if (signedDate && (signedDate >= currentDate)) {
      NotifyUtils.addWarning("Invalid Date", "The signed date must be before the current date.", NotificationDuration.Long);
      return;
    } else {
      // Check the file size and extension
      if (this.document) {
        let imageTypes = ["jpeg", "jpg", "png", "pdf"];
        let currentFileExtension = this.getFileExtension(this.document.name.toLowerCase())!
        let fileSizeInMegabytes = this.document.size / this.cnstMegabyte;
        if (this.thirdPartyNomination.file != null || this.receivedNominationForm || (!imageTypes.includes(currentFileExtension) && fileSizeInMegabytes > 5)) {
          this.showNominationFormDateModal = false;
          this.showFileSizeModal = true;
          return;
        }

        // populated filesToUpload with valid files
        let filesToUpload = [this.document];

        this.fileManager.uploadFiles(filesToUpload, {
          handleErrors: true,
          additionalData: {
            memberId: this.currentSchemeSelected.memberId ?? 0,
            schemeId: this.currentSchemeSelected.schemeId ?? 0,
            sourceId: this.currentSchemeSelected.sourceId ?? "",
            signedDate: this.thirdPartyNomination.signedDate?.toDateString() ?? "",
          },
        });
        this.showNominationFormDateModal = false;
        this.receivedNominationForm = true;
      }
    }
  }

  private getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  private getFileNameWithoutExtension(fileName: string): string {
    let filename = fileName.split('.').slice(0, -1).join('.');
    return filename;
  }

  // Download secure file
  public async downloadSecureDocument(fileDescriptorId: number, fileName: string) {
    if (this.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.currentSchemeSelected.sourceId) &&
      !StringUtils.isNullOrWhitespace(fileName)) {
      const response = await this.taskRunner.waitFor(this.thirdPartyQueryApiClient.getSecureDocument(this.currentSchemeSelected.sourceId, fileName, this.currentSchemeSelected.memberId));
      FileUtils.showSaveFile(response);
    } else {
      NotifyUtils.addWarning("Issue Fetching File", "There was an issue fetching the nomination form file.", NotificationDuration.Long)
    }
  }

  public async selectExistingFiles() {
    this.showFileSelectModal = true;
  }

  public async addOrRemoveDocumentFromList(fileName: string, add: boolean) {
    if (!add) {
      this.thirdPartyNomination.files.push(this.thirdPartyNomination.files.find(f => f.fileName === fileName)!);
    } else {
      this.thirdPartyNomination.files.remove(this.thirdPartyNomination.files.find(f => f.fileName === fileName)!);
    }
  }

  public async showSignedDateModal() {
    this.showNominationFormDateModal = true;
  }

  private getTabCodes() {
    var tabs = [];
    var index = 1;

    if (this.memberBenefitsLookup.hasApprovedBenefit) {
      tabs.push({ id: index, tabCodes: "Approved Death Benefits" });
      if (index === 1) {
        this.tabValue = "Approved Death Benefits"
      }
      index++;
    }
    if (this.memberBenefitsLookup.hasUnapprovedBenefit) {
      tabs.push({ id: index, tabCodes: "Unapproved Death Benefits" });
      if (index === 1) {
        this.tabValue = "Unapproved Death Benefits"
      }
      index++;
    }
    if (this.memberBenefitsLookup.hasFuneralBenefit) {
      tabs.push({ id: index, tabCodes: "Funeral Benefits" });
      if (index === 1) {
        this.tabValue = "Funeral Benefits"
      }
      index++;
    }
    tabs.push({ id: index, tabCodes: "Trusted Contact Person" });
    this.viewTabs = tabs;
  }
}