import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import InstructionsSearchResultsLookup from '../../Models/Lookups/Instructions/InstructionsSearchResultsLookUp';
import InstructionsSearchCriteria from '../../Models/Lookups/Instructions/IntructionsSearchCriteria';

import { AppService, Types } from '../../../Dashboard/DashboardTypes';


export interface ICallsAndInstructionsApiClient {

  /**
   * Returns the InstructionsSearchResultsLookupListPaged .
   * @returns Paged List Of InstructionsSearchResults.
   */
  getSchemeInstructionsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>>;

  /** 
   * Returns the InstructionsSearchResultsLookupListPaged .
   * @returns Paged List Of InstructionsSearchResults.
   */
  getMemberInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>>;

  /** 
  * Returns the InstructionsSearchResultsLookupListPaged .
  * @returns Paged List Of InstructionsSearchResults.
  */
  getMemberCompletedInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>>;
  /** 
  * Returns the InstructionsSearchResultsLookupListPaged .
  * @returns Paged List Of InstructionsSearchResults.
  */
  getSchemeCompletedInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>>;
  // Client only properties / methods
}

@injectable()
export default class CallsAndInstructionsApiClient extends Data.ApiClientBase implements ICallsAndInstructionsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/CallsAndInstructions`);
  }

  public getSchemeInstructionsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/instructions-scheme-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getMemberInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/instructions-member-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getMemberCompletedInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/instructions-member-completed-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getSchemeCompletedInstrcuctionsResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<InstructionsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<InstructionsSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/instructions-scheme-completed-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}