import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { Model } from '@singularsystems/neo-core';
import ProductsVM from '../../Views/Member/ProductsVM';
import MemberDependentsBenefitDetailsLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentsBenefitDetailsLookup';
import MemberDependentBenefitsLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentBenefitsLookup';
import MemberDependentsBenefitDataLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentsBenefitDataLookup';
import BenefitClassTypeCode from '../../../Party/Models/Enums/BenefitClassTypeCode';

interface IProductChildDeathBenefitProps {
  memberDependentsBenefitsLookup: MemberDependentBenefitsLookup,
  viewModel: ProductsVM
}

@observer
export default class ProductChildDeathBenefit extends React.Component<IProductChildDeathBenefitProps> {

  public isParentExpanded = new Model.ObservableValue(false);
  public isChildCoverCardExpanded = new Model.ObservableValue(false);
  public isExpandedArray: any[] = [];


  constructor(props: IProductChildDeathBenefitProps) {
    super(props);
  }

  public setTableText(memberDependentBenefitDetail: MemberDependentsBenefitDetailsLookup): string {
    if (
      (memberDependentBenefitDetail.fromAge == 0 && memberDependentBenefitDetail.toAge == 5) ||
      (memberDependentBenefitDetail.fromAge == 6 && memberDependentBenefitDetail.toAge == 13) ||
      (memberDependentBenefitDetail.fromAge == 14 && memberDependentBenefitDetail.toAge == 21)
    ) {
      return "Each child between the ages of " + memberDependentBenefitDetail.fromAge + " to " + memberDependentBenefitDetail.toAge
    }
    else {
      return ""
    }
  }

  public render() {
    return (
      <div>
        {this.props.memberDependentsBenefitsLookup?.funeralScaleName.length > 0 &&
          <Neo.Card title={"Risk benefits payable on death of a child"} className='cards-risk cards-headings-risk head-dropdown-cards-risk padding-phone'
            isExpanded={this.isParentExpanded}
          >
            <div className='grey-box-risk'>
              These are benefits that may become payable should any of your children pass away while you are still employed.
            </div>
            <br />
            <div className='table-title-risk'>
              <span className="table-title-risk bold">Funeral benefit - Your child's cover</span> (If your child passes away, the following will become payable to you towards funeral expenses)
            </div>
            <table className='table-width-risk'>
              <tr className='table-border-risk'>
                <th className='table-border-risk table-blank-right'></th>
                <th className='table-border-risk'>Benefit amount</th>
              </tr>
              {
                this.props.memberDependentsBenefitsLookup.memberDependentsBenefitData.filter((x: MemberDependentsBenefitDataLookup) => x.relationshipTypeCode == BenefitClassTypeCode.deathOfChild).map((memberDependentBenefitData: MemberDependentsBenefitDataLookup) => (
                  memberDependentBenefitData.memberDependentBenefitDetails.sortBy((a) => a.fromAge).filter(x => x.benefitAmount !== 0)?.map((memberDependentBenefitDetail: MemberDependentsBenefitDetailsLookup) => (
                    <tr className='table-border-risk'>
                      <td className='table-border-risk table-row-header-risk'>{this.setTableText(memberDependentBenefitDetail)}</td>
                      <td className='table-border-risk table-column-content-risk'><span>{memberDependentBenefitDetail.benefitAmount!.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</span></td>
                    </tr>
                  ))
                ))
              }
              {
                this.props.memberDependentsBenefitsLookup.memberDependentsBenefitData.filter((x: MemberDependentsBenefitDataLookup) => x.relationshipTypeCode == BenefitClassTypeCode.disabilityOfChildTertiary).map((memberDependentBenefitData: MemberDependentsBenefitDataLookup) => (
                  memberDependentBenefitData.memberDependentBenefitDetails.sortBy((a) => a.fromAge).filter(x => x.benefitAmount !== 0)?.map((memberDependentBenefitDetail: MemberDependentsBenefitDetailsLookup) => (
                    <tr className='table-border-risk'>
                      <td className='table-border-risk table-row-header-risk'>Each child between the age of  22 and 25 (inclusive) provided they are enrolled at a tertiary institution
                      </td>
                      <td className='table-border-risk table-column-content-risk'><span>{memberDependentBenefitDetail.benefitAmount!.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</span></td>
                    </tr>
                  ))
                ))
              }
              {
                this.props.memberDependentsBenefitsLookup.memberDependentsBenefitData.filter((x: MemberDependentsBenefitDataLookup) => x.relationshipTypeCode == BenefitClassTypeCode.physicalDisabilityOfChild).map((memberDependentBenefitData: MemberDependentsBenefitDataLookup) => (
                  memberDependentBenefitData.memberDependentBenefitDetails.sortBy((a) => a.fromAge).filter(x => x.benefitAmount !== 0)?.map((memberDependentBenefitDetail: MemberDependentsBenefitDetailsLookup) => (
                    <tr className='table-border-risk'>
                      <td className='table-border-risk table-row-header-risk'>Each child above age 21 provided they have a mental and/or physical disability and are fully dependent on you
                      </td>
                      <td className='table-border-risk table-column-content-risk'><span>{memberDependentBenefitDetail.benefitAmount!.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</span></td>
                    </tr>
                  ))
                ))
              }
            </table>
            <div className='grey-box-risk'>
              Cover is provided for an unlimited number of your children who fall into any of the above categories.
            </div>
          </Neo.Card>
        }
      </div>
    );
  }
}