import { Attributes, Components, DateUtils, EnumHelper, FileUtils, List, Misc, Model, ModelBase, NeoModel, NotifyUtils, Rules, Validation } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import ClaimDeclarationGroupTemplateLookup from '../../Domain/Models/Claims/ClaimDeclarationGroupedTemplateLookup';
import ContactUsTemplateLookup from '../../Domain/Models/ContactUs/ContactUsTemplateLookup';
import SavingsWithdrawalClaimTemplateLookup from '../../Domain/Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplateLookup';
import SavingsClaimDataCaptureLookup from '../Models/LookUps/MemberClaims/SavingsClaimDataCaptureLookup';
import MemberContactDetailsLookup from '../Models/LookUps/MemberContacts/MemberContactDetailsLookup';
import BeneficiariesVM from './Beneficiaries/BeneficiariesVM';
import { MemberWithdrawalClaimStep } from '../Components/WithdrawalClaim/MemberWithdrawalClaimStep.enum';
import { DocumentType } from '../Models/MemberClaims/Enums/DocumentType.enum';
import SavingsClaimPartyFileDescriptorLookup from '../Models/LookUps/Files/SavingsClaimPartyFileDescriptorLookup';
import AddressInfoLookup from '../Models/LookUps/AddressInfoLookup';
import ProgressStep from '../../Dashboard/Models/ProgressStep';
import { AppService, Types } from '../PartiesTypes';
import Products from '../../Dashboard/Views/Member/ProductsView';
import MemberEligibilityLookup from '../Models/LookUps/MemberClaims/MemberEligibilityLookup';
import PostCaseLookup from '../Models/LookUps/MemberClaims/PostCaseLookup';
import MemberDetailsLookup from '../Models/LookUps/MemberDetails/MemberDetailsLookup';
import PersonContactDetailsComponentVM from '../Components/Profile/PersonContactDetailsComponentVM';
import MemberBankingResponseLookup from '../Models/LookUps/MemberBanking/MemberBankingResponseLookup';
import SavingsWithdrawalClaimOverviewView from './SavingsWithdrawalClaimOverviewView';
import StepsContainerComponentVM from '../../Domain/Components/StepsContainerComponentVM';
import Step from '../../Domain/Models/Steps/Step';
import { BankAccountType } from '../Models/MemberClaims/Enums/BankAccountType.enum';
import { AccountOwnershipType } from '../Models/MemberClaims/Enums/AccountOwnershipType.enum';
import { ConfirmYesNo } from '../Models/MemberClaims/Enums/ConfirmYesNo.enum';
import SavingsClaimSecureDocumentLookup from '../Models/LookUps/MemberClaims/SavingsClaimSecureDocumentLookup';
import MemberCriteriaLookup from '../Models/LookUps/MemberClaims/MemberCriteriaLookup';
//import Banks from '../../MasterData/Models/Bank';
import MemberLiensLookup from '../Models/LookUps/MemberLiens/MemberLiensLookup';

@NeoModel
export class InvestmentPortfolioSelectionList extends ModelBase {
  public id: number = 0
  @Attributes.Display("Portfolio Name")
  public portfolioName: string = "";
  @Attributes.Display("Management Fee")
  public managementFee: number = 0;
  @Attributes.Display("Share")
  public share: number = 0;
  @Attributes.Display("Share")
  public isSelected: boolean = false;
}

@NeoModel
export default class SavingsWithdrawalClaimVM extends Views.ViewModelBase {
  public fundId: number | null = null;
  public financialAdvisor: number | null = null;
  public withdrawalClaimStepsVM: StepsContainerComponentVM = new StepsContainerComponentVM();
  public bankId: number | null = 0;
  public branchCode: string = "";
  public countryResidenceTaxId: number | null = 1;
  public financialAdvice: boolean = false;
  public isPercentage: boolean = true;
  public isYourBankAccount: boolean = true;

  @Attributes.OnChanged<SavingsWithdrawalClaimVM>(c => c.handleBeneficiaryStep)
  public showBeneficiaryStep: boolean = false;

  public addRemoveBeneficiaryStep = new ProgressStep();
  public withdrawalClaimNumber: number = 0;
  public showNotHundredShareAlert: boolean = false;
  public investmentPortfolioSelectionList = new List(InvestmentPortfolioSelectionList);
  public addInvestmentPortfolio = new List(InvestmentPortfolioSelectionList);
  public showAddInvestmentPortfolio: boolean = false;
  public beneficiariesVM: BeneficiariesVM = new BeneficiariesVM();
  public showPopup: boolean = true;
  public showFinalWarningPopup: boolean = false;
  public showFinalPopup: boolean = false;
  public showCancelClaimModal: boolean = false;
  public showSaveExitModal: boolean = false;

  public savingsClaimSecureDocumentLookup = new SavingsClaimSecureDocumentLookup();
  public memberCriteriaLookup = new MemberCriteriaLookup();

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);

    rules.failWhen(c => c.savingWithdrawalAmount > this.eligibilityData.sofSavingsPot, c => "You do not have the required funds");
    rules.failWhen(c => c.savingWithdrawalAmount < 2000, c => "Amount should be greater than R2000");
    rules.failWhen(c => c.savingWithdrawalPercentage > 1, c => "Percentage cannot exceed 100%");
  }

  @Attributes.Display("Savings withdrawal amount:")
  @Rules.Required("Amount required")
  @Attributes.Float()
  public savingWithdrawalAmount: number = 0.00;

  public feeAmount: number = 175;

  @Attributes.Display("Savings withdrawal amount:")
  @Rules.Required("Percentage required")
  @Attributes.Float()
  public savingWithdrawalPercentage: number = 0.00;

  public isEmployerView: boolean = false;
  public countryId: number | null = 1;
  public country: string = '+27';
  public provinceValueId: number | null = 1;
  public savingsWithdrawalClaimTemplate = new SavingsWithdrawalClaimTemplateLookup();
  public memberFAContactDetails = new List(MemberContactDetailsLookup);
  public contactUsTemplateLookups = new List(ContactUsTemplateLookup);
  public benefitCounsellingContactUsTemplateLookup = new ContactUsTemplateLookup;
  public addressInfoLookup = new AddressInfoLookup();

  public personContactDetailsComponentVM = this.registerViewModel(PersonContactDetailsComponentVM);

  public groupClaimDeclarationClaimTypes = new List(ClaimDeclarationGroupTemplateLookup);
  public eligibilityData: MemberEligibilityLookup = new MemberEligibilityLookup();
  public withdrawalClaimPostData = new List(PostCaseLookup);
  public memberDetails: MemberDetailsLookup = new MemberDetailsLookup();
  public memberLiens: MemberLiensLookup = new MemberLiensLookup();
  public bankingValidationResponse: MemberBankingResponseLookup = new MemberBankingResponseLookup();
  public savingsWithdrawalClaimCapture = new SavingsClaimDataCaptureLookup();

  public docsTooLarge: string[] = [];
  public showFileSizeModal: boolean = false;
  public constMegabyte: number = 1048576;
  public extraFileUploaded: boolean = false;

  //Enum list for bankAccountTypeList
  public bankAccountTypesList = EnumHelper.asList(BankAccountType).filter(item => item.id !== BankAccountType.None);
  //Enum list for accountOwnershipTypesList
  public accountOwnershipTypesList = EnumHelper.asList(AccountOwnershipType).filter(item =>
    item.id === AccountOwnershipType.MembersAccount ||
    item.id === AccountOwnershipType.JointAccount ||
    item.id === AccountOwnershipType.ThirdPartyAccount
  );
  //Enum list for confirmYesNoList
  public confirmYesNoList = EnumHelper.asList(ConfirmYesNo).filter(item => item.id !== ConfirmYesNo.NotSelected);

  public get memberDeclaration() {
    let today = new Date();
    return DateUtils.format(today, "dd/MM/yyyy");
  }

  public get submittedDate() {
    let today = new Date();
    return DateUtils.format(today, "dd MMM yyyy");
  }

  public memberDeclarationIAgree: boolean = false
  public employerDeclarationIAgree: boolean = false

  public get investmentPortfolioSelectionShareValue() {
    let total: number = 0;
    this.investmentPortfolioSelectionList.forEach(c => {
      total += c.share;
    });
    return total;
  }

  public confirmCellNumber: boolean = false;
  public confirmEmail: boolean = false;
  public confirmStreet: boolean = false;
  public confirmUnit: boolean = false;
  public confirmCity: boolean = false;
  public confirmSuburb: boolean = false;
  public confirmPostalCode: boolean = false;

  public setParentCardShowMoreShowLess(e: any, isExpanded: Model.ObservableValue<boolean>) {
    if (e.target.className.indexOf("card-header") >= 0) {
      isExpanded.value = !isExpanded.value;
    }
  }

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public appLayout = AppService.get(Types.App.Services.AppLayout),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    public userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private savingsWithdrawalClaimQueryApiClient = AppService.get(Types.Domain.ApiClients.SavingsWithdrawalClaimQueryApiClient),
    private contactUsQueryApiClient = AppService.get(Types.Domain.ApiClients.ContactUsQueryApiClient),
    public userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private memberContactDetailsApiClient = AppService.get(Types.Parties.ApiClients.MemberContactDetailsApiClient),
    private savingsClaimQueryApiClient = AppService.get(Types.Parties.ApiClients.SavingsClaimQueryApiClient),
    private memberDetailsApiClient = AppService.get(Types.Parties.ApiClients.MemberDetailsApiClient),
    public memberSavingsClaimEligibilityQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberSavingsClaimEligibilityQueryApiClient),
    public savingsClaimCommandApiClient = AppService.get(Types.Parties.ApiClients.SavingsClaimCommandApiClient),
    private claimDeclarationQueryApiClient = AppService.get(Types.Domain.ApiClients.ClaimDeclarationQueryApiClient),
    public masterDataService = AppService.get(Types.App.Services.MasterDataService)) {
    super(taskRunner);

    this.autoDispose(this.savingsWithdrawalClaimCapture.onAnyPropertyChanged(() => this.saveSavingsClaimData(), { delayMs: 3000, rootProperties: ["additionalDocumentsComment"] }));
  }

  public async initialise() {
    await this.fetchSavingsClaimForScheme();
    await this.masterDataService.getBanks();
  }

  public async selectSchemeId(schemeId: number) {
    if (schemeId === 0) { return; }
    this.userMemberSchemeService.setCurrentSchemeMember(schemeId);

    await this.getMemberSavingsClaimActiveStatus();

    if (this.savingsWithdrawalClaimCapture.isActive) {
      this.withdrawalClaimStepsVM.moveToNextStep();
    }

    await this.fetchSavingsClaimForScheme();
  }

  public async fetchSavingsClaimForScheme() {

    this.investmentPortfolioSelectionList.set([
      { id: 1, portfolioName: "Absa Absolute Fund", managementFee: 0.08, share: 0.2 },
      { id: 2, portfolioName: "Absa Balanced Fund", managementFee: 0.04, share: 0.5 },
      { id: 3, portfolioName: "Excelsior Financials", managementFee: 0.1, share: 0.1 },
    ]);

    this.addInvestmentPortfolio.set([
      { id: 1, portfolioName: "Absa Absolute Fund", managementFee: 0.08, isSelected: false },
      { id: 2, portfolioName: "Absa Balanced Fund", managementFee: 0.04, isSelected: false },
      { id: 3, portfolioName: "Excelsior Financials", managementFee: 0.10, isSelected: false },
      { id: 4, portfolioName: "Absolute Fund", managementFee: 0.08, isSelected: false },
    ]);

    this.withdrawalClaimStepsVM.stepList.set([
      { stepId: MemberWithdrawalClaimStep.MemberDetails, stepName: "Member details", stepNumber: 1, isActive: false, isCompleted: false, isFirstStep: true },
      { stepId: MemberWithdrawalClaimStep.HowItWorks, stepName: "How it works", stepNumber: 2, isActive: false, isCompleted: false },
      { stepId: MemberWithdrawalClaimStep.Selection, stepName: "Selection & bank details", stepNumber: 3, isActive: false, isCompleted: false },
      { stepId: MemberWithdrawalClaimStep.Deductions, stepName: "Deductions", stepNumber: 4, isActive: false, isCompleted: false },
      { stepId: MemberWithdrawalClaimStep.Documentation, stepName: "Documentation", stepNumber: 5, isActive: false, isCompleted: false },
      { stepId: MemberWithdrawalClaimStep.ReviewAndSubmit, stepName: "Review & submit", stepNumber: 6, isActive: false, isCompleted: false },
      { stepId: MemberWithdrawalClaimStep.MemberDeclaration, stepName: "Member declaration", stepNumber: 7, isActive: false, isCompleted: false, isLastStep: true },
    ]);

    this.addressInfoLookup = this.userRoleService.getFirstPersonAddressInfo();

    this.taskRunner.run(async () => {
      await Promise.all([
        this.getMemberSavingsClaim(),
        this.getLatestSavingsWithdrawalClaimTemplate(),
        this.setContactUsTemplates(),
        this.getMemberEligibilityData(),
        this.getSchemesFAContactDetails(),
        this.getClaimDeclarationTemplate(),
        this.personContactDetailsComponentVM.initialise(),
      ]);

      this.savingWithdrawalPercentageChange(false);

      if (this.savingsWithdrawalClaimCapture.isActive) {
        this.withdrawalClaimStepsVM.moveToNextStep();
      }
    })

    {/*TODO: This is will be used in the PreservationAndTransfer process once we get to those process - 08/09/2023 Benji*/ }
    //this.beneficiariesVM.initialise();
  }

  public getLatestSavingsWithdrawalClaimTemplate = async () => {
    const response = await this.savingsWithdrawalClaimQueryApiClient.getSavingsWithdrawalClaimTemplateLookup();
    const latestSavingsWithdrawalClaimTemplate = response.data;
    this.savingsWithdrawalClaimTemplate = SavingsWithdrawalClaimTemplateLookup.fromJSObject<SavingsWithdrawalClaimTemplateLookup>(latestSavingsWithdrawalClaimTemplate);
  }

  public addExistingInvestmentPortfolio() {
    this.showAddInvestmentPortfolio = false;
    const investmentToRemove = new List(InvestmentPortfolioSelectionList);
    this.addInvestmentPortfolio.forEach(c => {
      if (c.isSelected) {
        const newItem = this.investmentPortfolioSelectionList.addNew();
        newItem.mapFrom(c);
        newItem.share = 0;
        investmentToRemove.push(c);
      }
    });
    investmentToRemove.forEach(c => {
      this.addInvestmentPortfolio.remove(c);
    });
  }

  public deleteInvestmentPortfolio(item: InvestmentPortfolioSelectionList) {
    this.investmentPortfolioSelectionList.remove(item);
  }

  public async beforeStepChanged() {
    if (this.withdrawalClaimStepsVM.currentStep.stepNumber == MemberWithdrawalClaimStep.Selection && this.withdrawalClaimStepsVM.nextStep.stepNumber != MemberWithdrawalClaimStep.Selection) {
      if (this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes && this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.MembersAccount
        && !this.savingsWithdrawalClaimCapture.surnameChanged) {
        await this.bankValidation();
      }
      this.selectionDeleteFiles();
      if (this.savingsWithdrawalClaimCapture.accountOwnershipType !== AccountOwnershipType.MembersAccount && this.savingsWithdrawalClaimCapture.bankValidation !== "") {
        this.savingsWithdrawalClaimCapture.bankValidation = "";
        this.bankingValidationResponse = new MemberBankingResponseLookup();
      }
      if (!this.savingsWithdrawalClaimCapture.bankValidationsCheck() || !this.savingsWithdrawalClaimCapture.selectionValidationsCheck() || !this.savingWithdrawalAmountValidation()) {
        this.validationNotValidToast();
      }
      if (this.savingsWithdrawalClaimCapture.bankValidation == "Not Verified") {
        this.bankValidationNotValidToast();
      }
    }

    if (this.withdrawalClaimStepsVM.currentStep.stepNumber == MemberWithdrawalClaimStep.MemberDetails && this.withdrawalClaimStepsVM.nextStep.stepNumber != MemberWithdrawalClaimStep.MemberDetails) {
      this.personContactDetailsComponentVM.updateAllDetails();
    }

    this.withdrawalClaimStepsVM.currentStep.isCompleted = true;
    return true;
  }

  public showFinalModal() {
    this.showFinalPopup = true;
    this.showFinalWarningPopup = false;
  }

  public afterSubmit() {
    this.withdrawalClaimStepsVM.stepList.forEach((item) => {
      item.isCompleted = false;
      item.isActive = false;
    })

    this.withdrawalClaimStepsVM.currentStep = new Step();
    this.withdrawalClaimStepsVM.previousStep = new Step();
    this.showFinalPopup = false;
    this.navigateToDashboard();
  }

  public handleBeneficiaryStep() {
    //   {/*TODO: This is will be used in the PreservationAndTransfer process once we get to those process - 08/09/2023 Benji*/ }
    // if (this.showBeneficiaryStep) {
    //   // this.addRemoveBeneficiaryStep.set({ stepId: MemberWithdrawalClaimStep.Beneficiaries, stepName: "Beneficiaries", stepNumber: 4, isActive: false, isCompleted: false });
    //   this.WithdrawalClaimSteps.push(this.addRemoveBeneficiaryStep);
    // } else if (!this.showBeneficiaryStep) {
    //   this.WithdrawalClaimSteps.remove(this.addRemoveBeneficiaryStep);
    // }
  }

  public savingWithdrawalPercentageChange(tempSave: boolean = true) {
    const maxSavingsComponent = this.eligibilityData.sofSavingsPot ?? 0.00;
    if (!this.eligibilityData || maxSavingsComponent > 0) {
      if (this.isPercentage) {
        if (this.savingWithdrawalPercentage! > 1) {
          this.savingWithdrawalPercentage = 1;
        } else if (this.savingWithdrawalPercentage! < 0) {
          this.savingWithdrawalPercentage = this.savingWithdrawalPercentage * -1;
        }
        if (this.savingWithdrawalAmount < 0) {
          this.savingWithdrawalAmount = this.savingWithdrawalAmount * -1;
        }
        this.savingWithdrawalAmount = Number(this.savingWithdrawalPercentage) * maxSavingsComponent;
      }
      else {
        if (this.savingWithdrawalAmount < 0) {
          this.savingWithdrawalAmount = this.savingWithdrawalAmount * -1;
        }
        if (this.savingWithdrawalAmount > maxSavingsComponent) {
          this.savingWithdrawalAmount = maxSavingsComponent;
        }
        this.savingWithdrawalPercentage = (this.savingWithdrawalAmount / maxSavingsComponent);
      }
    }
    this.savingsWithdrawalClaimCapture.claimPercentage = this.savingWithdrawalPercentage;

    if (tempSave) {
      this.saveSavingsClaimData();
    }
  }

  public navigateToProductView() {
    this.navigation.navigateToView(Products);
  }

  public navigateToDashboard(): void {
    this.userRoleService.navigateToDashboard();
  }

  public navigateToSavingsClaim(): void {
    this.navigation.navigateToView(SavingsWithdrawalClaimOverviewView);
  }

  public get firstAddress() {
    if (this.userRoleService.personContactDetailsLookup.addressInfo.length > 0) {
      return this.userRoleService.personContactDetailsLookup.addressInfo[0];
    }
    return null;
  }

  public async setContactUsTemplates() {
    const contactUsResponse = await this.contactUsQueryApiClient.getContactUsTemplateLookups();

    this.contactUsTemplateLookups.set(contactUsResponse.data);
    this.benefitCounsellingContactUsTemplateLookup.set(contactUsResponse.data[4]);
  }

  public async getSchemesFAContactDetails() {
    if (this.userMemberSchemeService.currentSchemeSelected.schemeId) {
      var memberFAContacts = await this.memberContactDetailsApiClient.getMemberFAContactsLookups(this.userMemberSchemeService.currentSchemeSelected.schemeId, this.userMemberSchemeService.currentSchemeSelected.sourceId);
      if (memberFAContacts.data.length != 0) {
        this.memberFAContactDetails.set(memberFAContacts.data);
      } else {
        this.memberFAContactDetails.set([]);
      }
    }
  }

  public async getClaimDeclarationTemplate() {
    const response = await this.claimDeclarationQueryApiClient.getClaimDeclarations();
    this.groupClaimDeclarationClaimTypes.set(response.data);
  }

  public saveSavingsClaimData() {
    if (this.savingsWithdrawalClaimCapture.accountHolderName !== this.userRoleService.personContactDetailsLookup.lastName) {
      this.savingsWithdrawalClaimCapture.surnameChanged = true;
    } else {
      this.savingsWithdrawalClaimCapture.surnameChanged = false;
    }
    this.savingsWithdrawalClaimCapture.isSTP = true;
    this.savingsWithdrawalClaimCapture.source = "SSP";
    this.savingsWithdrawalClaimCapture.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
    this.savingsWithdrawalClaimCapture.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    this.savingsWithdrawalClaimCapture.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    this.savingsWithdrawalClaimCapture.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0;
    this.savingsWithdrawalClaimCapture.bankValidation = this.bankingValidationResponse.passed;
    this.savingsWithdrawalClaimCapture.schemeNumber = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    this.savingsWithdrawalClaimCapture.schemeName = this.userMemberSchemeService.currentSchemeSelected.schemeName ?? "";
    this.savingsWithdrawalClaimCapture.employerName = this.memberDetails.employerName ?? "";
    this.savingsWithdrawalClaimCapture.title = this.userRoleService.personContactDetailsLookup.title;
    this.savingsWithdrawalClaimCapture.initials = `${this.userRoleService.personContactDetailsLookup.firstName?.charAt(0) ?? ''}${this.userRoleService.personContactDetailsLookup.lastName?.charAt(0) ?? ''}`;
    this.savingsWithdrawalClaimCapture.memberFirstName = this.userRoleService.personContactDetailsLookup.firstName ?? "";
    this.savingsWithdrawalClaimCapture.memberLastName = this.userRoleService.personContactDetailsLookup.lastName ?? "";
    this.savingsWithdrawalClaimCapture.memberNumber = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";

    const birthDate = this.userRoleService.personContactDetailsLookup.birthDate;
    this.savingsWithdrawalClaimCapture.dob = birthDate ? birthDate.toLocaleDateString() : '';

    this.savingsWithdrawalClaimCapture.idNumber = this.userRoleService.personContactDetailsLookup.nationalIdNumber ?? "";
    this.savingsWithdrawalClaimCapture.passportNumber = this.userRoleService.personContactDetailsLookup.passportNumber ?? "";
    this.savingsWithdrawalClaimCapture.passportCountry = this.userRoleService.personContactDetailsLookup.passportCountryOfIssue ?? "";

    //Address Information
    this.savingsWithdrawalClaimCapture.addressLine1 = this.personContactDetailsComponentVM.addressInfo?.meta.addressLine1.value;
    this.savingsWithdrawalClaimCapture.addressLine2 = this.personContactDetailsComponentVM.addressInfo?.meta.addressLine2.value;
    this.savingsWithdrawalClaimCapture.cityTown = this.personContactDetailsComponentVM.addressInfo?.meta.city.value;
    this.savingsWithdrawalClaimCapture.suburb = this.personContactDetailsComponentVM.addressInfo?.meta.addressLine3.value;
    this.savingsWithdrawalClaimCapture.provinceOrState = this.personContactDetailsComponentVM.addressInfo?.meta.province.value;
    this.savingsWithdrawalClaimCapture.country = this.personContactDetailsComponentVM.addressInfo?.meta.country.value;
    this.savingsWithdrawalClaimCapture.postalCode = this.personContactDetailsComponentVM.addressInfo?.meta.postalCode.value;
    this.savingsWithdrawalClaimCapture.cellphoneNo = this.personContactDetailsComponentVM.telephoneInfo?.meta.countryValue.value + " " + this.personContactDetailsComponentVM.telephoneInfo?.meta.contactNumberBase.value;
    this.savingsWithdrawalClaimCapture.emailId = this.personContactDetailsComponentVM.emailInfo?.meta.emailAddress.value;

    this.savingsWithdrawalClaimCapture.nameOfBank = (this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes ? this.masterDataService.banks.find(b => b.bankId === this.savingsWithdrawalClaimCapture.bankId)?.name ?? null : this.savingsWithdrawalClaimCapture.nameOfBank ?? null);
    this.savingsWithdrawalClaimCapture.claimDate = DateUtils.format(new Date, "dd-MMM-yyyy").toString()
    this.savingsWithdrawalClaimCapture.isRejected = !this.eligibilityData.isEligible;

    if (this.savingsWithdrawalClaimCapture.isRejected) {
      this.savingsWithdrawalClaimCapture.rejectionCode = this.eligibilityData.eligibilityReason;
    }

    if (this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes) {
      this.savingsWithdrawalClaimCapture.nameOfBranch = "";
      this.savingsWithdrawalClaimCapture.swiftCode = "";
    }

    this.savingsWithdrawalClaimCapture.lien = false;
    this.savingsWithdrawalClaimCapture.attachedDocs = "Trusted";

    if (this.savingsWithdrawalClaimCapture.accountOwnershipType !== AccountOwnershipType.MembersAccount ||
      this.savingsWithdrawalClaimCapture.divorceOrderAgainstFund === ConfirmYesNo.Yes ||
      this.savingsWithdrawalClaimCapture.divorceOrderFinalised === ConfirmYesNo.Yes ||
      this.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund === ConfirmYesNo.Yes ||
      this.savingsWithdrawalClaimCapture.maintenanceOrderFinalised === ConfirmYesNo.Yes ||
      this.savingsWithdrawalClaimCapture.debtToEmployerForDeception === ConfirmYesNo.Yes ||
      this.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan === ConfirmYesNo.Yes ||
      this.extraFileUploaded) {
      this.savingsWithdrawalClaimCapture.lien = true;
      this.savingsWithdrawalClaimCapture.attachedDocs = "Not Trusted";
    }

    this.savingsClaimCommandApiClient.insertAndUpdateSavingsClaimQuery(this.savingsWithdrawalClaimCapture.toJSObject());
  }

  public async bankValidation() {
    await this.taskRunner.run(async () => {
      const response = await this.savingsClaimCommandApiClient.submitSavingsClaimBankingValidationQuery(this.savingsWithdrawalClaimCapture.toJSObject());
      if (response.data) {
        this.bankingValidationResponse = MemberBankingResponseLookup.fromJSObject(response.data);
      } else {
        this.bankingValidationResponse = new MemberBankingResponseLookup();
      }
    });
  }

  public async getMemberSavingsClaim() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId &&
      this.savingsWithdrawalClaimCapture.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId &&
      this.userMemberSchemeService.currentSchemeSelected.sourceId &&
      this.userMemberSchemeService.currentSchemeSelected.schemeId &&
      this.userMemberSchemeService.currentSchemeSelected.nameId) {
      this.memberCriteriaLookup.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      this.memberCriteriaLookup.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      this.memberCriteriaLookup.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
      this.memberCriteriaLookup.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;

      let result = await this.savingsClaimQueryApiClient.getMemberSavingsClaim(this.memberCriteriaLookup.toJSObject());
      this.savingsWithdrawalClaimCapture.set(result.data);
      this.savingWithdrawalPercentage = this.savingsWithdrawalClaimCapture.claimPercentage ?? 0;
      this.savingsWithdrawalClaimCapture.divorceOrderFinalised = result.data.memberLiens?.divorceOrderFinalised ? ConfirmYesNo.Yes : ConfirmYesNo.NotSelected;
      this.savingsWithdrawalClaimCapture.maintenanceOrderFinalised = result.data.memberLiens?.maintenanceOrderFinalised ? ConfirmYesNo.Yes : ConfirmYesNo.NotSelected;
      this.savingsWithdrawalClaimCapture.debtToEmployerForDeception = result.data.memberLiens?.debtToEmployerForDeception ? ConfirmYesNo.Yes : ConfirmYesNo.NotSelected;
      this.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan = result.data.memberLiens?.debtToEmployerForHousingLoan ? ConfirmYesNo.Yes : ConfirmYesNo.NotSelected;
    }
  }

  public validationNotValidToast() {
    NotifyUtils.addDanger("Fields not completed", "Please provide information for all required fields. You will not be able to submit your claim until all required fields are completed.", 10);
  }

  public bankValidationNotValidToast() {
    NotifyUtils.addDanger("Incorrect banking details", "Please be aware that your banking details are incorrect. Go back to update your information in order to submit.", 10);
  }

  public notEligibleToast() {
    NotifyUtils.addDanger("You are not eligible", "You do not meet the requirements to start a claim on the current selected scheme.", 10);
  }

  public notMembersAccountFileManager = this.newSavingsClaimFileManager(DocumentType.NotMembersAccount);
  public jointAccountFileManager = this.newSavingsClaimFileManager(DocumentType.JointAccount);
  public thirdPartyAccountFileManager = this.newSavingsClaimFileManager(DocumentType.ThirdPartyAccount);
  public divorceOrderAgainstFundFileManager = this.newSavingsClaimFileManager(DocumentType.DivorceOrderAgainstFund);
  public divorceOrderFinalisedFundFileManager = this.newSavingsClaimFileManager(DocumentType.DivorceOrderFinalised);
  public maintenanceOrderAgainstFundFileManager = this.newSavingsClaimFileManager(DocumentType.MaintenanceOrderAgainstFund);
  public maintenanceOrderFinalisedFileManager = this.newSavingsClaimFileManager(DocumentType.MaintenanceOrderFinalised);
  public debtToEmployerForHousingLoanFileManager = this.newSavingsClaimFileManager(DocumentType.DebtToEmployerForHousingLoan);
  public debtToEmployerForDeceptionFileManager = this.newSavingsClaimFileManager(DocumentType.DebtToEmployerForDeception);
  public extraFileManager = this.newSavingsClaimFileManager(DocumentType.Extra);
  public nameChangeFileManager = this.newSavingsClaimFileManager(DocumentType.NameChange);

  private newSavingsClaimFileManager(docType: DocumentType) {
    return new Components.FileManager(
      this.savingsClaimCommandApiClient.getUploadSavingsClaimSecureDocumentUrl(),
      {
        multiple: true,
        allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".tif", ".tiff", ".heic", ".heif"],
        afterUpload: this.afterDocumentUpload.bind(this),
        onFilesSelected: (files: any, manager: any) => this.onDocumentsSelected(files, docType, manager)
      });
  }

  public documents: File[] | undefined;

  public afterDocumentUpload(savingsClaimQueryResult: SavingsClaimDataCaptureLookup) {
    let files = savingsClaimQueryResult.files as List<SavingsClaimPartyFileDescriptorLookup>;

    if (files && files.length > 0) {
      if (!this.savingsWithdrawalClaimCapture.files) {
        this.savingsWithdrawalClaimCapture.files = new List(SavingsClaimPartyFileDescriptorLookup);
      }
      files.forEach(files => {
        if (!this.savingsWithdrawalClaimCapture.files.find(f => f.fileName === files.fileName)) {
          let savingsClaimPartyFileDescriptorLookup = new SavingsClaimPartyFileDescriptorLookup();
          savingsClaimPartyFileDescriptorLookup.mapFrom(files);
          this.savingsWithdrawalClaimCapture.files.push(savingsClaimPartyFileDescriptorLookup);
        }
      });
    }
  }

  public async onDocumentsSelected(fileList: File[] | FileList, documentType: DocumentType, fileManager: Components.FileManager) {
    this.documents = Array.from(fileList);
    this.docsTooLarge = [];

    const maxFilesPerType = 5;

    const currentFileCount = this.getFilesCountByType(documentType);

    if (currentFileCount + this.documents.length > maxFilesPerType) {
      this.showFileSizeModal = true;
      return;
    }

    let imageTypes = ["jpeg", "jpg", "png", "heic", "heif"];
    const compressAndReEncodePromises = this.documents.map(async (element) => {
      let fileSizeInMegabytes = element.size / this.constMegabyte;
      let currentFileExtension = this.getFileExtension(element.name.toLowerCase())!
      if (!imageTypes.includes(currentFileExtension) && fileSizeInMegabytes > 5) {
        this.docsTooLarge.push(this.getFileNameWithoutExtension(element.name));
        return;
      }
      return element;
    });
    let convertedFiles = await Promise.all(compressAndReEncodePromises) as File[];
    if (this.docsTooLarge.length > 0) { this.showFileSizeModal = true; return }
    fileManager.uploadFiles(convertedFiles, {
      handleErrors: true,
      additionalData: {
        memberId: this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0,
        nameId: this.userMemberSchemeService.currentSchemeSelected.nameId ?? 0,
        sourceId: this.userMemberSchemeService.currentSchemeSelected.sourceId ?? 0,
        documentType: documentType
      },
    });

    if (documentType === DocumentType.Extra) {
      this.extraFileUploaded = true;
    }
  }

  public getFilesCountByType(documentType: DocumentType): number {
    return this.savingsWithdrawalClaimCapture.files.filter(file => file.documentType === documentType).length;
  }

  private getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  private getFileNameWithoutExtension(fileName: string): string {
    let filename = fileName.split('.').slice(0, -1).join('.');
    return filename;
  }

  // Download secure query file
  public async downloadSavingsClaimSecureDocument(fileDescriptorId: number, fileName: string) {
    this.savingsClaimSecureDocumentLookup.fileDescriptorId = fileDescriptorId;
    this.savingsClaimSecureDocumentLookup.fileName = fileName;
    this.savingsClaimSecureDocumentLookup.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId!;
    this.savingsClaimSecureDocumentLookup.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
    this.savingsClaimSecureDocumentLookup.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

    const response = await this.taskRunner.waitFor(this.savingsClaimCommandApiClient.getSavingsClaimSecureDocument(this.savingsClaimSecureDocumentLookup.toQueryObject()));

    FileUtils.showSaveFile(response);
  }

  public async deleteSavingsClaimFile(fileName: string) {
    var fileDeletionResponse = await this.savingsClaimCommandApiClient.deleteMemberSavingsClaimFiles(this.savingsWithdrawalClaimCapture.memberId, this.savingsWithdrawalClaimCapture.nameId, this.savingsWithdrawalClaimCapture.sourceId, fileName);

    if (fileDeletionResponse.data === "Files deleted successfully.") {
      const file = this.savingsWithdrawalClaimCapture.files.find((item) => {
        return item.fileName == fileName;
      })
      if (file) {
        this.savingsWithdrawalClaimCapture.files.remove(file);
        let extraFiles = this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.Extra);
        if (extraFiles.length === 0) {
          this.extraFileUploaded = false;
        }
      }
    }
  }

  public selectionDeleteFiles() {
    if (this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.MembersAccount) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.NotMembersAccount).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.JointAccount).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.ThirdPartyAccount).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    else if (this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.JointAccount) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.ThirdPartyAccount).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    else if (this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.ThirdPartyAccount) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.JointAccount).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
  }

  public deductionsDeleteFiles() {
    if (this.savingsWithdrawalClaimCapture.divorceOrderAgainstFund === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DivorceOrderAgainstFund).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    if (this.savingsWithdrawalClaimCapture.divorceOrderFinalised === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DivorceOrderFinalised).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    if (this.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.MaintenanceOrderAgainstFund).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    if (this.savingsWithdrawalClaimCapture.maintenanceOrderFinalised === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.MaintenanceOrderFinalised).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    if (this.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DebtToEmployerForHousingLoan).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
    if (this.savingsWithdrawalClaimCapture.debtToEmployerForDeception === ConfirmYesNo.No) {
      {
        this.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DebtToEmployerForDeception).map((item) => {
          this.deleteSavingsClaimFile(item.fileName);
        })
      }
    }
  }

  public fileValidationCheck(): boolean {
    if (this.savingsWithdrawalClaimCapture.accountOwnershipType !== AccountOwnershipType.MembersAccount) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.NotMembersAccount)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.JointAccount) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.JointAccount)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.ThirdPartyAccount) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.ThirdPartyAccount)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.divorceOrderAgainstFund === ConfirmYesNo.Yes) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.DivorceOrderAgainstFund)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.divorceOrderFinalised === ConfirmYesNo.Yes && !this.savingsWithdrawalClaimCapture.memberLiens?.divorceOrderFinalised) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.DivorceOrderFinalised)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund === ConfirmYesNo.Yes) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.MaintenanceOrderAgainstFund)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.maintenanceOrderFinalised === ConfirmYesNo.Yes && !this.savingsWithdrawalClaimCapture.memberLiens?.maintenanceOrderFinalised) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.MaintenanceOrderFinalised)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan === ConfirmYesNo.Yes && !this.savingsWithdrawalClaimCapture.memberLiens?.debtToEmployerForHousingLoan) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.DebtToEmployerForHousingLoan)) {
        return false;
      }
    }

    if (this.savingsWithdrawalClaimCapture.debtToEmployerForDeception === ConfirmYesNo.Yes && !this.savingsWithdrawalClaimCapture.memberLiens?.debtToEmployerForDeception) {
      if (!this.savingsWithdrawalClaimCapture.files.some(item => item.documentType === DocumentType.DebtToEmployerForDeception)) {
        return false;
      }
    }

    return true;
  }

  public reviewAndSubmitValidation() {
    return (
      !this.validationCheckMemberDetails() ||
      !this.savingsWithdrawalClaimCapture.bankValidationsCheck() ||
      !this.savingsWithdrawalClaimCapture.selectionValidationsCheck() ||
      !this.savingWithdrawalAmountValidation() ||
      !this.savingsWithdrawalClaimCapture.deductionsValidationsCheck() ||
      !this.fileValidationCheck()
    )
  }

  public async getMemberEligibilityData() {
    const response = await
      this.memberSavingsClaimEligibilityQueryApiClient.getMemberEligibilityLookupById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId);
    if (response.data) {
      this.eligibilityData = MemberEligibilityLookup.fromJSObject(response.data);
    } else {
      this.eligibilityData = new MemberEligibilityLookup();
    }
  }

  public async getMemberDetails() {
    const response = await
      this.memberDetailsApiClient.getMemberDetailsById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId);
    if (response.data) {
      this.memberDetails = MemberDetailsLookup.fromJSObject(response.data);
    } else {
      this.memberDetails = new MemberDetailsLookup();
    }
  }

  public async submitWithdrawalClaim() {
    const newWithdrawalClaim = new PostCaseLookup();
    newWithdrawalClaim.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
    newWithdrawalClaim.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId || 0;
    newWithdrawalClaim.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    ;

    this.withdrawalClaimPostData.push(newWithdrawalClaim);

    this.withdrawalClaimPostData.forEach(item => {
      const existingCapture = item.savingsClaimDataCaptureLookups.find(lookup =>
        lookup.nameId === this.userMemberSchemeService.currentSchemeSelected.nameId && lookup.sourceId === this.userMemberSchemeService.currentSchemeSelected.sourceId
      );

      if (!existingCapture) {
        item.savingsClaimDataCaptureLookups.push(this.savingsWithdrawalClaimCapture);
      }
    });

    if (this.withdrawalClaimPostData != null && !this.savingsWithdrawalClaimCapture.lien) {
      await this.taskRunner.run(async () => this.savingsClaimCommandApiClient.submitSavingsClaimQuery(this.withdrawalClaimPostData.toJSArray()));
      this.showFinalModal();
    }
  }

  public async submitWithdrawalClaimEmail() {
    const newWithdrawalClaim = new PostCaseLookup();
    newWithdrawalClaim.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
    newWithdrawalClaim.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId || 0;
    newWithdrawalClaim.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

    const existingCapture = newWithdrawalClaim.savingsClaimDataCaptureLookups.some(lookup =>
      lookup.nameId === this.userMemberSchemeService.currentSchemeSelected.nameId && lookup.sourceId === this.userMemberSchemeService.currentSchemeSelected.sourceId
    );

    if (!existingCapture) {
      newWithdrawalClaim.savingsClaimDataCaptureLookups.push(this.savingsWithdrawalClaimCapture);
    }

    if ((newWithdrawalClaim && this.savingsWithdrawalClaimCapture.lien) || (newWithdrawalClaim && this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount === ConfirmYesNo.No)) {
      await this.taskRunner.run(async () =>
        this.savingsClaimCommandApiClient.submitSavingsClaimEmailQuery(newWithdrawalClaim.toJSObject())
      );
      this.showFinalModal();
    }
  }

  public validationCheckMemberDetails(): boolean {
    let isValid = true;
    switch (true) {
      case this.savingsWithdrawalClaimCapture.southAfricanIncomeTaxNumber === ""
        || this.savingsWithdrawalClaimCapture.southAfricanIncomeTaxNumber === null
        || this.savingsWithdrawalClaimCapture.southAfricanIncomeTaxNumber.length != 10
        || !/^[0123]/.test(this.savingsWithdrawalClaimCapture.southAfricanIncomeTaxNumber):
        isValid = false;
        break;
      case !this.personContactDetailsComponentVM.telephoneInfo.isValid:
        isValid = false;
        break;
      case !this.personContactDetailsComponentVM.emailInfo.isValid:
        isValid = false;
        break;
      case !this.personContactDetailsComponentVM.addressInfo.isValid:
        isValid = false;
        break;
      case this.savingsWithdrawalClaimCapture.annualIncome === 0 || this.savingsWithdrawalClaimCapture.annualIncome === null:
        isValid = false;
        break;
      default:
        break;
    }
    return isValid;
  }

  public async deleteSavingsClaim() {
    await this.taskRunner.run(async () =>
      this.savingsClaimCommandApiClient.deleteMemberSavingsClaim(this.savingsWithdrawalClaimCapture.memberId, this.savingsWithdrawalClaimCapture.nameId, this.savingsWithdrawalClaimCapture.sourceId)
    );
  }

  public async savingsClaimNextButtonLogic() {
    if (this.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.MemberDetails) {
      this.personContactDetailsComponentVM.updateAllDetails();

      if (!this.validationCheckMemberDetails()) {
        this.validationNotValidToast();
      }
    }
    else if (this.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Selection) {
      if (this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes && this.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.MembersAccount
        && !this.savingsWithdrawalClaimCapture.surnameChanged) {
        await this.bankValidation();
      }
      this.selectionDeleteFiles();
      if (this.savingsWithdrawalClaimCapture.accountOwnershipType !== AccountOwnershipType.MembersAccount && this.savingsWithdrawalClaimCapture.bankValidation !== "") {
        this.savingsWithdrawalClaimCapture.bankValidation = "";
        this.bankingValidationResponse = new MemberBankingResponseLookup();
      }
      if (!this.savingsWithdrawalClaimCapture.bankValidationsCheck() || !this.savingsWithdrawalClaimCapture.selectionValidationsCheck() || !this.savingWithdrawalAmountValidation()) {
        this.validationNotValidToast();
      }
      if (this.savingsWithdrawalClaimCapture.bankValidation == "Not Verified") {
        this.bankValidationNotValidToast();
      }
    }
    else if (this.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Deductions) {
      this.deductionsDeleteFiles();

      if (!this.savingsWithdrawalClaimCapture.deductionsValidationsCheck()) {
        this.validationNotValidToast();
      }
    }
    else if (this.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Documentation) {
      if (!this.fileValidationCheck()) {
        this.validationNotValidToast();
      }
    }
    else if (this.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.ReviewAndSubmit) {
      if (this.savingsWithdrawalClaimCapture.bankValidation == "Not Verified") {
        this.bankValidationNotValidToast();
      }
      else if (this.reviewAndSubmitValidation()) {
        this.validationNotValidToast();
      }
      else {
        this.withdrawalClaimStepsVM.moveToNextStep();
        this.saveSavingsClaimData();
        document.documentElement.scrollTop = 0;
      }
      return;
    }
    this.withdrawalClaimStepsVM.moveToNextStep();
    this.saveSavingsClaimData();
    document.documentElement.scrollTop = 0;
  }

  public async getMemberSavingsClaimActiveStatus() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId &&
      this.savingsWithdrawalClaimCapture.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId &&
      this.userMemberSchemeService.currentSchemeSelected.sourceId &&
      this.userMemberSchemeService.currentSchemeSelected.schemeId &&
      this.userMemberSchemeService.currentSchemeSelected.nameId) {
      this.memberCriteriaLookup.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      this.memberCriteriaLookup.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      this.memberCriteriaLookup.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
      this.memberCriteriaLookup.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;

      let result = await this.taskRunner.waitFor(this.savingsClaimQueryApiClient.isMemberSavingsClaimActive(this.memberCriteriaLookup.toQueryObject()));
      this.savingsWithdrawalClaimCapture.isActive = result.data;
    }
  }

  public proceedToClaim() {
    if (this.eligibilityData.isEligible) {
      this.savingsWithdrawalClaimCapture.isActive = true;
      this.withdrawalClaimStepsVM.moveToNextStep();
      this.saveSavingsClaimData();
    } else {
      this.notEligibleToast();
    }
  }

  public populateBankBranch(bankId: number | null) {
    if (this.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes && bankId) {
      this.savingsWithdrawalClaimCapture.branchCode = this.masterDataService.banks.find(bank => bank.bankId === bankId)!.branchCode;
      this.savingsWithdrawalClaimCapture.nameOfBank = this.masterDataService.banks.find(bank => bank.bankId === bankId)!.name;
    }
  }

  public savingWithdrawalAmountValidation(): boolean {
    return this.savingWithdrawalAmount >= 2000;
  }
}