import { Data, EnumHelper, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import { SchemeStatusGroup } from '../../../Common/Models/Enum/SchemeStatusGroup.enum';
import { MemberStatusGroup } from '../../../Common/Models/Enum/MemberStatusGroup.enum';
import EmployerMemberProfileView from './EmployerMemberProfileView';
import SearchCriteria from '../../Models/Lookups/SearchWidget/SearchCriteria';
import MemberSearchDataLookup from '../../Models/Lookups/SearchWidget/MemberSearchDataLookup';
import SchemeSearchDataLookup from '../../Models/Lookups/SearchWidget/SchemeSearchDataLookup';
import TAndCTemplateLookup from '../../../Domain/Models/TAndC/TAndCTemplateLookup';
import TAndCTemplate from '../../../Domain/Models/TAndC/TAndCTemplate';
import Permissions from '../../../Common/Models/Roles/Permissions';
import { IButtonDropDownItem } from '@singularsystems/neo-core/dist/Components';
import EmployerSchemeMembershipLookup from '../../Models/Lookups/EmployerProfile/EmployerSchemeMembershipLookup';

@NeoModel
class radioButtonsOptions extends ModelBase {
  public name: string = "";
  public value: string | number = 0;
  public isSelected: boolean = false;
}

@NeoModel
export default class LegacyEmployerDashboardVM extends Views.ViewModelBase {
  public showMembersTabGrid: boolean = false;
  public showSchemesTabGrid: boolean = false;
  public showMembersTabFilterCard: boolean = false;
  public showSchemesTabFilterCard: boolean = false;
  public memberStatuses = EnumHelper.asList(MemberStatusGroup);
  public schemeStatuses = EnumHelper.asList(SchemeStatusGroup);
  public membersTabMemberStatusList = new List(radioButtonsOptions);
  public membersTabSchemeStatusList = new List(radioButtonsOptions);
  public schemesTabStatusList = new List(radioButtonsOptions);
  public selectedTab: string = "Member search";
  public memberTabFilterByAll: boolean = false;
  public memberTabClearAll: boolean = false;
  public schemeTabFilterByAll: boolean = false;
  public schemeTabClearAll: boolean = false;
  public menuItemsPerScheme: IButtonDropDownItem[] = [];
  public showSearchWidget: boolean = false;
  public employerSchemes = new List(EmployerSchemeMembershipLookup);

  //TermsAndConditions Modal
  public agreeButtonCss: string = 'disabled';
  public showTermsLogoutModal: boolean = false;
  public tAndCTemplate = new TAndCTemplateLookup();
  public showTermsModal: boolean = true;
  public acceptedTAndC: boolean = true;
  public tAndCTemplateLatest = new TAndCTemplate();

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private memberDataService = AppService.get(Types.App.Services.MemberDataService),
    private tAndCQueryApiClient = AppService.get(Types.Domain.ApiClients.TAndCQueryApiClient),
    private authService = AppService.get(Types.App.Services.AuthenticationService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private userTAndCCommandApiClient = AppService.get(Types.Domain.ApiClients.UserTAndCCommandApiClient),
    private searchWidgetApiClient = AppService.get(Types.Dashboard.ApiClients.SearchWidgetApiClient)) {

    super(taskRunner);
  }

  public memberCriteria = new SearchCriteria();
  public memberPageManager = new Data.PageManager(this.memberCriteria, MemberSearchDataLookup,
    this.searchWidgetApiClient.getMemberSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "firstName",
      sortAscending: true,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
      beforeFetch: () => {
        window.scrollTo(0, 0);
      }
    });

  public schemeCriteria = new SearchCriteria();
  public schemePageManager = new Data.PageManager(this.schemeCriteria, SchemeSearchDataLookup,
    this.searchWidgetApiClient.getSchemeSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "schemeName",
      sortAscending: true,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
      beforeFetch: () => {
        window.scrollTo(0, 0);
      }
    });

  public async initialise() {
    await this.fetchData();
    this.schemeStatuses.forEach(status => {
      const newMembersTabSchemeStatusItem = new radioButtonsOptions();
      newMembersTabSchemeStatusItem.isSelected = status.name == EnumHelper.getItemMetadata(SchemeStatusGroup, SchemeStatusGroup.Active).name ? true : false;
      newMembersTabSchemeStatusItem.name = status.name!;
      newMembersTabSchemeStatusItem.value = status.id;
      this.membersTabSchemeStatusList.push(newMembersTabSchemeStatusItem);

      const newSchemesTabSchemeStatusItem = new radioButtonsOptions();
      newSchemesTabSchemeStatusItem.isSelected = status.name == EnumHelper.getItemMetadata(SchemeStatusGroup, SchemeStatusGroup.Active).name ? true : false;
      newSchemesTabSchemeStatusItem.name = status.name!;
      newSchemesTabSchemeStatusItem.value = status.id;
      this.schemesTabStatusList.push(newSchemesTabSchemeStatusItem);
    });

    this.memberStatuses.forEach(status => {
      const newMembersTabMemberStatusItem = new radioButtonsOptions();
      newMembersTabMemberStatusItem.isSelected = status.name == EnumHelper.getItemMetadata(MemberStatusGroup, MemberStatusGroup.Active).name ? true : false;
      newMembersTabMemberStatusItem.name = status.name!;
      newMembersTabMemberStatusItem.value = status.id;
      this.membersTabMemberStatusList.push(newMembersTabMemberStatusItem);
    });

    this.memberCriteria.validator.forceShowErrors = true;
    this.schemeCriteria.validator.forceShowErrors = true;

    this.showSelectedFilters();
  }

  public async changeToBeneficiariesView(item: MemberSearchDataLookup, roleType: string) {
    if (roleType == "view") {
      this.userRoleService.viewOnlyBeneficiary = true;
    }
    else {
      this.userRoleService.viewOnlyBeneficiary = false;
    }
    await this.memberDataService.getSchemes(item);
    await this.memberDataService.convertToPlainSchemes();
    this.memberDataService.setCurrentSchemeMember(item.schemeId);
    this.navigation.navigateToView(EmployerMemberProfileView, { profileSection: 2 })
  }

  public resetMembersTabFilterChanges() {
    this.membersTabSchemeStatusList.forEach(status => {
      let activeStatusGroup = this.memberCriteria.schemeStatusGroups.find(statusGroup => statusGroup == status.value);
      status.isSelected = activeStatusGroup ? true : false;
    });

    this.membersTabMemberStatusList.forEach(status => {
      let activeStatusGroup = this.memberCriteria.memberStatusGroups.find(statusGroup => statusGroup == status.value);
      status.isSelected = activeStatusGroup ? true : false;
    });
  }

  public resetSchemesTabFilterChanges() {
    this.schemesTabStatusList.forEach(status => {
      let activeStatusGroup = this.schemeCriteria.schemeStatusGroups.find(statusGroup => statusGroup == status.value);
      status.isSelected = activeStatusGroup ? true : false;
    });
  }

  public refreshMembersTabSchemeStatusFilter() {
    this.memberCriteria.schemeStatusGroups = [];
    this.membersTabSchemeStatusList.forEach(status => {
      if (status.isSelected) {
        this.memberCriteria.schemeStatusGroups.push(status.value as number);
      }
    });
  }

  public refreshMembersTabMemberStatusFilter() {
    this.memberCriteria.memberStatusGroups = [];
    this.membersTabMemberStatusList.forEach(status => {
      if (status.isSelected) {
        this.memberCriteria.memberStatusGroups.push(status.value as number);
      }
    });
  }

  public refreshSchemesTabSchemeStatusFilter() {
    this.schemeCriteria.schemeStatusGroups = [];
    this.schemesTabStatusList.forEach(status => {
      if (status.isSelected) {
        this.schemeCriteria.schemeStatusGroups.push(status.value as number);
      }
    });
  }

  public get memberSchemeStatusEnumValue() {
    let enumValue: number | null = 0;

    this.membersTabSchemeStatusList.filter(status => { return status.isSelected }).forEach(element => {
      enumValue! += (element.value as number);
    })

    if (enumValue == 0) {
      enumValue = null;
    }

    return enumValue;
  }

  public get memberStatusEnumValue() {
    let enumValue: number | null = 0;

    this.membersTabMemberStatusList.filter(status => { return status.isSelected }).forEach(element => {
      enumValue! += (element.value as number);
    })

    if (enumValue == 0) {
      enumValue = null;
    }

    return enumValue;
  }

  public get schemeStatusEnumValue() {
    let enumValue: number | null = 0;

    this.schemesTabStatusList.filter(status => { return status.isSelected }).forEach(element => {
      enumValue! += (element.value as number);
    })

    if (enumValue == 0) {
      enumValue = null;
    }

    return enumValue;
  }

  public showSelectedFilters() {
    if (this.selectedTab == "Member search") {
      this.showMembersTabFilterCard = false;
      this.refreshMembersTabMemberStatusFilter();
      this.refreshMembersTabSchemeStatusFilter();
      this.updateMembersGrid();
    }
    else {
      this.showSchemesTabFilterCard = false;
      this.refreshSchemesTabSchemeStatusFilter();
      this.updateSchemesGrid();
    }
  }

  public async removeMemberTabFiltersAndRefreshMemberGrid() {
    this.membersTabSchemeStatusList.forEach(element => {
      element.isSelected = element.name == EnumHelper.getItemMetadata(SchemeStatusGroup, SchemeStatusGroup.Active).name ? true : false;
    })
    this.membersTabMemberStatusList.forEach(element => {
      element.isSelected = element.name == EnumHelper.getItemMetadata(MemberStatusGroup, MemberStatusGroup.Active).name ? true : false;
    })

    this.memberTabFilterByAll = false;
    this.refreshMembersTabMemberStatusFilter();
    this.refreshMembersTabSchemeStatusFilter();
    if (this.memberCriteria.isValid) {
      await this.taskRunner.waitFor(this.memberPageManager.refreshData());
    }
  }

  public async updateMembersGrid() {
    if (this.memberCriteria.isValid) {
      await this.taskRunner.waitFor(this.memberPageManager.refreshData());
    }
    this.showMembersTabGrid = true;
  }

  public async allSchemeMembers(item: SchemeSearchDataLookup, selectedTab: string) {
    if (selectedTab == "Scheme search") {
      this.selectedTab = "Member search";
      this.memberCriteria.schemeNo = item.schemeNo;
      this.memberCriteria.schemeName = item.schemeName;

      this.membersTabSchemeStatusList.forEach(element => {
        element.isSelected = element.name == EnumHelper.getItemMetadata(SchemeStatusGroup, item.schemeStatusGroup ?? SchemeStatusGroup.Active).name ? true : false;
      })
      this.membersTabMemberStatusList.forEach(element => {
        element.isSelected = element.name == EnumHelper.getItemMetadata(MemberStatusGroup, MemberStatusGroup.Active).name ? true : false;
      })

      this.memberTabFilterByAll = false;
      this.refreshMembersTabMemberStatusFilter();
      this.refreshMembersTabSchemeStatusFilter();
      if (this.memberCriteria.isValid) {
        await this.taskRunner.waitFor(this.memberPageManager.refreshData());
      }

      this.showMembersTabGrid = true;
    }
  }

  public async updateSchemesGrid() {
    if (this.schemeCriteria.isValid) {
      await this.taskRunner.waitFor(this.schemePageManager.refreshData());
    }
    this.showSchemesTabGrid = true;
  }

  public async changeToProfileView(item: MemberSearchDataLookup) {
    this.userRoleService.viewOnlyBeneficiary = true;
    await this.memberDataService.getSchemes(item);
    await this.memberDataService.convertToPlainSchemes();
    this.memberDataService.setCurrentSchemeMember(item.schemeId);
    this.navigation.navigateToView(EmployerMemberProfileView, { profileSection: 1 });
  }

  public filterByAllStatuses(): void {
    if (this.selectedTab == "Member search") {
      this.memberTabFilterByAll = !this.memberTabFilterByAll;
      if (this.memberTabFilterByAll) {
        if (this.memberTabClearAll) {
          this.memberTabClearAll = false;
        }
        this.membersTabSchemeStatusList.forEach(status => {
          status.isSelected = true;
        })
        this.membersTabMemberStatusList.forEach(status => {
          status.isSelected = true;
        })
      }
      else {
        this.membersTabSchemeStatusList.forEach(status => {
          status.isSelected = false;
        })
        this.membersTabMemberStatusList.forEach(status => {
          status.isSelected = false;
        })
      }
    }
    else {
      this.schemeTabFilterByAll = !this.schemeTabFilterByAll;
      if (this.schemeTabFilterByAll) {
        if (this.schemeTabClearAll) {
          this.schemeTabClearAll = false;
        }
        this.schemesTabStatusList.forEach(status => {
          status.isSelected = true;
        })
      }
      else {
        this.schemesTabStatusList.forEach(status => {
          status.isSelected = false;
        })
      }
    }
  }

  public clearSelectedFilters(): void {
    if (this.selectedTab == "Member search") {
      this.memberTabClearAll = !this.memberTabClearAll;
      if (this.memberTabClearAll) {
        if (this.memberTabFilterByAll) {
          this.memberTabFilterByAll = false;
        }
        this.membersTabSchemeStatusList.forEach(status => {
          status.isSelected = false;
        })
        this.membersTabMemberStatusList.forEach(status => {
          status.isSelected = false;
        })
      }
    }
    else {
      this.schemeTabClearAll = !this.schemeTabClearAll;
      if (this.schemeTabClearAll) {
        if (this.schemeTabFilterByAll) {
          this.schemeTabFilterByAll = false;
        }
        this.schemesTabStatusList.forEach(status => {
          status.isSelected = false;
        })
      }
    }
  }

  public async clearMemberSchemeNoFilter() {
    this.memberCriteria.schemeName = "";
    this.memberCriteria.schemeNo = "";
    if (this.memberCriteria.isValid) {
      this.taskRunner.waitFor(this.memberPageManager.refreshData());
    }
  }

  public getIsFilteredExists(): boolean {
    if (this.selectedTab == "Member search") {
      const isSchemeSelected = this.membersTabSchemeStatusList.some(status => status.isSelected);
      const isMemberSelected = this.membersTabMemberStatusList.some(status => status.isSelected);
      return isSchemeSelected || isMemberSelected;
    }
    else {
      const isSchemeSelected = this.schemesTabStatusList.some(status => status.isSelected);
      return isSchemeSelected;
    }
  }

  public toggleSchemeCard(): void {
    this.selectedTab = "Scheme search";
    this.showSchemesTabFilterCard = !this.showSchemesTabFilterCard;
    this.showMembersTabFilterCard = false;
  }

  public resetFilterAndClearAll() {
    if (this.selectedTab == "Member search") {
      if (this.memberTabFilterByAll) {
        this.memberTabFilterByAll = false;
      }
      if (this.memberTabClearAll) {
        this.memberTabClearAll = false;
      }
    }
    else {
      if (this.schemeTabClearAll) {
        this.schemeTabClearAll = false;
      }
      if (this.schemeTabFilterByAll) {
        this.schemeTabFilterByAll = false;
      }
    }
  }

  public getSelectedItemsCount(): number {
    if (this.selectedTab == "Member search") {
      return this.membersTabMemberStatusList.filter(status => status.isSelected).length + this.membersTabSchemeStatusList.filter(status => status.isSelected).length
    }
    else {
      return this.schemesTabStatusList.filter(status => status.isSelected).length
    }
  }

  //TermsAndConditions
  public async acceptTAndC() {
    this.taskRunner.run(async () => await this.userTAndCCommandApiClient.acceptTAndC());
    this.acceptedTAndC = true;
    this.userRoleService.acceptedTAndC = true;
    this.showTermsModal = false;
    this.userRoleService.navigateToDashboard();
  }

  private async downloadPdf() {
    const response = await this.tAndCQueryApiClient.getTermsAndConditionsPdf();
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = downloadUrl;
    link.setAttribute('download', "TermsAndConditions.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public downloadTermsAndConditions() {
    this.taskRunner.run(async () => {
      await this.downloadPdf();
    });
  }

  public navigateToLogoutPage() {
    this.authService.beginSignOut();
  }

  public async fetchData() {
    await this.taskRunner.run(async () => {
      this.acceptedTAndC = this.userRoleService.acceptedTAndC;
      if (!this.acceptedTAndC) {
        var resp = await this.tAndCQueryApiClient.getLatestTAndCTemplate();
        this.tAndCTemplate = new TAndCTemplateLookup();
        this.tAndCTemplate.templateContent = resp.data.templateContent
      }
      this.employerSchemes = this.userMemberSchemeService.employerSchemeMembershipLookups;
      await this.getSearchWidgetAccess();
    });
  }

  public toggleFilterCard(): void {
    if (this.showSearchWidget) {
      if (this.selectedTab == "Member search") {
        this.showMembersTabFilterCard = !this.showMembersTabFilterCard
      }
      else {
        this.showSchemesTabFilterCard = !this.showSchemesTabFilterCard
      }
    }
  }

  public async getSearchWidgetAccess() {
    this.showSearchWidget = this.userMemberSchemeService.employerSchemeMembershipLookups.some(
      (lookup) => lookup.permissions.includes(Permissions.searchWidgetAccess)
    );
  }
}
