import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import UserProfileLookup from '../../Models/Lookups/UserProfileLookup';

export interface IUserProfileApiClient {

  /** 
   * Gets UserprofileLookup
   */
  getUserProfileLookup(): AxiosPromise<Model.PlainObject<UserProfileLookup>>;

  // Client only properties / methods
}

@injectable()
export default class UserProfileApiClient extends Data.ApiClientBase implements IUserProfileApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.identityConfig.identityApiPath}/userprofile`);
  }

  public getUserProfileLookup(): AxiosPromise<Model.PlainObject<UserProfileLookup>> {
    return this.axios.get(`${this.apiPath}/userprofilelookup`);
  }

  // Client only properties / methods
}