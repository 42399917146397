import { Attributes, LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class MemberNominationDataLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }


  public beneficiaryId: string = "";

  public nominationId: string | null = null;

  @Attributes.Integer()
  public sequence: number = 0;

  public relationshipType: string = "";

  public nominationTypeCode: string = "";

  public nominationType: string = "";

  @Attributes.Float()
  @Attributes.Display("Share")
  public nominationPercentage: number = 0;

  @Attributes.Date()
  public effectiveDate: Date | null = null;

  @Attributes.Date()
  public lastModifiedDate: Date | null = null;

  public lastModifiedUser: string = "";

  public firstName: string = "";

  public lastName: string = "";

  public nationalIdNoOrPassportNo: string = "";

  public lastModifiedByRole: string = "";

  public relationshipTypeCode: string = "";

  @Attributes.Date()
  public expiryDate: Date | null = null;

  public isDeleted: boolean = false;

  @Attributes.Date()
  public readonly birthDate: Date | null = null;

  public readonly telephoneNo: string = "";

  public readonly emailAddress: string = "";

  // Client only properties / methods
  public id: string = '';

  public orderOfBeneficiaries: number = 1;
}