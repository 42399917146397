import { EnumHelper, LookupBase } from '@singularsystems/neo-core';
import { IButtonDropDownItem } from '@singularsystems/neo-core/dist/Components';
import { MemberStatusGroup } from '../../../../Common/Models/Enum/MemberStatusGroup.enum';
import { SchemeStatusGroup } from '../../../../Common/Models/Enum/SchemeStatusGroup.enum';

export default class MemberSearchDataLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public readonly firstName: string = "";

  public readonly nameId: number = 0;

  public readonly sourceId: string = "";

  public readonly memberId: number = 0;

  public readonly schemeId: number = 0;

  public readonly lastName: string = "";

  public readonly schemeName: string = "";

  public readonly nationalIdNoOrPassportNo: string = "";

  public readonly memberNo: string = "";

  public readonly memberStatus: string | null = null;

  public readonly memberStatusGroup: MemberStatusGroup | null = null;

  public readonly schemeNo: string = "";

  public readonly schemeStatus: string | null = null;

  public readonly schemeStatusGroup: SchemeStatusGroup | null = null;

  public readonly paypoint: string = "";

  public readonly participationLevelRole: string = "";

  // Client only properties / methods

  public get memberstatusName(): string {
    if (this.memberStatus) {
      return EnumHelper.getItemMetadata(MemberStatusGroup, this.memberStatusGroup!).name
    }
    return "";
  }

  public get schemestatusName(): string {
    if (this.memberStatus) {
      return EnumHelper.getItemMetadata(SchemeStatusGroup, this.schemeStatusGroup!).name
    }
    return "";
  }

  public get memberFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public menuItems: IButtonDropDownItem[] = [];
}