import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import FAAssistantDataLookup from '../../Models/LookUps/FAAssistants/FAAssistantDataLookup';

export interface IFAAssistantQueryApiClient {

    /** 
     * GetFAAssistantLookup
     * @returns A FAAssistantLookup
     */
    getFAAssistantLookup(): AxiosPromise<Model.PlainObject<FAAssistantDataLookup>>;

    // Client only properties / methods
}

@injectable()
export default class FAAssistantQueryApiClient extends Data.ApiClientBase implements IFAAssistantQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/fa-assistants`);
    }

    public getFAAssistantLookup(): AxiosPromise<Model.PlainObject<FAAssistantDataLookup>> {
        return this.axios.get(`${this.apiPath}/lookup`);
    }

    // Client only properties / methods
}