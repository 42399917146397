import { Components, DateUtils, FileUtils, List, ModelBase, NeoModel, NotifyUtils, Rules, StringUtils, Validation } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';
import MemberQueryLookup from '../../Party/Models/LookUps/MemberQueries/MemberQueryLookup';
import MemberComplimentLookup from '../../Party/Models/LookUps/MemberQueries/MemberComplimentLookup';
import MemberSchemeMembershipLookup from '../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import PartyFileDescriptorLookup from '../../Party/Models/LookUps/Files/PartyFileDescriptorLookup';
import { NotificationDuration } from '../Models/NotificationDuration';

@NeoModel
export default class QueriesVM extends Views.ViewModelBase {
  public isEmailSelected: boolean = true;
  public displayCompliment: boolean = false;
  public showBlue: boolean = false;
  public emailAddress: string = "";
  public SMS: any = "SMS";
  public cellNumber: string = "";
  public tabValue: string = 'Queries';
  public tabValueId: number = 1;
  public complimentReview: boolean = false;
  public complimentSummary: boolean = false;
  public memberQuery = new MemberQueryLookup();
  public memberCompliment = new MemberComplimentLookup();
  public queryReview: boolean = false;
  public querySummary: boolean = false;
  public showModal: boolean = false;
  public showQuerySaveExitModal: boolean = false;
  public showComplimentSaveExitModal: boolean = false;
  public showGreyBoxMember: boolean = false;
  public showFileSizeModal: boolean = false;
  public schemeName: string = "";
  public schemeNumber: string = "";
  public memberNo: string = "";
  public memberName: string = "";
  public docsTooLarge: string[] = [];
  public cnstMegabyte: number = 1048576;
  public contactNumberTemp: string = '';

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private memberQueriesCommandApiClient = AppService.get(Types.Parties.ApiClients.MemberQueriesCommandApiClient),
    private memberQueriesQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberQueriesQueryApiClient),
    private memberComplimentsCommandApiClient = AppService.get(Types.Parties.ApiClients.MemberComplimentsCommandApiClient),
    private memberComplimentsQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberComplimentsQueryApiClient),
    public masterDataService = AppService.get(Types.App.Services.MasterDataService),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService)) {

    super(taskRunner);
    this.memberQuery.markOld();
    this.memberCompliment.markOld();

    this.autoDispose(this.memberQuery.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
    this.autoDispose(this.memberCompliment.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
  }

  public onContinue() {
    this.complimentReview = true;
  }

  public async showCompliment(e: React.MouseEvent) {
    this.displayCompliment = true;
    this.showBlue = true;
    this.memberCompliment.queryType = 2;
    this.fetchMemberCompliment();
  }

  public async showQuery(e: React.MouseEvent) {
    this.displayCompliment = false;
    this.showBlue = false;
    this.memberCompliment.queryType = 1;
    this.fetchMemberQuery();
  }

  public clearQueryOrComplimentInfo(): void {
    this.showModal = false;
    this.showGreyBoxMember = false;

    if (this.memberQuery != null && !this.displayCompliment) {
      if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
        this.memberQuery = new MemberQueryLookup();
        this.memberQuery.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        this.memberQuery.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
        this.memberQuery.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0;
        this.memberQuery.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        this.memberQuery.queryType = 1;

        this.helpersService.setCountryValue(this.memberQuery);
        this.memberQueriesCommandApiClient.deleteMemberQuery(this.memberQuery.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId);
        this.autoDispose(this.memberQuery.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
      } else {
        NotifyUtils.addWarning("Issue Clearing Query", "There was an issue clearing the query form.", NotificationDuration.Long)
      }
    } else if (this.memberCompliment != null && this.displayCompliment) {
      if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
        this.memberCompliment = new MemberComplimentLookup();
        this.memberCompliment.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        this.memberCompliment.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
        this.memberCompliment.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0;
        this.memberCompliment.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        this.memberCompliment.queryType = 2;

        this.helpersService.setCountryValue(this.memberCompliment);
        this.memberComplimentsCommandApiClient.deleteMemberCompliment(this.memberCompliment.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId);
        this.autoDispose(this.memberCompliment.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
      } else {
        NotifyUtils.addWarning("Issue Clearing Compliment", "There was an issue clearing the compliment form.", NotificationDuration.Long)
      }
    }
  }

  public async submitQueryOrCompliment() {
    this.showModal = false;
    this.showGreyBoxMember = false;

    if (this.memberQuery != null && !this.displayCompliment) {
      await this.taskRunner.run(async () => this.memberQueriesCommandApiClient.submitMemberQuery(this.memberQuery.toJSObject()));
      this.querySummary = true;
    } else if (this.memberCompliment != null && this.displayCompliment) {
      await this.taskRunner.run(async () => this.memberComplimentsCommandApiClient.submitMemberCompliment(this.memberCompliment.toJSObject()));
      this.complimentSummary = true;
    }
  }

  public showComplimentOrQueryScreen() {
    this.querySummary = false;
    this.queryReview = false;
  }

  public async backToComplimentAfterSubmit() {
    this.memberCompliment = new MemberComplimentLookup();
    this.displayCompliment = true;
    this.complimentReview = false;
    this.complimentSummary = false;
    await this.fetchMemberCompliment();
  }

  public async backToQueryAfterSubmit() {
    this.memberQuery = new MemberQueryLookup();
    this.displayCompliment = false;
    this.queryReview = false;
    this.querySummary = false;
    await this.fetchMemberQuery();
  }

  public navigateToDashboard(): void {
    this.userRoleService.navigateToDashboard();
  }

  public hasNoInfo() {
    if (!this.displayCompliment
      && StringUtils.isNullOrWhitespace(this.memberQuery.contactNumber)
      && StringUtils.isNullOrWhitespace(this.memberQuery.emailAddress)
      && StringUtils.isNullOrWhitespace(this.memberQuery.description)
      && !this.memberQuery.files
    ) {
      this.memberQuery.markOld();
    }
    else if (!this.displayCompliment && this.memberQuery.memberId && this.memberQuery.memberId > 0
      && (!StringUtils.isNullOrWhitespace(this.memberQuery.contactNumber)
        || !StringUtils.isNullOrWhitespace(this.memberQuery.emailAddress)
        || !StringUtils.isNullOrWhitespace(this.memberQuery.description)
        || this.memberQuery.files)
    ) {
      this.memberQuery.isSaved = true;
      this.memberQueriesCommandApiClient.insertUpdateMemberQuery(this.memberQuery.toJSObject());
    }
    else if (this.displayCompliment
      && StringUtils.isNullOrWhitespace(this.memberCompliment.contactNumber)
      && StringUtils.isNullOrWhitespace(this.memberCompliment.emailAddress)
      && StringUtils.isNullOrWhitespace(this.memberCompliment.description)
    ) {
      this.memberCompliment.markOld();
    } else if (this.displayCompliment && this.memberCompliment.memberId && this.memberCompliment.memberId > 0
      && (!StringUtils.isNullOrWhitespace(this.memberCompliment.contactNumber)
        || !StringUtils.isNullOrWhitespace(this.memberCompliment.emailAddress)
        || !StringUtils.isNullOrWhitespace(this.memberCompliment.description))
    ) {
      this.memberCompliment.isSaved = true;
      this.memberComplimentsCommandApiClient.insertUpdateMemberCompliment(this.memberCompliment.toJSObject());
    }
  }

  public async fetchMemberQuery() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      if (this.memberQuery.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId) {
        let result = await this.taskRunner.waitFor(this.memberQueriesQueryApiClient.getMemberQuery(this.userMemberSchemeService.currentSchemeSelected.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId));
        this.memberQuery.set(result.data);
        // Populates clientId/memberId/schemeId on blank records (where no active query exists)
        if (this.memberQuery.nameId === 0) {
          this.memberQuery.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        }
        if (this.memberQuery.memberId === 0) {
          this.memberQuery.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
        }
        if (this.memberQuery.schemeId === 0) {
          this.memberQuery.schemeId = this.userMemberSchemeService.currentSchemeSelected.memberId;
        }
        if (!this.memberQuery.isSaved && StringUtils.isNullOrWhitespace(this.memberQuery.emailAddress)) {
          this.memberQuery.emailAddress = this.userRoleService.personEmailAddress;
        }
        if (!this.memberQuery.isSaved && StringUtils.isNullOrWhitespace(this.memberQuery.contactNumber)) {
          this.memberQuery.contactNumber = this.userRoleService.personTelephoneNumber;
        }
        if (StringUtils.isNullOrWhitespace(this.memberQuery.sourceId)) {
          this.memberQuery.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        }
        this.memberQuery.queryType = 1;
      }
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Queries & Compliments", "There was an issue fetching existing queries and compliments.", "warning", NotificationDuration.Long);
    }

    if (!StringUtils.isNullOrWhitespace(this.memberQuery.contactNumber)) {
      this.helpersService.cleanUpContactNumber(this.memberQuery)
      this.memberQuery.contactNumberBase = this.helpersService.parseContactNumber(this.memberQuery.contactNumber);
    } else {
      this.memberQuery.contactNumberBase = this.memberQuery.contactNumber;
    }

    this.memberQuery.countryValue = this.helpersService.countryValue;
  }

  public async fetchMemberCompliment() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      if (this.memberCompliment.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId) {
        let result = await this.taskRunner.waitFor(this.memberComplimentsQueryApiClient.getMemberCompliment(this.userMemberSchemeService.currentSchemeSelected.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId));
        this.memberCompliment.set(result.data);

        // Populates clientId/memberId/schemeId on blank records (where no active compliment exists)
        if (this.memberCompliment.nameId === 0) {
          this.memberCompliment.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        }
        if (this.memberCompliment.memberId === 0) {
          this.memberCompliment.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
        }
        if (this.memberCompliment.schemeId === 0) {
          this.memberCompliment.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
        }
        if (!this.memberCompliment.isSaved && StringUtils.isNullOrWhitespace(this.memberCompliment.emailAddress)) {
          this.memberCompliment.emailAddress = this.userRoleService.personEmailAddress;
        }
        if (!this.memberCompliment.isSaved && StringUtils.isNullOrWhitespace(this.memberCompliment.contactNumber)) {
          this.memberCompliment.contactNumber = this.userRoleService.personTelephoneNumber;
        }
        if (StringUtils.isNullOrWhitespace(this.memberCompliment.sourceId)) {
          this.memberCompliment.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        }
        this.memberCompliment.queryType = 2;
      }
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Queries & Compliments", "There was an issue fetching existing queries and compliments.", "warning", NotificationDuration.Long);
    }

    if (!StringUtils.isNullOrWhitespace(this.memberCompliment.contactNumber)) {
      this.helpersService.cleanUpContactNumber(this.memberCompliment)
      this.memberCompliment.contactNumberBase = this.helpersService.parseContactNumber(this.memberCompliment.contactNumber);
    } else {
      this.memberCompliment.contactNumberBase = this.memberCompliment.contactNumber;
    }

    this.memberCompliment.countryValue = this.helpersService.countryValue;
  }

  public async setSchemeInfo(schemeMembership: MemberSchemeMembershipLookup) {
    if (this.displayCompliment) {
      await this.fetchMemberCompliment();
      if (this.memberCompliment.schemeId !== schemeMembership.schemeId) {
        this.memberCompliment.schemeId = schemeMembership.schemeId;
        this.memberCompliment.memberId = schemeMembership.memberId
      }
    }
    else {
      await this.fetchMemberQuery();
      if (this.memberQuery.schemeId !== schemeMembership.schemeId) {
        this.memberQuery.schemeId = schemeMembership.schemeId;
        this.memberQuery.memberId = schemeMembership.memberId;
      }
    }
    this.schemeName = schemeMembership.schemeName ?? "";
    this.schemeNumber = schemeMembership.schemeNumber ?? "";
    this.memberNo = schemeMembership.memberNumber ?? "";
    this.memberName = this.userRoleService.personContactDetailsLookup.personFullName;
  }

  public queriesFileManager = new Components.FileManager(
    this.memberQueriesCommandApiClient.getUploadQueriesSecureDocumentUrl(),
    {
      multiple: true,
      allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".tif", ".tiff", ".heic"],
      afterUpload: this.afterQueryDocumentUpload.bind(this),
      onFilesSelected: this.onDocumentsSelected.bind(this)
    });

  public documents: File[] | undefined;

  public afterQueryDocumentUpload(memberQueryResult: MemberQueryLookup) {
    let files = memberQueryResult.files as List<PartyFileDescriptorLookup>;
    if (files && files.length > 0) {
      if (!this.memberQuery.files) {
        this.memberQuery.files = new List(PartyFileDescriptorLookup);
      }
      files.forEach(files => {
        if (!this.memberQuery.files.find(f => f.fileName === files.fileName)) {
          let partyFileDescriptorLookup = new PartyFileDescriptorLookup();
          partyFileDescriptorLookup.mapFrom(files);
          this.memberQuery.files.push(partyFileDescriptorLookup);
        }
      });
    }
  }

  public async onDocumentsSelected(fileList: File[] | FileList) {
    this.documents = Array.from(fileList);
    this.docsTooLarge = [];
    if (this.memberQuery.getfilesCount() + this.documents.length > 5) {
      this.showFileSizeModal = true;
      return;
    }
    let imageTypes = ["jpeg", "jpg", "png", "heic"];
    const compressAndReEncodePromises = this.documents.map(async (element) => {
      let fileSizeInMegabytes = element.size / this.cnstMegabyte;
      let currentFileExtension = this.getFileExtension(element.name.toLowerCase())!
      if (!imageTypes.includes(currentFileExtension) && fileSizeInMegabytes > 5) {
        this.docsTooLarge.push(this.getFileNameWithoutExtension(element.name));
        return;
      }
      return element;
    });
    let convertedFiles = await Promise.all(compressAndReEncodePromises) as File[];
    if (this.docsTooLarge.length > 0) { this.showFileSizeModal = true; return }
    this.queriesFileManager.uploadFiles(convertedFiles, {
      handleErrors: true,
      additionalData: {
        memberId: this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0,
        nameId: this.userMemberSchemeService.currentSchemeSelected.nameId,
        sourceId: this.userMemberSchemeService.currentSchemeSelected.sourceId
      },
    });
  }

  private getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  private getFileNameWithoutExtension(fileName: string): string {
    let filename = fileName.split('.').slice(0, -1).join('.');
    return filename;
  }

  public deleteQueryFile(fileName: string) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId) &&
      !StringUtils.isNullOrWhitespace(fileName)) {
      this.memberQueriesCommandApiClient.deleteMemberQueriesFiles(this.memberQuery.memberId!, this.userMemberSchemeService.currentSchemeSelected.sourceId, fileName);
      const file = this.memberQuery.files.find((item) => {
        return item.fileName == fileName;
      })
      if (file) {
        this.memberQuery.files.remove(file);
      }
    } else {
      NotifyUtils.addWarning("Issue Removing File", "There was an issue removing the file from the query.", NotificationDuration.Long);
    }
  }

  // Download secure query file
  public async downloadQuerySecureDocument(fileDescriptorId: number, fileName: string) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId) &&
      !StringUtils.isNullOrWhitespace(fileName)) {
      const response = await this.taskRunner.waitFor(this.memberQueriesCommandApiClient.getQueriesSecureDocument(fileDescriptorId, this.userMemberSchemeService.currentSchemeSelected.memberId!, this.userMemberSchemeService.currentSchemeSelected.sourceId, fileName));
      FileUtils.showSaveFile(response);
    } else {
      NotifyUtils.addWarning("Issue Fetching File", "There was an issue fetching the query file.", NotificationDuration.Long)
    }
  }
}