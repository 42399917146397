import { List, ModelBase, StringUtils } from "@singularsystems/neo-core"
import { injectable } from "inversify";
import MemberSearchDataLookup from "../../Dashboard/Models/Lookups/SearchWidget/MemberSearchDataLookup";
import SearchCriteria from "../../Dashboard/Models/Lookups/SearchWidget/SearchCriteria";
import { UserRoleType } from "../../Common/Models/Enum/UserRoleType.enum";
import GetPersonContactDetailLookup from "../../Party/Models/LookUps/GetPersonContactDetailLookup";
import MemberSchemeMembershipLookup from "../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup";
import { AppService, Types } from "./AppService";

class SchemeNames {
  schemeId: number | null = 0;
  schemeName: string | null = ''
}

@injectable()
export default class MemberDataService extends ModelBase {
  public estimatedMonthlyIncome: number = 20000;
  public showDashboard: string = "";
  private hasInitialised = false;

  public memberSchemes = new List(MemberSchemeMembershipLookup);
  public currentSchemeSelected = new MemberSchemeMembershipLookup();
  public schemeDetailsLookups: MemberSchemeMembershipLookup[] = [];
  public namesArray: SchemeNames[] = [];

  public personContactDetailsLookup = new GetPersonContactDetailLookup;
  public personEmailAddress: string = "";
  public personTelephoneNumber: string = "";

  constructor(
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private personContactDetailsQueryApiClient = AppService.get(Types.Parties.ApiClients.PersonContactDetailsQueryApiClient),
    private searchWidgetApiClient = AppService.get(Types.Dashboard.ApiClients.SearchWidgetApiClient),
  ) {
    super();
    this.makeObservable();
  }

  public initialise() {
    if (!this.hasInitialised) {
      this.hasInitialised = true;
    }
  }

  public async getSchemes(memberInfo: MemberSearchDataLookup | null = null) {
    if (memberInfo) {
      switch (this.userRoleService.loggedInUser.userRoleType) {
        case UserRoleType.FinancialAdviser:
        case UserRoleType.Employer:
        case UserRoleType.Staff:
        case UserRoleType.FinancialAdviserAssistant:
          let thirdPartySearchCriteria = new SearchCriteria();
          thirdPartySearchCriteria.memberNameId = memberInfo.nameId;
          thirdPartySearchCriteria.sourceId = memberInfo.sourceId;
          const thirdPartyResponse = await this.searchWidgetApiClient.getPersonMemberSearchDataLookups(thirdPartySearchCriteria.toJSObject({ suppressTrackingState: true }));
          if (thirdPartyResponse) {
            let memberData = thirdPartyResponse.data;
            this.memberSchemes = new List(MemberSchemeMembershipLookup);
            memberData.forEach(member => {
              let memberScheme = new MemberSchemeMembershipLookup();
              memberScheme.schemeId = member.schemeId;
              memberScheme.memberId = member.memberId;
              memberScheme.schemeName = member.schemeName;
              memberScheme.schemeNumber = member.schemeNo;
              memberScheme.memberNumber = member.memberNo;
              memberScheme.sourceId = member.sourceId;
              memberScheme.nameId = member.nameId;

              this.memberSchemes.push(memberScheme);
            });

            await this.getSchemeDetails();
          }
          break;
        default:
          break;
      }
    }
  }

  public setCurrentSchemeMember(schemeId: number) {
    let schemeMembership = this.memberSchemes.find(s => s.schemeId === schemeId);
    if (schemeMembership) {
      this.currentSchemeSelected.mapFrom(schemeMembership, { isMap: true });
    }
  }

  public async getSchemeDetails() {
    if (this.schemeDetailsLookups.length === 0) {
      this.schemeDetailsLookups = this.memberSchemes;
      this.convertToPlainSchemes();
      if (this.schemeDetailsLookups.length > 0) {
        let scheme = this.schemeDetailsLookups[0];
        this.currentSchemeSelected.schemeName = scheme.schemeName;
        this.currentSchemeSelected.schemeId = scheme.schemeId;
        this.currentSchemeSelected.memberId = scheme.memberId;
        return scheme;
      }
    } else {
      if (this.currentSchemeSelected.schemeId != null) {
        if (this.currentSchemeSelected.schemeId > 0) {
          let scheme = this.schemeDetailsLookups.find(s => s.schemeId === this.currentSchemeSelected.schemeId);
          return scheme;
        }
      }

      let scheme = this.schemeDetailsLookups[0];
      this.currentSchemeSelected.schemeName = scheme.schemeName;
      this.currentSchemeSelected.schemeId = scheme.schemeId;
      this.currentSchemeSelected.memberId = scheme.memberId;
      return scheme;
    }
    return;
  }

  public async convertToPlainSchemes() {
    if (this.namesArray.length === 0) {
      this.schemeDetailsLookups.forEach(item => {
        let itemScheme = new SchemeNames();
        itemScheme.schemeId = item.schemeId,
          itemScheme.schemeName = item.schemeName
        this.namesArray.push(itemScheme);
      });
    }
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }

  public async getPersonContactDetails(nameId: number | null = null, sourceId: string | null = null) {
    let personContactDetails = null;
    switch (this.userRoleService.loggedInUser.userRoleType) {
      case UserRoleType.FinancialAdviser:
      case UserRoleType.Employer:
      case UserRoleType.Staff:
      case UserRoleType.FinancialAdviserAssistant:
        if (nameId && sourceId) {
          personContactDetails = await this.personContactDetailsQueryApiClient.getMemberPersonContactDetailSummaryLookup(nameId, sourceId);
        }
        break;
      default:
        personContactDetails = await this.personContactDetailsQueryApiClient.getPersonContactDetailsSummaryLookupById();
        break;
    }
    if (personContactDetails && personContactDetails.data) {
      this.personContactDetailsLookup.mapFrom(personContactDetails.data);

      this.personContactDetailsLookup.personFullName = this.personContactDetailsLookup.firstName + " " + this.personContactDetailsLookup.lastName;

      let defaultEmailInfo = this.personContactDetailsLookup.emailInfo.find(c => !StringUtils.isNullOrWhitespace(c.emailAddress));
      this.personEmailAddress = defaultEmailInfo ? defaultEmailInfo.emailAddress : "";

      let defaultTelephoneInfo = this.personContactDetailsLookup.telephoneInfo.find(c => !StringUtils.isNullOrWhitespace(c.telePhoneNo));
      this.personTelephoneNumber = defaultTelephoneInfo ? defaultTelephoneInfo.telePhoneNo : "";

      if (this.personContactDetailsLookup.nationalIdNumber) {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.nationalIdNumber
      } else {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.passportNumber
      }
    }
    else {
      this.personContactDetailsLookup = new GetPersonContactDetailLookup();
    }
  }
}
