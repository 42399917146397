import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../App/Services/AppService';
import BeneficiariesSummaryLookup from '../../Models/Lookups/Member/Summary/BeneficiariesSummaryLookup';
import ProductsSummaryLookup from '../../Models/Lookups/Member/Summary/ProductsSummaryLookup';
import ProfileSummaryLookup from '../../Models/Lookups/Member/Summary/ProfileSummaryLookup';
import UnderWritingSummaryLookup from '../../Models/Lookups/Member/Summary/UnderWritingSummaryLookup';

export interface IMemberDashboardApiClient {

  /** 
   * Returns the profile summary of the current user.
   * @returns ProfileSummaryLookup.
   */
  getProfileSummary(): AxiosPromise<Model.PlainObject<ProfileSummaryLookup>>;

  /** 
   * Returns the Beneficiaries summary of the current user.
   * @returns BeneficiariesSumaryLookup.
   */
  getBeneficiarySummary(): AxiosPromise<Model.PlainObject<BeneficiariesSummaryLookup>>;

  /** 
   * Returns the UnderWriting summary of the current user.
   * @returns UnderWritingSummaryLookup.
   */
  getUnderWritingSummary(): AxiosPromise<Model.PlainObject<UnderWritingSummaryLookup>>;

  /** 
   * Returns the Products summary of the current user.
   * @returns List Of ProductsSummaryLookup.
   */
  getProductsSummary(): AxiosPromise<Array<Model.PlainObject<ProductsSummaryLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class MemberDashboardApiClient extends Data.ApiClientBase implements IMemberDashboardApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/MemberDashboard`);
  }

  public getProfileSummary(): AxiosPromise<Model.PlainObject<ProfileSummaryLookup>> {
    return this.axios.get(`${this.apiPath}/profileSummary`);
  }

  public getBeneficiarySummary(): AxiosPromise<Model.PlainObject<BeneficiariesSummaryLookup>> {
    return this.axios.get(`${this.apiPath}/beneficiariesSummary`);
  }

  public getUnderWritingSummary(): AxiosPromise<Model.PlainObject<UnderWritingSummaryLookup>> {
    return this.axios.get(`${this.apiPath}/underWritingSummary`);
  }

  public getProductsSummary(): AxiosPromise<Array<Model.PlainObject<ProductsSummaryLookup>>> {
    return this.axios.get(`${this.apiPath}/productsSummaryList`);
  }

  // Client only properties / methods
}