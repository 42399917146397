import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../App/Services/AppService';
import MemberProfileStatusCriteria from '../../Models/Lookups/Member/MemberProfileStatusCriteria';
import MemberProfileStatusLookup from '../../Models/Lookups/Member/MemberProfileStatusLookup';
import EmployerComplaintsSearchCriteria from '../../Models/Lookups/EmployerDashboard/EmployerComplaintsSearchCriteria';
import EmployerDashboardMemberRetirementGoalStatusResultsLookup from '../../Models/Lookups/EmployerDashboard/EmployerDashboardMemberRetirementGoalStatusResultsLookup';
import EmployerDashboardMemberBillingStatusLookup from '../../Models/Lookups/EmployerDashboard/EmployerDashboardMemberBillingStatusLookup';
import MemberBenefitiaryStatusLookup from '../../Models/Lookups/Member/MemberBeneficiaryStatusLookup';
import MemberBeneficiaryStatusCriteria from '../../Models/Lookups/Member/MemberBeneficiaryStatusCriteria';
import SearchCriteria from '../../Models/Lookups/SearchWidget/SearchCriteria';
import MemberSearchDataLookup from '../../Models/Lookups/SearchWidget/MemberSearchDataLookup';
import SchemeSearchDataLookup from '../../Models/Lookups/SearchWidget/SchemeSearchDataLookup';
import GetPersonContactDetailLookup from '../../../Party/Models/LookUps/GetPersonContactDetailLookup';

export interface IEmployerDashboardApiClient {

  /**
   * Returns the EmployerMemberBeneficiaryResultsListPaged.
   * @returns Paged List Of MemberBenefitiarySearchResults.
   */
  getMemberBenefitiarySearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MemberBeneficiaryStatusCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberBenefitiaryStatusLookup>>>;

  /**
  * Returns the MemberProfileStatusListPaged.
  * @returns Paged List Of MemberProfileStatus.
  */
  getMemberProfileSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MemberProfileStatusCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberProfileStatusLookup>>>;

  /**
   * Returns the EmployerDashboardMemberRetirementGoalStatusResultsLookup.
   * @returns Paged List Of EmployerDashboardMemberRetirementGoalStatusResultsLookup.
   */
  getEmployerDashboardMemberRetirementGoalsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerComplaintsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerDashboardMemberRetirementGoalStatusResultsLookup>>>;

  /**
   * Returns the EmployerDashboardMemberBillingStatusLookup .
   * @returns Paged List Of EmployerDashboardMemberBillingStatusLookup.
   */
  getEmployerDashboardBillingStatusSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerComplaintsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerDashboardMemberBillingStatusLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class EmployerDashboardApiClient extends Data.ApiClientBase implements IEmployerDashboardApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/EmployerDashboard`);
  }

  public getMemberBenefitiarySearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MemberBeneficiaryStatusCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberBenefitiaryStatusLookup>>> {
    return this.axios.get(`${this.apiPath}/employer-member-beneficiary-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getMemberProfileSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MemberProfileStatusCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberProfileStatusLookup>>> {
    return this.axios.get(`${this.apiPath}/employer-member-profile-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getEmployerDashboardMemberRetirementGoalsSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerComplaintsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerDashboardMemberRetirementGoalStatusResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/employer-dashboard-member-retirement-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  public getEmployerDashboardBillingStatusSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EmployerComplaintsSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EmployerDashboardMemberBillingStatusLookup>>> {
    return this.axios.get(`${this.apiPath}/employer-dashboard-member-billing-status-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}
