import React from 'react';
import { observer } from 'mobx-react';
import InstructionsSearchResultsLookup from '../../../../Dashboard/Models/Lookups/Instructions/InstructionsSearchResultsLookUp';


interface IInstructionHeadingPhoneScreenProps {
  instruction: InstructionsSearchResultsLookup
}

@observer
export default class InstructionHeadingWebScreen extends React.Component<IInstructionHeadingPhoneScreenProps> {

  constructor(props: IInstructionHeadingPhoneScreenProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-web-screen'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='card-heading-instructions instructon-view-heading'>{this.props.instruction.instruction}</div>
          </div>
          <div className='col-md-8 right'>
            <div className='instructon-view-status'>{this.props.instruction.name} | {this.props.instruction.schemeName} - {this.props.instruction.schemeNumber}</div>
          </div>
        </div>
      </div>
    );
  }
}