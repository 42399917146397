import { Attributes, List, Validation, ValueObject } from '@singularsystems/neo-core';
import MemberBeneficiaryLookup from './MemberBeneficiaryLookup';

export default class UpdateMemberBeneficiariesCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public memberId: number = 0;

  public nameId: number = 0;

  public memberNo: string = "";

  public schemeNo: string = "";

  public sourceId: string = "";

  @Attributes.ChildObject(MemberBeneficiaryLookup)
  public memberBeneficiaries = new List(MemberBeneficiaryLookup);

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Update Member Beneficiaries Command";
  }
}