import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DashboardTypes';
import EmployerSchemeMembershipLookup from '../../Models/Lookups/EmployerProfile/EmployerSchemeMembershipLookup';

export interface IPermissionQueryApiClient {

  /** 
   * GetEmployerMemberShipLookupsWithPermissions
   */
  getEmployerMemberShipLookupsWithPermissions(): AxiosPromise<Array<Model.PlainObject<EmployerSchemeMembershipLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class PermissionQueryApiClient extends Data.ApiClientBase implements IPermissionQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/PermissionQuery`);
  }

  public getEmployerMemberShipLookupsWithPermissions(): AxiosPromise<Array<Model.PlainObject<EmployerSchemeMembershipLookup>>> {
    return this.axios.get(`${this.apiPath}/get-employer-membership-lookups-with-permissions`);
  }

  // Client only properties / methods
}