import { Views } from '@singularsystems/neo-react';
import React from 'react';
import { observer } from 'mobx-react';
import "../../../App/Styles/Components/Dashboard.scss";
import { Neo } from '@singularsystems/neo-react';
import ReactSpeedometer from "react-d3-speedometer";
import img_Plant from '../../../App/assets/img/plant.png';
import img_Umbrella from '../../../App/assets/img/umbrella.png';
import img_Phone from '../../../App/assets/img/phone.png';
import img_Star from '../../../App/assets/img/star-big.png';
import img_Mail from '../../../App/assets/img/mail.png';
import img_Right from '../../../App/assets/img/down-right.png';
import img_RightBlack from '../../../App/assets/img/down-right-black.png';
import MemberDashboardVM from './MemberDashboardVM';
import img_QueriesBig from '../../../App/assets/img/Icon_Queries.png';

@observer
export default class MemberDashboardView extends Views.ViewBase<MemberDashboardVM> {

  constructor(props: unknown) {
    super("My dashboard", MemberDashboardVM, props);
  }

  public render() {
    function segmentPlus(value: string): string {
      if (Number(value) < 90) {
        return `${value.toString()}`
      }

      return `${value.toString()} +`
    }

    return (
      <div className='m-2'>
        <div className='row'>
          <div className='goal-card-col col-12 col-md-7'>
            <div className='card mt-4 goal-card'>
              <h5 className="card-title mt-2 mb-1">My goals<Neo.Button className="dashboard right-arrow" onClick={() => this.viewModel.navigateToMyGoalsDetail()} /></h5>
              <p className="card-text">Summary of your retirement goals.</p>
              <div className='card-body align-goal-inner-cards'>
                <div className='card goals-achieved-inner-cards'>
                  <div className='card-body align-goal-inner-card-content'>
                    <h5>Net replacement ratio</h5>
                    <div className='speedometer-goal-dashboard'>
                      <ReactSpeedometer textColor='#FFF' minValue={0} maxValue={100} value={this.viewModel.calculateProjectedSavingsPercentage > 100 ? 100 : this.viewModel.calculateProjectedSavingsPercentage}
                        needleHeightRatio={0.4} ringWidth={25} maxSegmentLabels={6} currentValueText={this.viewModel.calculateProjectedSavingsPercentage.toString()} segmentValueFormatter={segmentPlus}
                        segments={10} width={164} height={140} labelFontSize={'10'} valueTextFontSize={'15'} valueTextFontWeight={'600'}
                        segmentColors={[
                          "#F46363",
                          "#F46363",
                          "#F46363",
                          "#FFAB00",
                          "#FFAB00",
                          "#FFAB00",
                          "#FFAB00",
                          "#84C83A",
                          "#84C83A",
                          "#84C83A",
                        ]}
                        needleColor="#D9D9D9" />
                    </div>
                  </div>
                </div>
                <div className={this.viewModel.calculateProjectedSavingsPercentage <= 49 ? 'card monthly-income-inner-cards' : this.viewModel.calculateProjectedSavingsPercentage < 70 ? 'card monthly-income-inner-cards yellow' : 'card monthly-income-inner-cards green'}>
                  <div className='card-body align-goal-inner-card-content'>
                    <h5>Est. monthly income at retirement</h5>
                    <p>{this.viewModel.estimatedMonthlyIncome.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</p>
                    <div className='goal-divider'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='retirement-card-col col-12 col-md-5'>
            <div className='card retirement-card mt-4' >
              <h5 className='card-title retirement-card-title mt-2 mb-1'>
                Retirement calculator</h5>
              <p className="retirement-text">Let's plan for your future</p>
              <div className='card-body'></div>
              <div className='card-footer display-footer'>
                <Neo.Button text="Learn more" className='btn btn-primary btn-lg learn-more-btn' />
              </div>
            </div>
          </div>
        </div>
        <div className='dashboard-divider'></div>
        <div className='row'>
          <div className='col-12 col-md-4 profile-card-col'>
            <div className='card profile-card' onClick={() => this.viewModel.navigateToProfileView()}>
              <h5 className="card-title mt-2 mb-1">My Profile <i className='me-3'><img className='align-arrow' src={img_Right} /></i> </h5>
              <p className="card-text">Update your profile with ease.</p>
              <div className='card-footer progress-footer'>
                <div className='align-progress'>
                  <span className='align-complete'>Complete</span>
                  {this.viewModel.profileVM.getMemberProfileCompleteness() >= 99.99 ? <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="success" />
                    : this.viewModel.profileVM.getMemberProfileCompleteness() >= 50 ? <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="warning" />
                      : <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="danger" />}
                </div>
                <div className='align-span'>
                  <span className='box'>0%</span>
                  <span className='box'>50%</span>
                  <span className='box'>100%</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 profile-card-col'>
            <div className='card profile-card beneficiary-card'>
              <h5 className="card-title mt-2 mb-1">My beneficiaries <i className='mt-0 me-3'><img src={img_Right} /></i> </h5>
              <p className="card-text">Add your beneficiaries on your own.</p>
              <div className='card-footer progress-footer'>
                <div className='align-progress'>
                  <span className='align-complete'>Complete</span>
                  <Neo.ProgressBar progress={0.7} variant="success" />
                </div>
                <div className='align-span'>
                  <span className='box'>0%</span>
                  <span className='box'>50%</span>
                  <span className='box'>100%</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 profile-card-col'>
            <div className='card profile-card beneficiary-card'>
              <h5 className="card-title mt-2 mb-1">My underwriting <i className='mt-0 me-3'><img src={img_Right} /></i> </h5>
              <p className="card-text">Update your medical information.</p>
              <div className='card-footer progress-footer'>
                <div className='align-progress'>
                  <span className='align-complete'>Complete</span>
                  <Neo.ProgressBar progress={0.3} variant="warning" />
                </div>
                <div className='align-span'>
                  <span className='box'>0%</span>
                  <span className='box'>50%</span>
                  <span className='box'>100%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='dashboard-divider2'></div>
        <div className='row'>
          <div className='col-12 product-card-col col-md-4'>
            <div className='card product-card'>
              <h5 className="card-title mt-2">My products <i className='mt-0 me-3'><img src={img_RightBlack} /> </i></h5>
              <div className='card-body align-product-inner-cards'>
                <div className='card product-investment-card'>
                  <div className='investment-content'>
                    <i><img src={img_Plant} /></i>
                    <div>
                      <h5 className='investment-heading'>Investments</h5>
                      <div>
                        <span>Total:R1,000,000.00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card product-inner-cards'>
                  <div className='card-body align-product-card-content'>
                    <i className='align-risk-img'><img src={img_Umbrella} /></i>
                    <h5 className='align-risk'>Risk Cover</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-8'>
            <div className='card get-in-touch-card align-get-in-touch'>
              <h5 className="card-title mt-2">Get in touch</h5>
              <div className='card-body align-inner-cards'>
                <div className='card inner-cards' onClick={() => this.viewModel.navigateToContactUsView()}>
                  <div className='card-body align-inner-card-content'>
                    <i><img className='align-queries' src={img_Phone} /></i>
                    <h5>Contact us</h5>
                  </div>
                </div>
                <div className='card inner-cards' onClick={() => this.viewModel.navigateToQueriesView()}>
                  <div className='card-body align-inner-card-content'>
                    <i><img className='align-queries-compliments' src={img_QueriesBig} /></i>
                    <h5 className='align-queries-compliments-text'>Queries & Compliments </h5>
                  </div>
                </div>
                <div className='card inner-cards' onClick={() => this.viewModel.navigateToComplaintsView()}>
                  <div className='card-body align-inner-card-content'>
                    <i><img className='align-queries' src={img_Mail} /></i>
                    <h5>Complaints</h5>
                  </div>
                </div>
                <div className='card inner-cards'>
                  <div className='card-body align-inner-card-content' onClick={() => this.viewModel.navigateToVASView()}>
                    <i><img className='align-vas' src={img_Star} /></i>
                    <h5 className='align-vas-text'>Value-added services</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}