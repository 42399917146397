import { Attributes, List, Model, ModelBase, NeoModel, NotifyUtils, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import { IValueByPortfolio } from '../../../Domain/Models/Portfolios/ValueByPortfolio';
import MemberSchemeMembershipLookup from '../../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import MemberInvestmentValuesLookup from '../../../Party/Models/LookUps/MemberInvestmentValuesLookup';
import GetPersonContactDetailLookup from '../../../Party/Models/LookUps/GetPersonContactDetailLookup';
import MemberDashboardInvestmentValuesLookup from '../../../Party/Models/LookUps/MemberInvestmentValuesLookup';
import MemberCurrentInvestmentValuesLookup from '../../../Party/Models/LookUps/MemberInvestmentValues/MemberCurrentInvestmentValuesLookup';
import MemberDetailsLookup from '../../../Party/Models/LookUps/MemberDetails/MemberDetailsLookup';
import MemberBillingLookup from '../../../Party/Models/LookUps/MemberBilling/MemberBillingLookup';
import MemberDependentsBenefitDetailsLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentsBenefitDetailsLookup';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import MemberDependentsBenefitDataLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentsBenefitDataLookup';
import MemberBenefitsData from '../../../Party/Models/LookUps/MemberBenefits/MemberBenefitsData';
import MemberDependentBenefitsLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentBenefitsLookup';
import MemberBenefitsLookup from '../../../Party/Models/LookUps/MemberBenefits/MemberBenefitsLookup';
import { SchemeProductClassType } from '../../../Party/Models/LookUps/SchemeProductClassType';
import { NotificationDuration } from '../../../App/Models/NotificationDuration';
import BenefitClassName from '../../../Party/Models/Enums/BenefitClassName';
import BenefitType from '../../../Party/Models/Enums/BenefitType';

@NeoModel
class contributionExpensesBreakdown extends ModelBase {
  public contribution: string = "";
  public contributionAmount: number = 0;
  public voluntaryContribution: string = "";
  public voluntaryContributionAmount: number = 0;
  public employersContribution: string = "";
  @Attributes.Float()
  public employersContributionAmount: number = 0;
  public approvedRiskBenefit: string = "";
  public approvedRiskBenefitAmount: number = 0;
  public unapprovedRiskBenefits: string = "";
  public unapprovedRiskBenefitsAmount: number = 0;
  public managementFees: string = "";
  public managementFeesAmount: number = 0;
  public salesRelatedCharges: string = "";
  public salesRelatedChargesAmount: number = 0;
  public total: string = "";
  public totalAmount: number = 0;
}

@NeoModel
export default class ProductsVM extends Views.ViewModelBase {
  public investmentTableContainerClassName: string = '';
  public phoneScreenInvestmentTableContainerClassName: string = '';
  public investmentContainerClassName: string = 'investment-container';
  public phoneScreenInvestmentContainerClassName: string = 'phone-investment-container';
  public savingsGridContainerClassName: string = 'savings-cards-grid collapsed';
  public savingsContainerClassName: string = 'savings-container';
  public showRiskBenefitModal: boolean = false;
  public tabValueId: number = 1;
  public tabValue: string = 'Investment';
  public schemeName: string = "";
  public schemeNumber: string = "";
  public memberNo: string = "";
  public memberName: string = "";
  public investmentData = new MemberDashboardInvestmentValuesLookup;
  // Benefits
  public memberBenefitsLookup = new MemberBenefitsLookup();
  public memberDependentBenefitsLookup = new MemberDependentBenefitsLookup();
  public memberDependentsExist: boolean = false;
  public spouseBenefitGroup: BenefitGroup | undefined;
  public spouseBenefitDetail: MemberDependentsBenefitDetailsLookup | undefined;
  public spouseDisabilityBenefitGroup: BenefitGroup | undefined;

  public investmentDetails = new MemberInvestmentValuesLookup;
  public graphData: IValueByPortfolio[] = [];
  public investmentsVauleTotal: number = 0;
  public contributionBillingData = new MemberBillingLookup;
  public memberContactDetailsData = new GetPersonContactDetailLookup;
  public clientId: string = "";
  public memberId: number = 0;
  public memberDetailsDetails = new MemberDetailsLookup;
  public showModal: boolean = false;
  public showYourContributionsModal = false;
  public showAdditionalVoluntaryContributionsModal = false;
  public showEmployersNetContributionModal = false;
  public contributionExpensesBreakdownItems = new List(contributionExpensesBreakdown);
  public isChildCardExpanded = new Model.ObservableValue(false);
  public showDependentsCard: boolean = false;
  public isMonthlyTitleShown: boolean = false;
  public isLumpSumTitleShown: boolean = false;
  public schemeProductClassType: SchemeProductClassType = SchemeProductClassType.RiskAndInvestment;
  public IsPreservationScheme: boolean = false;

  public tabCodes = [
    { Id: 1, tabCodes: "Investment" },
    { Id: 2, tabCodes: "Risk benefits" },
    { Id: 3, tabCodes: "Product details" }
  ];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private pdfQueryApiClient = AppService.get(Types.Domain.ApiClients.PDFQueryApiClient),
    public navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    public userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private memberBenefitsApiClient = AppService.get(Types.Parties.ApiClients.MemberBenefitsApiClient),
    private memberInvestmentValuesApiClient = AppService.get(Types.Parties.ApiClients.MemberInvestmentValuesApiClient),
    private memberBillingApiClient = AppService.get(Types.Parties.ApiClients.MemberBillingApiClient),
    private memberDetailsApiClient = AppService.get(Types.Parties.ApiClients.MemberDetailsApiClient),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private fundFactSheetQueryApiClient = AppService.get(Types.Domain.ApiClients.FundFactSheetQueryApiClient)) {

    super(taskRunner);
  }

  public async initialise() {
    this.getInvestmentContainerCss();
  }

  public setShowMoreShowLessInvestments() {
    if (this.investmentTableContainerClassName.indexOf('collapsed') >= 0) {
      this.investmentContainerClassName = 'investment-container';
      this.phoneScreenInvestmentContainerClassName = 'phone-investment-container';
      this.phoneScreenInvestmentTableContainerClassName = 'phone-investment-table';
      this.investmentTableContainerClassName = 'investment-table';
    } else {
      this.investmentContainerClassName = 'investment-container';
      this.phoneScreenInvestmentContainerClassName = 'phone-investment-container';
      this.investmentTableContainerClassName = 'investment-table collapsed';
      this.phoneScreenInvestmentTableContainerClassName = 'phone-investment-table collapsed';
    }
  }

  public setShowMoreShowLessSavings() {
    if (this.savingsGridContainerClassName.indexOf('collapsed') >= 0) {
      this.savingsContainerClassName = 'savings-container';
      this.savingsGridContainerClassName = 'savings-cards-grid';
    } else {
      this.savingsGridContainerClassName = 'savings-cards-grid collapsed';
      this.savingsContainerClassName = 'savings-container';
    }
  }

  //TODO Put in the right file as there is no spot for it right now.
  public getPDFasBytes() {
    this.taskRunner.run(async () => {
      let key: string = "";
      const response = await this.pdfQueryApiClient.getPDFasBytes();
      let viewTab = window.open();
      viewTab!.document.write('<iframe width="100%" height="100%" src="' + "data:application/pdf;base64," + encodeURI(response.data) +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%"; allowfullscreen></iframe>');
      viewTab!.document.title = "memberwithdrawalnotification";
    })
  }

  public async getContributionBillingData() {
    let order = {
      "your contribution": 1,
      "additional voluntary contributions": 2,
      "employer net contributions to retirement": 3,
      "approved risk benefits": 4,
      "unapproved risk benefits": 5,
      "management fees": 6,
      "distribution support charge": 7,
    }

    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberBillingApiClient.getMemberBillingById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));

      if (response.data) {
        response.data.contributionDetails.forEach((item) => {
          const groupName = (item.groupName ? item.groupName : "").toLocaleLowerCase();
          let orderSetting = order[groupName];

          if (orderSetting) {
            item.order = orderSetting;
          } else {
            item.order = 99;
          }
        })
        this.contributionBillingData.mapFrom(response.data);
      } else {
        this.contributionBillingData = new MemberBillingLookup();
      }
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member product details.", "warning", NotificationDuration.Long);
    }
  }

  public async setSchemeInfo(scheme: MemberSchemeMembershipLookup) {
    this.schemeName = this.userMemberSchemeService.currentSchemeSelected.schemeName ?? "";
    this.schemeNumber = scheme.schemeNumber ?? "";
    this.memberNo = scheme.memberNumber ?? "";
    this.memberName = this.userRoleService.personContactDetailsLookup.personFullName;
  }

  public async showOrHideTabs(scheme: MemberSchemeMembershipLookup) {
    if (this.userMemberSchemeService.currentSchemeSelected) {
      this.schemeProductClassType = this.userMemberSchemeService.currentSchemeSelected.schemeProductClassType;
      this.IsPreservationScheme = this.userMemberSchemeService.currentSchemeSelected.isPreservationScheme;
      this.tabValueId = 1;

      if (this.schemeProductClassType == SchemeProductClassType.InvestmentOnly) {
        this.tabCodes = [
          { Id: 1, tabCodes: "Investment" },
          { Id: 2, tabCodes: "Product details" }
        ];
        this.tabValue = 'Investment';
      }
      if (this.schemeProductClassType == SchemeProductClassType.RiskOnly) {
        this.tabCodes = [
          { Id: 1, tabCodes: "Risk benefits" },
          { Id: 2, tabCodes: "Product details" }
        ];
        this.tabValue = 'Risk benefits';
        this.showRiskBenefitModal = true;
      }

      if (this.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) {
        this.tabCodes = [
          { Id: 1, tabCodes: "Investment" },
          { Id: 2, tabCodes: "Risk benefits" },
          { Id: 3, tabCodes: "Product details" }
        ];
        this.tabValue = 'Investment';
      }
    }
  }

  public async getMemberInvestmentData() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberInvestmentValuesApiClient.getMemberInvestmentValuesLookupById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));
      if (response.data) {
        this.investmentDetails.mapFrom(response.data);
        this.investmentDetails.currentInvestmentValues.forEach(async (item) => {
          var url = await this.fundFactSheetQueryApiClient.getFundFactSheetUrl(item.investmentFundName ?? '');
          item.url = url.data;
        });

        this.getGraphData();
      }
      else {
        this.investmentDetails.totalInvestmentFundValueForScheme = 0;
        this.investmentDetails.currentInvestmentValues = new List(MemberCurrentInvestmentValuesLookup);
        this.graphData = [];
      }
    } else {
      this.investmentDetails.totalInvestmentFundValueForScheme = 0;
      this.investmentDetails.currentInvestmentValues = new List(MemberCurrentInvestmentValuesLookup);
      this.graphData = [];
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member product details.", "warning", NotificationDuration.Long);
    }
  }

  public getGraphData() {
    this.graphData = this.investmentDetails.currentInvestmentValues.map((item) => (
      {
        portfolioName: item.investmentFundName,
        value: ((item.totalInvestmentFundValue ? item.totalInvestmentFundValue : 0) / this.investmentDetails.totalInvestmentFundValueForSchemeSafe) * 100
      }
    ));
  }

  public checkIfMonthlyBenefit(benefitGroup: BenefitGroup) {
    var listOfMonthlyBenefitTypes = [BenefitType.progressiveLevelIncomeProtectionBenefit,
    BenefitType.progressiveReducingIncomeProtectionBenefit,
    BenefitType.occupationalLimitedTermIncomeProtectionBenefit,
    BenefitType.occupationalLevelIncomeProtectionBenefit,
    BenefitType.occupationalReducingIncomeProtectionBenefit,
    BenefitType.healthPremiumWaiver,
    BenefitType.employerRetirementContributionAndPremiumWaiver,
    BenefitType.incomeProtectionPlusBenefit];
    if (listOfMonthlyBenefitTypes.includes(benefitGroup.benefitType) && this.isMonthlyTitleShown == false) {
      this.isMonthlyTitleShown = true;
      return true;
    }
    return false;
  }

  public checkIfLumpSumBenefit(benefitGroup: BenefitGroup) {
    var listOfMonthlyBenefitTypes = [BenefitType.progressiveCapitalDisability,
    BenefitType.occupationalCapitalDisability,
    BenefitType.accidentalDisability];
    if (listOfMonthlyBenefitTypes.includes(benefitGroup.benefitType) && this.isLumpSumTitleShown == false) {
      this.isLumpSumTitleShown = true;
      return true;
    }
    return false;
  }

  public async getMemberBenefitsData() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberBenefitsApiClient.getMemberBenefits(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId)
      );
      this.memberBenefitsLookup.mapFrom(response.data);
      this.memberBenefitsLookup.memberBenefitsData.forEach((benefitData: MemberBenefitsData) => {
        benefitData.isExpanded = new Model.ObservableValue(false);
        benefitData.benefitGrouping.forEach((benefitGroup: BenefitGroup) => {
          benefitGroup.isExpanded = new Model.ObservableValue(false);
        });
      });

      let memberBenefitData = this.memberBenefitsLookup.memberBenefitsData.find(benefitData => benefitData.benefitClassName == BenefitClassName.deathOfSpouse);

      if (memberBenefitData) {
        this.spouseBenefitGroup = memberBenefitData.benefitGrouping.find(x => x.benefitType == BenefitType.spouseDeathBenefit);
        this.spouseDisabilityBenefitGroup = memberBenefitData.benefitGrouping.find(x => x.benefitType == BenefitType.spouseDisabilityBenefit);
      }
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member product details.", "warning", NotificationDuration.Long);
    }
  }

  public async getMemberDependentBenefitsData() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberBenefitsApiClient.getMemberDependentsBenefits(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId)
      );
      this.memberDependentBenefitsLookup = MemberDependentBenefitsLookup.fromJSObject(response.data);

      if (this.memberDependentBenefitsLookup.memberDependentsBenefitData && this.memberDependentBenefitsLookup.memberDependentsBenefitData.length > 0) {
        this.checkMemberDependentsBenefits(this.memberDependentBenefitsLookup.memberDependentsBenefitData.filter(x => x.relationshipType.includes("Child")));
        this.addIsExpandedFlag(this.memberDependentBenefitsLookup.memberDependentsBenefitData);

        let spouseBenefitData = this.memberDependentBenefitsLookup.memberDependentsBenefitData.find(benefitData => benefitData.relationshipType == "Spouse");
        if (spouseBenefitData && spouseBenefitData.memberDependentBenefitDetails.length > 0) {
          this.spouseBenefitDetail = spouseBenefitData.memberDependentBenefitDetails[0];
        }
      }
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member product details.", "warning", NotificationDuration.Long);
    }
  }

  public addIsExpandedFlag(memberDependentsBenefitData: MemberDependentsBenefitDataLookup[]) {
    memberDependentsBenefitData.forEach((x: MemberDependentsBenefitDataLookup) => {
      x.isExpanded = new Model.ObservableValue(false);
      x.memberDependentBenefitDetails.forEach((y: MemberDependentsBenefitDetailsLookup) => {
        y.isExpanded = new Model.ObservableValue(false);
      });
    });
  }

  public checkMemberDependentsBenefits(memberDependentBenefitData: MemberDependentsBenefitDataLookup[]) {
    memberDependentBenefitData.forEach((data: MemberDependentsBenefitDataLookup) => {
      data.memberDependentBenefitDetails.forEach((detail: MemberDependentsBenefitDetailsLookup) => {
        if (detail.benefitAmount !== 0) {
          this.showDependentsCard = true;
          return;
        }
      });
    });
  }

  public async getMemberDetailsData() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberDetailsApiClient.getMemberDetailsById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));
      this.memberDetailsDetails.mapFrom(response.data);
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Details", "There was an issue fetching member product details.", "warning", NotificationDuration.Long);
    }
  }

  public getInvestmentContainerCss = async () => {
    if (this.investmentDetails.currentInvestmentValues.length > 1) {
      this.investmentTableContainerClassName = 'investment-table';
      this.phoneScreenInvestmentTableContainerClassName = 'phone-investment-table';
    }
    else {
      this.investmentTableContainerClassName = 'investment-table collapsed';
      this.phoneScreenInvestmentTableContainerClassName = 'phone-investment-table collapsed';
    }
  }
}


