import { Components, DateUtils, FileUtils, List, ModelBase, NeoModel, NotifyUtils, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';
import MemberComplaintLookup from '../../Party/Models/LookUps/MemberQueries/MemberComplaintLookup';
import PartyFileDescriptorLookup from '../../Party/Models/LookUps/Files/PartyFileDescriptorLookup';
import MemberSchemeMembershipLookup from '../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import { NotificationDuration } from '../Models/NotificationDuration';

@NeoModel
export default class ComplaintsVM extends Views.ViewModelBase {
  public displayComplaint: boolean = false;
  public showBlue: boolean = false;
  public complaintReview: boolean = false;
  public complaintSummary: boolean = false;
  public memberComplaint = new MemberComplaintLookup();
  public showModal: boolean = false;
  public showSaveExitModal: boolean = false;
  public showGreyBoxMember: boolean = false;
  public showFileSizeModal: boolean = false;
  public schemeName: string = "";
  public schemeNumber: string = "";
  public memberNo: string = "";
  public memberName: string = "";
  public docsTooLarge: string[] = [];
  public cnstMegabyte: number = 1048576;
  public contactNumberTemp: string = '';

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private memberComplaintsCommandApiClient = AppService.get(Types.Parties.ApiClients.MemberComplaintsCommandApiClient),
    private memberComplaintsQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberComplaintsQueryApiClient),
    public masterDataService = AppService.get(Types.App.Services.MasterDataService),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService)) {

    super(taskRunner);
    this.memberComplaint.markOld();

    this.autoDispose(this.memberComplaint.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
  }

  public async showComplain(e: React.MouseEvent) {
    this.displayComplaint = true;
    this.showBlue = true;
    this.memberComplaint.queryType = 3;
    this.fetchMemberComplaint();
  }

  public async showContactUs(e: React.MouseEvent) {
    this.displayComplaint = false;
    this.showBlue = false;
  }

  public clearComplaintInfo(): void {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      this.showModal = false;
      this.showGreyBoxMember = false;

      this.memberComplaint = new MemberComplaintLookup();

      this.memberComplaint.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
      this.memberComplaint.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
      this.memberComplaint.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
      this.memberComplaint.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      this.memberComplaint.queryType = 3;

      this.helpersService.setCountryValue(this.memberComplaint);
      this.memberComplaintsCommandApiClient.deleteMemberComplaint(this.memberComplaint.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId);
      this.autoDispose(this.memberComplaint.onAnyPropertyChanged(() => this.hasNoInfo(), { delayMs: 3000, rootProperties: ["description"] }));
    } else {
      NotifyUtils.addWarning("Issue Clearing Complaint", "There was an issue clearing the complaint form.", NotificationDuration.Long)
    }
  }

  public async submitComplaint() {
    this.showModal = false;
    this.showGreyBoxMember = false;
    await this.taskRunner.run(async () => this.memberComplaintsCommandApiClient.submitMemberComplaint(this.memberComplaint.toJSObject()));;
    this.complaintSummary = true;
  }

  public async backToComplaintAfterSubmit() {
    this.memberComplaint = new MemberComplaintLookup();
    this.displayComplaint = true;
    this.complaintReview = false;
    this.complaintSummary = false;
    await this.fetchMemberComplaint();
  }

  public navigateToDashboard(): void {
    this.userRoleService.navigateToDashboard();
  }

  public hasNoInfo() {
    if (this.displayComplaint
      && StringUtils.isNullOrWhitespace(this.memberComplaint.contactNumber)
      && StringUtils.isNullOrWhitespace(this.memberComplaint.emailAddress)
      && StringUtils.isNullOrWhitespace(this.memberComplaint.description)
      && !this.memberComplaint.files
    ) {
      this.memberComplaint.markOld();
    }
    else if (this.memberComplaint.memberId && this.memberComplaint.memberId > 0 && this.displayComplaint
      && (!StringUtils.isNullOrWhitespace(this.memberComplaint.contactNumber)
        || !StringUtils.isNullOrWhitespace(this.memberComplaint.emailAddress)
        || !StringUtils.isNullOrWhitespace(this.memberComplaint.description)
        || this.memberComplaint.files)
    ) {
      this.memberComplaint.isSaved = true;
      this.memberComplaintsCommandApiClient.insertUpdateMemberComplaint(this.memberComplaint.toJSObject());
    }
  }

  public async fetchMemberComplaint() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      if (this.memberComplaint.memberId !== this.userMemberSchemeService.currentSchemeSelected.memberId) {
        let result = await this.taskRunner.waitFor(this.memberComplaintsQueryApiClient.getMemberComplaint(this.userMemberSchemeService.currentSchemeSelected.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId));
        this.memberComplaint.set(result.data);

        // Populates clientId/memberId/schemeId on blank records (where no active complaint exists)
        if (this.memberComplaint.nameId === 0) {
          this.memberComplaint.nameId = this.userMemberSchemeService.currentSchemeSelected.nameId;
        }
        if (this.memberComplaint.memberId === 0) {
          this.memberComplaint.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
        }
        if (this.memberComplaint.schemeId === 0) {
          this.memberComplaint.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId;
        }
        if (!this.memberComplaint.isSaved && StringUtils.isNullOrWhitespace(this.memberComplaint.emailAddress)) {
          this.memberComplaint.emailAddress = this.userRoleService.personEmailAddress;
        }
        if (!this.memberComplaint.isSaved && StringUtils.isNullOrWhitespace(this.memberComplaint.contactNumber)) {
          this.memberComplaint.contactNumber = this.userRoleService.personTelephoneNumber;
        }
        if (StringUtils.isNullOrWhitespace(this.memberComplaint.sourceId)) {
          this.memberComplaint.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
        }
        this.memberComplaint.queryType = 3;
      }
    } else {
      NotifyUtils.addWarning("Issue Fetching Complaint", "There was an issue fetching existing complaint.", NotificationDuration.Long)
    }

    if (!StringUtils.isNullOrWhitespace(this.memberComplaint.contactNumber)) {
      this.helpersService.cleanUpContactNumber(this.memberComplaint)
      this.memberComplaint.contactNumberBase = this.helpersService.parseContactNumber(this.memberComplaint.contactNumber);
    } else {
      this.memberComplaint.contactNumberBase = this.memberComplaint.contactNumber;
    }

    this.memberComplaint.countryValue = this.helpersService.countryValue;
  }

  public async setSchemeInfo(schemeMembership: MemberSchemeMembershipLookup) {
    if (this.displayComplaint) {
      await this.fetchMemberComplaint();
      if (this.memberComplaint.schemeId !== schemeMembership.schemeId) {
        this.memberComplaint.schemeId = schemeMembership.schemeId;
        this.memberComplaint.memberId = schemeMembership.memberId;
      }
    }

    this.schemeName = schemeMembership.schemeName ?? "";
    this.schemeNumber = schemeMembership.schemeNumber ?? "";
    this.memberNo = schemeMembership.memberNumber ?? "";
    this.memberName = this.userRoleService.personContactDetailsLookup.personFullName;

    if (StringUtils.isNullOrWhitespace(this.memberComplaint.emailAddress)) {
      this.memberComplaint.emailAddress = this.userRoleService.personEmailAddress;
    }

    if (StringUtils.isNullOrWhitespace(this.memberComplaint.contactNumber)) {
      this.memberComplaint.contactNumber = this.userRoleService.personTelephoneNumber;
    }
  }

  public complaintsFileManager = new Components.FileManager(
    this.memberComplaintsCommandApiClient.getUploadComplaintSecureDocumentUrl(),
    {
      multiple: true,
      allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".tif", ".tiff", ".heic"],
      afterUpload: this.afterComplaintDocumentUpload.bind(this),
      onFilesSelected: this.onDocumentsSelected.bind(this),
    });

  public documents: File[] | undefined;

  public afterComplaintDocumentUpload(memberComplaintResult: MemberComplaintLookup) {
    let files = memberComplaintResult.files as List<PartyFileDescriptorLookup>;
    if (files && files.length > 0) {
      if (!this.memberComplaint.files) {
        this.memberComplaint.files = new List(PartyFileDescriptorLookup);
      }
      files.forEach(files => {
        if (!this.memberComplaint.files.find(f => f.fileName === files.fileName)) {
          let partyFileDescriptorLookup = new PartyFileDescriptorLookup();
          partyFileDescriptorLookup.mapFrom(files);
          this.memberComplaint.files.push(partyFileDescriptorLookup);
        }
      });
    }
  }

  public deleteComplaintFile(fileName: string) {
    if (this.memberComplaint.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId) &&
      !StringUtils.isNullOrWhitespace(fileName)) {
      this.memberComplaintsCommandApiClient.deleteMemberComplaintFile(this.memberComplaint.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId, fileName);
      const file = this.memberComplaint.files.find((item) => {
        return item.fileName == fileName;
      })
      if (file) {
        this.memberComplaint.files.remove(file);
      }
    } else {
      NotifyUtils.addWarning("Issue Removing File", "There was an issue removing the file from the complaint.", NotificationDuration.Long);
    }
  }

  public async onDocumentsSelected(fileList: File[] | FileList) {
    this.documents = Array.from(fileList);
    this.docsTooLarge = [];
    if (this.memberComplaint.getfilesCount() + this.documents.length > 5) {
      this.showFileSizeModal = true;
      return;
    }
    let imageTypes = ["jpeg", "jpg", "png", "heic"];
    const compressAndReEncodePromises = this.documents.map(async (element) => {
      let fileSizeInMegabytes = element.size / this.cnstMegabyte;
      let currentFileExtension = this.getFileExtension(element.name.toLowerCase())!
      if (!imageTypes.includes(currentFileExtension) && fileSizeInMegabytes > 5) {
        this.docsTooLarge.push(this.getFileNameWithoutExtension(element.name));
        return;
      }
      return element;
    });
    let convertedFiles = await Promise.all(compressAndReEncodePromises) as File[];
    if (this.docsTooLarge.length > 0) { this.showFileSizeModal = true; return }
    this.complaintsFileManager.uploadFiles(convertedFiles, {
      handleErrors: true,
      additionalData: {
        memberId: this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0,
        nameId: this.userMemberSchemeService.currentSchemeSelected.nameId,
        sourceId: this.userMemberSchemeService.currentSchemeSelected.sourceId
      },
    });
  }

  // Download secure complaint file
  public async downloadComplaintSecureDocument(fileDescriptorId: number, fileName: string) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId) &&
      !StringUtils.isNullOrWhitespace(fileName)) {
      const response = await this.taskRunner.waitFor(this.memberComplaintsCommandApiClient.getComplaintSecureDocument(fileDescriptorId, this.userMemberSchemeService.currentSchemeSelected.memberId!, this.userMemberSchemeService.currentSchemeSelected.sourceId, fileName));
      FileUtils.showSaveFile(response);
    } else {
      NotifyUtils.addWarning("Issue Fetching File", "There was an issue fetching the complaint file.", NotificationDuration.Long)
    }
  }

  private getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  private getFileNameWithoutExtension(fileName: string): string {
    return fileName.split('.').slice(0, -1).join('.');
  }
}