import { Attributes, Validation, ValueObject } from '@singularsystems/neo-core';
import ThirdPartyNominationLookup from './ThirdPartyNominationLookup';

export default class UpdateThirdPartyInterimNominationCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public sourceId: string = "";

  public beneficiaryId: string = "";

  public nominationId: string | null = null;

  public memberId: number = 0;

  public nominationType: string = "";

  @Attributes.Float()
  public nominationPercentage: number = 0;

  public isDeleted: boolean = false;

  @Attributes.ChildObject(ThirdPartyNominationLookup)
  public thirdPartyNominationLookup: ThirdPartyNominationLookup | null = null;

  // Client only properties / methods

  public toString(): string {
    return "Update Third Party Interim Nomination Command";
  }
}