import { AppServices } from '@singularsystems/neo-core';
import MemberBillingApiClient from './ApiClients/MemberBilling/MemberBillingApiClient';
import MemberComplaintsCommandApiClient from './ApiClients/MemberComplaints/MemberComplaintsCommandApiClient';
import MemberComplaintsQueryApiClient from './ApiClients/MemberComplaints/MemberComplaintsQueryApiClient';
import MemberComplimentsCommandApiClient from './ApiClients/MemberCompliments/MemberComplimentsCommandApiClient';
import MemberComplimentsQueryApiClient from './ApiClients/MemberCompliments/MemberComplimentsQueryApiClient';
import MemberContactDetailsApiClient from './ApiClients/MemberContacts/MemberContactDetailsApiClient';
import MemberDetailsApiClient from './ApiClients/MemberDetails/MemberDetailsApiClient';
import MemberInvestmentValuesApiClient from './ApiClients/MemberInvestmentValues/MemberInvestmentValuesApiClient';
import MemberQueriesCommandApiClient from './ApiClients/MemberQueries/MemberQueriesCommandApiClient';
import MemberQueriesQueryApiClient from './ApiClients/MemberQueries/MemberQueriesQueryApiClient';
import MemberBenefitsApiClient from './ApiClients/MemberBenefits/MemberBenefitsApiClient';
import BackgroundDataApiClient from './ApiClients/BackgroundData/BackgroundDataApiClient';
import PersonProfileApiClient from './ApiClients/PersonProfile/PersonProfileApiClient';
import MemberBeneficiariesQueryApiClient from './ApiClients/MemberBeneficiaries/MemberBeneficiariesQueryApiClient';
import MemberBeneficiariesCommandApiClient from './ApiClients/MemberBeneficiaries/MemberBeneficiaryCommandApiClient';
import PersonContactDetailsQueryApiClient from './ApiClients/PersonContactDetails/PersonContactDetailsQueryApiClient';
import PersonContactDetailsCommandApiClient from './ApiClients/PersonContactDetails/PersonContactDetailsCommandApiClient';
import MemberNominationsQueryApiClient from './ApiClients/MemberNominations/MemberNominationsQueryApiClient';
import MemberNominationsCommandApiClient from './ApiClients/MemberNominations/MemberNominationsCommandApiClient';
import SavingsClaimCommandApiClient from './ApiClients/MemberClaims/SavingsClaimCommandApiClient';
import SavingsClaimQueryApiClient from './ApiClients/MemberClaims/SavingsClaimQueryApiClient';
import MemberSavingsClaimEligibilityQueryApiClient from './ApiClients/MemberSavingsClaimEligibility/MemberSavingsClaimEligibilityQueryApiClient';
import ThirdPartyNominationsCommandApiClient from './ApiClients/ThirdPartyNominations/ThirdPartyNominationsCommandApiClient';
import ThirdPartyNominationsQueryApiClient from './ApiClients/ThirdPartyNominations/ThirdPartyNominationsQueryApiClient';
import FAAssistantQueryApiClient from './ApiClients/FAAssistants/FAAssistantQueryApiClient';


// Symbols to expose outside of this module

export const PartiesExportedTypes = {
  ApiClients: {
    PersonContactDetailsQueryApiClient: new AppServices.ServiceIdentifier<PersonContactDetailsQueryApiClient>("Party.ApiClients.PersonContactDetailsQueryApiClient"),
    PersonContactDetailsCommandApiClient: new AppServices.ServiceIdentifier<PersonContactDetailsCommandApiClient>("Party.ApiClients.PersonContactDetailsCommandApiClient"),
    MemberComplaintsCommandApiClient: new AppServices.ServiceIdentifier<MemberComplaintsCommandApiClient>("Party.ApiClients.MemberComplaintsCommandApiClient"),
    MemberComplaintsQueryApiClient: new AppServices.ServiceIdentifier<MemberComplaintsQueryApiClient>("Party.ApiClients.MemberComplaintsQueryApiClient"),
    MemberComplimentsCommandApiClient: new AppServices.ServiceIdentifier<MemberComplimentsCommandApiClient>("Party.ApiClients.MemberComplimentsCommandApiClient"),
    MemberComplimentsQueryApiClient: new AppServices.ServiceIdentifier<MemberComplimentsQueryApiClient>("Party.ApiClients.MemberComplimentsQueryApiClient"),
    MemberQueriesCommandApiClient: new AppServices.ServiceIdentifier<MemberQueriesCommandApiClient>("Party.ApiClients.MemberQueriesCommandApiClient"),
    MemberQueriesQueryApiClient: new AppServices.ServiceIdentifier<MemberQueriesQueryApiClient>("Party.ApiClients.MemberQueriesQueryApiClient"),
    PersonProfileApiClient: new AppServices.ServiceIdentifier<PersonProfileApiClient>("Party.ApiClients.PersonProfileApiClient"),
    MemberInvestmentValuesApiClient: new AppServices.ServiceIdentifier<MemberInvestmentValuesApiClient>("Party.ApiClients.MemberInvestmentValuesApiClient"),
    MemberBillingApiClient: new AppServices.ServiceIdentifier<MemberBillingApiClient>("Party.ApiClients.MemberBilling.MemberBillingApiClient"),
    MemberDetailsApiClient: new AppServices.ServiceIdentifier<MemberDetailsApiClient>("Party.ApiClients.MemberDetails.MemberDetailsApiClient"),
    MemberContactDetailsApiClient: new AppServices.ServiceIdentifier<MemberContactDetailsApiClient>("Party.ApiClients.MemberContactDetailsApiClient"),
    MemberBenefitsApiClient: new AppServices.ServiceIdentifier<MemberBenefitsApiClient>("Party.ApiClients.MemberBenefitsApiClient"),
    BackgroundDataApiClient: new AppServices.ServiceIdentifier<BackgroundDataApiClient>("Party.ApiClients.BackgroundDataApiClient"),
    MemberBeneficiariesQueryApiClient: new AppServices.ServiceIdentifier<MemberBeneficiariesQueryApiClient>("Party.ApiClients.MemberBeneficiariesQueryApiClient"),
    MemberBeneficiariesCommandApiClient: new AppServices.ServiceIdentifier<MemberBeneficiariesCommandApiClient>("Party.ApiClients.MemberBeneficiariesCommandApiClient"),
    MemberNominationsQueryApiClient: new AppServices.ServiceIdentifier<MemberNominationsQueryApiClient>("Party.ApiClients.MemberNominationsQueryApiClient"),
    MemberNominationsCommandApiClient: new AppServices.ServiceIdentifier<MemberNominationsCommandApiClient>("Domain.ApiClient.MemberNominationsCommandApiClient"),
    SavingsClaimCommandApiClient: new AppServices.ServiceIdentifier<SavingsClaimCommandApiClient>("Party.ApiClients.SavingsClaimCommandApiClient"),
    SavingsClaimQueryApiClient: new AppServices.ServiceIdentifier<SavingsClaimQueryApiClient>("Party.ApiClients.SavingsClaimQueryApiClient"),
    MemberSavingsClaimEligibilityQueryApiClient: new AppServices.ServiceIdentifier<MemberSavingsClaimEligibilityQueryApiClient>("Party.ApiClients.MemberSavingsClaimEligibilityQueryApiClient"),
    ThirdPartyNominationsCommandApiClient: new AppServices.ServiceIdentifier<ThirdPartyNominationsCommandApiClient>("Party.ApiClients.ThirdPartyNominationsCommandApiClient"),
    ThirdPartyNominationsQueryApiClient: new AppServices.ServiceIdentifier<ThirdPartyNominationsQueryApiClient>("Party.ApiClients.ThirdPartyNominationsQueryApiClient"),
    FAAssistantQueryApiClient: new AppServices.ServiceIdentifier<FAAssistantQueryApiClient>("Party.ApiClients.FAAssistantQueryApiClient"),
  },
  Services: {

  }
}