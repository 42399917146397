import { Data, Model, Validation } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import SchemeDetailsLookup from '../../Models/LookUps/SchemeDetails/SchemeDetailsLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface ISchemeDetailsApiClient {

  /** 
   * Retrieves Scheme Details Lookup.
   * @param schemeId Id
   * @param sourceId sourceId
   * @returns SchemeDetailsLookup.
   */
  getSchemeDetailsLookupById(schemeId: number, sourceId: string): AxiosPromise<Model.PlainObject<SchemeDetailsLookup>>;

  // Client only properties / methods
}

@injectable()
export default class SchemeDetailsApiClient extends Data.ApiClientBase implements ISchemeDetailsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/scheme-details`);
  }

  public getSchemeDetailsLookupById(schemeId: number, sourceId: string): AxiosPromise<Model.PlainObject<SchemeDetailsLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${schemeId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}