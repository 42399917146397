import { ModelBase } from '@singularsystems/neo-core';
import MemberBenefitsData from './MemberBenefitsData';

export default class MemberBenefitsLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public deathEducatorBenefit: string = "";
  public disabledEducatorBenefit: string = "";
  public memberBenefitsData: MemberBenefitsData[] = [];
  public hasFuneralBenefit: boolean = false;
  public hasApprovedBenefit: boolean = false;
  public hasUnapprovedBenefit: boolean = false;
  // Client only properties / methods
}