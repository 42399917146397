import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import img_Upload from '../../../App/assets/img/primary-upload.png';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import img_view from '../../../App/assets/img/open.png';
import img_delete from '../../../App/assets/img/close-cross.png';
import { DocumentType } from '../../../Party/Models/MemberClaims/Enums/DocumentType.enum';
import { AccountOwnershipType } from '../../Models/MemberClaims/Enums/AccountOwnershipType.enum';
import { ConfirmYesNo } from '../../Models/MemberClaims/Enums/ConfirmYesNo.enum';

interface IDocumentationProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class Documentation extends React.Component<IDocumentationProps> {

  constructor(props: IDocumentationProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='header-of-step documentation-heading'>
          Member documents
        </div>
        <div className='documents-sub-heading'>
          Supporting Documents
        </div>
        <div className='documents-text'>
          Max file size: 5mb. &nbsp;&nbsp;
          File types allowed: .pdf, .jpeg, .docx, .png, .tiff, .heif
        </div>
        {this.props.viewModel.savingsWithdrawalClaimCapture.accountOwnershipType !== AccountOwnershipType.MembersAccount &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Bank authorisation form <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.notMembersAccountFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.NotMembersAccount).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.JointAccount &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Certified copy of joint member Id <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.jointAccountFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.JointAccount).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.accountOwnershipType === AccountOwnershipType.ThirdPartyAccount &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Certified copy of 3rd party member Id <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.thirdPartyAccountFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.ThirdPartyAccount).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderAgainstFund == ConfirmYesNo.Yes &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Divorce order consent form from spouse <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.divorceOrderAgainstFundFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DivorceOrderAgainstFund).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderFinalised == ConfirmYesNo.Yes && !this.props.viewModel.savingsWithdrawalClaimCapture.memberLiens?.divorceOrderFinalised &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Certified copy of divorce court order <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.divorceOrderFinalisedFundFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DivorceOrderFinalised).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund == ConfirmYesNo.Yes &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Interim court order <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.maintenanceOrderAgainstFundFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.MaintenanceOrderAgainstFund).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderFinalised == ConfirmYesNo.Yes && !this.props.viewModel.savingsWithdrawalClaimCapture.memberLiens?.maintenanceOrderFinalised &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Certified copy of the maintenance court order <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.maintenanceOrderFinalisedFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.MaintenanceOrderFinalised).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan == ConfirmYesNo.Yes && !this.props.viewModel.savingsWithdrawalClaimCapture.memberLiens?.debtToEmployerForHousingLoan &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Copy of surety letter from employer/ copy of the latest outstanding balance <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.debtToEmployerForHousingLoanFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DebtToEmployerForHousingLoan).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForDeception == ConfirmYesNo.Yes && !this.props.viewModel.savingsWithdrawalClaimCapture.memberLiens?.debtToEmployerForDeception &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Copy of court order/copy of members admission of liability/copy of court summons <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.debtToEmployerForDeceptionFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.DebtToEmployerForDeception).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        {this.props.viewModel.savingsWithdrawalClaimCapture.surnameChanged &&
          <div className='row m-3'>
            <div className='col-12'>
              <div className='file-upload-documents-header'>
                Confirmation of name change <span className='display-asterisk'>*</span>
              </div>
            </div>
            <div className='col-12'>
              <Neo.FileContext
                fileManager={this.props.viewModel.nameChangeFileManager} >
                <Neo.FileDropArea
                  className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label>
                  <Neo.FileUploadButton
                    className='browse-button'>browse to upload</Neo.FileUploadButton>
                </Neo.FileDropArea>
                <Neo.FileUploadProgress className='grey-upload-box' />
                {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                  <div>
                    {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.NameChange).map((item) => {
                      return <div className='row file-upload-documents phone-remove-margin'>
                        <div className='col-12 phone-padding-left-zero'>
                          <div className='add-margin-to-right phone-justify-left'>
                            <div className='uploaded-document-info-block'>
                              <div className='uploaded-document-info-text'>
                                <span className='file-name' title={item.fileName}>
                                  {item.fileName}
                                </span>
                                <span className='image-container'>
                                  <img className='uploaded-document-info-image' src={img_view}
                                    onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                  <img className='uploaded-document-info-image delete' src={img_delete}
                                    onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                }
              </Neo.FileContext>
            </div>
          </div>
        }
        <div className='pika-separating-line m-4' />
        <div className='row  m-3'>
          <div className='col-12'>
            <div className='file-upload-documents-header'>
              Add another document
            </div>
          </div>
          <div className='col-12'>
            <Neo.FileContext
              fileManager={this.props.viewModel.extraFileManager} >
              <Neo.FileDropArea
                className='file-drop-area'>
                <i><img src={img_Upload} /></i>
                <label className='file-upload-label'> Drag-and-drop a file or</label>
                <Neo.FileUploadButton
                  className='browse-button'>browse to upload</Neo.FileUploadButton>
              </Neo.FileDropArea>
              <Neo.FileUploadProgress className='grey-upload-box' />
              {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                <div>
                  {this.props.viewModel.savingsWithdrawalClaimCapture.files.filter(item => item.documentType === DocumentType.Extra).map((item) => {
                    return <div className='row file-upload-documents phone-remove-margin'>
                      <div className='col-12 phone-phone-padding-left-zero'>
                        <div className='add-margin-to-right phone-justify-left'>
                          <div className='uploaded-document-info-block'>
                            <div className='uploaded-document-info-text'>
                              <span className='file-name' title={item.fileName}>
                                {item.fileName}
                              </span>
                              <span className='image-container'>
                                <img className='uploaded-document-info-image' src={img_view}
                                  onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                <img className='uploaded-document-info-image delete' src={img_delete}
                                  onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              }
            </Neo.FileContext>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12">
            <Neo.FormContext >
              <Neo.FormGroup
                className="document-description-control"
                bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.additionalDocumentsComment}
                placeholder='Type your comment here'
                input={{ rows: 4 }}>
              </Neo.FormGroup>
            </Neo.FormContext>
          </div>
        </div>
      </div>
    );
  }
}