import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import NonEditableProfileVM from './NonEditableProfileVM';
import { observer } from 'mobx-react';
import '../../Styles/Components/NonEditableProfile.scss';
import img_alert from '../../assets/img/alert.png';
import { AppConfig } from '../../Models/AppConfig';
import ContainerFooterComponent from '../Components/ContainerFooterComponent';
import BlueRibbon from '../Components/BlueRibbon';

@observer
export default class NonEditableProfileView extends Views.ViewBase<NonEditableProfileVM> {

  private countryCodes = this.viewModel.countries.map(c => { return { Id: c.countryId, CountryCode: c.dialCode } });

  constructor(props: unknown) {
    super("My profile", NonEditableProfileVM, props);
  }

  public render() {
    return (
      <>
        <div>
          <div className='member-profile-container-non-editable'>
            <div className='member-profile-card-non-editable'>
              <div className='row'>
                <div className='align-profile-info'>
                  <div className='col-md-6 col-12'>
                    <p className='card-heading-profile'>Member profile information</p>
                  </div>
                  <div className='col-md-6 col-12 align-alert-info'>
                    <i><img className='mb-1' src={img_alert} /></i>
                    <p className='alert-text'>Details outstanding</p>
                  </div>
                </div>
              </div>
              <div className='row align-divider'>
                <div className='divider1'></div>
              </div>
              <BlueRibbon
                text='If you would like to edit your identification information, please contact your employer to make the changes.'
              />
              <div>
                <div className='align-form-group-row-profile row'>
                  <Neo.GridLayout>
                    <Neo.FormGroup href='Title' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.title} readOnly isDisabled />
                    <Neo.FormGroup href='Surname' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.lastName} readOnly isDisabled />
                    <Neo.FormGroup href='Name' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.firstName} readOnly isDisabled />
                    <Neo.FormGroup href="SAIdOrPassportNumber" bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.nationalIdNumber} readOnly isDisabled />
                    <Neo.FormGroup href='DateOfBirth' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.birthDate} readOnly isDisabled />
                    <div className='col-md-12'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Cell number</label>
                        <div className='row country-dropdown-row-profile'>
                          <div className='col-2 col-md-2 country-col'>
                            <div className='country-codes-dropdown-profile'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryId}
                                bindDisplay={this.viewModel.meta.country}
                                items={this.countryCodes}
                                menuIsOpen={false}
                                disabled />
                            </div>
                          </div>
                          <div className='col-10 col-md-10'>
                            <div className="input-group cell-group-control">
                              <input type="text" className="form-control"
                                value={this.viewModel.userRoleService.personTelephoneNumber.substring(1)}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Neo.FormGroup href='CountryOfIssue' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.passportCountryOfIssue} readOnly isDisabled />
                  </Neo.GridLayout>
                </div>
                <div className='line-profile' />
                <div className='row mt-4'>
                  <div className='col-12 col-md-12'>
                    <Neo.Alert variant="primary" className='display-alert-profile non-editable-alert' heading="Not editable at this time" />
                  </div>
                </div>
                <div className='align-form-group-row-profile row'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Cell number</label>
                        <div className='row country-dropdown-row-profile'>
                          <div className='col-2 col-md-2 country-col'>
                            <div className='country-codes-dropdown-profile'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryId}
                                bindDisplay={this.viewModel.meta.country}
                                items={this.countryCodes}
                                menuIsOpen={false}
                                disabled />
                            </div>
                          </div>
                          <div className='col-10 col-md-10'>
                            <div className="input-group cell-group-control">
                              <input type="text" className="form-control"
                                value={this.viewModel.userRoleService.personTelephoneNumber.substring(1)}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Email address</label>
                        <div className='row country-dropdown-row-second-profile'>
                          <div className="input-group">
                            <input type="text" className="form-control add-border"
                              defaultValue={this.viewModel.userRoleService.personEmailAddress}
                              aria-label="Text input with checkbox" readOnly disabled />
                            <div className="input-group-text">
                              <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Street name & number</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.addressLine1 == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].addressLine1}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Unit/apartment number & complex/building name</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.addressLine2 == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].addressLine2}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>City/Town</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.city == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].city}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Suburb</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.subUrban == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].subUrban}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Commented for Launch1 */}
                  {/* <div className='align-form-group-row-profile row'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className="align-communication-method-profile">
                                                        <label className='align-province-label mt-0'> Provice/State </label>
                                                        <div className='row dropdown-row'>
                                                            <div className='input-group dropdown-input-group'>
                                                                <Neo.AutoCompleteDropDown className='mt-0 custom-select form-control'
                                                                    bind={this.viewModel.meta.provinceValueId}
                                                                    items={this.states}
                                                                    placeholder="Select province/state"
                                                                    disabled
                                                                >
                                                                </Neo.AutoCompleteDropDown>
                                                                <div className="input-group-text dropdown-append-profile">
                                                                    <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className="align-communication-method-profile">
                                                        <label className='align-province-label mt-0'> Country </label>
                                                        <div className='row dropdown-row'>
                                                            <div className='input-group dropdown-input-group'>
                                                                <Neo.AutoCompleteDropDown className='mt-0 mb-1 custom-select form-control'
                                                                    bind={this.viewModel.meta.countryValueId}
                                                                    items={this.countries}
                                                                    placeholder="Select country"
                                                                    disabled
                                                                >
                                                                </Neo.AutoCompleteDropDown>
                                                                <div className="input-group-text dropdown-append-profile">
                                                                    <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Province/State</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.province == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].province}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Country</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.country == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].country}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Postal code</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group align-on-andriod">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.postalCode == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].postalCode}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ContainerFooterComponent />
            </div>
          </div>
        </div>
        <div className='version-number'> Version Number: {this.viewModel.versionNumber}</div>
      </>
    );
  }
}