import React from 'react';
import { observer } from 'mobx-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import { ClaimType } from '../../../Domain/Models/Claims/Enums/ClaimType.enum';
import { RoleType } from '../../../Domain/Models/Claims/Enums/RoleType.enum';
import { Neo } from '@singularsystems/neo-react';
import checked from '../../../App/assets/img/checked.png';
import unchecked from '../../../App/assets/img/unchecked.png';
import alert from '../../../App/assets/img/alert.png';
import successful from '../../../App/assets/img/successful.png';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import { ConfirmYesNo } from '../../Models/MemberClaims/Enums/ConfirmYesNo.enum';

interface IMemberDeclarationProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class MemberDeclaration extends React.Component<IMemberDeclarationProps> {

  constructor(props: IMemberDeclarationProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='header-of-step mb-4'>
          Member Declaration
        </div>
        <div className='p-2 p-sm-0'>
          <BlueRibbon
            width='97%'
            semiboldPart={'Please note:'}
            text=' This declaration is only required if no Financial adviser has been appointed by the member.'
          />
        </div>
        <div className='member-declaration-text'>
          {this.props.viewModel.groupClaimDeclarationClaimTypes.map(claimType => {
            if (claimType.claimType === ClaimType.SavingsClaim) {
              return claimType.roleTypeGroups.map(roleType => {
                if (roleType.roleType === RoleType.MemberDeclaration) {
                  return roleType.claimDeclarationTemplates.map(declaration => (
                    <div key={declaration.claimDeclarationTemplateId}>
                      <p dangerouslySetInnerHTML={{ __html: declaration.claimDeclarationBody }} />
                    </div>
                  ));
                }
                return null;
              });
            }
            return null;
          })}
        </div>
        <div className='member-declaration-text'>
          <span className='bold'>Date:</span> {this.props.viewModel.memberDeclaration}
        </div>
        <Neo.Button
          variant='secondary'
          className='btn-no-outline-secondary checkbox-table-bak mb-4'
          onClick={() => this.props.viewModel.memberDeclarationIAgree = this.props.viewModel.memberDeclarationIAgree ? false : true} >
          {this.props.viewModel.memberDeclarationIAgree ? <img src={checked} /> : <img src={unchecked} />} &nbsp; &nbsp; I agree
        </Neo.Button>
        <div className='p-2 p-sm-0'>
          <BlueRibbon
            width='97%'
            text='By clicking "I agree", you agree to declaration terms stated above.'
          />
        </div>
        <Neo.Modal title='' bind={this.props.viewModel.meta.showFinalWarningPopup}
          acceptButton={{
            text: "Continue", variant: "primary", onClick: () => {
              this.props.viewModel.saveSavingsClaimData()
              if (!this.props.viewModel.savingsWithdrawalClaimCapture.lien &&
                this.props.viewModel.bankingValidationResponse.passed != "NA" &&
                this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes) {
                this.props.viewModel.submitWithdrawalClaim()
              } else {
                this.props.viewModel.submitWithdrawalClaimEmail()
              }
            }
          }}
          closeButton={{ text: "Cancel", variant: "danger" }}
          size={"lg"}
          className='withdrawal-claim-process-status-modal'>
          <img src={alert} className='alert-img' />
          <h4 className='modal-heading final'>Are you sure you want to continue?</h4>
          Once Liberty commences with processing the claim payment, the transaction cannot be reversed.
        </Neo.Modal>
        <Neo.Modal
          title=''
          bind={this.props.viewModel.meta.showFinalPopup}
          acceptButton={{
            text: "Continue", variant: "light",
            onClick: () => this.props.viewModel.afterSubmit()
          }}
          closeButton={false}
          size={"lg"}
          className='withdrawal-claim-process-status-modal'>
          <img src={successful} className='checked-img' />
          <h4 className='modal-heading final'>Your claim has been submitted successfully.</h4>
          Please find the details of your submission below. You will receive an email with your claim reference number shortly.
          <br /><br />
          <div className='pika-separating-line m-4' />
          <div className='details margin'>
            <span className='bold'>Date submitted:</span> {this.props.viewModel.submittedDate}
          </div>
          <div className='details'>
            <span className='bold'>Status:</span> Submitted
          </div>
          <div className='details'>
            <span className='bold'>Estimated claim processing time:</span>
            {(!this.props.viewModel.savingsWithdrawalClaimCapture.lien &&
              this.props.viewModel.bankingValidationResponse.passed != "NA" &&
              this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes
              && this.props.viewModel.savingsWithdrawalClaimCapture.files.length == 0) ?
              (<span>&nbsp;{this.props.viewModel.savingsWithdrawalClaimTemplate.stpProcessingDays}</span>) :
              (<span>&nbsp;{this.props.viewModel.savingsWithdrawalClaimTemplate.iwsProcessingDays}</span>)
            }
          </div>
        </Neo.Modal>
      </div>
    );
  }
}