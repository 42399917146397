import { EnumHelper } from "@singularsystems/neo-core";

export enum UserRoleType {
  None = 0,

  //legacy
  Member = 1,

  //Employer
  Employer = 2,

  //FinancialAdviser
  FinancialAdviser = 3,

  //Staff
  Staff = 4,

  //FinancialAdviserAssistant
  FinancialAdviserAssistant = 5,

  //Change Default Role only exists on the frontend
  ChangeDefaultRole = 6,

  //non legacy,this exists only on the frontend
  LibertyNonLegacyMember = 7
}

EnumHelper.decorateEnum(UserRoleType, decorator => {
  decorator.describe(UserRoleType.Member, "Member", "");
  decorator.describe(UserRoleType.Employer, "Employer", "");
  decorator.describe(UserRoleType.FinancialAdviser, "Financial adviser", "");
  decorator.describe(UserRoleType.Staff, "Staff", "");
  decorator.describe(UserRoleType.FinancialAdviserAssistant, "Financial adviser assistant", "");
  decorator.describe(UserRoleType.ChangeDefaultRole, "Change default role", "");
});