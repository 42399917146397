import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberBeneficiaryGroupingDataLookup from '../../Models/LookUps/MemberNominations/MemberBeneficiaryGroupingDataLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberNominationsQueryApiClient {

  /**
   * GetMemberNominationLookups
   */
  getMemberNominationLookups(memberId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryGroupingDataLookup>>>;

  /** 
   * GetMemberBeneficiariesPercentage
   */
  getMemberBeneficiariesPercentage(): AxiosPromise<number>;

  // Client only properties / methods
}

@injectable()
export default class MemberNominationsQueryApiClient extends Data.ApiClientBase implements IMemberNominationsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-nominations`);
  }

  public getMemberNominationLookups(memberId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryGroupingDataLookup>>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public getMemberBeneficiariesPercentage(): AxiosPromise<number> {
    return this.axios.get(`${this.apiPath}/beneficiaries-percentage`);
  }

  // Client only properties / methods
}