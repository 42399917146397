import React from 'react';
import { observer } from 'mobx-react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerComplaintsVM from '../../Views/Employer/EmployerComplaintsVM';

interface IEmployerComplaintReview {
  viewModel: EmployerComplaintsVM,
}

@observer
export default class EmployerComplaintReview extends React.Component<IEmployerComplaintReview> {

  constructor(props: IEmployerComplaintReview) {
    super(props);

  }

  public render() {
    return (
      <div className='compliments-css'>
        <div className='background-card'>
          <div className='show-on-web-screen'>
            <div className='heading-co pad-left'>Review complaint information</div><br />
            <div className='row'>
              <div className='divider1 col-md-12'></div>
            </div><br />
            <div className='pad-rl'>
              <div className='border'>
                <div className='heading-cu pad-left'>Employer information</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  <span className='col-3 card-text'>Employer</span>
                  <span className='col-3 card-text'>Cell number</span>
                  <span className='col-3 card-text'>Email address</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-subheading-text '>John Doe</span>
                  <span className='col-3 card-subheading-text '>+27 83 901 2109</span>
                  <span className='col-3 card-subheading-text '>john.doe@gmail.com</span>
                </div><br />
                <div>
                  <div className='divider1'></div>
                </div>

                <div className='heading-cu pad-left'>Member/scheme information </div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  {!this.props.viewModel.showSchemeTabDetails &&
                    <span className='col-3 card-text'>Membership details</span>
                  }
                  <span className='col-3 card-text'>Scheme details</span>
                </div>
                {!this.props.viewModel.showSchemeTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedMember.firstName} {this.props.viewModel.selectedMember.lastName}: {this.props.viewModel.selectedMember.memberNumber}</span>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedMember.schemeName}: {this.props.viewModel.selectedMember.schemeNumber} </span>
                  </div>}
                {this.props.viewModel.showSchemeTabDetails &&
                  <div className='row table-heading-badb'>
                    <span className='col-3 card-subheading-text '>{this.props.viewModel.selectedScheme.schemeName}: {this.props.viewModel.selectedScheme.schemeNumber} </span>
                  </div>}<br />
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Complaint Note</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <p className='card-text'>Complaints</p>
                {!this.props.viewModel.showSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complaintTextMember.complaint}</p>
                }
                {this.props.viewModel.showSchemeTabDetails &&
                  <p className='card-summary-text'>{this.props.viewModel.complaintTextScheme.complaint}</p>
                }
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Document </div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  <span className='col-3 card-text'>Uploaded document</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-subheading-text '>KFC.pdf</span>
                </div><br />

              </div>
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='divider1 margin-top'></div>
          </div>
          <div className='queries-css'>
            <div className='align-buttons-desktop row p-3'>
              <div className="form-group align-link col-12 padding-fix-queries col-md-6">
                <Neo.Link className='save-return-link pad-left'>Save & exit</Neo.Link>
              </div>
              <div className='form-group align-buttons col-12 padding-fix-queries col-md-6'>
                <Neo.Button text="Back" className='col-12 padding-fix-queries btn btn-cancel btn-lg' onClick={() => this.props.viewModel.showComplaintScreen()}
                />
                <Neo.Button text="Submit" className='col-12 padding-fix-queries btn btn-continue btn-lg' onClick={() => this.props.viewModel.showComplaintSummary()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}