import { Attributes, ModelBase, NeoModel, Rules, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';
import AddressInfoLookup from '../../../Party/Models/LookUps/AddressInfoLookup';
import EmailInfoLookup from '../../../Party/Models/LookUps/EmailInfoLookup';
import GetPersonContactDetailLookup from '../../../Party/Models/LookUps/GetPersonContactDetailLookup';
import TelephoneInfoLookup from '../../../Party/Models/LookUps/TelephoneInfoLookup';
import { User } from '../../../UserModel/User';
import { AppService, Types } from '../../DashboardTypes';
import Permissions from '../../../Common/Models/Roles/Permissions'
import { ProfileSection } from '../../../Common/Models/Enum/ProfileSection.enum';

@NeoModel
export class MemberProfile extends ModelBase {
  public title: string = 'Mr';
  public surName: string = 'Doe';
  public name: string = 'John';

  @Attributes.Display('SA ID or Passport Number')
  public passportOrNationalIdNumber: string = '80907358435';

  public dateOfBirth: string = '21 August 1989';

  @Attributes.Display('Country of issue')
  public passportCountryOfIssue: string = 'N/A';

  @Attributes.Display('Street name & number')
  public streetName: string = '1 Liberty street';

  @Attributes.Display('Unit/apartment number & complex/building name')
  public buildingName: string = 'Optional';

  @Attributes.Display('City/Town')
  public city: string = 'Johannesburg';

  public suburb: string = 'Sandton';
  public postalCode: string = 'Eg: 2199';
}

export default class EmployerMemberProfileVM extends Views.ViewModelBase {
  public schemeId: number | null = 3;
  public schemeName: string = 'The ABC Company(PTY)LTD-123';
  public profileSectionId: number = ProfileSection.Profile;
  public profileSectionName: string = 'Profile';
  public memberProfileDetails = new MemberProfile();
  public provinceId: number | null = 3;
  public provinceName: string = 'Gauteng';
  public countryId: number | null = 1;
  public showMemberProfile: boolean = true;
  public showMemberBeneficiary: boolean = false;
  public currentUser = new User(UserRoleType.LibertyNonLegacyMember);

  @Rules.Required("Province/state is required")
  public provinceValueId: number | null = null;
  public provinces = this.masterDataService.provinces;
  public countries = this.masterDataService.countries;

  public personContactDetailsLookup = new GetPersonContactDetailLookup();
  public telephoneInfo = new TelephoneInfoLookup();
  public addressInfo = new AddressInfoLookup();
  public emailInfo = new EmailInfoLookup();
  public permissions: String[] = [];
  public profileSections: ProfileSection[] = [];
  public southAfricanId = this.countries.find(s => s.name.toLowerCase() === "south africa")?.countryId;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public memberDataService = AppService.get(Types.App.Services.MemberDataService),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService)) {

    super(taskRunner);
    this.makeObservable();
  }

  public async initialise() {
    this.currentUser = this.userRoleService.loggedInUser;
    if (this.currentUser.userRoleType === UserRoleType.Employer) {
      await this.getProfileSectionItems();
    }
    await this.getPersonContactDetails();
  }

  public async getProfileSectionItems() {
    if (this.memberDataService.currentSchemeSelected.schemeId) {
      var profile = this.userMemberSchemeService.employerSchemeMembershipLookups
        .find(scheme => scheme.schemeId == this.memberDataService.currentSchemeSelected.schemeId
          && scheme.sourceId == this.memberDataService.currentSchemeSelected.sourceId);
      this.permissions = profile?.permissions ?? [];

      if (this.permissions.includes(Permissions.viewMemberProfile)) {
        if (this.profileSections.length == 0) {
          this.profileSections.push(ProfileSection.Profile);
        }
        // TODO: Add additional sections in R4.2
        // this.profileSections.push(ProfileSection.Documents);
        // this.profileSections.push(ProfileSection.Products
      }
    }
  }

  public async getPersonContactDetails() {
    if (this.memberDataService.currentSchemeSelected.nameId && !StringUtils.isNullOrWhitespace(this.memberDataService.currentSchemeSelected.sourceId)) {
      await this.taskRunner.waitFor(this.memberDataService.getPersonContactDetails(this.memberDataService.currentSchemeSelected.nameId, this.memberDataService.currentSchemeSelected.sourceId));
      await this.taskRunner.run(async () => {
        this.personContactDetailsLookup.mapFrom(this.memberDataService.personContactDetailsLookup);
        this.telephoneInfo = this.personContactDetailsLookup.telephoneInfo[0] ? this.personContactDetailsLookup.telephoneInfo[0] : new TelephoneInfoLookup();
        this.emailInfo = this.personContactDetailsLookup.emailInfo[0] ? this.personContactDetailsLookup.emailInfo[0] : new EmailInfoLookup();
        this.addressInfo = this.personContactDetailsLookup.addressInfo[0] ? this.personContactDetailsLookup.addressInfo[0] : new AddressInfoLookup();
        this.addressInfo.country = this.getCountry(this.addressInfo.country ?? "");
        this.addressInfo.province = this.getProvince(this.addressInfo.province ?? "");
      });
    }
  }

  public navigateToDashboard(): void {
    this.userRoleService.navigateToDashboard();
  }

  public profileSectionSelected(profileSection: number): void {
    if (profileSection == ProfileSection.Profile) {
      this.showMemberProfile = true;
      this.showMemberBeneficiary = false;
      this.userRoleService.viewOnlyBeneficiary = false;
    }
    if (profileSection == ProfileSection.Beneficiaries) {
      this.showMemberProfile = false;
      this.showMemberBeneficiary = true;
      this.userRoleService.viewOnlyBeneficiary = true;
    }
  }

  public getProvince(province: string | null): string {
    if (province) {
      if (this.countryId != this.southAfricanId && StringUtils.isNullOrWhitespace(province)) {
        return "";
      }
      if (this.countryId == this.southAfricanId && StringUtils.isNullOrWhitespace(province)) {
        this.provinceValueId = 1;
        return this.getProvinceById(this.provinceValueId);
      }
      if (province.toLowerCase() == "kwazulu natal") {
        this.provinceValueId = 4;
        return this.getProvinceById(this.provinceValueId);
      }
      let result = this.provinces.find(x => x.province.toLowerCase() === province.toLowerCase());
      if (result) {
        this.provinceValueId = result.id;
        return result.province
      }
      else {
        return province;
      }
    }
    else {
      return "";
    }
  }

  public getProvinceById(id: number | null): string {
    if (id) {
      let result = this.provinces.find(province => province.id == id);
      if (result) {
        return result?.province;
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountry(country: string | null): string {
    if (country) {
      if (country.toLowerCase() == "sa" || country.toLowerCase() == "southafrica" || StringUtils.isNullOrWhitespace(country)) {
        this.countryId = this.southAfricanId ?? null;
        return this.getCountryById(this.countryId);
      }
      if (country.toLowerCase() == "za") {
        this.countryId = this.countries.find(s => s.name.toLowerCase() === "zimbabwe")?.countryId ?? null;
        return this.getCountryById(this.countryId);
      }

      let result = this.countries.find(s => s.name.toLowerCase() === country.toLowerCase());
      if (result) {
        this.countryId = result.countryId;
        return result.name;
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountryById(id: number | null): string {
    if (id) {
      let result = this.countries.find(country => country.countryId == id);
      if (result) {
        return result?.name;
      }
      else {
        return "";
      }
    } else {
      return "";
    }
  }
}