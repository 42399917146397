import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import MyGoalsDetailsVM from './MyGoalsDetailsVM';
import { observer } from 'mobx-react';
import icn_pdf from '../../../App/assets/img/icon-pdf.png';
import whiteCalender from '../../../App/assets/img/white-calendar.png';
import retirement from '../../../App/assets/img/retirement.png';
import notes from '../../../App/assets/img/notes.png';
import future from '../../../App/assets/img/future.png';
import savings from '../../../App/assets/img/savings.png';
import approved from '../../../App/assets/img/approved.png';
import img_Video from '../../../App/assets/img/live_tv.png';
import ReactSpeedometer from 'react-d3-speedometer';
import "../../../App/Styles/Components/Dashboard.scss";
import whiteCircle from '../../../App/assets/img/circle-white.png';
import blueCircle from '../../../App/assets/img/circle.png';
import SchemeInformationHeader from '../../../App/Views/Components/SchemeInformationHeader';
import ReportsAndDocumentsView from '../../../App/Views/ReportsAndDocumentsView';
import { ReportType } from '../../../Enums/ReportType.enum';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import { SchemeProductClassType } from '../../../Party/Models/LookUps/SchemeProductClassType';

@observer
export default class MyGoalsDetailsView extends Views.ViewBase<MyGoalsDetailsVM> {

  constructor(props: unknown) {
    super("My retirement projections", MyGoalsDetailsVM, props);
  }

  public render() {
    function segmentPlus(value: string): string {
      if (Number(value) < 90) {
        return `${value.toString()}`
      }

      return `${value.toString()} +`
    }

    return (
      <div className='my-goals-detail' hidden={!this.viewModel.showMyGoalDetailComponent}>
        <Neo.Modal
          title='Monthly Contribution Salary'
          acceptButton={{ text: "Okay", variant: "light" }}
          closeButton={false}
          bind={this.viewModel.meta.showMonthlyContributionSalaryPopup}>
          Contribution Salary is the proportion of your income package amount, as agreed with your employer, which is used to determine the monthly amounts contributed by yourself and/or your employer.
        </Neo.Modal>
        <Neo.Modal
          title="Consider increasing your contributions through Additional Voluntary Contributions (AVC's)."
          closeButton={false}
          acceptButton={{ text: "Okay", variant: "light" }}
          bind={this.viewModel.meta.showVoluntaryContributionPopup}>
          By making AVC's or increasing the level and frequency of AVC's you make, you will be able to put more away in your savings, which may lead to a higher amount of retirement savings and an increase to your income at retirement.
        </Neo.Modal>
        <Neo.Modal
          title='Speak to a Financial Adviser about looking at investment options.'
          closeButton={false}
          acceptButton={{ text: "Okay", variant: "light" }}
          bind={this.viewModel.meta.showFinancialAdviserPopup}>
          This can help ensure that your underlying investment portfolio selection is appropriate given your long-term retirement needs and goals, and based on your particular circumstances.
        </Neo.Modal>
        <Neo.Modal
          title='Total Projected Benefits at Retirement'
          closeButton={false}
          acceptButton={{ text: "Okay", variant: "light" }}
          bind={this.viewModel.meta.showTotalProjectedBenefitsAtRetirement}>
          As future conditions and investment returns are unknown, certain assumptions are made in order to calculate your projected retirement savings, income in retirement and net replacement ratio. These projected values are therefore for illustrative purposes only and are not guaranteed. These aim to help you better understand what your retirement savings may be at your retirement, and what factors may influence them. Please refer to your Member benefit statement to view these assumptions.
        </Neo.Modal>
        <SchemeInformationHeader schemeHeader={'Select a related scheme'}
          memberName={'Benji'}
          showClaimButton={false}
          extraCssClasses=''
          onSchemeSelected={async () => {
            await this.viewModel.showOrHideBenefitLinks();
            await this.viewModel.getMemberInvestmentData();
            await this.viewModel.getMemberDetails();
          }} />
        <div className='background-card retirement-projection-background'>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <div className='card-header mt-2'>Summary of your retirement projections</div>
            </div>
            <div className='col-md-6'>
              {!this.viewModel.IsPreservationScheme ? (
                <div>
                  {(this.viewModel.schemeProductClassType == SchemeProductClassType.RiskOnly || this.viewModel.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) &&
                    <div className='member-benefit-statement'>
                      <img src={icn_pdf} className="pdf-icon" />
                      <Neo.Link className='mb-statement' onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.MemberBenefitStatement }); }}>Member benefit statement</Neo.Link>
                    </div>
                  }
                  {(this.viewModel.schemeProductClassType == SchemeProductClassType.InvestmentOnly || this.viewModel.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) &&
                    <div className='member-benefit-statement'>
                      <img src={icn_pdf} className="pdf-icon" />
                      <Neo.Link className='mb-statement' onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.MemberInvestmentStatement }); }}>Member investment statement</Neo.Link>
                    </div>
                  }
                </div>) : (
                <div>
                  <div className='member-benefit-statement'>
                    <img src={icn_pdf} className="pdf-icon" />
                    <Neo.Link className='mb-statement' onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.PaidupMemberCertificate }); }}>Paid-up member certificate</Neo.Link>
                  </div>
                  <div className='member-benefit-statement'>
                    <img src={icn_pdf} className="pdf-icon" />
                    <Neo.Link className='mb-statement' onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.PaidupMemberInvestmentStatement }); }}>Paid-up member investment statement</Neo.Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row align-blue-card-center'>
            <div className='col-3 blue-card-size'>
              {this.viewModel.investmentDetails &&
                <div className='blue-card'>
                  <img src={whiteCalender} className="blue-card-image" />
                  <div className='blue-card-text'>Retirement date</div>
                  <div className='seperating-line seperating-blue-card' />
                  <Neo.FormGroup className='blue-card-date'
                    display={this.viewModel.investmentDetails.meta.retirementDate}
                    dateProps={{ formatString: 'd MMMM yyyy' }}
                    suppressLabel />
                </div>
              }
            </div>
            <div className='col-3 blue-card-size'>
              <div className='blue-card'>
                <img src={retirement} className="blue-card-image" />
                <div className='blue-card-text'>Years to retirement</div>
                <div className='seperating-line seperating-blue-card' />
                <div className='blue-card-date'>
                  {this.viewModel.investmentDetails?.yearstoRetirement?.toString()} years
                </div>
              </div>
            </div>
            <div className='col-3 blue-card-size'>
              <div className='blue-card'>
                <img onClick={() => { this.viewModel.showMonthlyContributionSalaryPopup = true }} className='icons right-card' src={whiteCircle} />
                <img src={notes} className="blue-card-money" />
                <div className='blue-card-text padding-botom'>Monthly contribution salary </div>
                <div className='seperating-line seperating-blue-card' />
                <div className='blue-card-date margin-top'>{this.viewModel.memberDetails.monthlySalary?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</div>
              </div>
            </div>
            <div className='col-3 blue-card-size'>
              <div className='blue-card'>
                <img onClick={() => { this.viewModel.showTotalProjectedBenefitsAtRetirement = true }} className='icons right-card' src={whiteCircle} />
                <img src={future} className="blue-card-money" />
                <div className='blue-card-text padding-botom'>Total projected benefits at retirement</div>
                <div className='seperating-line seperating-blue-card' />
                <div className='blue-card-date margin-top'>
                  {this.viewModel.investmentDetails.bestProjectedRetirementBenefit?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' }) ?? 0}
                </div>
              </div>
            </div>
          </div>
          <div className='seperating-line' />
          <div className='expectations-header'>
            Based on your current retirement savings, portfolio selection and expectations for investment returns,
            your projected savings at retirement will likely not be adequate, as described below.
          </div>
          <div className='row net-card'>
            <div className='col-md-6 outside-net-replacement-ratio-card'>
              <Neo.Card className='net-replacement-ratio-card'>
                <div className='net-card-header'>
                  <h5>Net replacement ratio (%) *</h5>
                  <i className='mt-0'>
                    <a href='https://www.insiteeducation.co.za/systems/insite/useruploads/media/Liberty-CSUF_NNR%20Video_2023.mp4' target="_blank">
                      <img src={img_Video} />
                    </a>
                  </i>
                </div>
                <div className='speedometer-goal-card-dashboard'>
                  <ReactSpeedometer textColor='#FFF' minValue={0} maxValue={100}
                    value={this.viewModel.investmentDetails.bestNetReplacementRatioSafe! > 100 ? 100 : this.viewModel.investmentDetails.bestNetReplacementRatioSafe!}
                    needleHeightRatio={0.4} ringWidth={25} maxSegmentLabels={6} currentValueText={this.viewModel.investmentDetails.bestNetReplacementRatioSafe?.toString() + "%"}
                    segmentValueFormatter={segmentPlus} segments={10} width={164} height={130} labelFontSize={'10'}
                    valueTextFontSize={'15'} valueTextFontWeight={'600'}
                    segmentColors={[
                      "#F46363",
                      "#F46363",
                      "#F46363",
                      "#F46363",
                      "#F46363",
                      "#FFAB00",
                      "#FFAB00",
                      "#84C83A",
                      "#84C83A",
                      "#84C83A",]}
                    needleColor="#D9D9D9" />
                </div>
              </Neo.Card>
            </div>
            <div className='col-md-6 estimated-monthly-income-card'>
              <Neo.Card
                className={this.viewModel.investmentDetails.bestNetReplacementRatioSafe! <= 49 ? 'monthly-income-card' : this.viewModel.investmentDetails.bestNetReplacementRatioSafe! < 70 ? 'monthly-income-card yellow' : 'monthly-income-card green'}>
                <div className='net-card-header'>
                  <h5>Estimated monthly income at retirement</h5>
                </div>
                <div className='monthly-income-card-content'>
                  <p>{this.viewModel.investmentDetails.bestEstimatedMonthlyIncome?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</p>
                  <div className='monthly-income-card-divider'></div>
                </div>
              </Neo.Card>
            </div>
          </div>
          <div className='row monthly-card-center'>
            <div className='col-12 monthly-card-size'>
              {this.viewModel.investmentDetails.bestNetReplacementRatioSafe! <= 49 &&
                <div className='expectation-card'>
                  <div className='expectation-card-red'>
                    <div className='expectation-card-header'>
                      Not adequate
                    </div>
                  </div>
                  <div className='expectation-card-text'>
                    You are likely at risk of not meeting the desired replacement ratio of 70%.
                  </div>
                </div>
              }
              {this.viewModel.investmentDetails.bestNetReplacementRatioSafe! > 49 && this.viewModel.investmentDetails.bestNetReplacementRatioSafe! < 70 &&
                <div className='expectation-card'>
                  <div className='expectation-card-red yellow'>
                    <div className='expectation-card-header'>
                      Partially adequate
                    </div>
                  </div>
                  <div className='expectation-card-text yellow'>
                    You are likely not meeting the desired replacement ratio of 70% but your replacement ratio is within acceptable industry standards.
                  </div>
                </div>
              }
              {this.viewModel.investmentDetails.bestNetReplacementRatioSafe! >= 70 &&
                <div className='expectation-card'>
                  <div className='expectation-card-red green'>
                    <div className='expectation-card-header'>
                      Adequate
                    </div>
                  </div>
                  <div className='expectation-card-text green'>
                    You are likely meeting the desired replacement ratio of 70%.
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='seperating-line hidden-on-web' />
          <div className='improve-header'>
            Tips to help improve your retirement goals
          </div>
          <div className='row align-improve-cards'>
            <div className='col-6 improve-card-size'>
              <div className='improve-card'>
                <img onClick={() => { this.viewModel.showVoluntaryContributionPopup = true }} className='icons right-card' src={blueCircle} />
                <img src={savings} className="improve-card-image" />
                <div className='improve-card-text'>Consider increasing your contributions through Additional Voluntary Contributions (AVC's).</div>
              </div>
            </div>
            <div className='col-6 improve-card-size'>
              <div className='improve-card'>
                <img onClick={() => { this.viewModel.showFinancialAdviserPopup = true }} className='icons right-card' src={blueCircle} />
                <img src={approved} className="improve-card-image" />
                <div className='improve-card-text'>Speak to a Financial Adviser about looking at investment option.
                </div>
              </div>
            </div>
          </div>
          <div className='notes-text'>
            *The projected income at retirement expressed as a percentage of the final salary pre-retirement.
          </div>
          <ContainerFooterComponent />
        </div>
      </div >
    );
  }
}
