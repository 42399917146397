import { Attributes, DateUtils, List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import SavingsWithdrawalClaimTemplateCardLookup from './SavingsWithdrawalClaimTemplateCardLookup';

export default class SavingsWithdrawalClaimTemplateLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public savingsWithdrawalClaimTemplateId: number = 0;

  @Attributes.Float()
  public readonly administrationFee: number = 0;

  @Attributes.Integer()
  public readonly stpProcessingDays: number = 0;

  @Attributes.Integer()
  public readonly iwsProcessingDays: number = 0;

  public savingsWithdrawalClaimTemplateCards = new List(SavingsWithdrawalClaimTemplateCardLookup);

  // Client only properties / methods
}