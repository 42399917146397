import { Attributes, StringUtils, Validation, ValueObject } from '@singularsystems/neo-core';

export default class SearchCriteria extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public partyId: string = "";

  public participationLevelRole: string = "";

  public paypointId: string = "";

  public memberStatusGroups: Array<number> = [];

  public schemeStatusGroups: Array<number> = [];

  public schemeNo: string = "";

  public keyword: string = "";

  public memberNameId: number = 0;

  public sourceId: string = "";

  public memberName: string = "";

  @Attributes.Display("National ID or passport")
  public memberIDOrPassport: string = "";

  public memberNumber: string = "";

  // Client only properties / methods

  protected static addBusinessRules(rules: Validation.Rules<SearchCriteria>) {
    super.addBusinessRules(rules);

    rules.failWhen(c => !StringUtils.isNullOrWhitespace(c.keyword) && !/^[a-zA-Z0-9äöüÄÖÜáéíóúÁÉÍÓÚ\'\s\-\(\)]+$/.test(c.keyword), "Search criteria is invalid").onProperties(c => c.keyword);
    rules.failWhen(c => !StringUtils.isNullOrWhitespace(c.memberName) && !/^[a-zA-Z0-9äöüÄÖÜáéíóúÁÉÍÓÚ\'\s\-\(\)]+$/.test(c.memberName), "Member name is invalid").onProperties(c => c.memberName);
    rules.failWhen(c => !StringUtils.isNullOrWhitespace(c.memberNumber) && !/^[a-zA-Z0-9]+$/.test(c.memberNumber), "Member number is invalid").onProperties(c => c.memberNumber);
    rules.failWhen(c => !StringUtils.isNullOrWhitespace(c.memberIDOrPassport) && !/^[a-zA-Z0-9]+$/.test(c.memberIDOrPassport), "National ID or passport is invalid").onProperties(c => c.memberIDOrPassport);
  }

  public schemeName: string = "";

  public toString(): string {
    return "Search Criteria";
  }
}