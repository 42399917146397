import { Attributes, ModelBase, Rules, StringUtils, Validation } from '@singularsystems/neo-core';
import BeneficiaryAddressInfoLookup from './BeneficiaryAddressInfoLookup';

export default class MemberBeneficiaryLookup extends ModelBase {
  constructor() {
    super();
    this.makeBindable();
  }

  public beneficiaryId: string = "";

  @Attributes.Integer()
  public titleCode: number = 0;

  @Rules.Required()
  @Attributes.Display("First name")
  @Rules.StringLength(0, 80, "First name cannot be more than 80 characters in length")
  public firstName: string = "";

  @Rules.StringLength(0, 80, "Middle name cannot be more than 80 characters in length")
  public middleName: string = "";

  @Rules.Required()
  @Attributes.Display("Last name")
  @Rules.StringLength(0, 80, "Last name cannot be more than 80 characters in length")
  public lastName: string = "";

  @Attributes.ChildObject(BeneficiaryAddressInfoLookup)
  public beneficiaryAddressInfo: BeneficiaryAddressInfoLookup = new BeneficiaryAddressInfoLookup();

  public title: string = "";

  @Attributes.Display("RSA ID/Passport Number")
  public nationalIdNoOrPassportNo: string = "";

  @Attributes.Display("RSA ID Number")
  public nationalIdNo: string | null = null;

  @Attributes.Display("Passport number")
  public passportNo: string | null = null;

  @Attributes.Date()
  @Attributes.Display("Date of birth")
  @Rules.Required()
  public birthDate: Date | null = null;

  @Attributes.Display("Email address")
  @Rules.EmailAddress("Email address is invalid")
  @Rules.StringLength(0, 80, "Email address cannot be more than 80 characters in length")
  public emailAddress: string = "";

  @Attributes.Date()
  public expiryDate: Date | null = null;

  public signedByMember: boolean = false;

  public lastModifiedUser: string = "";

  public lastModifiedByRole: string = "";

  @Rules.Required()
  @Rules.StringLength(0, 80, "Relationship cannot be more than 80 characters in length")
  public relationship: string = "";

  public trustedPerson: boolean = false;

  @Attributes.Display("Country of issue")
  public passportCountryOfIssue: string | null = null;

  public trustNo: string = "";

  public trustName: string = "";

  @Attributes.Display("Cell number")
  public telephoneNo: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => !/^[a-zA-Z0-9äöüÄÖÜáéíóúÁÉÍÓÚ'\s\-\(\)\/.]+$/.test(c.firstName), "First name is invalid").onProperties(c => c.firstName);
    rules.failWhen(c => !/^[a-zA-Z0-9äöüÄÖÜáéíóúÁÉÍÓÚ'\s\-\(\)\/.]+$/.test(c.lastName), "Last name is invalid").onProperties(c => c.lastName);
    rules.failWhen(c => c.isIDNumber && StringUtils.isNullOrWhitespace(c.nationalIdNo), "RSA ID Number is required").onProperties(c => c.nationalIdNo);
    rules.failWhen(c => !c.isIDNumber && StringUtils.isNullOrWhitespace(c.passportNo), "Passport Number is required").onProperties(c => c.passportNo);
    rules.failWhen(c => !c.isIDNumber && !c.countryOfIssueValueId, "Country of Issue is required").onProperties(c => c.countryOfIssueValueId);
    rules.failWhen(c => c.isIDNumber && c.nationalIdNo !== null && !/^[0-9]*$/.test(c.nationalIdNo), "RSA ID Number can only contain numbers").onProperties(c => c.nationalIdNo);
    rules.failWhen(c => !c.isIDNumber && c.passportNo !== null && !/^[a-zA-Z0-9]*$/.test(c.passportNo), "Passport Number can only contain letters and numbers").onProperties(c => c.passportNo);
    rules.failWhen(c => /.*\*.*/.test(c.emailAddress), "Email address is invalid").onProperties(c => c.emailAddress);
    rules.failWhen(c => c.isIDNumber && c.nationalIdNo !== null && c.nationalIdNo.length !== 13, "RSA ID Number must be 13 characters").onProperties(c => c.nationalIdNo);
    rules.failWhen(c => !c.isIDNumber && c.passportNo !== null && c.passportNo.length > 20, "Passport Number can't be greater than 20 characters").onProperties(c => c.passportNo);
    rules.failWhen(c => !/^(?!\s+$)[a-zA-Z\s]+$/.test(c.relationship), "Relationship can only contain letters").onProperties(c => c.relationship);
    rules.failWhen(c => !/^[a-zA-Z\s]*$/.test(c.trustedPersonRelationship), "Relationship can only contain letters").onProperties(c => c.trustedPersonRelationship);
    // TODO: get the validation text to show up on the screen without hovering over the dropdown
    rules.add(this.validPassportCountryOfIssue).onProperties(c => [c.passportCountryOfIssue, c.countryOfIssueValueId]);
    rules.add(this.telephoneValidationRules).onProperties(c => c.telephoneNo);
    rules.failWhen(c => c.birthDate !== null && c.birthDate > new Date(), "Date of birth cannot be in the future").onProperties(c => c.birthDate);
  }

  public isSelected: boolean = false;

  public canChangeSelection: boolean = false;

  @Attributes.Display("Profile Picture")
  public profilePicture: string = '';

  @Attributes.Display("Full Name")
  public fullName: string = this.firstName + " " + this.lastName;

  public isIDNumber: boolean = true;

  public passportNumber: string = '';

  @Attributes.Observable()
  public countryOfIssueValueId: number = 1;

  @Attributes.Display("Country code")
  public countryCodeId: number | null = 1;

  public countryCode: string = '+27';

  public provinceValueId: number = 1;

  public countryValueId: number = 1;

  @Attributes.Float()
  @Attributes.Display("Share allocation")
  public share: number = 0;

  public isApproved: boolean = true;

  public beneficiaryNumber: number = 0;

  public orderOfBeneficiaries: number = 99;

  public getFullName(): string {
    const trimmedFirstName = this.firstName.trim();
    const trimmedLastName = this.lastName.trim();
    const fullName = trimmedFirstName === '' ? trimmedLastName : trimmedLastName === '' ? trimmedFirstName : trimmedFirstName + ' ' + trimmedLastName;

    return fullName;
  }

  @Attributes.Display("Relationship")
  public get trustedPersonRelationship() {
    return this.relationship;
  }

  public set trustedPersonRelationship(value: string) {
    this.relationship = value;
  }

  private validPassportCountryOfIssue(context: Validation.IRuleContext) {
    if (!this.isIDNumber && this.countryOfIssueValueId == null) {
      context.addError("Country of Issue is required");
    }
  }

  private isValidateTelephone() {
    var telephoneToTest = "";
    let numberOfSplits = this.telephoneNo.split(' ');
    if (this.telephoneNo.startsWith("+") && numberOfSplits.length === 1) {
      return false;
    }
    numberOfSplits.forEach(piece => {
      if (!piece.includes("+")) {
        telephoneToTest += piece;
      }
    });
    if (this.countryCode === "+27") {
      // This test for that if a user uses spaces in the number the format
      // will be xx xxx xxxx or xxx xxx xxxx where the spaces are optional and letters are not allowed
      // not allowing for more than 10 characters.
      const cellNumberRegex = /^\(?(\d{2,3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      var valid = cellNumberRegex.test(telephoneToTest)
      if (!valid || (telephoneToTest.length >= 10 && telephoneToTest.charAt(0) !== "0" && !telephoneToTest.includes(" "))) {
        return false
      }
    } else {
      const foreignNumber = /^[0-9]*$/;
      var foreignNumberValid = foreignNumber.test(telephoneToTest)
      if (telephoneToTest.length > 15 || !foreignNumberValid) {
        return false;
      }
    }
    return true;
  }

  private telephoneValidationRules(context: Validation.IRuleContext) {
    if (this.trustedPerson && StringUtils.isNullOrWhitespace(this.telephoneNo)) {
      context.addError("Cell Number is required");
    }

    if (this.telephoneNo.length > 0) {
      if (!this.isValidateTelephone()) {
        context.addError("Cell number is invalid");
      }
    }
  }

  public isValidTrustedPerson() {
    if (this.trustedPerson &&
      !StringUtils.isNullOrWhitespace(this.firstName) &&
      !StringUtils.isNullOrWhitespace(this.lastName) &&
      !StringUtils.isNullOrWhitespace(this.telephoneNo) && this.isValidateTelephone()
      && this.isValidTrustedPersonRelationship()) {
      return true;
    }

    return false
  }
  private isValidTrustedPersonRelationship() {
    if (/^(?!\s+$)[a-zA-Z\s]*$/.test(this.trustedPersonRelationship)) {
      return true;
    }
    return false;
  }
  public telephoneNumberToDisplay: string = '';
}