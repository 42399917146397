import { Attributes, List, ModelBase, Rules, Validation } from '@singularsystems/neo-core';
import SavingsClaimPartyFileDescriptorLookup from '../Files/SavingsClaimPartyFileDescriptorLookup';
import { AccountOwnershipType } from '../../MemberClaims/Enums/AccountOwnershipType.enum';
import { ConfirmYesNo } from '../../MemberClaims/Enums/ConfirmYesNo.enum';
import { BankAccountType } from '../../MemberClaims/Enums/BankAccountType.enum';
import MemberLiensLookup from '../MemberLiens/MemberLiensLookup';

export default class SavingsClaimDataCaptureLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public savingsClaimDataCaptureId: number = 0;

  public memberId: number = 0;

  public schemeId: number = 0;

  public nameId: number = 0;

  public sourceId: string = "";

  public source: string | null = null;

  public isSTP: boolean = false;

  public bankValidation: string | null = null;

  public accountOwnershipType: AccountOwnershipType = AccountOwnershipType.None;

  public isSouthAfricanBankAccount: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public lien: boolean = false;

  public divorceOrderAgainstFund: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public divorceOrderFinalised: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public maintenanceOrderAgainstFund: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public maintenanceOrderFinalised: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public debtToEmployerForHousingLoan: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public debtToEmployerForDeception: ConfirmYesNo = ConfirmYesNo.NotSelected;

  public dbScheme: boolean = false;

  public isRejected: boolean = false;

  public rejectionCode: string | null = null;

  public schemeNumber: string | null = null;

  public schemeName: string | null = null;

  public employerName: string | null = "";

  public title: string | null = "";

  public initials: string | null = "";

  public memberFirstName: string | null = null;

  public memberLastName: string | null = null;

  public memberNumber: string | null = null;

  public dob: string | null = "";

  public idNumber: string | null = null;

  public passportNumber: string | null = null;

  public passportCountry: string | null = null;

  @Rules.StringLength(10, 10, "Tax number must be 10 digits")
  @Attributes.Display("South African tax number")
  public southAfricanIncomeTaxNumber: string | null = null;

  public addressLine1: string | null = null;

  public addressLine2: string | null = "";

  public cityTown: string | null = null;

  public suburb: string | null = null;

  public provinceOrState: string | null = null;

  public country: string | null = null;

  public postalCode: string | null = null;

  public cellphoneNo: string | null = null;

  public emailId: string | null = "";

  @Attributes.Nullable()
  @Attributes.Float()
  public claimPercentage: number | null = null;

  @Attributes.Display("Annual income")
  @Rules.Required("Annual income required")
  @Attributes.Nullable()
  @Attributes.Integer()
  public annualIncome: number | null = null;

  public claimDate: string | null = null;

  public typeOfAccount: BankAccountType = BankAccountType.None;

  @Attributes.Display("Account holder surname")
  @Rules.Required("Surname required")
  public accountHolderName: string | null = "";

  @Attributes.Display("Name of bank")
  @Rules.Required("Bank name required")
  public nameOfBank: string | null = null;

  @Attributes.Display("Account number")
  @Rules.Required("Account number required")
  public accountNumber: string | null = "";

  @Attributes.Display("Name of branch")
  public nameOfBranch: string | null = "";

  @Attributes.Display("Branch code")
  @Rules.Required("Branch code required")
  public branchCode: string | null = "";

  @Attributes.Display("Swift code")
  public swiftCode: string | null = "";

  @Rules.StringLength(0, 18000, "Description cannot be more than 18000 characters in length")
  @Attributes.Display("Add a comment")
  public additionalDocumentsComment: string | null = "";

  public attachedDocs: string | null = "";

  public isActive: boolean = true;

  @Attributes.Date()
  public sentDate: Date | null = null;

  public files = new List(SavingsClaimPartyFileDescriptorLookup);

  public bankId: number | null = null;

  @Attributes.ChildObject(MemberLiensLookup)
  public memberLiens: MemberLiensLookup | null = null;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen((c: this) => (c.swiftCode?.length === 0 && this.isSouthAfricanBankAccount) === ConfirmYesNo.No, "Swift code required");
    rules.failWhen((c: this) => (c.nameOfBranch?.length === 0 && this.isSouthAfricanBankAccount) === ConfirmYesNo.No, "Name of branch required");
    rules.failWhen((c: this) => {
      return c.southAfricanIncomeTaxNumber ? !/^[0123]/.test(c.southAfricanIncomeTaxNumber) : false;
    }, "Tax number must start with a number from 0-3");
    rules.failWhen((c: this) => (c.branchCode ? c.branchCode.replace(/\s/g, '').length !== c.branchCode.length : false), "Branch code cannot contain spaces");
    rules.failWhen((c: this) => (c.southAfricanIncomeTaxNumber ? !/^\d+$/.test(c.southAfricanIncomeTaxNumber) : false), "Tax number must contain only numbers");
    rules.failWhen((c: this) => (c.swiftCode ? !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(c.swiftCode) : false), "Invalid swift code format");
  }

  public selectionValidationsCheck(): boolean {
    let isValid = true;

    switch (true) {
      case this.claimPercentage == null || this.claimPercentage <= 0 || this.claimPercentage > 1:
        isValid = false;
        break;
      default:
        break;
    }
    return isValid;
  }

  public bankValidationsCheck(): boolean {
    let isValid = true;

    switch (true) {
      case this.accountHolderName == "":
        isValid = false;
        break;
      case this.accountNumber == "":
        isValid = false;
        break;
      case this.nameOfBank == "" || this.nameOfBank == null:
        isValid = false;
        break;
      case this.branchCode == "":
        isValid = false;
        break;
      case this.isSouthAfricanBankAccount == ConfirmYesNo.No && this.nameOfBranch == "":
        isValid = false;
        break;
      case this.isSouthAfricanBankAccount == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.accountOwnershipType == AccountOwnershipType.None:
        isValid = false;
        break;
      case this.typeOfAccount == null:
        isValid = false;
        break;
      default:
        break;
    }

    return isValid;
  }

  public deductionsValidationsCheck(): boolean {
    let isValid = true;

    switch (true) {
      case this.divorceOrderAgainstFund == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.divorceOrderFinalised == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.maintenanceOrderAgainstFund == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.maintenanceOrderFinalised == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.debtToEmployerForHousingLoan == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      case this.debtToEmployerForDeception == ConfirmYesNo.NotSelected:
        isValid = false;
        break;
      default:
        break;
    }

    return isValid;
  }

  public surnameChanged: boolean = false;
}