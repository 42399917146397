import React from 'react';
import { observer } from 'mobx-react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import InstructionsVM from '../InstructionsVM';
import LibertyPager from '../../../Dashboard/Components/LibertyPager';
import InstructionsSearchResultsLookup from '../../../Dashboard/Models/Lookups/Instructions/InstructionsSearchResultsLookUp';
import { Data } from '@singularsystems/neo-core';
import InstructionsSearchCriteria from '../../../Dashboard/Models/Lookups/Instructions/IntructionsSearchCriteria';
import { IPropertyInstance } from '@singularsystems/neo-core/dist/Model';

interface IInstructionsMobileMemberTableProps {
  pageManagerMember: Data.PageManager<InstructionsSearchCriteria, InstructionsSearchResultsLookup>
  propValues: any[]
  propValueId: IPropertyInstance<number>
  propValue: IPropertyInstance<string>
  gotToInstructionView(instruction: InstructionsSearchResultsLookup): void
  viewModel: InstructionsVM
}

@observer
export default class InstructionsMobileMemberTable extends React.Component<IInstructionsMobileMemberTableProps> {

  constructor(props: IInstructionsMobileMemberTableProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-phone-screen'>
        <div className='drop-down'>
          <Neo.AutoCompleteDropDown
            bind={this.props.propValueId}
            placeholder="Sort by"
            bindDisplay={this.props.propValue}
            items={this.props.viewModel.propValues}
            onItemSelected={(val: any) => {
              this.props.viewModel.SortItems(val.prop);
            }}
          />
        </div>
        <br />
        <LibertyPager
          maxPagesToShow={2}
          pageManager={this.props.pageManagerMember}
          pageControlProps={{
            pageSizeLabel: " ",
            lastText: " ", totalRecords: this.props.pageManagerMember.totalRecords
          }}>
          <NeoGrid.Grid<InstructionsSearchResultsLookup>>
            {(item) => (
              <div className='card-container'>
                <Neo.Card
                  className='info-card'
                  key={item.instructionId}
                  onClick={() => this.props.gotToInstructionView(item)}
                >
                  <div className='row'>
                    <div className='col-6 card-small-heading-text'>Name</div>
                    <div className='col-6 card-small-label'>{item.name}</div>
                  </div>
                  <div className='row'>
                    <div className='col-6 card-small-heading-text'>Number</div>
                    <div className='col-6 card-small-label'>{item.instructionNumber}</div>
                  </div>
                  <div className='row'>
                    <div className='col-6 card-small-heading-text'>Scheme name</div>
                    <div className='col-6 card-small-label'>{item.schemeName}</div>
                  </div>
                  <div className='row'>
                    <div className='col-6 card-small-heading-text'>Scheme number</div>
                    <div className='col-6 card-small-label'>{item.schemeNumber}</div>
                  </div>
                  <div className='row'>
                    <div className='col-6 card-small-heading-text'>Instruction</div>
                    <div className='col-6 card-small-label'>{item.instruction}</div>
                  </div>
                  <br />
                  <span className={item.status == 'In progress' ? 'in-progress-pill pull-right' : item.status == 'Incomplete' || item.status == 'Requires documents' ? 'incomplete-pill pull-right' : 'completed-pill pull-right'}>
                    {item.status}
                  </span>
                </Neo.Card>
                <br />
              </div>
            )}
          </NeoGrid.Grid>
        </LibertyPager>
      </div>
    );
  }
}