import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo } from '@singularsystems/neo-react';
import img_Upload from '../../../App/assets/img/primary-upload.png';
import img_view from '../../../App/assets/img/open.png';
import eye from '../../../App/assets/img/open.png';
import close from '../../../App/assets/img/close.png';
import { User } from '../../../UserModel/User';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';

interface IFileUploadBeneficiariesProps {
  viewModel: BeneficiariesVM
}

@observer
export default class FileUploadBeneficiaries extends React.Component<IFileUploadBeneficiariesProps> {

  constructor(props: IFileUploadBeneficiariesProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        {(this.props.viewModel.currentUser.userRoleType === UserRoleType.Staff || this.props.viewModel.currentUser.userRoleType === UserRoleType.Employer || this.props.viewModel.currentUser.userRoleType === UserRoleType.FinancialAdviser || this.props.viewModel.currentUser.userRoleType === UserRoleType.FinancialAdviserAssistant) &&
          <div className='nominated-form-background'>
            <div>
              <div className='nominated-form-heading'>
                Upload beneficiary nomination form
              </div>
              <div className=' row'>
                <div className='col-md-12 col-12 alert-heading'>
                  <Neo.Alert variant="primary" className='display-alert-employer-beneficiary' heading="Please note: you may not submit any updates without including the signed beneficiary nomination form in the submission." >
                  </Neo.Alert>
                </div>
              </div>
              <div className='upload-card card'>
                <div className="form-group col-12 padding-fix-complaint col-md-12">
                  <label className='ms-2 form-label'>Max file size: 5mb <br></br> File types allowed: .pdf, .jpeg, .docx, .png, .tiff, .heic</label>
                </div>
                <div className='form-group col-12 col-md-12'>
                  <Neo.FileContext fileManager={this.props.viewModel.fileManager}>
                    <Neo.FileDropArea
                      className='file-drop-area'>
                      <i><img src={img_Upload} /></i>
                      <label className='file-upload-label'> Drag-and-drop a file or</label>
                      <Neo.FileUploadButton
                        fileManager={this.props.viewModel.fileManager}
                        className='browse-button'>browse to upload</Neo.FileUploadButton>
                    </Neo.FileDropArea>
                    <Neo.FileUploadProgress className='grey-upload-box' />
                    {this.props.viewModel.thirdPartyNomination.file &&
                      this.props.viewModel.thirdPartyNomination.file.fileName !== ""
                      && this.props.viewModel.thirdPartyNomination.file.fileName !== null &&
                      <div className='pb-3'>
                        < div className="form-group padding-fix-complaints ms-2 mt-2 col-12 col-md-12 ">
                          <label className='form-label'>Uploaded document/s</label>
                        </div>
                        <div>
                          <div className='row file-upload-documents phone-remove-margin'>
                            <div className='col-12 phone-padding-left-zero'>
                              <div className='add-margin-to-right phone-justify-left'>
                                <div className='uploaded-document-info-block'>
                                  <div className='uploaded-document-info-text'>
                                    <div className='file-name'>
                                      {this.props.viewModel.thirdPartyNomination.file.fileName}
                                    </div>
                                  </div>
                                  <span className='image-container'>
                                    <img src={img_view} onClick={() => this.props.viewModel.downloadSecureDocument(this.props.viewModel.thirdPartyNomination.file.fileDescriptorId, this.props.viewModel.thirdPartyNomination.file.fileName)} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </Neo.FileContext>
                </div>
              </div>
              <Neo.Modal
                title='Please insert the date at which the member signed the form that is being uploaded'
                bind={this.props.viewModel.meta.showNominationFormDateModal}
                acceptButton={{
                  text: "Continue", variant: "primary",
                  disabled: !this.props.viewModel.thirdPartyNomination.meta.signedDate,
                  onClick: () => this.props.viewModel.onDocumentsSelected(),
                }}
                closeButton={{ text: "Cancel", variant: "secondary", className: "btn-no-outline-secondary" }}>
                <Neo.DatePicker
                  required
                  bind={this.props.viewModel.thirdPartyNomination.meta.signedDate}
                  placeholder="Select date"
                  dateProps={{ formatString: "dd/MM/yyyy", maxDate: new Date() }}
                />
              </Neo.Modal>
            </div>
          </div>
        }
      </div>
    );
  }
}