import { Attributes, ModelBase, Rules, Validation } from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { IRuleContext } from '@singularsystems/neo-core/dist/Validation';

export default class BeneficiaryAddressInfoLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  @Attributes.Display("Street name & number")
  public addressLine1: string = "";

  @Attributes.Display("Unit/apartment number & complex/building name")
  public addressLine2: string = "";

  @Attributes.Display("Suburb")
  public addressLine3: string = "";

  @Attributes.Display("City/Town")
  @Rules.Required()
  @Rules.StringLength(0, 50, "City/Town cannot be more than 50 characters in length")
  public city: string | null = null;

  public province: string | null = null;

  public country: string | null = null;

  @Attributes.Display("Postal code")
  public postalCode: string | null = "1234";

  // Client only properties / methods
  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.add(this.postalCodeLimit).onProperties(c => c.postalCode);
    rules.failWhen(c => !/^[a-zA-Z0-9\s\/\(\)\-.,]+$/.test(c.addressLine1), "Street name & number is invalid").onProperties(c => c.addressLine1);
    rules.failWhen(c => !/^[a-zA-Z0-9\s\/\(\)\-.,]+$/.test(c.city ? c.city : ""), "City/Town is invalid").onProperties(c => c.city);
    rules.failWhen(c => !/^[a-zA-Z0-9\s\/\(\)\-.,]+$/.test(c.addressLine3), "Suburb is invalid").onProperties(c => c.addressLine3);
  }
  // add the postal code limit rule
  private postalCodeLimit(context: IRuleContext) {
    if (this.postalCode) {
      if (this.postalCode.length != 4 && this.country === "South Africa") {
        context.addError("Postal code must be 4 characters");
      } else if (this.postalCode.length > 10 && this.country !== "South Africa") {
        context.addError("Postal code can't be greater than 10 characters");
      }
      if (this.country == "South Africa" && this.postalCode.length > 0) {
        const regex = /^[0-9]+$/;
        if (!regex.test(this.postalCode)) {
          context.addError("Postal code can only contain numbers");
        }
      }
    }
  }
}