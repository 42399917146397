import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerComplaintsVM from './EmployerComplaintsVM';
import { observer } from 'mobx-react';
import img_Upload from '../../../App/assets/img/primary-upload.png';
import img_view from '../../../App/assets/img/open.png';
import img_bin from '../../../App/assets/img/bin.png';
import ComplainComponent from '../../../App/Views/Components/ComplainComponent';
import '../../Styles/EmployerComplaints.scss';
import EmployerComplaintReview from '../../Components/Employer/EmployerComplaintReview';
import EmployerComplaintSummary from '../../Components/Employer/EmployerComplaintSummary';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';

@observer
export default class EmployerComplaintsView extends Views.ViewBase<EmployerComplaintsVM> {

  constructor(props: unknown) {
    super("Employer Complaints", EmployerComplaintsVM, props);
  }

  private countryCodes = [
    { Id: 1, CountryCode: "+27" },
    { Id: 2, CountryCode: "+44" },
    { Id: 3, CountryCode: "+21" }
  ]

  public render() {
    return (
      <div className='employer-complaints'>
        {!this.viewModel.complaintReview && !this.viewModel.complaintSummary &&
          <div>
            <div className='background-card body-card-complaints'>
              <div className='row'>
                <div className='form-group col-12 col-md-12'>
                  <p className='card-header-query mt-2'>Have we disappointed you?</p>
                  <div>
                  </div>
                  <p className='card-header-complaint'>Please inform us how things went wrong, we will do our best to resolve it for you.</p>
                </div>
              </div>
              <div className='row btn-row'>
                <div className='align-contact-complain-buttons mt-2 col-6 col-md-6'>
                  <Neo.Button text="Contact us" id='btn-contact-us' onClick={(e) => this.viewModel.showComplain(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-complain' : 'col-6 btn btn-contact'} />
                  <Neo.Button text="Complain online" id='btn-complain' onClick={(e) => this.viewModel.showComplain(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-contact' : 'col-6 btn btn-complain'} />
                </div>
              </div>
            </div>
            <div className={this.viewModel.displayComplain ? 'hide-complain' : 'show-complain'}>
              <div className='background-card body-card-complaints-cu'>
                <div className='row'>
                  <div className='form-group col-12 col-md-12'>
                    <div className='contact-us'>
                      <ComplainComponent />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={this.viewModel.displayComplain ? 'show-complain' : 'hide-complain'}>
              <div className='background-card body-card-complaints-tabs'>
                <Neo.TabContainer className='complaints-tabs'>
                  <Neo.Tab header="Member">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className="search-bar"
                          bind={this.viewModel.meta.memberId}
                          bindDisplay={this.viewModel.meta.memberName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSearchResults}
                          valueMember="memberNumber"
                          displayMember='memberNameAndID'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.memberSelected(item)
                          }}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Member details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedMember.firstName} {this.viewModel.selectedMember.lastName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.memberId}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedMember.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedMember.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedMember.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedMember.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12 ">
                          <label className='form-label'>Complaint</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your complaint here'
                            rows={8}
                            value={this.viewModel.complaintTextMember.meta.complaint.value}
                            onChange={this.viewModel.handleMemberComplaintChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                onFilesSelected={() => { this.viewModel.showGreyBoxMember = true }}
                                allowedExtensions={[".pdf", ".doc", ".jpeg"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                            </div>
                            {this.viewModel.showGreyBoxMember &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='grey-upload-box-text'>
                                        Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyBoxMember = false} /></span>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <span className='grey-upload-box-text'>
                                        KFC.pdf
                                      </span>
                                      <span className='grey-upload-box-text floar-right'>
                                        50% Completed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplaintReview()}
                        primaryButtonText='Continue'
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                  <Neo.Tab header="Scheme">
                    <div className='row search-by'>
                      <Neo.Card className='col-md-10'>
                        <Neo.AutoCompleteDropDown
                          className='search-bar'
                          bind={this.viewModel.meta.schemeId}
                          bindDisplay={this.viewModel.meta.schemeName}
                          itemSource={this.viewModel.employerComplaintsApiClient.getEmployerComplaintsSchemeSearchResults}
                          valueMember="schemeNumber"
                          displayMember='schemeNameAndNumber'
                          placeholder="Search by name/number"
                          onItemSelected={(item) => {
                            this.viewModel.schemeSelected(item)
                          }}
                          typeToSearchPrompt=""
                        ></Neo.AutoCompleteDropDown>
                      </Neo.Card>
                      <Neo.Button className="col-md-2 btn-filter">Search</Neo.Button>
                    </div>
                    <div className='seperating-line' />
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='detail-cards'>
                          <div className='detail-cards-heading'>
                            Scheme details
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedScheme.schemeName}
                          </div>
                          <div className='detail-cards-text'>
                            {this.viewModel.selectedScheme.schemeNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='queries-css'>
                      <div className='pt-3 pb-3 row'>
                        <div className='form-group padding-fix-queries col-12 col-md-6'>
                          <label className='form-label'>Cell Number</label>
                          <div className='row p-0 col-md-12'>
                            <div className='country-dropdown col-2 col-md-2'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryValueId}
                                bindDisplay={this.viewModel.meta.countryValue}
                                items={this.countryCodes}
                              />
                            </div>
                            <div className='col-9 col-md-9'>
                              <Neo.FormGroup className='phone-control tel-control-queries' bind={this.viewModel.selectedScheme.meta.cellNumber} placeholder={"Cell number"} required></Neo.FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className='form-group padding-fix-queries  col-12 col-md-6'>
                          <label className='form-label'>Email Address</label>
                          <Neo.FormGroup className='phone-control email-control' bind={this.viewModel.selectedScheme.meta.emailAdress} placeholder={"Email"} required></Neo.FormGroup>
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className="form-group padding-fix-queries col-12 col-md-12">
                          <label className='form-label'>Complaint</label>
                          <textarea className="query-control form-control"
                            placeholder='Type your complaint here'
                            rows={8}
                            value={this.viewModel.complaintTextScheme.meta.complaint.value}
                            onChange={this.viewModel.handleSchemeComplaintChange} />
                        </div>
                      </div>
                      <div className='p-1 mt-4 row'>
                        <div className='col-12 padding-fix-queries col-md-12'>
                          <div className='upload-card card'>
                            <div className='row'>
                              <div className="form-group padding-fix-queries col-12 col-md-12">
                                <label className='mt-2 ms-2 form-label'>Upload document/s</label>
                              </div>
                            </div>
                            <div className="form-group col-12 padding-fix-queries col-md-12">
                              <label className='ms-2 form-label'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
                            </div>
                            <div className='form-group col-12 col-md-12'>
                              <Neo.FileContext
                                onFilesSelected={() => { this.viewModel.showGreyBoxScheme = true }}
                                allowedExtensions={[".pdf", ".doc", ".jpeg"]}>
                                <Neo.FileDropArea className='file-drop-area'>
                                  <i><img src={img_Upload} /></i>
                                  <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                                  <Neo.FileUploadProgress />
                                </Neo.FileDropArea>
                              </Neo.FileContext>
                            </div>
                            {this.viewModel.showGreyBoxScheme &&
                              <div>
                                < div className="form-group padding-fix-queries ms-2 mt-2 col-12 col-md-12">
                                  <label className='form-label'>Uploaded Document/s</label>
                                </div>
                                <div className='grey-upload-box'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='grey-upload-box-text'>
                                        Document <span className='float-right hover'><img src={img_view} /><img src={img_bin} onClick={() => this.viewModel.showGreyBoxScheme = false} /></span>
                                      </div>
                                    </div>
                                    <div className='grey-upload-box-line' />
                                    <div className='col-md-12'>
                                      <span className='grey-upload-box-text'>
                                        KFC.pdf
                                      </span>
                                      <span className='grey-upload-box-text floar-right'>
                                        50% Completed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <ContainerFooterComponent
                        backLinkText='Save & exit'
                        primaryButtonCallback={() => this.viewModel.showComplaintReview()}
                        primaryButtonText='Continue'
                        showPrimaryButton={true}
                        showSecondaryButton={true} />
                    </div>
                  </Neo.Tab>
                </Neo.TabContainer>
              </div>
            </div>
          </div>
        }
        {this.viewModel.complaintReview && !this.viewModel.complaintSummary &&
          <EmployerComplaintReview viewModel={this.viewModel} />
        }
        {this.viewModel.complaintSummary && !this.viewModel.complaintReview &&
          <EmployerComplaintSummary viewModel={this.viewModel} />
        }
      </div >
    );
  }
}