import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import LegacyMemberDashboardVM from './LegacyMemberDashboardVM';
import { observer } from 'mobx-react';
import "../../../../src/Dashboard/Styles/LegacyMemberDashboard.scss";
import ReactSpeedometer from 'react-d3-speedometer';
import img_RightBlack from '../../../App/assets/img/down-right-black.png';
import img_Right from '../../../App/assets/img/right-arrow-white.png';
import img_Plant from '../../../App/assets/img/plant.png';
import img_Umbrella from '../../../App/assets/img/icn-risk.png';
import img_Phone from '../../../App/assets/img/phone.png';
import img_Star from '../../../App/assets/img/star-big.png';
import img_Mail from '../../../App/assets/img/mail.png';
import img_QueriesBig from '../../../App/assets/img/Icon_Queries.png';
import img_Video from '../../../App/assets/img/live_tv.png';
import img_retirement_image_legacy from '../../../App/assets/img/retirement-image-legacy.png';
import '../../../App/Styles/Components/TAndCModal.scss';
import { SchemeProductClassType } from '../../../Party/Models/LookUps/SchemeProductClassType';
import { NumberUtils } from '@singularsystems/neo-core';

@observer
export default class LegacyMemberDashboardView extends Views.ViewBase<LegacyMemberDashboardVM> {

  private handleScroll() {
    let modalElement = document.getElementsByClassName('terms-conditions-modal');
    if (modalElement.length === 1) {
      let modalContentContainer = document.getElementById('modal-tc');
      if (modalContentContainer) {
        const contentContainerHeight = modalContentContainer.clientHeight;
        const contentContainerScrollTop = modalContentContainer.scrollTop;
        const ourContent = document.getElementById('modal-tc-content');
        const ourContentHeight = ourContent!.offsetHeight;
        const hasScrolledAll = ((contentContainerHeight + contentContainerScrollTop) > ourContentHeight);
        if (hasScrolledAll) {
          this.viewModel.agreeButtonCss = 'btn-primary';
        }
      }
    }
  }

  constructor(props: unknown) {
    super("Legacy Member Dashboard", LegacyMemberDashboardVM, props);
  }

  public render() {
    function segmentPlus(value: string): string {
      if (Number(value) < 90) {
        return `${value.toString()}`
      }
      return `${value.toString()} +`
    }
    return (

      <div className='legacy-member-dashboard-container'>
        {/* Terms and conditions modal  */}
        <div>
          {!this.viewModel.acceptedTAndC &&
            <div>
              <Neo.Modal
                size="lg"
                title='Liberty Corporate Terms & Conditions:'
                bind={this.viewModel.meta.showTermsModal}
                buttons={[
                  { text: "Download T&C's", className: 'btn-download', onClick: () => this.viewModel.downloadTermsAndConditions() },
                  { text: "Cancel", variant: "secondary", onClick: () => { this.viewModel.showTermsLogoutModal = true, this.viewModel.showTermsModal = false; } },
                  { text: "I agree", variant: "primary", onClick: () => this.viewModel.acceptTAndC(), className: this.viewModel.agreeButtonCss },
                ]}
                className='terms-conditions-modal'
                showCancelButton={false}>
                <div className='modal-content'>
                  {/* Commented for now */}
                  {/* <div className='modal-header'>
                    <Neo.Alert variant="primary" className='display-alert-terms-conditions' heading="Our Terms & Conditions have been updated.">
                    </Neo.Alert>
                  </div> */}
                  <p className='modal-text' id='modal-tc' onScroll={() => this.handleScroll()}>
                    <div id='modal-tc-content' dangerouslySetInnerHTML={{ __html: this.viewModel.tAndCTemplate.templateContent }} />
                  </p>
                </div>
              </Neo.Modal>

              <Neo.Modal
                bind={this.viewModel.meta.showTermsLogoutModal}
                title=' Are you sure you want to cancel?'
                acceptButton={{ text: "Yes", variant: "primary", onClick: () => this.viewModel.navigateToLogoutPage() }}
                closeButton={{ text: "No", variant: "danger", onClick: () => { this.viewModel.showTermsModal = true, this.viewModel.showTermsLogoutModal = false } }}
              >
                By doing so, you will be logged out and asked to log in again.

              </Neo.Modal>
            </div>
          }
        </div>
        {/* Dashboard  */}
        <div className='row'>
          <div className='col-md-7 fix-padding-dashboard-content'>
            <div className={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly ? 'dashboard-content hide-retirement-grid' : 'dashboard-content'}>
              <div className={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly ? 'hide-retirement' : 'my-retirement'}>
                <div className='my-retirement-content-container'>
                  <div className='my-retirement-content'>
                    <Neo.Card className={this.viewModel.netReplacementContainerClassName} hidden={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly}>
                      <div>
                        <h5 className="goal-card-title">My retirement projections
                          <i className='align-arrow'></i>
                        </h5>
                        <p className="goal-card-text">Summary of your retirement projections.</p>
                        <span className="card-text-fund-legacy">{this.viewModel.firstScheme?.schemeName}</span>
                        <div className='row goal-row'>
                          <div className='col-md-6 fix-padding-col'>
                            <Neo.Card className='net-replacement-ratio-card'>
                              <div className='net-card-header'>
                                <h5>Net replacement ratio (%) *</h5>
                                <i className='mt-0'>
                                  <a href='https://www.insiteeducation.co.za/systems/insite/useruploads/media/Liberty-CSUF_NNR%20Video_2023.mp4' target="_blank">
                                    <img src={img_Video} />
                                  </a>
                                </i>
                              </div>
                              <div className='speedometer-goal-card-dashboard'>
                                <ReactSpeedometer textColor='#FFF' minValue={0} maxValue={100}
                                  value={this.viewModel.firstScheme?.bestNetReplacementRatioSafe! > 100 ? 100 : this.viewModel.firstScheme?.bestNetReplacementRatioSafe!}
                                  needleHeightRatio={0.4} ringWidth={25} maxSegmentLabels={6}
                                  currentValueText={this.viewModel.firstScheme?.bestNetReplacementRatioSafe?.toString() + "%"}
                                  segmentValueFormatter={segmentPlus} segments={10} width={164} height={130} labelFontSize={'10'}
                                  valueTextFontSize={'15'} valueTextFontWeight={'600'}
                                  segmentColors={[
                                    "#F46363",
                                    "#F46363",
                                    "#F46363",
                                    "#F46363",
                                    "#F46363",
                                    "#FFAB00",
                                    "#FFAB00",
                                    "#84C83A",
                                    "#84C83A",
                                    "#84C83A",]}
                                  needleColor="#D9D9D9" />
                              </div>
                            </Neo.Card>
                          </div>
                          <div className='col-md-6 fix-padding-col'>
                            <Neo.Card onClick={() => this.viewModel.navigateToMyGoalsDetail(this.viewModel.firstScheme?.bestEstimatedMonthlyIncomeSafe!, this.viewModel.firstScheme?.schemeId!)}
                              className={this.viewModel.firstScheme?.bestNetReplacementRatioSafe! <= 49 ? 'monthly-income-card' : this.viewModel.firstScheme?.bestNetReplacementRatioSafe! < 70 ? 'monthly-income-card yellow' : 'monthly-income-card green'}>
                              <div className='net-card-header'>
                                <h5>Estimated monthly income at retirement</h5>
                                <i className='mt-0'><img src={img_Right} /></i>
                              </div>
                              <div className='monthly-income-card-content'>
                                <p>{this.viewModel.firstScheme?.bestEstimatedMonthlyIncomeSafe?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</p>
                                <div className='monthly-income-card-divider'></div>
                              </div>
                            </Neo.Card>
                          </div>
                        </div>
                        {this.viewModel.otherSchemes.map((item) => <div className={this.viewModel.replacementContainerClassName}>
                          <span className="card-text-fund-legacy">{item.schemeName}</span>
                          <div className='row goal-row'>
                            <div className='col-md-6 fix-padding-col'>
                              <Neo.Card className='net-replacement-ratio-card'>
                                <div className='net-card-header'>
                                  <h5>Net replacement ratio (%) *</h5>
                                  <i className='mt-0'>
                                    <a href='https://www.insiteeducation.co.za/systems/insite/useruploads/media/Liberty-CSUF_NNR%20Video_2023.mp4' target="_blank">
                                      <img src={img_Video} />
                                    </a>
                                  </i>
                                </div>
                                <div className='speedometer-goal-card-dashboard'>
                                  <ReactSpeedometer textColor='#FFF' minValue={0} maxValue={100}
                                    value={item.bestNetReplacementRatioSafe! > 100 ? 100 : item.bestNetReplacementRatioSafe!}
                                    needleHeightRatio={0.4} ringWidth={25} maxSegmentLabels={6}
                                    currentValueText={item.bestNetReplacementRatioSafe?.toString() + "%"}
                                    segmentValueFormatter={segmentPlus}
                                    segments={10} width={164} height={130} labelFontSize={'10'} valueTextFontSize={'15'} valueTextFontWeight={'600'}
                                    segmentColors={[
                                      "#F46363",
                                      "#F46363",
                                      "#F46363",
                                      "#F46363",
                                      "#F46363",
                                      "#FFAB00",
                                      "#FFAB00",
                                      "#84C83A",
                                      "#84C83A",
                                      "#84C83A",
                                    ]}
                                    needleColor="#D9D9D9" />
                                </div>
                              </Neo.Card>
                            </div>
                            <div className='col-md-6 fix-padding-col'>
                              <Neo.Card
                                onClick={() => this.viewModel.navigateToMyGoalsDetail(item.bestEstimatedMonthlyIncomeSafe!, item.schemeId!)}
                                className={item.bestNetReplacementRatioSafe! <= 49 ? 'monthly-income-card' : item.bestNetReplacementRatioSafe! < 70 ? 'monthly-income-card yellow' : 'monthly-income-card green'} >
                                <div className='net-card-header'>
                                  <h5>Estimated monthly income at retirement</h5>
                                  <i className='mt-0'><img src={img_Right} /></i>
                                </div>
                                <div className='monthly-income-card-content'>
                                  <p>{item.bestEstimatedMonthlyIncomeSafe?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</p>
                                  <div className='monthly-income-card-divider'></div>
                                </div>
                              </Neo.Card>
                            </div>
                          </div>
                        </div>
                        )}
                      </div>
                      <div className='card-footer display-goal-footer-legacy'>
                        <Neo.Button hidden={this.viewModel.otherSchemes.length < 1}
                          onClick={() => { this.viewModel.setShowMoreShowLess(); }}
                          text={this.viewModel.netReplacementContainerClassName.indexOf('collapsed') >= 0 ? "Show more" : "Show less"}
                          className='btn btn-primary btn-lg show-more-btn' />
                      </div>
                    </Neo.Card>
                  </div>
                </div>
              </div>
              <div className={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly ? 'my-products align-products-without-investments' :
                this.viewModel.memberLandingPageType == SchemeProductClassType.InvestmentOnly ? 'my-products my-products-with-investments-only' :
                  'my-products'}>
                <div className='my-product-content'>
                  <Neo.Card className='products-card'>
                    <h5 onClick={() => this.viewModel.navigateToProductsPage()} className="product-card-title">My products
                      <i className='align-arrow'><img className='align-arrow' src={img_RightBlack} /> </i>
                    </h5>
                    <div className='card-body align-product-inner-cards'>
                      <Neo.Card onClick={() => this.viewModel.navigateToProductsPage()} className='product-investment-card-legacy' hidden={!(this.viewModel.memberLandingPageType == SchemeProductClassType.RiskAndInvestment) && !(this.viewModel.memberLandingPageType == SchemeProductClassType.InvestmentOnly)}>
                        <div className='investment-content-legacy'>
                          <i><img src={img_Plant} /></i>
                          <div className='align-investment-legacy-heading'>
                            <h5 className='investment-heading-legacy'>Investments</h5>
                            <div>
                              <span>Total: {this.viewModel.totalInvestmentsAcrossAllSchemes.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</span>
                            </div>
                          </div>
                        </div>
                      </Neo.Card>
                      <Neo.Card onClick={() => this.viewModel.navigateToProductsPage()} className='product-inner-cards-legacy' hidden={!(this.viewModel.memberLandingPageType == SchemeProductClassType.RiskAndInvestment) && !(this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly)}>
                        <div className='align-product-card-content-legacy'>
                          <i className='align-risk-img-legacy'><img src={img_Umbrella} /></i>
                          <h5 className='align-risk-legacy'>Risk benefits</h5>
                        </div>
                      </Neo.Card>
                    </div>
                  </Neo.Card>
                </div>
              </div>
              <div className={(this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly) ? 'get-in-touch align-get-in-touch-no-investments' :
                (this.viewModel.memberLandingPageType == SchemeProductClassType.InvestmentOnly) ? 'get-in-touch align-get-in-touch-mobile-with-only-investments' : 'get-in-touch'}>
                <div className='my-get-in-touch-content-container'>
                  <div className='my-get-in-touch-content'>
                    <Neo.Card className='get-in-touch-card-legacy'>
                      <h5 className="get-in-touch-card-title">Get in touch </h5>
                      <div className='align-inner-cards-legacy'>
                        <Neo.Card className='inner-cards-legacy' onClick={() => this.viewModel.navigateToContactUsView()}>
                          <div className='card-body align-inner-card-content-legacy'>
                            <i><img className='align-queries-legacy' src={img_Phone} /></i>
                            <h5 className='align-contact-us-text'>Contact us</h5>
                          </div>
                        </Neo.Card>
                        <Neo.Card className='inner-cards-legacy' onClick={() => this.viewModel.navigateToQueriesView()}>
                          <div className='card-body align-inner-card-content-legacy'>
                            <i><img className='align-queries-legacy' src={img_QueriesBig} /></i>
                            <h5 className='align-contact-us-text'>Queries & compliments</h5>
                          </div>
                        </Neo.Card>
                        <Neo.Card className='inner-cards-legacy' onClick={() => this.viewModel.navigateToComplaintsView()}>
                          <div className='card-body align-inner-card-content-legacy'>
                            <i><img className='align-queries-legacy' src={img_Mail} /></i>
                            <h5 className='align-contact-us-text'>Complaints</h5>
                          </div>
                        </Neo.Card>
                        <Neo.Card className='inner-cards-legacy' onClick={() => this.viewModel.navigateToVASView()}>
                          <div className='card-body align-inner-card-content-legacy'>
                            <i><img className='align-queries-legacy' src={img_Star} /></i>
                            <h5 className='align-contact-us-text'>Value-added services</h5>
                          </div>
                        </Neo.Card>
                      </div>
                    </Neo.Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5 fix-col-padding'>
            <div className='dashboard-right-content'>
              <div className='savings-card' hidden={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly}>
                <div className='my-savings-content-container'>
                  <div className='my-savings-content'>
                    <Neo.Card
                      className={this.viewModel.savingsContainerClassName}
                      hidden={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly}>
                      <h5 className="savings-card-title">My savings component</h5>
                      <Neo.Card
                        onClick={() => this.viewModel.navigateToMySavingsComponent(this.viewModel.firstScheme?.schemeIdSafe!)}
                        className='savings-sub-card'
                        title={
                          <Neo.Tooltip alignment='start' content={this.viewModel.firstScheme?.schemeName ?? ''}>
                            <div className='align-savings-header-text'>
                              {this.viewModel.firstScheme?.schemeName}
                            </div>
                          </Neo.Tooltip>
                        }>
                        <div>
                          <div className='value-text'>
                            <span className='value-title'>Current value:</span>
                            <span className='amount-text'>
                              {NumberUtils.format(this.viewModel.firstScheme?.sofSavingsPot ?? 0, "R # ##0.00")}
                            </span>
                          </div>
                          <div className='accessibility-text'>
                            <span className='accessibility-title'>Accessibility status:</span>
                            {this.viewModel.firstScheme?.isEligible &&
                              <span className='accessibility-value'>Accessible</span>
                            }
                            {!this.viewModel.firstScheme?.isEligible &&
                              <span className='accessibility-value'>Not Accessible</span>
                            }
                          </div>
                        </div>
                      </Neo.Card>
                      {this.viewModel.otherSchemes.map((item) =>
                        <div className={this.viewModel.savingsContainerClassNameSecond}>
                          <Neo.Card
                            onClick={() => this.viewModel.navigateToMySavingsComponent(item.schemeIdSafe!)}
                            className='savings-sub-card'
                            title={
                              <Neo.Tooltip alignment='start' content={item.schemeName ?? ''}>
                                <div className='align-savings-header-text'>
                                  {item.schemeName}
                                </div>
                              </Neo.Tooltip>
                            }>
                            <div>
                              <div className='value-text'>
                                <span className='value-title'>Current value:</span>
                                <span className='amount-text'>
                                  {item.sofSavingsPot !== null ? NumberUtils.format(item.sofSavingsPot, "R # ##0.00") : 'R 0.00'}
                                </span>
                              </div>
                              <div className='accessibility-text'>
                                <span className='accessibility-title'>Accessibility status:</span>
                                {item.isEligible &&
                                  <span className='accessibility-value'>Accessible</span>
                                }
                                {!item.isEligible &&
                                  <span className='accessibility-value'>Not Accessible</span>
                                }
                              </div>
                            </div>
                          </Neo.Card>
                        </div>
                      )}
                      <div className='savings-link'>
                        <p className='align-savings-link-text' onClick={() => this.viewModel.navigateToSavingsWithdrawalClaimsPage()}>View your claim options</p>
                      </div>
                      <div className='card-footer savings-card-footer-button'>
                        <Neo.Button
                          hidden={this.viewModel.otherSchemes.length < 1}
                          onClick={() => { this.viewModel.setShowMoreShowLessSavings(); }}
                          text={this.viewModel.savingsContainerClassName.indexOf('collapsed') >= 0 ? "Show more" : "Show less"}
                          className='btn btn-primary btn-lg show-more-btn' />
                      </div>
                    </Neo.Card>
                  </div>
                </div>
              </div>
              <div className={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly ? 'my-profile my-profile-without-investments' : 'my-profile'} onClick={() => this.viewModel.navigateToProfileView()}>
                <div className='card my-profile-card'>
                  <h5 className="card-title mt-2 mb-1">My profile <i className='mt-0 me-3'><img className='align-arrow' src={img_RightBlack} /></i> </h5>
                  <p className="card-text">Update your profile with ease.</p>
                  <div className='card-footer progress-footer'>
                    <div className='align-progress'>
                      <span className='align-complete'>Complete</span>
                      {this.viewModel.profileVM.getMemberProfileCompleteness() >= 99.99 ? <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="success" />
                        : this.viewModel.profileVM.getMemberProfileCompleteness() >= 50 ? <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="warning" />
                          : <Neo.ProgressBar progress={this.viewModel.profileVM.getMemberProfileCompleteness() / 100} variant="danger" />}
                    </div>
                    <div className='align-span'>
                      <span className='box'>0%</span>
                      <span className='box'>50%</span>
                      <span className='box'>100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.viewModel.memberLandingPageType == SchemeProductClassType.RiskOnly ? 'my-beneficiaries my-beneficiaries-without-investments' : 'my-beneficiaries'} onClick={() => this.viewModel.navigateToBeneficiariesView()}>
                <div className='card my-profile-card'>
                  <h5 className="card-title mt-2 mb-1">My beneficiaries <i className='mt-0 me-3'><img className='align-arrow' src={img_RightBlack} /></i> </h5>
                  <p className="card-text">Update your beneficiaries with ease.</p>
                  <div className='card-footer progress-footer'>
                    <div className='align-progress'>
                      <span className='align-complete'>Complete</span>
                      {this.viewModel.percentageComplete >= 80 ? <Neo.ProgressBar progress={this.viewModel.percentageComplete / 100} variant="success" />
                        : this.viewModel.percentageComplete >= 30 ? <Neo.ProgressBar progress={this.viewModel.percentageComplete / 100} variant="warning" />
                          : <Neo.ProgressBar progress={this.viewModel.percentageComplete / 100} variant="danger" />}
                    </div>
                    <div className='align-span'>
                      <span className='box'>0%</span>
                      <span className='box'>50%</span>
                      <span className='box'>100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='get-in-touch-container-mobile'>
            <Neo.Card className='get-in-touch-card-legacy-mobile'>
              <h5 className="get-in-touch-card-title-mobile">Get in touch </h5>
              <div className='align-inner-cards-legacy-mobile'>
                <Neo.Card className='inner-cards-legacy-mobile' onClick={() => this.viewModel.navigateToContactUsView()}>
                  <div className='card-body align-inner-card-content-legacy-mobile'>
                    <i><img className='align-queries-legacy-mobile' src={img_Phone} /></i>
                    <h5 className='align-contact-us'>Contact us</h5>
                  </div>
                </Neo.Card>
                <Neo.Card className='inner-cards-legacy-mobile' onClick={() => this.viewModel.navigateToQueriesView()}>
                  <div className='card-body align-inner-card-content-legacy-mobile'>
                    <i><img className='align-queries-compliments-legacy-mobile' src={img_QueriesBig} /></i>
                    <h5 className='align-queries-compliments-text-legacy-mobile'>Queries & Compliments </h5>
                  </div>
                </Neo.Card>
                <Neo.Card className='inner-cards-legacy-mobile' onClick={() => this.viewModel.navigateToComplaintsView()}>
                  <div className='card-body align-inner-card-content-legacy-mobile'>
                    <i><img className='align-queries-legacy-mobile' src={img_Mail} /></i>
                    <h5>Complaints</h5>
                  </div>
                </Neo.Card>
                <Neo.Card className='inner-cards-legacy-mobile' onClick={() => this.viewModel.navigateToVASView()}>
                  <div className='card-body align-inner-card-content-legacy-mobile'>
                    <i><img className='align-vas-legacy-mobile' src={img_Star} /></i>
                    <h5 className='align-vas-text-legacy-mobile'>Value-added services</h5>
                  </div>
                </Neo.Card>
              </div>
            </Neo.Card>
          </div>
        </div>
      </div>
    );
  }
}