/* tslint:disable:max-classes-per-file */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Scrollbar from 'react-custom-scrollbars';
import { AppService, Types } from '../Services/AppService';
import { IAppLayout, ScreenSize } from '../Services/AppLayout';
import img_liberty_logo from '../assets/img/liberty-logo-std-bank.png';
import img_up from "../assets/img/down-white-up.png";
import img_down from "../assets/img/down-white.png";
import img_Bell from "../assets/img/bell-white.png";
import NotificationsView from '../Views/NotificationsView';
import img_ellipse from '../assets/img/icn-ellipse.png';
import img_edit from '../assets/img/edit-white.png';
import ProfileView from '../Views/MyPortfolio/ProfileView';
import MemberIcon from '../Views/Components/MemberIcon';
import { Neo } from '@singularsystems/neo-react';
import { IAppMenuItem } from '../Services/RouteService';
import { UserRoleType } from '../../Common/Models/Enum/UserRoleType.enum';

interface ISidebarProps {
  /**
   * If set to true, will make sub-menu items the same size as parent items.
   */
  fixedItemSize?: boolean;
}

@observer
class Sidebar extends React.Component<ISidebarProps> {

  private routeService = AppService.get(Types.App.Services.RouteService);
  private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
  private appLayout = AppService.get(Types.App.Services.AppLayout);
  private authService = AppService.get(Types.App.Services.AuthenticationService);
  private userRoleService = AppService.get(Types.App.Services.UserRoleService);
  private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService);
  private masterDataService = AppService.get(Types.App.Services.MasterDataService);

  private menuContainer: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.menuContainer = React.createRef<HTMLDivElement>();
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
  }

  private mouseEnter() {
    this.appLayout.menuHover(true);
  }

  private mouseLeave() {
    this.appLayout.menuHover(false);
  }

  public navigateNotification(): void {
    this.navigation.navigateToView(NotificationsView);
  }

  public navigateToProfile(): void {
    this.navigation.navigateToView(ProfileView);
  }

  public render() {
    const user = this.authService.user;
    const layout = this.appLayout;
    const menuItemProvider = AppService.get(Types.Neo.Routing.MenuItemProvider);
    const menuItems = menuItemProvider.processRoutes(this.routeService.getMainMenuRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const supportMenuItems = menuItemProvider.processRoutes(this.routeService.getSupportRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const supportSettingsItems = menuItemProvider.processRoutes(this.routeService.getSettingRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const EmployerSettingsItems = menuItemProvider.processRoutes(this.routeService.getEmployerRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const EmployerSupportSettingsItems = menuItemProvider.processRoutes(this.routeService.getEmployerSupportRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const FASettingsItems = menuItemProvider.processRoutes(this.routeService.getFARoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const StaffSettingsItems = menuItemProvider.processRoutes(this.routeService.getStaffRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const LegacyMemberSettingsItems = menuItemProvider.processRoutes(this.routeService.getLegacyMemberRoutes(), { collapseSingle: true, hideWhenEmpty: true });
    const FAAssistantSettingsItems = menuItemProvider.processRoutes(this.routeService.getFAAssistantRoutes(), { collapseSingle: true, hideWhenEmpty: true });

    return (
      user &&
      <div>
        <div ref={this.menuContainer}
          id="left-panel"
          className="app-left-panel"
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}>
          <div className={"sidebar" + (this.props.fixedItemSize ? " sidebar-fixed-item-size" : "")}>
            <div className="sidebar-header">
              <div className="logo">
                <img className='sidebar-logo-img' src={img_liberty_logo} alt="logo" />
                <div className="divider divider-top"></div>
                {this.userRoleService.loggedInUser.userRoleType == UserRoleType.Member &&
                  <Neo.Link className='align-edit-link' onClick={() => this.navigateToProfile()}>
                    <i><img className='align-edit-icon' src={img_edit} /></i>
                    Edit
                  </Neo.Link>}
                <div className={(this.userRoleService.loggedInUser.userRoleType == UserRoleType.Member ? 'user-display-edit' : 'user-display')}>
                  <div className="profile-image">
                    <MemberIcon firstName={user.userData.profile.given_name} lastName={user.userData.profile.family_name} className='profile-icon'></MemberIcon>
                  </div>
                  <div className="user-info">
                    <p>{user.userData.profile.given_name} {user.userData.profile.family_name}</p>
                  </div>
                </div>
                <div className="divider"></div>
                {this.userRoleService.loggedInUser.userRoleType != UserRoleType.Member &&
                  <div><div className="divider-thin-sidebar"></div>
                    <div className="display-notification">
                      <div className='row align-notifications'>
                        <div className='col-md-1 bell'>
                          <a href="" onClick={() => this.navigation.navigateToView(NotificationsView)}>
                            <i className="fas fa-bell fa-2x icon"></i>
                            <span className="position-absolute badge rounded-pill badge-notification notification-bubble">129</span>
                          </a>
                        </div>
                        <div className='col-md-4 notifications-text'>Notifications</div>
                      </div>
                    </div>
                  </div>
                }
                <div className="divider-thin-sidebar"></div>
              </div>
            </div>
            {(!this.userRoleService.getHasInitialised() || !this.userMemberSchemeService.getHasInitialised() || !this.masterDataService.getHasInitialised()) &&
              <div className="sidebar-content">
                <h5>
                  Loading...
                </h5>
              </div>}
            {this.userRoleService.getHasInitialised() && this.userMemberSchemeService.getHasInitialised() && this.masterDataService.getHasInitialised() &&
              <div className="sidebar-content">
                <h5>
                  Menu
                </h5>
                {/* Full screen menu */}
                {layout.currentScreenSize > ScreenSize.Medium &&
                  <Scrollbar height="100%" hideTracksWhenNotNeeded autoHeightMax={800}>
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.Member &&
                      <div>
                        <Menu items={LegacyMemberSettingsItems} level={1} appLayout={this.appLayout} />
                        <div>
                          <h5>Support</h5>
                          <Menu items={supportMenuItems} level={1} appLayout={this.appLayout} />
                        </div>
                      </div>
                    }


                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.Employer &&
                      <div className='employer-menu-item'>
                        <Menu items={EmployerSettingsItems} level={1} appLayout={this.appLayout} />
                        {/* TODO: Uncomment for R4.2  */}
                        {/* <div>
                          <h5>Support</h5>
                          <Menu items={EmployerSupportSettingsItems} level={1} appLayout={this.appLayout} />
                        </div> */}
                      </div>
                    }
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.FinancialAdviser &&
                      <div className='employer-menu-item'>
                        <Menu items={FASettingsItems} level={1} appLayout={this.appLayout} />
                        <div>
                        </div>
                      </div>
                    }
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.Staff &&
                      <div className='employer-menu-item'>
                        <Menu items={StaffSettingsItems} level={1} appLayout={this.appLayout} />
                        <div>
                        </div>
                      </div>
                    }
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.FinancialAdviserAssistant &&
                      <div className='employer-menu-item'>
                        <Menu items={FAAssistantSettingsItems} level={1} appLayout={this.appLayout} />
                        <div>
                        </div>
                      </div>
                    }
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.LibertyNonLegacyMember &&
                      <div>
                        <Menu items={menuItems} level={1} appLayout={this.appLayout} />
                        <div>
                          <h5>Support</h5>
                          <Menu items={supportMenuItems} level={3} appLayout={this.appLayout} />
                        </div>
                      </div>
                    }
                  </Scrollbar>}

                {/* Medium screen menu */}
                {layout.currentScreenSize <= ScreenSize.Medium &&
                  <Scrollbar height="100%" hideTracksWhenNotNeeded autoHeight={true} autoHeightMax={800}>
                    {this.userRoleService.loggedInUser.userRoleType == UserRoleType.Member &&
                      <div>
                        <Menu items={menuItems} level={1} appLayout={this.appLayout} />
                        <div>
                          <h5>Support</h5>
                          <Menu items={supportMenuItems} level={1} appLayout={this.appLayout} />
                        </div>
                      </div>
                    }
                  </Scrollbar>
                }
              </div>}
            <div className="sidebar-menu-footer">
              <div>
                <h5>Settings</h5>
                <Menu items={supportSettingsItems} level={1} appLayout={this.appLayout} />
              </div>
              <div className="divider"></div>
              <div className="info">Last login: {this.userRoleService.getLastLogin()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
interface IMenuProps {
  appLayout: IAppLayout;
  items: IAppMenuItem[];
  level: number;
}

@observer
class Menu extends React.Component<IMenuProps> {

  public render() {

    return (
      <ul className={"menu-level-" + this.props.level}>
        {this.props.items.map(item => <MenuItem key={item.path || item.name} item={item} level={this.props.level} appLayout={this.props.appLayout} />)}
      </ul>
    )
  }
}

interface IMenuItemProps {
  appLayout: IAppLayout;
  item: IAppMenuItem;
  level: number;
}

@observer
class MenuItem extends React.Component<IMenuItemProps> {

  @observable.ref
  public isExpanded = false;

  private settingsKey: string;

  constructor(props: IMenuItemProps) {
    super(props);

    this.settingsKey = `menu-expand:${props.level}:${props.item.name}`;

    if (props.item.children) {
      const settingValue = localStorage.getItem(this.settingsKey);

      if (settingValue === "1" || props.item.expanded) {
        this.isExpanded = true;
      }
    }

    this.onExpanderClick = this.onExpanderClick.bind(this);
  }

  private onExpanderClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (this.props.item.children) {
      e.stopPropagation();
      this.isExpanded = !this.isExpanded;
      localStorage.setItem(this.settingsKey, this.isExpanded ? "1" : "0");
      this.forceUpdate();
    }
  }

  public render() {
    const item = this.props.item;
    const hasChildren = !!item.children;

    let fakeIconText = "";
    if (!item.icon) {
      let matches = item.name.match(/\b(\w)/g);
      let acronym = matches?.join('');
      if (acronym) {
        fakeIconText = acronym.substr(0, 2);
      }
    }

    // Comment this back to use FontAwesome icons.
    // let icon = item.icon ? <i className={"sidebar-icon fa-fw " + Utils.getFontAwesomeClass(item.icon)} /> : <span className="sidebar-icon fake-icon">{fakeIconText}</span>;
    const icon = item.icon ? <i className={"sidebar-icon fake-icon"}><img src={item.icon} /></i> : <i className={"sidebar-icon fake-icon"}></i>;

    let text = <span className="menu-item-text">{item.name}</span>;
    let itemContent = <>
      <div className="sidebar-icon-container" onClick={item.onClick ? item.onClick : this.onExpanderClick}>
        {icon}
        {hasChildren && <div className="sidebar-mini-expander">
          <img src={(this.isExpanded ? img_up : img_down)} />
        </div>}
      </div> {text}
    </>

    return (
      <li>
        <div className={"menu-item" + (hasChildren ? " has-children" : "") + (this.isExpanded ? " is-expanded" : "")}>

          {item.path ?
            <NavLink exact={item.exact || this.isExpanded} to={item.path}>
              {itemContent}
            </NavLink> :
            <span className="sidebar-icon-container static-item" onClick={item.onClick ? item.onClick : this.onExpanderClick}>
              {itemContent}
            </span>
          }

          {hasChildren &&
            <div className={"menu-expander " + (this.isExpanded ? "expanded" : "collapsed")} onClick={item.onClick ? item.onClick : this.onExpanderClick}>
              <img src={(this.isExpanded ? img_up : img_down)} />
            </div>
          }
        </div>

        {this.isExpanded && item.children && <Menu items={item.children} level={this.props.level + 1} appLayout={this.props.appLayout} />}
      </li>)
  }
}

export default Sidebar;