import React from 'react';
import ProductsVM from './ProductsVM';
import { observer } from 'mobx-react';
import '../../Styles/Products.scss';
import icn_pdf from '../../../App/assets/img/icon-pdf.png';
import { Neo, Views } from '@singularsystems/neo-react';
import SchemeInformationHeader from '../../../App/Views/Components/SchemeInformationHeader';
import ReportsAndDocumentsView from '../../../App/Views/ReportsAndDocumentsView';
import { ReportType } from '../../../Enums/ReportType.enum';
import RiskBenefitsTab from '../../Components/Member/RiskBenefits/RiskBenefitsTab';
import InvestmentsTab from '../../Components/Member/InvestmentsTab';
import ProductDetailsTab from '../../Components/Member/ProductDetailsTab';
import RiskBenefitsModal from '../../Components/Member/RiskBenefits/RiskBenefitsModal';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import { SchemeProductClassType } from '../../../Party/Models/LookUps/SchemeProductClassType';
import { UserRoleType } from '../../../Common/Models/Enum/UserRoleType.enum';

@observer
export default class Products extends Views.ViewBase<ProductsVM> {

  constructor(props: unknown) {
    super("My products", ProductsVM, props);
  }

  public render() {
    const viewModel = this.viewModel;
    return (
      <div className='product-css'>
        <SchemeInformationHeader schemeHeader={'Select a scheme for more information'}
          memberName={'Benji'}
          showClaimButton={UserRoleType.Member ? false : true}
          extraCssClasses=''
          onSchemeSelected={async (scheme) => {
            await this.viewModel.showOrHideTabs(scheme);
            await this.viewModel.setSchemeInfo(scheme);
            await this.viewModel.getContributionBillingData();
            await this.viewModel.getMemberDetailsData();
            await this.viewModel.getMemberInvestmentData();
            await this.viewModel.getMemberBenefitsData();
            await this.viewModel.getMemberDependentBenefitsData();
          }} />
        <div >
          <div className='background-card products-view'>
            <div className='card-heading-products'>
              Select product information to view
            </div>
            <div className='tabs-dropdown-products'>
              <div className='dropdown-header-products'> Product </div>
              <Neo.AutoCompleteDropDown onItemSelected={() => viewModel.tabValue === "Risk benefits" ? this.viewModel.showRiskBenefitModal = true : ""}
                bind={viewModel.meta.tabValueId}
                bindDisplay={viewModel.meta.tabValue}
                items={this.viewModel.tabCodes} />
            </div>
            {!this.viewModel.IsPreservationScheme ? (
              <div className='member-investment-summary-products'>
                {(this.viewModel.schemeProductClassType == SchemeProductClassType.RiskOnly || this.viewModel.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) &&
                  <div className='align-benefit-summary'>
                    <img src={icn_pdf} className="pdf-icon-product" />
                    <Neo.Link onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.MemberBenefitStatement }); }}>Member benefit statement</Neo.Link>
                  </div>
                }
                {(this.viewModel.schemeProductClassType == SchemeProductClassType.InvestmentOnly || this.viewModel.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) &&
                  <div className='align-investment-summary'>
                    <img src={icn_pdf} className="pdf-icon-product" />
                    <Neo.Link onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.MemberInvestmentStatement }); }}>Member investment statement</Neo.Link>
                  </div>
                }
              </div>) : (
              <div className='member-investment-summary-products'>
                <div className='align-benefit-summary'>
                  <img src={icn_pdf} className="pdf-icon-product" />
                  <Neo.Link onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.PaidupMemberCertificate }); }}>Paid-up member certificate</Neo.Link>
                </div>
                <div className='align-investment-summary'>
                  <img src={icn_pdf} className="pdf-icon-product" />
                  <Neo.Link onClick={() => { this.navigation.navigateToView(ReportsAndDocumentsView, { reportType: ReportType.PaidupMemberInvestmentStatement }); }}>Paid-up member investment statement</Neo.Link>
                </div>
              </div>
            )}
            {this.viewModel.schemeProductClassType == SchemeProductClassType.RiskAndInvestment &&
              <Neo.TabContainer variant="pills" selectedTab={viewModel.meta.tabValue}>
                <InvestmentsTab viewModel={viewModel} />
                <RiskBenefitsTab viewModel={viewModel} />
                <ProductDetailsTab viewModel={viewModel} />
              </Neo.TabContainer>
            }
            {this.viewModel.schemeProductClassType == SchemeProductClassType.InvestmentOnly &&
              <div className='mt-4'>
                <Neo.TabContainer variant="pills" selectedTab={viewModel.meta.tabValue}>
                  <InvestmentsTab viewModel={viewModel} />
                  <ProductDetailsTab viewModel={viewModel} />
                </Neo.TabContainer>
              </div>
            }
            {this.viewModel.schemeProductClassType == SchemeProductClassType.RiskOnly &&
              <div className='mt-4'>
                <Neo.TabContainer variant="pills" selectedTab={viewModel.meta.tabValue}>
                  <RiskBenefitsTab viewModel={viewModel} />
                  <ProductDetailsTab viewModel={viewModel} />
                </Neo.TabContainer>
              </div>
            }
            <ContainerFooterComponent />
            <RiskBenefitsModal viewModel={viewModel} navigation={this.navigation} />
          </div >
        </div >
      </div >
    );
  }
}