import React from 'react';
import { observer } from 'mobx-react';
import ProductsVM from '../../Views/Member/ProductsVM';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import blueWarning from '../../../App/assets/img/blue-Warning.png';

interface IProductDetailsTabProps {
  viewModel: ProductsVM
}

@observer
export default class ProductDetailsTab extends React.Component<IProductDetailsTabProps> {

  constructor(props: IProductDetailsTabProps) {
    super(props);
  }

  public render() {
    const viewModel = this.props.viewModel;

    return (
      <Neo.Tab header={<div className='card-tab-text-products '>Product details</div>} name="Product details" id="Product details">
        <div className='line-products' />
        <BlueRibbon
          text='Below is a high-level breakdown of contributions and associated fees. For more detail, please view your Member benefit statement and Member investment summary.'
        />
        <div className='row contribution-cards-cb'>
          <div className='col-sm-12 col-md-6'>
            <Neo.Card className='contribution-card-cb' title={
              <div className='card-heading-cb'>Contribution & fees breakdown
                <br />
                {!this.props.viewModel.IsPreservationScheme &&
                  <span>
                    <span className='card-sub-heading-cb'> Breakdown as at: </span><span className='card-sub-heading-cb regular'>
                      {viewModel.contributionBillingData.billDueDateFormatted}
                    </span>
                  </span>
                }
                <br /><span className='card-header-height-cb'>blank</span>
              </div>
            } >
              <NeoGrid.Grid items={viewModel.contributionBillingData.contributionDetails} initialSort="order" initialSortAscending>
                {(item, meta) => (
                  <NeoGrid.Row>
                    <NeoGrid.Column className='column-contribution-expenses-cb left-align' display={meta.groupName} footerContent={(t) => "Total"} >
                      {item.groupName == 'Your Contribution' &&
                        <img src={blueWarning} className="pdf-icon-product hover" onClick={() => this.props.viewModel.showYourContributionsModal = true} />
                      }
                      {item.groupName == 'Additional Voluntary Contributions' &&
                        <img src={blueWarning} className="pdf-icon-product hover" onClick={() => this.props.viewModel.showAdditionalVoluntaryContributionsModal = true} />
                      }
                      {item.groupName == 'Employer�s net contribution' &&
                        <img src={blueWarning} className="pdf-icon-product hover" onClick={() => this.props.viewModel.showEmployersNetContributionModal = true} />
                      }
                    </NeoGrid.Column>
                    <NeoGrid.Column className='column-contribution-expenses-cb right-align' display={meta.amount} sum
                      numProps={{ formatString: "R # ##0,00" }} />
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </Neo.Card>
          </div>
          <div className='col-sm-12 col-md-6'>
            <Neo.Card className='contribution-card-cb' title={<div className='card-heading-cb'>My details
              <br></br> <span className='card-sub-heading-cb'> Scheme number:</span><span className='card-sub-heading-cb regular'> {viewModel.schemeNumber}</span><span className='pipe-separator'> &nbsp; | &nbsp; </span>
              <span className='phone-line-break-contribution'><br /></span>
              <span className='card-sub-heading-cb'> Member number:</span><span className='card-sub-heading-cb regular'>{viewModel.memberNo} </span>
              <br /> <span className='card-sub-heading-cb'> Employee number:</span><span className='card-sub-heading-cb regular'> {viewModel.memberDetailsDetails.payrollNo}</span></div>} >
              <table className='table neo-grid table-bordered'>
                <tbody>
                  <tr>
                    <td className='column-details-cb left-align col-md-6'>
                      Date of birth
                    </td>
                    <td className="column-details-cb right-align ">
                      {viewModel.userRoleService.personContactDetailsLookup.birthDateStringFormat}
                    </td>
                  </tr>
                  <tr>
                    <td className='column-details-cb left-align'>
                      ID/ Passport
                    </td>
                    <td className="column-details-cb right-align">
                      {viewModel.userRoleService.personContactDetailsLookup.idOrPassport}
                    </td>
                  </tr>
                  <tr>
                    <td className='column-details-cb left-align'>
                      Marital status
                    </td>
                    <td className="column-details-cb right-align">
                      {viewModel.userRoleService.personContactDetailsLookup.maritalStatus}
                    </td>
                  </tr>
                  {!this.props.viewModel.IsPreservationScheme &&
                    <tr>
                      <td className='column-details-cb left-align'>
                        Annual remuneration
                      </td>
                      <td className="column-details-cb right-align ">
                        {viewModel.memberDetailsDetails.totalAnnualSalary.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                      </td>
                    </tr>
                  }
                  {!this.props.viewModel.IsPreservationScheme &&
                    <tr>
                      <td className='column-details-cb left-align'>
                        Date of joining company
                      </td>
                      <td className="column-details-cb right-align">
                        {viewModel.memberDetailsDetails.employmentDate}
                      </td>
                    </tr>
                  }
                  {!this.props.viewModel.IsPreservationScheme &&
                    <tr>
                      <td className='column-details-cb left-align'>
                        Date of joining scheme
                      </td>
                      <td className="column-details-cb right-align">
                        {viewModel.memberDetailsDetails.schemeJoinDate}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td className='column-details-cb left-align'>
                      Normal retirement date
                    </td>
                    <td className="column-details-cb right-align">
                      {viewModel.memberDetailsDetails.normalRetirementDate}
                    </td>
                  </tr>
                  <tr>
                    <td className='column-details-cb left-align'>
                      Normal retirement age
                    </td>
                    <td className="column-details-cb right-align ">
                      {viewModel.memberDetailsDetails.retirementAge}
                    </td>
                  </tr>
                  {!this.props.viewModel.IsPreservationScheme &&
                    <tr>
                      <td className='column-details-cb left-align'>
                        Membership category
                      </td>
                      <td className="column-details-cb right-align">
                        {viewModel.memberDetailsDetails.memberGroupName}
                      </td>
                    </tr>
                  }
                  {!this.props.viewModel.IsPreservationScheme &&
                    <tr>
                      <td className='column-details-cb left-align'>
                        Paypoint
                      </td>
                      <td className="column-details-cb right-align">
                        {viewModel.memberDetailsDetails.paypoint}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Neo.Card>
          </div>
        </div>
        <Neo.Modal
          title='Your contribution'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showYourContributionsModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showYourContributionsModal}>
          This is the regular monthly amount (as agreed with your employer) that your employer deducts from your salary and pays to the scheme providing your member benefits.
        </Neo.Modal>
        <Neo.Modal
          title='Additional Voluntary Contributions'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showAdditionalVoluntaryContributionsModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showAdditionalVoluntaryContributionsModal}>
          These are the extra contributions made into your retirement fund, at your discretion, over and above the regular contributions already made by you and your employer.
        </Neo.Modal>
        <Neo.Modal
          title='Employer�s net contribution'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showEmployersNetContributionModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showEmployersNetContributionModal}>
          This is a portion of the regular monthly amount that your employer pays to the scheme providing your member benefits, after deducting management fees and risk benefit costs.
        </Neo.Modal>
      </Neo.Tab>
    );
  }
}