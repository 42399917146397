import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DashboardTypes';
import SearchCriteria from '../../Models/Lookups/SearchWidget/SearchCriteria';
import MemberSearchDataLookup from '../../Models/Lookups/SearchWidget/MemberSearchDataLookup';
import SchemeSearchDataLookup from '../../Models/Lookups/SearchWidget/SchemeSearchDataLookup';

export interface ISearchWidgetApiClient {

  /**
   * Returns the Employer Dashboard Member Search Results.
   * @returns Paged List Of MemberSearchDataLookup.
   */
  getMemberSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberSearchDataLookup>>>;

  /**
   * Returns the Employer Dashboard Scheme Search Results.
   * @returns Paged List Of SchemeSearchDataLookup.
   */
  getSchemeSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SchemeSearchDataLookup>>>;

  /** 
   * Gets all the member search data lookups linked to a person for the current user.
   * @param criteria The search criteria.
   */
  getPersonMemberSearchDataLookups(criteria: Model.PartialPlainNonTrackedObject<SearchCriteria>): AxiosPromise<Array<Model.PlainObject<MemberSearchDataLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class SearchWidgetApiClient extends Data.ApiClientBase implements ISearchWidgetApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/SearchWidget`);
  }

  public getMemberSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<MemberSearchDataLookup>>> {
    return this.axios.get(`${this.apiPath}/member-search-list-paged?${Utils.getQueryString(request)}`);
  }

  public getSchemeSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SchemeSearchDataLookup>>> {
    return this.axios.get(`${this.apiPath}/scheme-search-list-paged?${Utils.getQueryString(request)}`);
  }

  public getPersonMemberSearchDataLookups(criteria: Model.PartialPlainNonTrackedObject<SearchCriteria>): AxiosPromise<Array<Model.PlainObject<MemberSearchDataLookup>>> {
    return this.axios.get(`${this.apiPath}/person-member-search-data-lookups?${Utils.getQueryString(criteria)}`);
  }

  // Client only properties / methods
}